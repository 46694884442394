import React, { FC } from 'react';
import ButtonsNextPrev from 'src/pages/businessOffice/Claim/components/ButtonsNextPrev';
import { Form } from 'react-final-form';
import { FormCounterpartyWrapper } from 'src/pages/businessOffice/Claim/components/FormCounterparty/styles';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { enumTabsConnection, IStep } from 'src/pages/businessOffice/Claim/components/MainConnectionPayment';
import { useSelector } from 'react-redux';
import { getCurrentCounterparty } from 'src/store/Counterparty/selectors';
import useValidationSchema from 'src/hooks/useValidationSchema';
import { counterpartyPaymentSchema } from 'src/pages/businessOffice/Claim/validations';
import { ICounterparty } from 'src/store/Counterparty/types';
import { useAppDispatch } from 'src/store';
import { updateCounterparty } from 'src/store/Counterparty';
import { removePlusAndGapsToPhone } from 'src/utils/formatters';
import dayjs from 'dayjs';
import { DATE_YYYY_MM_DD } from 'src/utils/constants';
import CounterpartyInputs from 'src/pages/businessOffice/Claim/components/CounterpartyInputs';
import useLoadingSubmit from 'src/hooks/useLoadingSubmit';

const FormCounterparty: FC<IStep> = ({ setStep }) => {
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const dispatch = useAppDispatch();
    const validation = useValidationSchema(counterpartyPaymentSchema);
    const counterparty = useSelector(getCurrentCounterparty);

    const onClickBack = () => {
        setStep(enumTabsConnection.tariff);
    };

    const onSubmit = async (formValues: ICounterparty) => {
        toggleLoadingSubmit();
        const data = await dispatch(
            updateCounterparty({
                ...formValues,
                [COUNTERPARTY_FIELDS.phone]: removePlusAndGapsToPhone(formValues[COUNTERPARTY_FIELDS.phone]),
                [COUNTERPARTY_FIELDS.registrationDate]: dayjs(formValues[COUNTERPARTY_FIELDS.registrationDate]).format(
                    DATE_YYYY_MM_DD,
                ),
            }),
        );

        // @ts-ignore
        if (!data?.error && data?.payload) {
            setStep(enumTabsConnection.bankDetails);
        }
        toggleLoadingSubmit();
    };

    return (
        <Form onSubmit={onSubmit} initialValues={counterparty} validate={validation}>
            {({ handleSubmit, valid }) => (
                <FormCounterpartyWrapper>
                    <CounterpartyInputs />
                    <ButtonsNextPrev
                        isLoading={isLoadingSubmit}
                        isActiveNextButton={valid}
                        onClickPrev={onClickBack}
                        onClickNext={handleSubmit}
                    />
                </FormCounterpartyWrapper>
            )}
        </Form>
    );
};

export default FormCounterparty;
