import React, { FC } from 'react';
import { IFeedItem } from 'src/store/Feed/types';
import { Description, ModalFeedWrapper, Title } from 'src/components/Widgets/FeedWidget/components/ModalFeed/styles';

interface IModalFeed {
    onClose: () => void;
    isOpen: boolean;
    feed: IFeedItem;
}

const ModalFeed: FC<IModalFeed> = ({ onClose, isOpen, feed }) => (
    <ModalFeedWrapper isOpen={isOpen} closeModal={onClose}>
        <Title variant="title">{feed?.title}</Title>
        <Description variant="regular14">{feed?.description}</Description>
    </ModalFeedWrapper>
);

export default ModalFeed;
