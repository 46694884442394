import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { IS_DEV, IS_SKIP_INTL_ERROR } from 'src/utils/constants';
import App from './App';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line no-console
const consoleError = console.error.bind(console);
// eslint-disable-next-line no-console
console.error = (message, ...args) => {
    if (IS_SKIP_INTL_ERROR && IS_DEV && message.toString().startsWith('Error: [@formatjs/intl')) {
        return;
    }

    consoleError(message, ...args);
};

if (!IS_DEV) {
    Sentry.init({
        dsn: 'https://749ffa466e7b972d1db308124a2e3db4@sentry.invoicebox.ru/4',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
