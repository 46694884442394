import { assert } from 'src/utils/assert';
import { TCurrencyResponse } from './types';

type TNameShortPos = 'L' | 'R';

export class Currency {
    private readonly id: string;
    private readonly number: number;
    private readonly name: string;
    private readonly nameShort: string;
    private readonly nameShortPos: TNameShortPos;
    private readonly nameI18n: string;
    private readonly minorUnits: number;

    private constructor(state: {
        id: string;
        number: number;
        name: string;
        nameShort: string;
        nameShortPos: TNameShortPos;
        nameI18n: string;
        minorUnits: number;
    }) {
        const { id, number, name, nameShort, nameShortPos, nameI18n, minorUnits } = state;

        this.id = id;
        this.number = number;
        this.name = name;
        this.nameShort = nameShort;
        this.nameShortPos = nameShortPos;
        this.nameI18n = nameI18n;
        this.minorUnits = minorUnits;
    }

    static fromResponse(response: unknown): Currency {
        assert(typeof response === 'object' && response !== null);

        assert('id' in response);
        const { id } = response;
        assert(typeof id === 'string');
        assert(!!id.length);

        assert('number' in response);
        const { number } = response;
        assert(typeof number === 'number');
        assert(Number.isInteger(number));
        assert(number > 0);

        assert('name' in response);
        const { name } = response;
        assert(typeof name === 'string');
        assert(!!name.length);

        assert('nameShort' in response);
        const { nameShort } = response;
        assert(typeof nameShort === 'string');
        assert(!!nameShort.length);

        assert('nameShortPos' in response);
        const { nameShortPos } = response;
        assert(typeof nameShortPos === 'string');
        assert(['L', 'R'].includes(nameShortPos));

        assert('nameI18n' in response);
        const { nameI18n } = response;
        assert(typeof nameI18n === 'string');
        assert(!!nameI18n.length);

        assert('minorUnits' in response);
        const { minorUnits } = response;
        assert(typeof minorUnits === 'number');
        assert(Number.isInteger(minorUnits));
        assert(minorUnits >= 0);

        const checkedResponse = {
            id,
            number,
            name,
            nameShort,
            nameShortPos: nameShortPos as TNameShortPos,
            nameI18n,
            minorUnits,
        } satisfies TCurrencyResponse;

        return new Currency(checkedResponse);
    }

    static fromMock(): Currency {
        return new Currency({
            id: 'RUB',
            number: 643,
            name: 'Российский рубль',
            nameShort: 'руб',
            nameShortPos: 'R',
            nameI18n: 'Ruble',
            minorUnits: 2,
        });
    }

    moneyView(money: number): string {
        const intlCurrencyFormatter = new Intl.NumberFormat('ru', {
            maximumFractionDigits: this.minorUnits,
        });
        const value = intlCurrencyFormatter.format(money);
        const symbol = this.nameShort;

        return this.nameShortPos === 'L' ? `${symbol} ${value}` : `${value} ${symbol}`;
    }
}
