import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { ButtonConnect, TitleClaim } from 'src/pages/businessOffice/Claim/styles';
import DesktopSlider, { ISliderContent } from 'src/pages/businessOffice/Claim/components/DesktopSlider';
import { useTablet } from 'src/hooks/useMedia';
import MobileSlider from 'src/pages/businessOffice/Claim/components/MobileSlider';
import MainConnectionPayment from 'src/pages/businessOffice/Claim/components/MainConnectionPayment';
import { useAppDispatch } from 'src/store';
import { setCounterparty } from 'src/store/Counterparty';
import { setSelectedMerchantId } from 'src/store/Merchant';
import { useIntl } from 'react-intl';

const Claim = () => {
    const intl = useIntl();
    const isTablet = useTablet();
    const dispatch = useAppDispatch();
    const [isStartConnectPayment, setIsStartConnectPayment] = useState(false);
    const { counterpartyId, merchantId } = useParams<{ counterpartyId: string; merchantId: string }>();
    const slidersContent: ISliderContent[] = [
        {
            frame: 1,
            title: intl.formatMessage({ defaultMessage: 'Электронный документооборот' }),
            paragraphs: [
                intl.formatMessage({
                    defaultMessage:
                        'Принимайте оплату через популярные платежные инструменты Visa/MasterCard/МИР по безналичному расчёту, отслеживая факт поступления оплаты.',
                }),
                intl.formatMessage({
                    defaultMessage: 'Интегрируйте сервис со своим сайтом и контролируйте платежи через приложение.',
                }),
            ],
        },
        {
            frame: 2,
            title: intl.formatMessage({ defaultMessage: 'Автоматизация оформления счёта' }),
            paragraphs: [
                intl.formatMessage({
                    defaultMessage:
                        'Принимайте оплату через популярные платежные инструменты Visa/MasterCard/МИР по безналичному расчёту, отслеживая факт поступления оплаты.',
                }),
                intl.formatMessage({
                    defaultMessage: 'Интегрируйте сервис со своим сайтом и контролируйте платежи через приложение.',
                }),
            ],
        },
        {
            frame: 3,
            title: intl.formatMessage({ defaultMessage: 'Обеспечение документооборота с клиентом' }),
            paragraphs: [
                intl.formatMessage({
                    defaultMessage:
                        'Принимайте оплату через популярные платежные инструменты Visa/MasterCard/МИР по безналичному расчёту, отслеживая факт поступления оплаты.',
                }),
                intl.formatMessage({
                    defaultMessage: 'Интегрируйте сервис со своим сайтом и контролируйте платежи через приложение.',
                }),
            ],
        },
        {
            frame: 4,
            title: intl.formatMessage({ defaultMessage: 'Бесплатное подключение' }),
            paragraphs: [
                intl.formatMessage({
                    defaultMessage:
                        'Принимайте оплату через популярные платежные инструменты Visa/MasterCard/МИР по безналичному расчёту, отслеживая факт поступления оплаты.',
                }),
                intl.formatMessage({
                    defaultMessage: 'Интегрируйте сервис со своим сайтом и контролируйте платежи через приложение.',
                }),
            ],
        },
    ];

    useEffect(() => {
        dispatch(setCounterparty(counterpartyId));
        if (merchantId) {
            dispatch(setSelectedMerchantId(merchantId));
        }
    }, [counterpartyId, dispatch, merchantId]);

    const onClickButtonConnectPayment = async () => {
        setIsStartConnectPayment(true);
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item to={RoutesBusinessOffice.dashboard}>
                    {intl.formatMessage({ defaultMessage: 'Главная' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{intl.formatMessage({ defaultMessage: 'Приём платежей' })}</Breadcrumb.Item>
            </Breadcrumb>
            <TitleClaim variant="medium54">{intl.formatMessage({ defaultMessage: 'Приём платежей' })}</TitleClaim>
            {isTablet ? <MobileSlider slides={slidersContent} /> : <DesktopSlider slides={slidersContent} />}
            {isStartConnectPayment ? (
                <MainConnectionPayment isMerchantPageToStart={Boolean(merchantId)} />
            ) : (
                <ButtonConnect
                    onClick={async () => {
                        await onClickButtonConnectPayment();
                    }}
                >
                    {intl.formatMessage({ defaultMessage: 'Подключить услугу' })}
                </ButtonConnect>
            )}
        </div>
    );
};

export default Claim;
