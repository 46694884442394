import React, { FC, useEffect, useState } from 'react';
import ButtonClientModalSearch from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/ButtonClientModalSearch';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyClients } from 'src/store/Client/selectors';
import { IClientCounterparty } from 'src/store/ClientCounterparty/types';
import { IClient } from 'src/store/Client/types';
import { getClientCounterparties } from 'src/store/ClientCounterparty/selectors';
import { RootState } from 'src/store';
import { getSortedClients } from 'src/utils/helpers';
import { useIntl } from 'react-intl';
import {
    ClientsList,
    ContentModalFindClient,
    HeaderModalFindClient,
    HeadLetterModalFindClient,
    ModalFindClientWrapper,
} from '../../styles';
import { InputFindClient } from './styles';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';

interface IModalFindClient {
    closeModal: () => void;
    isOpen: boolean;
    isLegalCustomer: boolean;
    selectClient: (clients: IClient | IClientCounterparty, isLegal: boolean, isClientCounterparty: boolean) => void;
    openModalCounterpartyClient: (idCounterparty: number) => void;
}

const ModalFindClient: FC<IModalFindClient> = ({
    selectClient,
    closeModal,
    isOpen,
    openModalCounterpartyClient,
    isLegalCustomer,
}) => {
    const intl = useIntl();
    const selector: (state: RootState) => IClient[] | IClientCounterparty[] = isLegalCustomer
        ? getClientCounterparties
        : getCurrentCounterpartyClients;
    const clients = useSelector(selector);

    const sortedClients = getSortedClients(clients);

    const [searchWord, setSearchWord] = useState('');
    const [sortedListClients, setSortedListClients] = useState(sortedClients);

    useEffect(() => {
        // @ts-ignore
        const filteredClients = sortedClients.filter(
            (client: IClient | IClientCounterparty) =>
                client.name.toUpperCase().indexOf(searchWord.toUpperCase()) === 0,
        );
        // @ts-ignore
        setSortedListClients(filteredClients);
    }, [sortedClients, searchWord]);

    let firstLetter: string | null = null;

    return (
        <ModalFindClientWrapper
            title={intl.formatMessage({ defaultMessage: 'Клиенты' })}
            HeaderComponent={HeaderModalFindClient}
            closeModal={closeModal}
            isOpen={isOpen}
        >
            <ContentModalFindClient>
                <InputFindClient
                    onChange={(inputSearchWord: string) => {
                        setSearchWord(inputSearchWord);
                    }}
                    value={searchWord}
                    name="searchClientWord"
                    placeholder={intl.formatMessage({ defaultMessage: 'Поиск по клиентам' })}
                    icon={<SearchIcon />}
                />
                <ClientsList>
                    {sortedListClients.map((client, index) => {
                        if (client.name[0].toUpperCase() !== firstLetter) {
                            // @ts-ignore
                            // eslint-disable-next-line prefer-destructuring
                            firstLetter = client.name[0].toUpperCase();
                            return (
                                <div key={client.id}>
                                    <HeadLetterModalFindClient isFirstLetter={index === 0}>
                                        {firstLetter}
                                    </HeadLetterModalFindClient>
                                    <ButtonClientModalSearch
                                        isLegalCustomer={isLegalCustomer}
                                        selectClient={selectClient}
                                        client={client}
                                        closeModal={closeModal}
                                        openModalCounterpartyClient={openModalCounterpartyClient}
                                    />
                                </div>
                            );
                        }
                        return (
                            <ButtonClientModalSearch
                                isLegalCustomer={isLegalCustomer}
                                selectClient={selectClient}
                                key={client.id}
                                client={client}
                                closeModal={closeModal}
                                openModalCounterpartyClient={openModalCounterpartyClient}
                            />
                        );
                    })}
                </ClientsList>
            </ContentModalFindClient>
        </ModalFindClientWrapper>
    );
};

export default React.memo(ModalFindClient);
