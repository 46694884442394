import { IntlShape } from 'react-intl';

export enum UnprocessableOrderStatuses {
    canceled = 'canceled',
    expired = 'expired',
    created = 'created',
    completed = 'completed',
}

export enum ProcessableOrderStatuses {
    processing = 'processing',
    processingError = 'processing-error',
}

export enum ORDER_ITEM_FIELDS {
    sku = 'sku',
    name = 'name',
    measure = 'measure',
    measureCode = 'measureCode',
    grossWeight = 'grossWeight',
    netWeight = 'netWeight',
    quantity = 'quantity',
    amount = 'amount',
    amountWoVat = 'amountWoVat',
    totalAmount = 'totalAmount',
    totalVatAmount = 'totalVatAmount',
    vatCode = 'vatCode',
    type = 'type',
}

export type TOrderItem = {
    [ORDER_ITEM_FIELDS.sku]: string | number;
    [ORDER_ITEM_FIELDS.name]: string;
    [ORDER_ITEM_FIELDS.measure]: string;
    [ORDER_ITEM_FIELDS.measureCode]: string;
    [ORDER_ITEM_FIELDS.grossWeight]: number;
    [ORDER_ITEM_FIELDS.netWeight]: number;
    [ORDER_ITEM_FIELDS.quantity]: number;
    [ORDER_ITEM_FIELDS.amount]: number;
    [ORDER_ITEM_FIELDS.amountWoVat]: number;
    [ORDER_ITEM_FIELDS.totalAmount]: number;
    [ORDER_ITEM_FIELDS.totalVatAmount]: number;
    [ORDER_ITEM_FIELDS.vatCode]: string;
    [ORDER_ITEM_FIELDS.type]: string;
};

export enum CUSTOMER_TYPES {
    legal = 'legal',
    private = 'private',
}

export enum ORDER_CUSTOMER_FIELDS {
    email = 'email',
    name = 'name',
    phone = 'phone',
    type = 'type',
    vatNumber = 'vatNumber',
    registrationAddress = 'registrationAddress',
}

export type TOrderCustomer = {
    [ORDER_CUSTOMER_FIELDS.name]: string;
    [ORDER_CUSTOMER_FIELDS.phone]: string;
    [ORDER_CUSTOMER_FIELDS.email]: string;
    [ORDER_CUSTOMER_FIELDS.type]: CUSTOMER_TYPES;
    [ORDER_CUSTOMER_FIELDS.vatNumber]?: string;
    [ORDER_CUSTOMER_FIELDS.registrationAddress]?: string;
};

export enum ORDER_FIELDS {
    id = 'id',
    description = 'description',
    currencyId = 'currencyId',
    amount = 'amount',
    vatAmount = 'vatAmount',
    basketItems = 'basketItems',
    paymentType = 'paymentType',
    merchantId = 'merchantId',
    merchantOrderId = 'merchantOrderId',
    expirationDate = 'expirationDate',
    customer = 'customer',
    createdAt = 'createdAt',
    status = 'status',
    contactId = 'contactId',
    contactCounterpartyId = 'contactCounterpartyId',
    paymentUrl = 'paymentUrl',
    processable = 'processable',
    orderContainerId = 'orderContainerId',
    paidAt = 'paidAt',
    subtype = 'subtype',

    // fields for definition customer type and valid
    isHideRequisites = 'isHideRequisites',
    isShowRequisites = 'isShowRequisites',
    isLegalCustomer = 'isLegalCustomer',
}

type TUnprocessableOrder = {
    [ORDER_FIELDS.processable]: false;
    [ORDER_FIELDS.status]: UnprocessableOrderStatuses;
};

type TProcessableOrder = {
    [ORDER_FIELDS.processable]: true;
    [ORDER_FIELDS.status]: ProcessableOrderStatuses | UnprocessableOrderStatuses;
};

export type TOrder = (TProcessableOrder | TUnprocessableOrder) &
    TOrderCustomer & {
        [ORDER_FIELDS.id]: string;
        [ORDER_FIELDS.description]: string;
        [ORDER_FIELDS.currencyId]: string;
        [ORDER_FIELDS.amount]: number;
        [ORDER_FIELDS.vatAmount]: number;
        [ORDER_FIELDS.basketItems]: TOrderItem[];
        [ORDER_FIELDS.paymentType]: string;
        [ORDER_FIELDS.merchantId]: string;
        [ORDER_FIELDS.merchantOrderId]: string;
        [ORDER_FIELDS.expirationDate]: string;
        [ORDER_FIELDS.customer]: TOrderCustomer;
        [ORDER_FIELDS.createdAt]: string;
        [ORDER_FIELDS.contactId]?: number;
        [ORDER_FIELDS.contactCounterpartyId]?: number;
        [ORDER_FIELDS.paymentUrl]: string;
        [ORDER_FIELDS.orderContainerId]: string;
        [ORDER_FIELDS.paidAt]: string;
        [ORDER_FIELDS.subtype]: string;

        // fields for definition customer type and valid
        [ORDER_FIELDS.isHideRequisites]: boolean;
        [ORDER_FIELDS.isShowRequisites]: boolean;
        [ORDER_FIELDS.isLegalCustomer]: boolean;
    };

export type TOrderStatusUpdateProps = Pick<TOrder, ORDER_FIELDS.id | ORDER_FIELDS.status>;

export enum OrderContainerStatuses {
    pending = 'pending',
    canceled = 'canceled',
    expired = 'expired',
    completed = 'completed',
}

export interface IOrderContainer {
    id: string;
    merchantId: string;
    originalAmount: number;
    originalVatAmount: number;
    expirationDate: string;
    customer?: TOrderCustomer;
    languageId: string;
    status: OrderContainerStatuses;
    returnUrl: string;
    successUrl: string;
    failUrl: string;
    payerCounterpartyId?: string;
    payerUserId: string;
}

export const COMMON_DICTIONARY_BASKET_ITEM_TYPES = {
    COMMODITY: 'commodity',
    SERVICE: 'service',
};

export const getBasketItemTypeOptions = (intl: IntlShape) => [
    {
        id: COMMON_DICTIONARY_BASKET_ITEM_TYPES.COMMODITY,
        label: intl.formatMessage({ defaultMessage: 'Товар' }),
        value: COMMON_DICTIONARY_BASKET_ITEM_TYPES.COMMODITY,
    },
    {
        id: COMMON_DICTIONARY_BASKET_ITEM_TYPES.SERVICE,
        label: intl.formatMessage({ defaultMessage: 'Услуга' }),
        value: COMMON_DICTIONARY_BASKET_ITEM_TYPES.SERVICE,
    },
];

export type TVatType = {
    code: string;
    id: number;
    main: boolean;
    name: string;
    public: boolean;
    rate: number;
};

export interface IFilter {
    [key: string]: string;
}

export interface IFilterStatus {
    label: string;
    state: string;
    [UnprocessableOrderStatuses.completed]?: string;
    [UnprocessableOrderStatuses.expired]?: string;
    [UnprocessableOrderStatuses.canceled]?: string;
    [ProcessableOrderStatuses.processingError]?: string;
    [UnprocessableOrderStatuses.created]?: string;
}

export enum typeOrder {
    ORDER = 'order',
    ORDER_CONTAINER = 'order-container',
    ORDER_REFUND = 'order-refund',
}
