import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const DateHours = styled(Typography)`
    height: 22px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    color: ${({ theme }) => theme.colors.white()};
    background: ${({ theme }) => theme.colors.base()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding-left: 9px;
    padding-right: 9px;
`;

export const RemoveHoursButton = styled.button`
    padding-left: 6px;
`;

export const DateHoursWorkContainer = styled.div`
    position: relative;
    z-index: 6;
`;
