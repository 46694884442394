import styled from 'styled-components';
import { ReactComponent as IconPlusImage } from 'src/assets/icons/plus.svg';

export const MerchantsListWrapper = styled.div`
    position: absolute;
    z-index: 7;
    left: 50px;
    top: 50px;
    width: 392px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.dark};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    background-color: ${({ theme }) => theme.colors.white()};
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 328px;
        left: 0;
    }
`;

export const MerchantList = styled.div`
    height: 240px;
    overflow: scroll;
`;

export const ButtonAddMerchant = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 10px;
    padding: 32px 24px 32px 50px;
    width: 100%;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-left: 27px;
    }
`;

export const IconPlus = styled(IconPlusImage)`
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;
