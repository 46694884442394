import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const RowLabelWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 84px;
    grid-column-gap: 4px;
`;

export const RefundLabel = styled(Typography)``;

export const RefundValue = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: right;
`;
