import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { TDictionary, TDictionaryData, TExternalDictionary } from './types';
import { loadDictionaryRequest } from './http';
import { createInitialDictionaries, DICTIONARY_TYPES } from './constants';
import createAsyncThunkWithErrorHandler from '../../utils/helpers/createAsyncThunkWithErrorHandler';

export const dictionariesAdapter = createEntityAdapter<TDictionary>();
const initialState = dictionariesAdapter.getInitialState();

export const loadDictionary = createAsyncThunkWithErrorHandler<Pick<TDictionary, 'id' | 'data'>, DICTIONARY_TYPES>(
    'dictionaries/loadDictionary',
    async (name, thunkAPI) => {
        const { selectById } = dictionariesAdapter.getSelectors((state: RootState) => state.dictionaries);
        const dict = selectById(thunkAPI.getState(), name) as TExternalDictionary;
        const {
            data: { data },
        } = await loadDictionaryRequest<TDictionaryData>(dict.dataUrl);
        return { id: name, data };
    },
);

const Dictionaries = createSlice({
    name: 'dictionaries',
    initialState,
    reducers: {
        initializeDictionaries(state) {
            dictionariesAdapter.setAll(state, createInitialDictionaries());
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadDictionary.fulfilled, (state, { payload }) => {
            // @ts-ignore
            dictionariesAdapter.upsertOne(state, { ...payload, isLoaded: true });
        });
    },
});

export const { initializeDictionaries } = Dictionaries.actions;
export default Dictionaries.reducer;
