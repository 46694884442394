import styled from 'styled-components';
import Button from 'src/components/Button';
import Typography from 'src/components/Typography';
import { ReactComponent as SuccessIcon } from 'src/assets/icons/checkCircle.svg';
import RowValue from 'src/pages/businessOffice/Refund/components/RowValue';

export const RefundTotalWrapper = styled.div`
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    padding: 30px 30px 40px 30px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 30px 0 40px 0;
        box-shadow: none;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 40px 20px 70px 20px;
    }
`;

export const RefundTotalTitle = styled(Typography)`
    margin-bottom: 25px;
`;

export const RefundButton = styled(Button)`
    margin: 0 auto;
`;

export const OrderWrapper = styled.div`
    display: grid;
    grid-template-columns: 36px 1fr;
    grid-template-rows: repeat(2, 20px);
    grid-column-gap: 10px;
`;

export const StatusIcon = styled(SuccessIcon)`
    display: flex;
    position: absolute;
    bottom: 9px;
    right: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.lightGrey()};
`;

export const WrapOrderIcon = styled.div`
    background-color: ${({ theme }) => theme.colors.lightGrey()};
    position: relative;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    grid-row-start: 1;
    grid-row-end: 3;
`;

export const OrderCustomer = styled(Typography)``;

export const MerchantOrderNumber = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const Fields = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
`;

export const TotalAfterRefund = styled(RowValue)`
    margin: 20px 0 40px;
`;
