import React, { FC } from 'react';
import {
    ClientItemWrapper,
    Email,
    NameWrapper,
    Phone,
    VatNumber,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/Clients/components/ClientListItem/styles';
import { IClient } from 'src/store/Client/types';
import Avatar, { AVATAR_SIZE, AVATAR_VARIANT } from 'src/components/Avatar';
import { CLIENT_FIELDS } from 'src/store/Client/contants';
import { convertPhoneToShow } from 'src/utils/formatters';
import { IClientCounterparty } from 'src/store/ClientCounterparty/types';
import { CLIENT_COUNTERPARTY_FIELDS } from 'src/store/ClientCounterparty/constants';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useParams } from 'react-router-dom';
import { typesClients } from '../../index';

interface IClientListItem {
    client: IClient | IClientCounterparty;
    typeClient: typesClients;
}

const ClientListItem: FC<IClientListItem> = ({ typeClient, client }) => {
    const { merchantId } = useParams<{ merchantId: string }>();
    const generalPath = `${RoutesBusinessOffice.shop}/${merchantId}`;
    const clientName = client[CLIENT_FIELDS.name];
    const isClientCounterParty = typeClient === typesClients.CLIENT_COUNTERPARTY;
    const uniqPathOnClickClient = isClientCounterParty
        ? `${generalPath}${RoutesBusinessOffice.detailclientcounterparty}/${client[CLIENT_FIELDS.id]}`
        : `${generalPath}${RoutesBusinessOffice.detailclient}/${client[CLIENT_FIELDS.id]}`;

    const renderDescription = (renderingClient: IClient | IClientCounterparty) => {
        if (isClientCounterParty) {
            const clientCounterparty: IClientCounterparty = renderingClient as any;
            return (
                <VatNumber variant="regular14">{clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.vatNumber]}</VatNumber>
            );
        }
        const clientData: IClient = renderingClient as any;
        return (
            <>
                <Email variant="regular14">{clientData[CLIENT_FIELDS.email]}</Email>
                <Phone variant="regular14">
                    {clientData[CLIENT_FIELDS.phone] && convertPhoneToShow(clientData[CLIENT_FIELDS.phone])}
                </Phone>
            </>
        );
    };

    return (
        // @ts-ignore не дает упростить isclientcounterparty, иначе падает ошибка в консоли якобы свойство не булево
        <ClientItemWrapper to={uniqPathOnClickClient} isclientcounterparty={isClientCounterParty ? 1 : 0}>
            <NameWrapper>
                <Avatar
                    isColumnPosition={false}
                    size={AVATAR_SIZE.xs}
                    name={clientName}
                    variant={AVATAR_VARIANT.dark}
                />
                {clientName}
            </NameWrapper>
            {renderDescription(client)}
        </ClientItemWrapper>
    );
};

export default ClientListItem;
