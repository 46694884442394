import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import Typography from 'src/components/Typography';

export const EmptyTariffWrapper = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.grey(COLOR_BRIGHTNESS.LIGHT)};
    padding: 16px 0;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 12px;
    }
`;

export const Title = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
`;

export const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-shrink: 0;
    margin-left: -6px;

    svg {
        transform: scale(1.2);
    }
`;
