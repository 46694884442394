import React from 'react';
import { UnprocessableOrderStatuses } from 'src/store/Order/types';
import { ReactComponent as SuccessIcon } from 'src/assets/icons/checkCircle.svg';
import { ReactComponent as CanceledIcon } from 'src/assets/icons/cancel.svg';
import { ReactComponent as ExpiredIcon } from 'src/assets/icons/error.svg';

import colors from 'src/theme/colors';

export const getIconByStatusAndColor = (status: string | undefined, colorsTheme: any) => {
    if (status === UnprocessableOrderStatuses.canceled) return { icon: <CanceledIcon />, color: colorsTheme.yellow() };
    if (status === UnprocessableOrderStatuses.expired) return { icon: <ExpiredIcon />, color: colorsTheme.red() };
    if (status === UnprocessableOrderStatuses.completed) return { icon: <SuccessIcon />, color: colorsTheme.green() };
    return { icon: null, color: colors.base() };
};
