import { TSettingFields } from 'src/store/types';

export type TApplication = {
    id: number;
    applicationId: string;
    name: string;
    grants: Array<string>;
    scopes: Array<string>;
    applicationTypeId?: string | null;
    settings?: TSettingFields;
    public?: boolean;
};

export const IIKO_APPLICATION_ID = 'iiko';
