import { useEffect, useRef, useState } from 'react';

const SMS_COUNTDOWN_INTERVAL = 120;

export const useNewCodeTimer = () => {
    const newCodeIntervalId = useRef<number | null>(null);
    const [timer, setTimer] = useState(SMS_COUNTDOWN_INTERVAL);

    const clearNewCodeInterval = () => {
        if (newCodeIntervalId?.current) {
            window.clearInterval(newCodeIntervalId.current);
        }
    };

    const startNewCodeTimer = () => {
        newCodeIntervalId.current = window.setInterval(() => {
            setTimer((time) => time - 1);
        }, 1000);

        setTimer(SMS_COUNTDOWN_INTERVAL);

        return () => clearNewCodeInterval();
    };

    useEffect(() => {
        if (timer === 0) clearNewCodeInterval();
    }, [timer]);

    return { startNewCodeTimer, timer };
};
