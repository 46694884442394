import React from 'react';
import SettingsWidget from 'src/components/Widgets/SettingsWidget';
import GeneralInformation from 'src/pages/businessOffice/Shop/ShopTabsTables/Settings/components/GeneralInformation';
import Address from 'src/pages/businessOffice/Shop/ShopTabsTables/Settings/components/Address';
import { IMerchant } from 'src/store/Merchant/types';
import { useSelector } from 'react-redux';
import { getMerchantById } from 'src/store/Merchant/selectors';
import UpdateImage from 'src/pages/businessOffice/Shop/ShopTabsTables/Settings/components/UpdateImage';
import useModal from 'src/hooks/useModal';
import RemoveMerchantModal from 'src/pages/businessOffice/Shop/ShopTabsTables/Settings/components/RemoveMerchantModal';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ButtonRemoveMerchant, ContainerSettings } from './styles';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';

export const FORM_MERCHANT_GENERAL_INFORMATION = 'form_merchant_general_information';
export const FORM_MERCHANT_ADDRESS = 'form_merchant_address';

const Settings = () => {
    const intl = useIntl();
    const { merchantId } = useParams<{ merchantId: string }>();
    const merchant: IMerchant = useSelector(getMerchantById(merchantId)) as any;
    const { isOpen: isRemoveMerchantModalVisible, open: openModal, close: closeModal } = useModal();

    return (
        <ContainerSettings>
            <SettingsWidget
                title={intl.formatMessage({ defaultMessage: 'Общая информация' })}
                formId={FORM_MERCHANT_GENERAL_INFORMATION}
            >
                <GeneralInformation merchant={merchant} />
            </SettingsWidget>
            <SettingsWidget title={intl.formatMessage({ defaultMessage: 'Адрес' })} formId={FORM_MERCHANT_ADDRESS}>
                <Address merchant={merchant} />
            </SettingsWidget>
            <UpdateImage merchant={merchant} />
            <ButtonRemoveMerchant icon={<DeleteIcon />} onClick={openModal}>
                {intl.formatMessage({ defaultMessage: 'Удалить магазин' })}
            </ButtonRemoveMerchant>
            <RemoveMerchantModal closeModal={closeModal} isOpen={isRemoveMerchantModalVisible} />
        </ContainerSettings>
    );
};

export default Settings;
