import React from 'react';
import {
    Name,
    SkeletonAvatar,
    SkeletonClientGroupHead,
    SkeletonClientsWrapper,
    SkeletonClientWrapper,
    SkeletonEmail,
    SkeletonName,
    SkeletonPhone,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/Clients/components/SkeletonClientsList/styles';

const SkeletonClientItem = () => (
    <SkeletonClientWrapper>
        <Name>
            <SkeletonAvatar />
            <SkeletonName />
        </Name>
        <SkeletonEmail />
        <SkeletonPhone />
    </SkeletonClientWrapper>
);

const SkeletonClientsList = () => {
    const skeletonQueueClients = [
        SkeletonClientGroupHead,
        SkeletonClientItem,
        SkeletonClientGroupHead,
        SkeletonClientItem,
        SkeletonClientItem,
        SkeletonClientItem,
    ];
    return (
        <SkeletonClientsWrapper>
            {skeletonQueueClients.map((SkeletonComponent, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SkeletonComponent key={index} />
            ))}
        </SkeletonClientsWrapper>
    );
};

export default SkeletonClientsList;
