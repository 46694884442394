import styled from 'styled-components';
import Checkbox from 'src/components/Checkbox';

export const PositionsWrapper = styled.div`
    padding: 30px 0 58px 0;
`;

export const CheckboxSelectAll = styled(Checkbox)`
    margin-bottom: 30px;
    ${({ theme }) => theme.mixins.getTypography('regular12')}
`;
