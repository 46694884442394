import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APP_VARIANT_ROUTES } from './constants';
import BusinessOfficeRoutes from './BusinessOfficeRoutes';
import DocsRoutes from './DocsRoutes';
import Loader from '../components/Loader';
import { useReCaptchaTokenContext } from '../context/ReCaptchaTokenContext';
import { getIsAuthInitialized, getIsAuthLoading, getIsVerificationExists } from '../store/Auth/selectors';
// import { useThrottledEffect } from '../hooks/useThrottledEffect';
import { fetchUserProfile } from '../store/Auth';
import { useAppDispatch } from '../store';

const AppRoutes = () => {
    const dispatch = useAppDispatch();

    const { token, onRefreshReCaptcha } = useReCaptchaTokenContext();
    const isAuthInitialized = useSelector(getIsAuthInitialized);
    const isAuthLoading = useSelector(getIsAuthLoading);
    const isVerificationExist = useSelector(getIsVerificationExists);

    useEffect(() => {
        if (!isAuthInitialized && !isAuthLoading && token) {
            dispatch(fetchUserProfile(token)).finally(() => onRefreshReCaptcha());
        }
    }, [dispatch, isAuthInitialized, isAuthLoading, onRefreshReCaptcha, token]);

    if (!isAuthInitialized || (!isVerificationExist && isAuthLoading)) {
        return <Loader />;
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route path={APP_VARIANT_ROUTES.dashboard} component={BusinessOfficeRoutes} />
                <Route path={APP_VARIANT_ROUTES.docs} component={DocsRoutes} />
                <Redirect to={APP_VARIANT_ROUTES.dashboard} />
            </Switch>
        </BrowserRouter>
    );
};

export default AppRoutes;
