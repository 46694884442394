import React, { FC } from 'react';
import {
    Balance,
    MerchantItemWrapper,
    MerchantName,
} from 'src/pages/businessOffice/Shop/components/MerchantListItem/styles';
import { IMerchant } from 'src/store/Merchant/types';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import MerchantLogo, { enumSizeLogo } from 'src/components/MerchantLogo';
import { formatCurrencyToShow } from 'src/utils/helpers';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useHistory } from 'react-router-dom';

interface IMerchantListItem {
    merchant: IMerchant;
    balance: number;
    isActive: boolean;
    hideMerchantList: () => void;
}

const MerchantListItem: FC<IMerchantListItem> = ({ hideMerchantList, isActive, balance, merchant }) => {
    const history = useHistory();

    const onClickToMerchantItem = () => {
        history.push(`${RoutesBusinessOffice.shop}/${merchant.id}${RoutesBusinessOffice.overview}`);
        hideMerchantList();
    };

    return (
        <MerchantItemWrapper
            onClick={onClickToMerchantItem}
            // @ts-ignore fix error button to set param isActive
            isactivemerchant={isActive ? 1 : undefined}
        >
            <MerchantLogo merchantId={merchant[MERCHANT_FIELDS.id]} size={enumSizeLogo.SMALL} />
            <MerchantName variant="regular14">{merchant[MERCHANT_FIELDS.name]}</MerchantName>
            <Balance variant="regular14">{formatCurrencyToShow(balance)}</Balance>
        </MerchantItemWrapper>
    );
};

export default MerchantListItem;
