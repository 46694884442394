import React, { FC } from 'react';
import {
    OrderItemName,
    OrderItemWrapper,
    QuantityAmount,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/RefundDetail/components/OrderItem/styles';
import { ORDER_ITEM_FIELDS, TOrderItem } from 'src/store/Order/types';
import { formatCurrencyToShow } from 'src/utils/helpers';

interface IOrderItem {
    orderItem: TOrderItem;
}

const OrderItem: FC<IOrderItem> = ({ orderItem }) => {
    const quantityAmountValues = `${orderItem[ORDER_ITEM_FIELDS.quantity]} x ${formatCurrencyToShow(
        orderItem[ORDER_ITEM_FIELDS.amount],
    )}`;

    return (
        <OrderItemWrapper>
            <OrderItemName variant="regular14">{orderItem[ORDER_ITEM_FIELDS.name]}</OrderItemName>
            <QuantityAmount variant="regular14">{quantityAmountValues}</QuantityAmount>
        </OrderItemWrapper>
    );
};

export default OrderItem;
