import styled from 'styled-components';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';

export const ScheduleModalWrapper = styled(Modal)`
    width: 642px;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

export const ButtonSubmitSchedule = styled(Button)`
    margin: 50px auto 0;
`;
