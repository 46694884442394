import React, { FC, ReactNode, useState } from 'react';
import { Arrow, Typography } from '@invoicebox/ui';
import * as S from './styles';
import Checkbox from '../../../components/Checkbox';
import { THeadLabel, TRow } from '../constants';
import { getColSpanFromHeadLabels } from '../getColSpanFromHeadLabels';

export type TProps = {
    row: TRow;
    headLabels: Array<THeadLabel>;
    isShowBorderBottom: boolean;
    children: ReactNode;
    isSelected: boolean;
    onSelectRow: (id: string) => void;
};

export const TableRow: FC<TProps> = ({ isShowBorderBottom, children, onSelectRow, row, headLabels, isSelected }) => {
    const [isShowDescription, setIsShowDescription] = useState(false);

    const handleChecked = () => {
        onSelectRow(row.id);
    };

    return (
        <>
            <S.TableRowWrapper
                $isShowBorder={isShowBorderBottom && !isShowDescription}
                onClick={() => setIsShowDescription((prevState) => !prevState)}
                $isPointer
            >
                <S.CheckboxRowWrapper>
                    <Checkbox onChange={handleChecked} checked={isSelected} />
                </S.CheckboxRowWrapper>
                {headLabels.map((headLabel) => {
                    const tdContent = row[headLabel.name];

                    if (typeof tdContent === 'string') {
                        return (
                            <S.RowCell key={headLabel.name}>
                                <Typography variant="bodyMRegular">{tdContent}</Typography>
                            </S.RowCell>
                        );
                    }

                    return <S.RowCell key={headLabel.name}> {tdContent} </S.RowCell>;
                })}

                <S.ArrowIconWrapper onClick={() => setIsShowDescription((prevState) => !prevState)}>
                    <Arrow isOpen={isShowDescription} outterSize={18} />
                </S.ArrowIconWrapper>
            </S.TableRowWrapper>

            {isShowDescription && (
                <S.TableRowWrapper $isShowBorder={isShowBorderBottom && isShowDescription} $isPointer={false}>
                    <S.DescriptionWrapper colSpan={getColSpanFromHeadLabels(headLabels)}>
                        <S.Description>{children}</S.Description>
                    </S.DescriptionWrapper>
                </S.TableRowWrapper>
            )}
        </>
    );
};
