import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { DocsAuthRouter, DocsNotAuthRouter } from './routes';
import { getUser, getUserName } from '../store/Auth/selectors';
import DocsFooter from '../components/DocsFooter';
import DocsHeader from '../components/DocsHeader';
import { RoutesDocs } from './constants';

const DocsRoutes = () => {
    const intl = useIntl();

    const user = useSelector(getUser);
    const userName = useSelector(getUserName);

    const headerMenuItems = [
        {
            label: intl.formatMessage({ id: 'Документы', defaultMessage: 'Документы' }),
            href: RoutesDocs.dashboard,
        },
    ];

    return (
        <Wrapper>
            <div>
                <DocsHeader
                    menuLinks={user ? headerMenuItems : undefined}
                    userName={user ? userName || 'Без имени' : null}
                />
                {user ? <DocsAuthRouter /> : <DocsNotAuthRouter />}
            </div>
            <DocsFooter />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-color: #f5f6fa;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

export default DocsRoutes;
