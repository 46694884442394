import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';

export const ButtonAddNewItemOrder = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
    margin: 20px 0;
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        text-align: left;
    }
`;
