import React, { useRef, useState } from 'react';
import { storiesFetcher, useSWR } from 'src/hooks/useSWR';
import { STRAPI_BASE_URL, strapiConfig } from 'src/utils/http';
import {
    ButtonScrollSlider,
    LeftIcon,
    RightIcon,
    SkeletonSlide,
    SlidesWrapper,
    StoriesButtons,
    StoriesHeader,
    StoriesTitle,
    StoriesWrapper,
} from 'src/pages/businessOffice/Dashboard/components/Stories/styles';
import Slider from 'react-slick';
import SlidePreview from 'src/pages/businessOffice/Dashboard/components/Stories/components/SlidePreview';
import { createStoriesArray } from 'src/utils/helpers';
import useModal from 'src/hooks/useModal';
import ModalStories from 'src/pages/businessOffice/Dashboard/components/Stories/components/ModalStories';
import { useLargeTablet, useMiniLaptop, useMobile, useTablet } from 'src/hooks/useMedia';
import { useIntl } from 'react-intl';

const Stories = () => {
    const intl = useIntl();
    const isMiniLaptop = useMiniLaptop();
    const isLargeTablet = useLargeTablet();
    const isTablet = useTablet();
    const isMobile = useMobile();
    const sliderRef = useRef();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);
    const { data, error } = useSWR<any[]>('api/stories', strapiConfig, storiesFetcher, {
        errorRetryCount: 0,
    });

    const storiesArray = createStoriesArray(data);

    const [selectedStoryIndex, setSelectedStoryIndex] = useState<number>(0);

    const getCountSlideToMedia = () => {
        if (isMobile) {
            return 2;
        }
        if (isTablet) {
            return 3;
        }
        if (isLargeTablet) {
            return 4;
        }
        if (isMiniLaptop) {
            return 5;
        }
        return 4;
    };

    const countSlidesToPage = getCountSlideToMedia();

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: countSlidesToPage,
        slidesToScroll: countSlidesToPage,
    };

    const goToNextSlide = () => {
        // @ts-ignore
        sliderRef?.current?.slickNext();
    };

    const goToPrevSlide = () => {
        // @ts-ignore
        sliderRef?.current?.slickPrev();
    };

    const onClickToPreview = (indexSlide: number) => {
        setSelectedStoryIndex(indexSlide);
        openModal();
    };

    const renderPreviewSlider = () => {
        if (!data) {
            const arrSkeletons = new Array(countSlidesToPage).fill(SkeletonSlide);
            // eslint-disable-next-line react/no-array-index-key
            return arrSkeletons.map((Skeleton, index) => <Skeleton key={index} />);
        }
        return storiesArray.map((story, index) => (
            <SlidePreview index={index} onClickToPreview={onClickToPreview} key={story.id} slide={story} />
        ));
    };

    // @ts-ignore
    const imagesToLoad: { uri: string }[] = storiesArray.reduce<{ url: string }[]>(
        // @ts-ignore
        (result, { slides }) => [
            ...result,
            ...slides.map(({ background }) => ({
                uri: `${STRAPI_BASE_URL}${background.slice(1)}`,
            })),
        ],
        [] as { url: string }[],
    );

    if (!error) {
        return (
            <StoriesWrapper>
                <StoriesHeader>
                    <StoriesTitle variant="headline">{intl.formatMessage({ defaultMessage: 'Для вас' })}</StoriesTitle>
                    {countSlidesToPage < storiesArray.length && (
                        <StoriesButtons>
                            <ButtonScrollSlider type="button" onClick={goToPrevSlide}>
                                <LeftIcon />
                            </ButtonScrollSlider>
                            <ButtonScrollSlider type="button" onClick={goToNextSlide}>
                                <RightIcon />
                            </ButtonScrollSlider>
                        </StoriesButtons>
                    )}
                </StoriesHeader>
                <SlidesWrapper>
                    <Slider
                        // @ts-ignore
                        ref={sliderRef}
                        {...settings}
                    >
                        {renderPreviewSlider()}
                    </Slider>
                </SlidesWrapper>
                {isOpenModal && (
                    <ModalStories
                        setSelectedStoryIndex={setSelectedStoryIndex}
                        selectedStoryIndex={selectedStoryIndex}
                        storiesArray={storiesArray}
                        isOpen={isOpenModal}
                        onClose={onCloseModal}
                        imagesToLoad={imagesToLoad}
                    />
                )}
            </StoriesWrapper>
        );
    }

    return null;
};

export default Stories;
