import styled, { css } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import Typography from 'src/components/Typography';
import Skeleton from 'src/components/Skeleton';
import { ReactComponent as ArrowDown } from './assets/arrow-down.svg';

interface IDrawerWrapperProps {
    isOpen: boolean;
}

interface IDrawerLanguageItemLabelProps {
    isActive?: boolean;
}

export const HeaderWrapper = styled.header`
    background-color: ${({ theme }) => theme.colors.base()};
    height: 70px;
    width: 100%;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.dark};
`;

export const HeaderInner = styled.div`
    height: 100%;
    flex: 1;
    align-items: center;
    ${({ theme }) => theme.mixins.mainGrid};

    @media ${({ theme }) => theme.breakpoints.xl} {
        grid-template-columns: 260px 1fr;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-gap: 0;
    }
`;

export const HeaderMenu = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

export const LogoLink = styled(Link)`
    padding-top: 8px;
    display: flex;

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding-top: 6px;
    }
`;

export const HeaderMenuItem = styled.div<{ $isBorderLeft: boolean }>`
    height: 30px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white()};
    transition: ${({ theme }) => theme.decorations.transition.base};

    ${({ $isBorderLeft, theme }) =>
        $isBorderLeft && `border-left: 1px solid ${theme.colors.grey(COLOR_BRIGHTNESS.MEDIUM)}`};
    cursor: default;

    &:hover {
        color: ${({ theme }) => theme.colors.yellow()};
    }

    & svg {
        transition: ${({ theme }) => theme.decorations.transition.base};
    }
`;

export const LanguageDropdown = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 19px 0 12px;
    //transition: ${({ theme }) => theme.decorations.transition.base};
`;

export const LanguageDropdownIcon = styled(ArrowDown)`
    margin-left: 9px;
`;

export const NavigationWrapper = styled.nav`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;

    @media ${({ theme }) => theme.breakpoints.xl} {
        flex-direction: column;
        width: 100%;
    }
`;

export const NavigationItem = styled.div`
    &:not(:last-of-type) {
        margin-right: 24px;

        @media ${({ theme }) => theme.breakpoints.xl} {
            margin-right: 0;
        }
    }

    @media ${({ theme }) => theme.breakpoints.xl} {
        width: 100%;
    }
`;

export const NavigationLink = styled(NavLink)`
    color: ${({ theme }) => theme.colors.white()};
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    text-decoration: none;
    height: 100%;
    position: relative;
    transition: ${({ theme }) => theme.decorations.transition?.base};

    &:hover {
        color: ${({ theme }) => theme.colors.yellow()};

        @media ${({ theme }) => theme.breakpoints.xl} {
            color: ${({ theme }) => theme.colors.base()};
        }
    }

    @media ${({ theme }) => theme.breakpoints.xl} {
        display: flex;
        width: 100%;
        line-height: 48px;
        padding-left: 45px;
        color: ${({ theme }) => theme.colors.grey()};
    }

    &:before {
        content: '';
        position: absolute;
        display: none;
        left: 0;
        right: 0;
        bottom: -23px;
        height: 2px;
        border-radius: 10px 10px 0 0;
        transform: translateY(3px);
        background-color: ${({ theme }) => theme.colors.yellow()};
        transition: ${({ theme }) => theme.decorations.transition?.base};

        @media ${({ theme }) => theme.breakpoints.xl} {
            bottom: 0;
            top: 0;
            right: auto;
            height: auto;
            width: 4px;
            transform: translateX(-4px);
        }
    }

    &.active:before {
        display: block;
    }

    ${css`
        &.isActive {
            @media ${({ theme }) => theme.breakpoints.xl} {
                color: ${({ theme }) => theme.colors.base()};
            }

            &:before {
                transform: translateY(0);

                @media ${({ theme }) => theme.breakpoints.xl} {
                    transform: translateX(-1px);
                }
            }
        }
    `}
`;

export const HeaderControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const HeaderLeft = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
`;

export const BurgerButton = styled.button`
    margin-right: 6px;
    display: none;

    @media ${({ theme }) => theme.breakpoints.xl} {
        display: flex;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: ${({ theme }) => theme.colors.darkest(COLOR_BRIGHTNESS.MEDIUM)};
`;

export const DrawerWrapper = styled.div<IDrawerWrapperProps>`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white()};
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 244px;
    z-index: 999;
    transform: translateX(-100%);
    transition: ${({ theme }) => theme.decorations.transition?.base};
    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: translateX(0);
        `} @media ${({ theme }) => theme.breakpoints.xl} {
        width: 310px;
    }
`;

export const DrawerHeaderInner = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    height: 100%;
    padding: 0 10px 0 24px;
`;

export const CloseButton = styled.button`
    display: flex;
`;

export const DrawerNavigationWrapper = styled.div`
    margin-top: 26px;
    display: flex;
    width: 100%;
`;

export const DrawerFooter = styled.div`
    display: flex;
    height: 50px;
    margin-top: auto;
    background-color: ${({ theme }) => theme.colors.lightGrey()};
`;

const drawerFooterButtonStyle = css`
    ${({ theme }) => theme.mixins.flexCenter};
    ${({ theme }) => theme.mixins.getTypography('regular14')}
`;

const DrawerFooterButton = styled.button`
    ${drawerFooterButtonStyle};
`;

export const ProfileButton = styled(Link)`
    ${drawerFooterButtonStyle};
    border-left: 1px solid ${({ theme }) => theme.colors.grey(COLOR_BRIGHTNESS.LIGHT)};
    border-right: 1px solid ${({ theme }) => theme.colors.grey(COLOR_BRIGHTNESS.LIGHT)};
    padding: 0 17px;
    margin: 10px 0;
    text-decoration: none;
    ${({ theme }) => theme.mixins.textOverflowDots};

    svg {
        min-width: 18px;
        width: 18px;
    }
`;

export const ProfileName = styled.span`
    margin-left: 10px;
    ${({ theme }) => theme.mixins.textOverflowDots};
`;

export const LogoutButton = styled(DrawerFooterButton)`
    padding-right: 19px;
    padding-left: 13px;
`;

export const ProfileTooltipInner = styled.div`
    display: flex;
    padding: 20px 28px;
    flex-direction: column;
`;

const profileMenuItem = css`
    width: 100%;
    min-width: 158px;
    justify-content: space-between;
    ${({ theme }) => theme.mixins.flexCenter};
`;

export const ProfileMenuLink = styled(Link)`
    ${profileMenuItem};
    text-decoration: none;
    margin-bottom: 16px;
`;

export const ProfileMenuButton = styled.button`
    ${profileMenuItem};
`;

export const LanguagesTooltipInner = styled.div`
    margin: 0 -9px;
`;

export const LanguagesTooltipItem = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

export const LanguagesTooltipLabel = styled(Typography)`
    margin-left: 10px;
`;

export const DrawerLanguageWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    position: relative;
`;

export const DrawerLanguageTrigger = styled.button`
    padding-left: 15px;
    padding-right: 12px;
    ${drawerFooterButtonStyle};
    ${({ theme }) => theme.mixins.flexCenter};
`;

export const DrawerLanguageActive = styled(DrawerLanguageTrigger)`
    height: 50px;
    background-color: ${({ theme }) => theme.colors.lightGrey()};
`;

export const DrawerLanguageList = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.white()};
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
    border-top-left-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border-top-right-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    overflow: hidden;
`;

export const DrawerLanguageItem = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    padding: 9px;
`;

export const DrawerLanguageItemLabel = styled(Typography)<IDrawerLanguageItemLabelProps>`
    margin-left: 10px;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.base() : theme.colors.grey())};
`;

export const NavDropdownSkeleton = styled(Skeleton)`
    height: 70px;
`;
