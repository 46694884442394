import React, { FC, useEffect, useState } from 'react';
import FormField, { FormFieldTypeEnum } from 'src/components/FormField';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { ILegalEntityType } from 'src/store/Counterparty/types';
import { useField } from 'react-final-form';
import { INDIVIDUAL_VAT_NUMBER_LENGTH } from 'src/utils/constants';
import { useIntl } from 'react-intl';

interface IInputsGeneral {
    legalEntityTypes: ILegalEntityType[] | undefined;
}

const InputsGeneral: FC<IInputsGeneral> = ({ legalEntityTypes }) => {
    const intl = useIntl();
    const [isCounterpartySP, setIsCounterpartySP] = useState(false);
    const {
        input: { value: vatNumber },
    } = useField(COUNTERPARTY_FIELDS.vatNumber);

    useEffect(() => {
        setIsCounterpartySP(vatNumber.length === INDIVIDUAL_VAT_NUMBER_LENGTH);
    }, [vatNumber]);

    return (
        <>
            <FormField
                name={COUNTERPARTY_FIELDS.vatNumber}
                label={intl.formatMessage({ defaultMessage: 'ИНН' })}
                placeholder={intl.formatMessage({ defaultMessage: 'Введите номер ИНН' })}
            />
            <FormField
                name={COUNTERPARTY_FIELDS.name}
                label={
                    isCounterpartySP
                        ? intl.formatMessage({ defaultMessage: 'ФИО' })
                        : intl.formatMessage({ defaultMessage: 'Наименование' })
                }
                placeholder={
                    isCounterpartySP
                        ? intl.formatMessage({ defaultMessage: 'Введите ФИО' })
                        : intl.formatMessage({ defaultMessage: 'Введите наименование' })
                }
            />
            {legalEntityTypes && (
                <FormField
                    name={COUNTERPARTY_FIELDS.legalEntityTypeId}
                    label={intl.formatMessage({ defaultMessage: 'Тип ОПФ' })}
                    placeholder={intl.formatMessage({ defaultMessage: 'Выберите ОПФ' })}
                    fieldType={FormFieldTypeEnum.select}
                    options={legalEntityTypes?.map(({ name, id }) => ({
                        label: name,
                        value: String(id),
                        id,
                    }))}
                />
            )}
            <FormField
                fieldType={FormFieldTypeEnum.inputDateTime}
                name={COUNTERPARTY_FIELDS.registrationDate}
                label={intl.formatMessage({ defaultMessage: 'Дата регистрации' })}
                dateFormat="d.MM.yyyy"
            />
            <FormField
                fieldType={FormFieldTypeEnum.autoCompleteAddress}
                name={COUNTERPARTY_FIELDS.registrationAddress}
                label={intl.formatMessage({ defaultMessage: 'Юр.адрес' })}
                placeholder={intl.formatMessage({ defaultMessage: 'Начните вводить адрес' })}
            />
            <FormField
                name={COUNTERPARTY_FIELDS.registrationNumber}
                label={
                    isCounterpartySP
                        ? intl.formatMessage({ defaultMessage: 'ОГРНИП' })
                        : intl.formatMessage({ defaultMessage: 'ОГРН' })
                }
                placeholder={
                    isCounterpartySP
                        ? intl.formatMessage({ defaultMessage: 'Введите ОГРНИП' })
                        : intl.formatMessage({ defaultMessage: 'Введите ОГРН' })
                }
            />
            <FormField
                name={COUNTERPARTY_FIELDS.taxRegistrationReasonCode}
                label={intl.formatMessage({ defaultMessage: 'КПП' })}
                placeholder={intl.formatMessage({ defaultMessage: 'Введите КПП' })}
            />
        </>
    );
};

export default InputsGeneral;
