import React, { useEffect, useState } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { RoutesBusinessOffice } from 'src/pages/constants';
import FeedWidget from 'src/components/Widgets/FeedWidget';
import { FeedsWrapper } from 'src/pages/businessOffice/Feeds/styles';
import PaginationStack from 'src/components/FilterOrders/components/Pagination';
import { useSelector } from 'react-redux';
import { getFeedById, getFeedMetaData } from 'src/store/Feed/selectors';
import { useAppDispatch } from 'src/store';
import { fetchFeedList } from 'src/store/Feed';
import { COUNT_FEEDS_TO_FEEDS_PAGE, typesFeedOpenOrderContainer } from 'src/store/Feed/constants';
import { useHistory, useParams } from 'react-router-dom';
import ModalFeed from 'src/components/Widgets/FeedWidget/components/ModalFeed';
import useModal from 'src/hooks/useModal';
import { useIntl } from 'react-intl';

const Feeds = () => {
    const intl = useIntl();
    const metaData = useSelector(getFeedMetaData);
    const { isOpen, open, close } = useModal(false);
    const history = useHistory();
    const dispatch = useAppDispatch();

    const pushFeedIdObj = useParams<{ pushFeedId: string }>();
    const pushFeed = useSelector(getFeedById(Number(pushFeedIdObj.pushFeedId)));

    const [page, setPage] = useState(1);
    const [pagesButtonsCount, setPagesButtonsCount] = useState(0);

    const selectPage = (pageNumber: number) => {
        setPage(pageNumber);
    };

    // при переходе по системному пушу на страницу - выбираем что делать дальше
    useEffect(() => {
        if (pushFeed) {
            if (typesFeedOpenOrderContainer.find((type) => type === pushFeed.type) && pushFeed?.metadata?.targetId) {
                history.push(`${RoutesBusinessOffice.ordercontainer}/${pushFeed.metadata.targetId}`, {
                    isOrderContainer: true,
                });
            } else {
                open();
            }
        }
    }, [history, open, pushFeed]);

    useEffect(() => {
        dispatch(fetchFeedList({ _page: page, _pageSize: COUNT_FEEDS_TO_FEEDS_PAGE }));
    }, [dispatch, page]);

    useEffect(() => {
        if (metaData.totalCount > COUNT_FEEDS_TO_FEEDS_PAGE) {
            setPagesButtonsCount(Math.ceil(metaData.totalCount / COUNT_FEEDS_TO_FEEDS_PAGE));
        } else if (metaData.totalCount <= COUNT_FEEDS_TO_FEEDS_PAGE) {
            setPagesButtonsCount(1);
        }
    }, [metaData.totalCount]);

    return (
        <FeedsWrapper>
            <Breadcrumb>
                <Breadcrumb.Item to={RoutesBusinessOffice.dashboard}>
                    {intl.formatMessage({ defaultMessage: 'Обзор' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{intl.formatMessage({ defaultMessage: 'Уведомления' })}</Breadcrumb.Item>
            </Breadcrumb>
            <FeedWidget />
            <PaginationStack onChange={selectPage} pageNumber={page} pagesButtonsCount={pagesButtonsCount} />
            {pushFeed && <ModalFeed onClose={close} isOpen={isOpen} feed={pushFeed} />}
        </FeedsWrapper>
    );
};

export default Feeds;
