import styled from 'styled-components';
import Button from 'src/components/Button';

export const MobileButtonWrapper = styled.div`
    display: none;
    @media ${({ theme }) => theme.breakpoints.ld} {
        display: block;
        margin-top: 25px;
    }
`;

export const MobileButton = styled(Button)`
    width: 100%;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 189px;
        margin: 0 auto;
    }
`;
