import styled, { keyframes } from 'styled-components';
import Skeleton from 'src/components/Skeleton';
import { enumSizeLogo, sizeLogo, sizeLogoMobile } from 'src/components/MerchantLogo/index';

interface ILogo {
    size: enumSizeLogo;
}

export const Logo = styled.div<ILogo>`
    display: flex;
    width: ${({ size }) => sizeLogo[size]};
    height: ${({ size }) => sizeLogo[size]};
    border-radius: 50%;
    margin-right: 16px;
    background-color: ${({ theme }) => theme.colors.lightGrey()};
`;

const logoImgAnimation = keyframes`
    from {
        background-position: -200px 0;
    }
    to {
        background-position: calc(200px + 100%) 0;
    }
`;

export const LogoImg = styled.img<ILogo>`
    width: ${({ size }) => sizeLogo[size]};
    height: ${({ size }) => sizeLogo[size]};
    border-radius: 50%;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    animation: ${logoImgAnimation} 1.2s ease-in-out infinite;
    position: relative;
    transition: 4s;
    &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 4s;
    }
`;

export const LogoInitials = styled.p<ILogo>`
    margin: auto;
    width: ${({ size }) => sizeLogo[size]};
    text-align: center;
`;

export const MerchantLogoSkeleton = styled(Skeleton).attrs({ circle: true })<ILogo>`
    margin-right: 16px;
    width: ${({ size }) => sizeLogo[size]};
    height: ${({ size }) => sizeLogo[size]};

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: ${({ size }) => sizeLogoMobile[size]};
        height: ${({ size }) => sizeLogoMobile[size]};
    }
`;
