import styled from 'styled-components';
import Modal from 'src/components/Modal';
import Typography from 'src/components/Typography';
import { NavLink } from 'react-router-dom';

export const ModalSuccessRefundWrapper = styled(Modal)`
    padding: 60px 50px;
    width: 556px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 50px 20px;
        width: 100%;
    }
`;

export const ModalTitle = styled(Typography)`
    margin-bottom: 24px;
    text-align: center;
`;

export const ModalDescription = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    margin-bottom: 24px;
    text-align: center;
`;

export const ModalNote = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: center;
    padding: 0 134px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0 50px;
    }
`;

export const RefundsNavLink = styled(NavLink)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.base()};
`;
