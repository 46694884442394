import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Breadcrumb from 'src/components/Breadcrumb';

export const RefundTitle = styled(Typography)`
    margin: 45px 0 50px 0;
`;

export const FormRefundContent = styled.div`
    display: grid;
    align-items: flex-start;
    grid-template-columns: 602px 252px;
    grid-column-gap: 28px;
    @media ${({ theme }) => theme.breakpoints.xxl} {
        grid-template-columns: 1fr 252px;
    }
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: 1fr 192px;
        padding-right: 16px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        padding: 0;
    }
`;

export const RefundOptions = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
`;

export const BreadcrumbRefund = styled(Breadcrumb)`
    flex-wrap: wrap;
    grid-row-gap: 6px;
`;
