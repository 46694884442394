import { claimsAdapter } from './index';
import { RootState } from '../index';
import { ClaimStatuses } from './types';
import { ClaimFields } from './constants';

const { selectAll, selectById } = claimsAdapter.getSelectors();

export const getClaims = (state: RootState) => selectAll(state.claims);
export const getClaimById = (id: string) => (state: RootState) => selectById(state.claims, id);
export const getCurrentSelectClaimId = (state: RootState) => state.claims.selectedId;
export const getCurrentClaim = (state: RootState) =>
    getClaims(state).find((claim) => claim[ClaimFields.id] === state.claims.selectedId);

export const getOpenClaimsByCounterpartyId = (counterpartyId: string) => (state: RootState) =>
    getClaims(state).filter((claim) => claim.counterpartyId === counterpartyId && !claim.closed);

export const getOpenClaimsByMerchantId = (merchantId: string) => (state: RootState) =>
    getClaims(state).filter((claim) => claim[ClaimFields.merchantId] === merchantId && !claim.closed);

export const getClaimByMerchantId = (merchantId: string | null) => (state: RootState) =>
    getClaims(state).find((claim) => claim[ClaimFields.merchantId] === merchantId);

export const getOpenClaimDraftByMerchantId = (merchantId: string | null) => (state: RootState) =>
    getClaims(state).find(
        (claim) =>
            claim[ClaimFields.merchantId] === merchantId &&
            claim[ClaimFields.status] === ClaimStatuses.draft &&
            !claim[ClaimFields.closed],
    );

export const getOpenClaimModeratingByMerchantId = (merchantId: string | null) => (state: RootState) =>
    getClaims(state).find(
        (claim) =>
            claim[ClaimFields.merchantId] === merchantId &&
            claim[ClaimFields.status] === ClaimStatuses.moderating &&
            !claim[ClaimFields.closed],
    );

export const getClaimsOpenModerating = (state: RootState) =>
    getClaims(state).filter(
        (claim) => claim[ClaimFields.status] === ClaimStatuses.moderating && !claim[ClaimFields.closed],
    );

export const getCurrentSelectClaim = (state: RootState) => {
    const id = getCurrentSelectClaimId(state);
    return id ? getClaimById(id)(state) : undefined;
};

export const getSelectedPassportData = (state: RootState) => state.claims.passportData;

export const getMerchantClaimModeratingOrDraftOrCanceled = (merchantId: string) => (state: RootState) =>
    getOpenClaimsByMerchantId(merchantId)(state).find(
        (claim) =>
            claim[ClaimFields.status] === ClaimStatuses.moderating ||
            claim[ClaimFields.status] === ClaimStatuses.draft ||
            claim[ClaimFields.status] === ClaimStatuses.canceled,
    );

export const getIsLoadedClaims = (state: RootState) => state.claims.isLoaded;
