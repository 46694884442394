import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Button from 'src/components/Button';
import { NavLink } from 'react-router-dom';

export const MissingClaimWrapper = styled.div`
    padding: 0 50px;
    width: 100%;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 0 40px;
    }
`;

export const Info = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    padding: 55px 97px 45px 97px;
    border: 1px solid ${({ theme }) => theme.colors.orange()};
    @media ${({ theme }) => theme.breakpoints.md} {
        border: none;
        padding: 0;
    }
`;

export const Title = styled(Typography)`
    margin-bottom: 15px;
    text-align: center;
`;

export const Description = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: center;
`;

export const NavLinkToClaim = styled(NavLink)`
    text-decoration: none;
`;

export const ButtonConnectClaim = styled(Button)`
    margin: 40px auto;
`;
