export type TRequiredFields = {
    [key: string]: {
        type: string;
        minLength?: number;
    };
};

export type TTaskFlow = {
    id: string;
    taskflowCode: TASKFLOW_CODES;
    places: string[];
    requiredFields: TRequiredFields;
};

export enum TASKFLOW_CODES {
    download = 'download',
    edm_non_formalized = 'edm_non_formalized',
    email = 'email',
    mail = 'mail',
    edm_inbound_document = 'edm_inbound_document',
    edm_invoice_torg12 = 'edm_invoice_torg12',
    edm_upd = 'edm_upd',
}

export const TASKFLOW_CODE_LABELS: Record<TASKFLOW_CODES, string> = {
    download: 'Скачать',
    edm_non_formalized: 'По ЭДО',
    email: 'По электрононной почте',
    mail: 'Оригиналы по почте',
    edm_inbound_document: 'Входящий документ',
    edm_invoice_torg12: 'Исходящий документ ТОРГ-12',
    edm_upd: 'Исходящий документ УПД',
};
