import { IntlShape } from 'react-intl';
import { ProcessableOrderStatuses, UnprocessableOrderStatuses } from './types';

export const getUnprocessableOrderStatuses = (intl: IntlShape) => ({
    [UnprocessableOrderStatuses.created]: intl.formatMessage({ defaultMessage: 'Создан' }),
    [UnprocessableOrderStatuses.completed]: intl.formatMessage({ defaultMessage: 'Оплачен' }),
    [UnprocessableOrderStatuses.expired]: intl.formatMessage({ defaultMessage: 'Просрочен' }),
    [UnprocessableOrderStatuses.canceled]: intl.formatMessage({ defaultMessage: 'Отменён' }),
});

export const getProcessableOrderStatuses = (intl: IntlShape) => ({
    ...getUnprocessableOrderStatuses(intl),
    [ProcessableOrderStatuses.processing]: intl.formatMessage({ defaultMessage: 'Обрабатывается' }),
    [ProcessableOrderStatuses.processingError]: intl.formatMessage({ defaultMessage: 'Ошибка' }),
});

const getOrderGeneralStatuses = (intl: IntlShape) => [
    {
        [UnprocessableOrderStatuses.completed]: intl.formatMessage({ defaultMessage: 'Оплачен' }),
        label: intl.formatMessage({ defaultMessage: 'Оплачен' }),
        state: 'completed',
    },
    {
        [UnprocessableOrderStatuses.expired]: intl.formatMessage({ defaultMessage: 'Просрочен' }),
        label: intl.formatMessage({ defaultMessage: 'Просрочен' }),
        state: 'expired',
    },
    {
        [UnprocessableOrderStatuses.canceled]: intl.formatMessage({ defaultMessage: 'Отменён' }),
        label: intl.formatMessage({ defaultMessage: 'Отменён' }),
        state: 'canceled',
    },
];

export const getOrderStatuses = (intl: IntlShape) => [
    ...getOrderGeneralStatuses(intl),
    {
        [UnprocessableOrderStatuses.created]: intl.formatMessage({ defaultMessage: 'Создан' }),
        label: intl.formatMessage({ defaultMessage: 'Создан' }),
        state: 'created',
    },
];
export const getAllStatusLabel = (intl: IntlShape) => intl.formatMessage({ defaultMessage: 'Все' });

export const getOrderContainerStatuses = (intl: IntlShape) => [
    {
        [UnprocessableOrderStatuses.created]: intl.formatMessage({ defaultMessage: 'Создан' }),
        label: intl.formatMessage({ defaultMessage: 'Создан' }),
        state: 'pending',
    },
    ...getOrderGeneralStatuses(intl),
];
