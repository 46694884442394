import React, { FC } from 'react';
import Avatar, { AVATAR_SIZE, AVATAR_VARIANT } from 'src/components/Avatar';
import { IClientCounterparty } from 'src/store/ClientCounterparty/types';
import { IClient } from 'src/store/Client/types';
import { CLIENT_FIELDS } from 'src/store/Client/contants';
import { ButtonClientModalFindClient } from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/styles';

interface IButtonClientModalSearch {
    client: IClient | IClientCounterparty;
    selectClient: (clients: IClient | IClientCounterparty, isLegal: boolean, isClientCounterparty: boolean) => void;
    isLegalCustomer: boolean;
    closeModal: () => void;
    openModalCounterpartyClient: (idCounterparty: number) => void;
}

const ButtonClientModalSearch: FC<IButtonClientModalSearch> = ({
    closeModal,
    client,
    selectClient,
    isLegalCustomer,
    openModalCounterpartyClient,
}) => {
    const clientName = client[CLIENT_FIELDS.name];

    const onClickClientButton = () => {
        selectClient(client, isLegalCustomer, false);
        if (isLegalCustomer) {
            openModalCounterpartyClient(client.id);
        }
        closeModal();
    };

    return (
        <ButtonClientModalFindClient onClick={onClickClientButton}>
            <Avatar isColumnPosition={false} size={AVATAR_SIZE.xs} name={clientName} variant={AVATAR_VARIANT.dark} />
            {clientName}
        </ButtonClientModalFindClient>
    );
};
export default ButtonClientModalSearch;
