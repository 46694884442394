import { useEffect, useState } from 'react';
import { ICounterparty } from '../../../../store/Counterparty/types';
import { NewCounterparty } from '../../../../store/NewCounterparty/entity';
import { getCounterpartiesListByVatNumberRequest } from '../../../../store/NewCounterparty/http';

export const useFetchCounterparty = (vatNumber: string) => {
    const [counterparties, setCounterparties] = useState<Array<ICounterparty>>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (NewCounterparty.couldIdentifyByVat(vatNumber)) {
            setIsLoading(true);
            getCounterpartiesListByVatNumberRequest(vatNumber)
                .then(({ data }) => setCounterparties(data.data))
                .finally(() => setIsLoading(false));
        }
    }, [vatNumber]);

    const options = counterparties.map((counterparty) => ({ value: counterparty.vatNumber, entity: counterparty }));

    return { options, isLoading };
};
