import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from '../../../theme/utils';

export const Wrapper = styled.div`
    display: flex;
    gap: 16px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        gap: 8px;
    }
`;

export const CodeInputItem = styled.input<{ $hasError?: boolean }>`
    border: 1px solid
        ${({ theme, $hasError }) => ($hasError ? theme.colors.red() : theme.colors.base(COLOR_BRIGHTNESS.LIGHT))};
    border-radius: 8px;
    width: 45px;
    height: 54px;
    text-align: center;
    outline: none;
`;
