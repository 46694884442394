import styled, { css } from 'styled-components';
import { ReactComponent as LogoIcon } from 'src/assets/images/logo.svg';
import Typography from 'src/components/Typography';
import Button from 'src/components/Button';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import FormField from 'src/components/FormField';
import { IInputProps } from 'src/components/Input';
import { ITextareaProps } from 'src/components/Textarea';
import { ISelectProps } from 'src/components/Select';
import { IExternalSelectProps } from 'src/components/ExternalSelect';

export const Logo = styled(LogoIcon)`
    margin-bottom: 47px;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    height: 100vh;
`;

export const IntroduceInner = styled.div`
    width: 100%;
    max-width: 342px;
    ${({ theme }) => theme.mixins.flexCenter};
    flex-direction: column;
`;

export const GetProfileInner = styled(IntroduceInner)`
    max-width: 260px;
`;

export const Title = styled(Typography).attrs({ center: true })`
    margin-bottom: 25px;
`;

export const Description = styled(Typography).attrs({ center: true })`
    max-width: 330px;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.PRE_MEDIUM)};
`;

export const ButtonsContainer = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
`;

export const SaveButton = styled(Button)`
    margin-top: 46px;
    width: 153px;
`;

export const FormInner = styled.form`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const FormInputWrapper = styled.div`
    width: 100%;

    &:not(:last-of-type) {
        margin-bottom: 17px;
    }
`;

export const CheckboxLabel = styled.label`
    display: flex;
    margin-top: 30px;
`;

export const CheckboxLabelText = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    margin-left: 10px;
    margin-top: 3px;
`;

export const ModalInner = styled.div`
    padding-top: 19px;
    width: 543px;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: calc(100vw - 150px);
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: calc(100vw - 70px);
        padding-top: 27px;
    }
`;

export const ModalFormInner = styled.form`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-direction: column;
`;

export const ModalFormField = styled(FormField)<IInputProps | ITextareaProps | ISelectProps | IExternalSelectProps>`
    margin-top: 17px;
`;

const modalFormField = css`
    display: grid;
    width: 100%;
    grid-gap: 30px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-gap: 0;
    }
`;

export const ModalFromFieldWrapperThird = styled.div`
    ${modalFormField};
    grid-template-columns: 140px 1fr;

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 1fr;
    }
`;

export const ModalFromFieldWrapperHalf = styled.div`
    ${modalFormField};
    grid-template-columns: 1fr 1fr;

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 1fr;
    }
`;

export const ModalFormButton = styled(Button)`
    margin-top: 50px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-top: 30px;
    }
`;

export const ModalFormCaption = styled(Typography)`
  margin-top: 26px;
  width: 100%;
  color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.LIGHT)};
  
  @media ${({ theme }) => theme.breakpoints.sm} {
    ${({ theme }) => theme.mixins.getTypography('regular12')};
`;
