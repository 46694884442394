import React from 'react';
import { useSelector } from 'react-redux';
import { getContractsOrTemplatesForCurrentCounterparty, getSelectedContractId } from 'src/store/Contracts/selectors';
import { TariffsWrapper } from 'src/components/Widgets/ClaimModeratingWidget/components/Tariff/styles';
import TariffCard from 'src/pages/businessOffice/Claim/components/TariffCard';

const Tariff = () => {
    const contractTemplatesAndContracts = useSelector(getContractsOrTemplatesForCurrentCounterparty);
    const selectedContractId = useSelector(getSelectedContractId) || undefined;

    return (
        <TariffsWrapper>
            {contractTemplatesAndContracts.map((contract) => (
                <TariffCard
                    selectedContractTemplateId={selectedContractId}
                    key={contract.id}
                    contractTemplate={contract}
                />
            ))}
        </TariffsWrapper>
    );
};

export default Tariff;
