import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { ReactComponent as ArrowLeftIcon } from './assets/left-arrow.svg';
import { TMetaData } from '../../../utils/TMetaData';

export type TProps = {
    metaData: TMetaData;
    children: ReactNode;
    onNextPage: () => void;
    onPrevPage: () => void;
};

export const Footer: FC<TProps> = ({ metaData, children, onNextPage, onPrevPage }) => {
    const intl = useIntl();

    const { page, pageSize, totalCount } = metaData;

    const isNowLastPage = totalCount <= page * pageSize;

    const startNumberLabel = page * pageSize - pageSize + 1;
    const endNumberLabel = isNowLastPage ? totalCount : page * pageSize;

    const rangeRowsLabel = `${startNumberLabel} - ${endNumberLabel}`;

    return (
        <S.Wrapper>
            <S.CountRowsOnPageLabel variant="bodyMRegular">
                {intl.formatMessage({ id: 'Записей на страницу', defaultMessage: 'Записей на страницу' })}
            </S.CountRowsOnPageLabel>
            {children}
            <S.RangeRows variant="bodyMRegular">
                {`${rangeRowsLabel} ${intl.formatMessage({ id: 'из', defaultMessage: 'из' })} ${totalCount}`}
            </S.RangeRows>
            <S.PageSwitchersWrapper>
                <S.ButtonPageSwitcher disabled={page === 1} type="button" onClick={onPrevPage}>
                    <ArrowLeftIcon />
                </S.ButtonPageSwitcher>
                <S.ButtonPageSwitcher disabled={isNowLastPage} type="button" onClick={onNextPage}>
                    <S.ArrowRightIconWrapper>
                        <ArrowLeftIcon />
                    </S.ArrowRightIconWrapper>
                </S.ButtonPageSwitcher>
            </S.PageSwitchersWrapper>
        </S.Wrapper>
    );
};
