import React, { FC } from 'react';
import { TotalRowContainer, TotalRowLabel, TotalRowValue, OnlyMobileDesignContainer } from './styles';

interface ITotalRow {
    label: string;
    value: string;
}

const TotalRow: FC<ITotalRow> = ({ label, value }) => (
    <TotalRowContainer>
        <TotalRowLabel variant="regular14">{label}</TotalRowLabel>
        <OnlyMobileDesignContainer />
        <TotalRowValue variant="regular14">{value}</TotalRowValue>
    </TotalRowContainer>
);

export default TotalRow;
