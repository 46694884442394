import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';
import Checkbox from '../../../components/Checkbox';
import { THeadLabel } from '../constants';

export type TProps = {
    headLabels: Array<THeadLabel>;
    isSelectedAllRows: boolean;
    onSelectAllRows: () => void;
};

export const ColumnsHeader: FC<TProps> = ({ headLabels, onSelectAllRows, isSelectedAllRows }) => {
    const handleSelectAllRows = () => onSelectAllRows();

    return (
        <thead>
            <S.Wrapper>
                <th>
                    <Checkbox checked={isSelectedAllRows} onChange={handleSelectAllRows} />
                </th>
                {headLabels.map((headLabel) => (
                    <th key={headLabel.name}>
                        <Typography variant="bodyMRegular">{headLabel.label}</Typography>
                    </th>
                ))}
                <th> </th>
            </S.Wrapper>
        </thead>
    );
};
