import styled from 'styled-components';
import Modal from 'src/components/Modal';
import Typography from 'src/components/Typography';
import { Link } from 'react-router-dom';

export const ModalSuccessOrderWrapper = styled(Modal)`
    width: 655px;
    height: 295px;
    ${({ theme }) => theme.mixins.flexStart};
    padding: 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-direction: column-reverse;
        height: 476px;
        justify-content: flex-end;
    }
`;

export const SuccessQRArea = styled.div`
    width: 181px;
    padding: 45px 20px 50px 20px;
    background: ${({ theme }) => theme.colors.lightGrey()};
    height: 100%;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
        height: 190px;
        ${({ theme }) => theme.mixins.flexCenter};
        padding: 34px 20px 33px 20px;
        justify-content: space-between;
    }
`;

export const QRDescription = styled.div`
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 145px;
    }
`;

export const QRTitle = styled(Typography).attrs({ center: true })`
    margin-bottom: 20px;
`;

export const QRSubtitile = styled(Typography).attrs({ center: true })`
    color: ${({ theme }) => theme.colors.grey()};
    margin-bottom: 18px;
`;
export const SuccessContent = styled.div`
    padding: 40px 75px;
    width: 474px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-direction: column;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
        padding: 40px 0 46px 0;
        height: 295px;
    }
`;

export const SuccessContentTitle = styled(Typography).attrs({ center: true })`
    margin-bottom: 9px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-bottom: 12px;
    }
`;

export const SuccessContentSubtitle = styled(Typography).attrs({ center: true })`
    color: ${({ theme }) => theme.colors.grey()};
    width: 150px;
    margin-bottom: 26px;
`;

export const ButtonCopyLink = styled.button`
    width: 44px;
    height: 44px;
    position: relative;
`;

export const ReFindOrder = styled(Typography).attrs({ center: true })`
    color: ${({ theme }) => theme.colors.grey()};
    width: 170px;
    margin-top: 46px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-top: 36px;
    }
`;

export const OrdersLink = styled(Link)`
    color: ${({ theme }) => theme.colors.base()};
    text-decoration: none;
`;

export const WrapperQRCode = styled.div`
    min-width: 130px;
    width: 130px;
    height: 130px;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.white()};
    margin: 0 auto;
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin: 0;
    }
`;
