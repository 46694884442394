import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Button from 'src/components/Button';

export const ContainerSettings = styled.div`
    padding-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        padding-top: 50px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-gap: 20px;
        padding-top: 30px;
    }
`;

export const ValueSetting = styled(Typography)`
    line-height: 140%;
`;

export const LabelSetting = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    line-height: 140%;
`;

export const RowSetting = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 7px;
`;

interface IGeneralInformationSettings {
    isEdit: boolean;
}

export const GeneralInformationSettings = styled.div<IGeneralInformationSettings>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 33px;
    grid-column-gap: 29px;
    align-items: flex-start;

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        padding: ${({ isEdit }) => {
            if (isEdit) return '0 21px 30px 21px';
            return '0 37px 41px 37px';
        }};
    }

    padding: ${({ isEdit }) => {
        if (isEdit) return '0 31px 30px 31px';
        return '0 50px 43px 50px';
    }};
`;

export const SettingsForm = styled.form`
    display: contents;
`;

export const ButtonRemoveMerchant = styled(Button)`
    background-color: ${({ theme }) => theme.colors.lightGrey()};
    color: ${({ theme }) => theme.colors.grey()};
    padding: 10px 17px;
    height: 40px;
    margin: 20px auto 30px;
    svg {
        path {
            stroke: ${({ theme }) => theme.colors.grey()};
        }
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.activeGray()};
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 10px;
    }
`;
