import React, { FC, useCallback, useEffect, useState } from 'react';
import Typography from 'src/components/Typography';
import { ReactComponent as TickIcon } from 'src/assets/icons/tick-square.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';

import { useMobile } from 'src/hooks/useMedia';
import { useIntl } from 'react-intl';
import { ContainerSettingsWidget, HeaderSettingsWidget, EditSaveButton, ContentSettingsWidget, Saving } from './styles';

interface ISettingsWidget {
    title: string;
    formId: string;
    isEditDefault?: boolean;
}

const SettingsWidget: FC<ISettingsWidget> = ({ isEditDefault = false, children, title, formId }) => {
    const intl = useIntl();

    const [isEditSettings, setIsEditSettings] = useState(isEditDefault);
    const [isSaving, setIsSaving] = useState(false);

    const toggleSaving = () => setIsSaving((v) => !v);

    useEffect(() => {
        setIsEditSettings(isEditDefault);
    }, [isEditDefault]);

    const isMobile = useMobile();

    const renderButtonEditSave = useCallback(
        (isEditMode: boolean, isMobileDevice: boolean, isSavingForm: boolean) => {
            if (isSavingForm) {
                return <Saving variant="regular14">{intl.formatMessage({ defaultMessage: 'Сохраняем...' })}</Saving>;
            }
            if (isEditMode) {
                return (
                    <EditSaveButton key="saveButton" type="submit" form={formId}>
                        <TickIcon />
                        {!isMobileDevice && (
                            <Typography variant="regular14">
                                {intl.formatMessage({ defaultMessage: 'Сохранить' })}
                            </Typography>
                        )}
                    </EditSaveButton>
                );
            }
            return (
                <EditSaveButton key="editButton" onClick={() => setIsEditSettings(true)}>
                    <EditIcon />
                    {!isMobileDevice && (
                        <Typography variant="regular14">
                            {intl.formatMessage({ defaultMessage: 'Редактировать' })}
                        </Typography>
                    )}
                </EditSaveButton>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formId],
    );

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                // @ts-ignore
                isEditMode: isEditSettings,
                formId,
                setIsEditSettings,
                toggleSaving,
            });
        }
        return child;
    });

    return (
        <ContainerSettingsWidget>
            <HeaderSettingsWidget>
                <Typography variant="subheading">{title}</Typography>
                {renderButtonEditSave(isEditSettings, isMobile, isSaving)}
            </HeaderSettingsWidget>
            <ContentSettingsWidget>{childrenWithProps}</ContentSettingsWidget>
        </ContainerSettingsWidget>
    );
};

export default SettingsWidget;
