import React, { FC } from 'react';
import { IUserProfile } from 'src/store/Auth/types';
import { GeneralInformationWrapper } from 'src/pages/businessOffice/Profile/ProfileTabs/Personal/components/ContactInformation/styles';
import RowLabel from 'src/components/RowLabel';
import FormPersonal from 'src/pages/businessOffice/Profile/ProfileTabs/Personal/components/FormPersonal';
import { useIntl } from 'react-intl';
import { FORM_USER_PERSONAL } from '../../index';

interface IContactInformation {
    isEditMode?: boolean;
    formId?: string;
    setIsEditSettings?: (arg: boolean) => void;
    user: IUserProfile;
    toggleSaving?: () => void;
}

const ContactInformation: FC<IContactInformation> = ({
    isEditMode = false,
    formId = FORM_USER_PERSONAL,
    setIsEditSettings = () => null,
    user,
    toggleSaving = () => null,
}) => {
    const intl = useIntl();

    const userFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Фамилия' }),
            value: user && user.lastName,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Имя' }),
            value: user && user.firstName,
        },
        {
            label: 'Email',
            value: user?.email || '',
        },
    ];

    if (isEditMode) {
        return (
            <FormPersonal
                toggleSaving={toggleSaving}
                formId={formId}
                setIsEditSettings={setIsEditSettings}
                user={user}
            />
        );
    }

    return (
        <GeneralInformationWrapper>
            {userFields.map((userField) => (
                <RowLabel key={userField.label} label={userField.label} value={userField.value} />
            ))}
        </GeneralInformationWrapper>
    );
};

export default ContactInformation;
