import React from 'react';
import { MerchantWrapper } from 'src/components/Widgets/ClaimModeratingWidget/components/Merchant/styles';
import ColumnLabel from 'src/components/Widgets/ClaimModeratingWidget/components/ColumnLabel';
import { useSelector } from 'react-redux';
import { getCurrentMerchant } from 'src/store/Merchant/selectors';
import { IMcc, IMerchant } from 'src/store/Merchant/types';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import { convertPhoneToShow } from 'src/utils/formatters';
import useDictionary from 'src/hooks/useDictionarty';
import { DICTIONARY_TYPES } from 'src/store/Dictionaries/constants';
import { useIntl } from 'react-intl';

const Merchant = () => {
    const intl = useIntl();
    const currentMerchant: IMerchant = useSelector(getCurrentMerchant) as any;
    const mccList: IMcc[] = useDictionary(DICTIONARY_TYPES.mcc)?.data as any;

    const merchantFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Наименование' }),
            value: currentMerchant?.[MERCHANT_FIELDS.name],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Категория' }),
            value:
                mccList && mccList.find((category) => category.id === currentMerchant?.[MERCHANT_FIELDS.mccId])?.name,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Описание' }),
            value: currentMerchant?.[MERCHANT_FIELDS.description],
        },
        {
            label: 'Email',
            value: currentMerchant?.[MERCHANT_FIELDS.email],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Адрес веб-сайта' }),
            value: currentMerchant?.[MERCHANT_FIELDS.website],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Телефон' }),
            value:
                currentMerchant?.[MERCHANT_FIELDS.phone] &&
                convertPhoneToShow(currentMerchant?.[MERCHANT_FIELDS.phone]),
        },
    ];

    if (currentMerchant) {
        return (
            <MerchantWrapper>
                {merchantFields.map((field) => (
                    <ColumnLabel label={field.label} value={field.value} key={field.label} />
                ))}
            </MerchantWrapper>
        );
    }

    return null;
};

export default Merchant;
