import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ColumnFieldWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    flex-direction: column;
    grid-row-gap: 7px;
`;

export const ColumnFieldLabel = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const ColumnFieldValue = styled(Typography)``;
