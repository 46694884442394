import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as addIcon } from 'src/assets/icons/plus.svg';
import Typography from 'src/components/Typography';
import Button from 'src/components/Button';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Heading = styled.div`
    width: 100%;
    position: relative;
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
`;

export const Footer = styled.div`
    width: 100%;
    position: relative;
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    align-self: flex-end;
`;

export const Title = styled(Typography).attrs({ variant: 'title' })`
    ${({ theme }) => theme.mixins.textOverflowDots};

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.subheading.fontSize}px;
    }
`;

export const Balance = styled(Typography)`
    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.subheading.fontSize}px;
    }
`;

export const Empty = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    padding: 4px 0;
    text-align: center;
`;

export const InnerContent = styled.div`
    padding: 10px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    margin-bottom: 17px;
`;

export const WrapTop = styled.div``;

export const WrapTitle = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    min-width: 70%;
`;

export const AddIcon = styled(addIcon)`
    margin-left: 12px;
`;

export const NewOrderBtn = styled(NavLink)`
    color: ${({ theme }) => theme.colors.grey()};
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    text-decoration: none;
    ${({ theme }) => theme.mixins.flexCenter};
    span {
        flex-direction: row-reverse;
        svg {
            margin-right: 0;
        }
    }
`;

export const MoreBtn = styled(Button).attrs({ type: 'link' })`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const MerchantWidgetLink = styled(NavLink)`
    color: ${({ theme }) => theme.colors.black()};
    text-decoration: none;
    &:hover {
        color: ${({ theme }) => theme.colors.grey()};
    }
`;
