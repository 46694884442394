import React, { FC } from 'react';
import RowLabel from 'src/components/RowLabel';
import { GeneralInformation } from 'src/pages/businessOffice/OrderContainer/components/OrderGeneral/styles';
import { ORDER_FIELDS, TOrder } from 'src/store/Order/types';
import { convertDateAndTimeToShow } from 'src/utils/helpers';
import FormOrderGeneral from 'src/pages/businessOffice/OrderContainer/components/FormOrderGeneral';
import { useIntl } from 'react-intl';
import { OrderSubtitle } from '../DetailOrder/styles';

interface IOrderGeneral {
    isEdit?: boolean;
    order: TOrder;
}

const OrderGeneral: FC<IOrderGeneral> = ({ isEdit = false, order }) => {
    const intl = useIntl();
    const orderGeneralFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Номер счёта' }),
            value: order?.[ORDER_FIELDS.merchantOrderId],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Срок оплаты' }),
            value: order?.[ORDER_FIELDS.expirationDate] && convertDateAndTimeToShow(order[ORDER_FIELDS.expirationDate]),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Комментарий' }),
            value: order?.[ORDER_FIELDS.description],
        },
    ];

    return (
        <div>
            <OrderSubtitle variant="subheading">
                {intl.formatMessage({ defaultMessage: 'Общие сведения' })}
            </OrderSubtitle>
            {isEdit ? (
                <FormOrderGeneral />
            ) : (
                <GeneralInformation>
                    {orderGeneralFields.map((orderField) => (
                        <RowLabel
                            isShowSkeleton={!order}
                            key={orderField.label}
                            label={orderField.label}
                            value={orderField.value}
                        />
                    ))}
                </GeneralInformation>
            )}
        </div>
    );
};

export default OrderGeneral;
