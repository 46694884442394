import React, { FC, useState } from 'react';
import { PhoneInput } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import Subtitle from '../Subtitle';
import * as S from './styles';
import Button from '../../../../../components/Button';
import { useMobile } from '../../../../../hooks/useMedia';

type TProps = {
    goToNextStep: () => void;
};

const PhoneEnterStep: FC<TProps> = ({ goToNextStep }) => {
    const intl = useIntl();

    const [phone, setPhone] = useState<string>('');
    const isMobile = useMobile();

    const handleSendCode = () => {
        // тут будет логика по отправке кода на указанный номер телефона
        goToNextStep();
    };

    return (
        <>
            <Subtitle steps="2/2" label={intl.formatMessage({ defaultMessage: 'Отправим SMS на номер телефона' })} />
            <S.Wrapper>
                <PhoneInput
                    label={intl.formatMessage({ defaultMessage: 'Номер телефона' })}
                    countrySelectProps={{
                        selectedLabel: '',
                        placeholder: '',
                    }}
                    value={phone}
                    onChange={setPhone}
                />
                <Button fullWidth={isMobile} disabled={!phone} onClick={handleSendCode}>
                    {intl.formatMessage({ defaultMessage: 'Отправить код' })}
                </Button>
            </S.Wrapper>
        </>
    );
};

export default PhoneEnterStep;
