import http, { ICommonResponse } from '../../utils/http';
import { TOrderContainer } from './types';

export type TOrderContainerListFilter = {
    _page: number;
    _pageSize: number;

    payerCounterpartyId: string;
    'createdAt[_ge]'?: string;
    'createdAt[lt]'?: string;
};

export const getOrderContainerListRequest = (filter: TOrderContainerListFilter) =>
    http.get<ICommonResponse<Array<TOrderContainer>>>('billing/api/order/order-container', {
        params: { ...filter, '_order[createdAt]': 'desc' },
    });
