import React, { FC } from 'react';
import { ICounterparty, ILegalEntityType } from 'src/store/Counterparty/types';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { GeneralWrapper } from 'src/pages/businessOffice/Counterparty/components/General/styles';
import RowLabel from 'src/components/RowLabel';
import dayjs from 'dayjs';
import FormGeneral from 'src/pages/businessOffice/Counterparty/components/FormGeneral';
import useDictionary from 'src/hooks/useDictionarty';
import { DICTIONARY_TYPES } from 'src/store/Dictionaries/constants';
import { useIntl } from 'react-intl';
import { FORM_COUNTERPARTY_GENERAL } from '../../index';

interface IGeneral {
    isEditMode?: boolean;
    formId?: string;
    setIsEditSettings?: (arg: boolean) => void;
    counterparty: ICounterparty;
    toggleSaving?: () => void;
}

const General: FC<IGeneral> = ({
    isEditMode = false,
    formId = FORM_COUNTERPARTY_GENERAL,
    setIsEditSettings = () => null,
    counterparty,
    toggleSaving = () => null,
}) => {
    const intl = useIntl();
    const legalEntityTypes: ILegalEntityType[] = useDictionary(DICTIONARY_TYPES.legalEntityType)?.data as any;

    const counterpartyFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'ИНН' }),
            value: counterparty[COUNTERPARTY_FIELDS.vatNumber],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Наименование' }),
            value: counterparty[COUNTERPARTY_FIELDS.name],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Тип ОПФ' }),
            value:
                legalEntityTypes?.find(
                    (legalEntityType) => legalEntityType?.id === counterparty[COUNTERPARTY_FIELDS.legalEntityTypeId],
                )?.name || '',
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Дата регистрации' }),
            value: dayjs(counterparty[COUNTERPARTY_FIELDS.registrationDate]).format('D.MM.YYYY'),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Адрес регистрации' }),
            value: counterparty[COUNTERPARTY_FIELDS.registrationAddress],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'ОГРН' }),
            value: counterparty[COUNTERPARTY_FIELDS.registrationNumber],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'КПП' }),
            value: counterparty[COUNTERPARTY_FIELDS.taxRegistrationReasonCode],
        },
    ];
    if (isEditMode) {
        return (
            <FormGeneral
                legalEntityTypes={legalEntityTypes}
                counterparty={counterparty}
                formId={formId}
                setIsEditSettings={setIsEditSettings}
                toggleSaving={toggleSaving}
            />
        );
    }

    return (
        <GeneralWrapper>
            {counterpartyFields.map((field) => (
                <RowLabel key={field.label} label={field.label} value={field.value} />
            ))}
        </GeneralWrapper>
    );
};

export default General;
