import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    TCreateSecurityApiUserGroup,
    TSecurityApiUserGroup,
    TSecurityApiUserGroupFilter,
} from 'src/store/SecurityApiUseGroup/types';
import {
    codeActivateSecurityApiUserGroupRequest,
    createSecurityApiUserGroupRequest,
    fetchSecurityApiUserGroupListRequest,
} from 'src/store/SecurityApiUseGroup/http';
import dayjs from 'dayjs';
import { UTC_DATE_FORMAT } from 'src/utils/constants';

export const fetchSecurityApiUserGroupList = createAsyncThunkWithErrorHandler(
    'securityApiUserGroup/fetchSecurityApiUserGroupList',
    async (params: TSecurityApiUserGroupFilter) => {
        const { data } = await fetchSecurityApiUserGroupListRequest(params);

        return data.data;
    },
);

export const createSecurityApiUserGroup = createAsyncThunkWithErrorHandler(
    'securityApiUserGroup/createSecurityApiUserGroupList',
    async (params: TCreateSecurityApiUserGroup) => {
        const { data } = await createSecurityApiUserGroupRequest(params);

        return data.data;
    },
);

export const codeActivateSecurityApiUserGroup = createAsyncThunkWithErrorHandler(
    'securityApiUserGroup/codeActivateSecurityApiUserGroup',
    async (id: number) => {
        const currentDateAddFiftyMinutes = dayjs().add(20, 'minutes').utc().format(UTC_DATE_FORMAT);
        const { data } = await codeActivateSecurityApiUserGroupRequest(id, currentDateAddFiftyMinutes);

        return data.data;
    },
);

export const securityApiUserGroupAdapter = createEntityAdapter<TSecurityApiUserGroup>();

interface IInitialState {
    loading: boolean;
}

const initialState = securityApiUserGroupAdapter.getInitialState<IInitialState>({
    loading: false,
});

const SecurityApiUserGroup = createSlice({
    name: 'securityApiUserGroup',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetchSecurityApiUserGroupList
        builder.addCase(fetchSecurityApiUserGroupList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchSecurityApiUserGroupList.fulfilled, (state, { payload }) => {
            securityApiUserGroupAdapter.upsertMany(state, payload);
            state.loading = false;
        });
        builder.addCase(fetchSecurityApiUserGroupList.rejected, (state) => {
            state.loading = false;
        });
        // createSecurityApiUserGroupList
        builder.addCase(createSecurityApiUserGroup.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createSecurityApiUserGroup.fulfilled, (state, { payload }) => {
            securityApiUserGroupAdapter.upsertOne(state, payload);
            state.loading = false;
        });
        builder.addCase(createSecurityApiUserGroup.rejected, (state) => {
            state.loading = false;
        });
        // codeActivateSecurityApiUserGroup
        builder.addCase(codeActivateSecurityApiUserGroup.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(codeActivateSecurityApiUserGroup.fulfilled, (state, { payload }) => {
            securityApiUserGroupAdapter.upsertOne(state, payload);
            state.loading = false;
        });
        builder.addCase(codeActivateSecurityApiUserGroup.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default SecurityApiUserGroup.reducer;
