import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useSelector } from 'react-redux';
import { getAllCounterparties } from 'src/store/Counterparty/selectors';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { SettingsWrapper, Title } from 'src/pages/businessOffice/Counterparty/styles';
import SettingsWidget from 'src/components/Widgets/SettingsWidget';
import General from 'src/pages/businessOffice/Counterparty/components/General';
import Contacts from 'src/pages/businessOffice/Counterparty/components/Contacts';
import { useIntl } from 'react-intl';

export const FORM_COUNTERPARTY_GENERAL = 'form-counterparty-general';
export const FORM_COUNTERPARTY_CONTACT = 'form-counterparty-contact';

const Counterparty = () => {
    const intl = useIntl();
    const { counterpartyId } = useParams<{ counterpartyId: string }>();
    const openedCounterparty = useSelector(getAllCounterparties).find(
        (counterparty) => counterparty[COUNTERPARTY_FIELDS.id] === counterpartyId,
    );

    if (!openedCounterparty) {
        return null;
    }

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item to={RoutesBusinessOffice.organizations}>
                    {intl.formatMessage({ defaultMessage: 'Главная' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {intl.formatMessage({ defaultMessage: 'Профиль пользователя' })} -{' '}
                    {openedCounterparty[COUNTERPARTY_FIELDS.name]}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Title variant="bigTitle">{openedCounterparty.name}</Title>
            <SettingsWrapper>
                <SettingsWidget
                    title={intl.formatMessage({ defaultMessage: 'Общие сведения' })}
                    formId={FORM_COUNTERPARTY_GENERAL}
                >
                    <General counterparty={openedCounterparty} />
                </SettingsWidget>
                <SettingsWidget
                    title={intl.formatMessage({ defaultMessage: 'Контактная информация' })}
                    formId={FORM_COUNTERPARTY_CONTACT}
                >
                    <Contacts counterparty={openedCounterparty} />
                </SettingsWidget>
            </SettingsWrapper>
        </>
    );
};

export default Counterparty;
