import React, { FC } from 'react';
import FilteredOrderList from 'src/components/FilterOrders';
import { ContainerNavPage } from 'src/pages/businessOffice/Shop/styles';

const Orders: FC = () => (
    <ContainerNavPage>
        <FilteredOrderList />
    </ContainerNavPage>
);

export default Orders;
