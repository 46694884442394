import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IUserProfile } from 'src/store/Auth/types';
import MainLayout from 'src/layouts/Main';
import AuthDocs from 'src/pages/docs/Auth';
import Auth from 'src/pages/businessOffice/Auth';
import { RoutesBusinessOffice, RoutesDocs } from 'src/pages/constants';
import Dashboard from 'src/pages/businessOffice/Dashboard';
import Marketplace from 'src/pages/businessOffice/Marketplace';
import Introduce from 'src/pages/businessOffice/Introduce';
import Shop from 'src/pages/businessOffice/Shop';
import Payment from 'src/pages/businessOffice/Payment';
import OrderContainer from 'src/pages/businessOffice/OrderContainer';
import Profile from 'src/pages/businessOffice/Profile';
import Counterparty from 'src/pages/businessOffice/Counterparty';
import Feeds from 'src/pages/businessOffice/Feeds';
import Claim from 'src/pages/businessOffice/Claim';
import Refund from 'src/pages/businessOffice/Refund';
import DashboardDocs from './docs/Dashboard';
import Statuses from './docs/Statuses';
import DocsAuthLayout from '../components/DocsPageContainer';
import Mail from './docs/Mail';
import Edo from './docs/Edo';
import Email from './docs/Email';

interface IAuthRouterProps {
    user: IUserProfile;
}

export const BusinessOfficeAuthRouter: FC<IAuthRouterProps> = ({ user }) => {
    const isNewUser = !user?.firstName && !user?.lastName;

    if (isNewUser) {
        return (
            <Switch>
                <Route path={RoutesBusinessOffice.introduce}>
                    <Introduce />
                </Route>
                <Redirect to={RoutesBusinessOffice.introduce} />
            </Switch>
        );
    }

    return (
        <MainLayout>
            <Switch>
                <Route path={RoutesBusinessOffice.profile} component={Profile} />
                <Route path={`${RoutesBusinessOffice.counterparty}/:counterpartyId`} component={Counterparty} />
                <Route path={`${RoutesBusinessOffice.shop}/:merchantId`} component={Shop} />
                <Route path={RoutesBusinessOffice.dashboard} component={Dashboard} />
                <Route path={`${RoutesBusinessOffice.payment}/:payerUserId`} component={Payment} />
                <Route path={RoutesBusinessOffice.marketplace} exact component={Marketplace} />
                <Route path={RoutesBusinessOffice.feeds} exact component={Feeds} />
                {/* роут для случаев, когда переход по нажатию на фид в фоне */}
                <Route path={`${RoutesBusinessOffice.feeds}/:pushFeedId`} exact component={Feeds} />
                <Route
                    path={`${RoutesBusinessOffice.ordercontainer}/:orderContainerId${RoutesBusinessOffice.refundCreate}`}
                    component={Refund}
                />
                <Route path={`${RoutesBusinessOffice.ordercontainer}/:orderContainerId`} component={OrderContainer} />
                {/* когда переход на страницу создания заявки со страницы мерчанта */}
                <Route path={`${RoutesBusinessOffice.claim}/:counterpartyId/:merchantId`} component={Claim} />
                <Route path={`${RoutesBusinessOffice.claim}/:counterpartyId`} component={Claim} />
                <Redirect to={RoutesBusinessOffice.dashboard} />
            </Switch>
        </MainLayout>
    );
};

export const BusinessOfficeNotAuthRouter = () => (
    <>
        <Route path={RoutesBusinessOffice.auth} component={Auth} />
        <Redirect to={RoutesBusinessOffice.auth} />
    </>
);

export const DocsNotAuthRouter = () => (
    <>
        <Route path={RoutesDocs.auth} component={AuthDocs} />
        <Redirect to={RoutesDocs.auth} />
    </>
);

export const DocsAuthRouter = () => (
    <DocsAuthLayout>
        <Switch>
            <Route path={RoutesDocs.dashboard} component={DashboardDocs} />
            <Route path={RoutesDocs.statuses} component={Statuses} />
            <Route path={`${RoutesDocs.mail}/:documentId`} component={Mail} />
            <Route path={`${RoutesDocs.edo}/:documentId`} component={Edo} />
            <Route path={`${RoutesDocs.email}/:documentId`} component={Email} />
            <Redirect to={RoutesDocs.dashboard} />
        </Switch>
    </DocsAuthLayout>
);
