import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';
import { COLOR_BRIGHTNESS } from '../../../theme/utils';

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
    justify-content: space-between;
    padding: 0 16px 8px 8px;
`;

export const ChildrenWrapper = styled.div``;

export const OrganizationData = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 16px;
`;

export const Parameter = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 4px;
`;

export const Label = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.LIGHT)};
`;

export const Value = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
`;
