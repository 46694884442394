import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { thunkHandler } from 'src/utils/helpers';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import { createClientRequest, getClientsListRequest, updateClientRequest, removeClientRequest } from './http';
import { IClient, TClientRequestFields } from './types';

export const createClient = createAsyncThunkWithErrorHandler(
    'client/clientCreate',
    async (data: TClientRequestFields, thunkAPI) => thunkHandler(createClientRequest(data), thunkAPI),
);

export const getClientsList = createAsyncThunkWithErrorHandler('client/getClientsList', async (thunkAPI) =>
    thunkHandler(getClientsListRequest(), thunkAPI),
);

export const updateClient = createAsyncThunkWithErrorHandler('client/clientUpdate', async (data: any, thunkApi) =>
    thunkHandler(updateClientRequest(data), thunkApi),
);

export const removeClient = createAsyncThunkWithErrorHandler('client/clientDelete', async (id: number) => {
    await removeClientRequest(id);
    return id;
});

export const clientAdapter = createEntityAdapter<IClient>();
const initialState = clientAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
});

const Client = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClient(state, action) {
            state.selectedId = action.payload;
        },
        clearClients() {
            return initialState;
        },
        clearCurrentClient(state) {
            state.selectedId = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createClient.fulfilled, (state, action) => {
            clientAdapter.addOne(state, action.payload);
        });
        builder.addCase(getClientsList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getClientsList.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getClientsList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isLoaded = true;
            clientAdapter.addMany(state, action.payload);
        });
        builder.addCase(updateClient.fulfilled, (state, action) => {
            clientAdapter.upsertOne(state, action.payload);
        });
        builder.addCase(removeClient.fulfilled, (state, id) => {
            clientAdapter.removeOne(state, id);
        });
    },
});

export const { setClient, clearClients, clearCurrentClient } = Client.actions;
export default Client.reducer;
