import styled from 'styled-components';
import Typography from 'src/components/Typography';
import RowLabel from 'src/components/RowLabel';

export const RequisitesInformation = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 32px;
    grid-column-gap: 45px;
    padding-left: 20px;
    margin-bottom: 46px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 100%;
    }
`;

export const EmptyCustomerLabel = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    margin-bottom: 46px;
`;

export const RegistrationAddress = styled(RowLabel)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 100%;
        grid-column-end: 2;
    }
`;
