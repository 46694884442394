import React from 'react';
import { ButtonCreateWrapper } from 'src/pages/businessOffice/OrderContainer/components/ButtonCreateRefund/styles';
import { ReactComponent as IconRefund } from 'src/assets/icons/return.svg';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

const ButtonCreateRefund = () => {
    const { orderContainerId } = useParams<{ orderContainerId: string }>();
    const intl = useIntl();

    return (
        <ButtonCreateWrapper
            to={`${RoutesBusinessOffice.ordercontainer}/${orderContainerId}${RoutesBusinessOffice.refundCreate}`}
        >
            <IconRefund /> {intl.formatMessage({ defaultMessage: 'Создать возврат' })}
        </ButtonCreateWrapper>
    );
};

export default ButtonCreateRefund;
