import React, { FC, useState } from 'react';
import {
    ButtonOpenCodeIntegration,
    IntegrationWrapper,
    MerchantIntegrationName,
    MerchantIntegrationSettingName,
    Status,
    StatusWrapper,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/Integrations/components/IntegrationItem/styles';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useModal from 'src/hooks/useModal';
import ModalIntegrationCode from 'src/pages/businessOffice/Shop/ShopTabsTables/Integrations/components/ModalIntegrationCode';
import { useTablet } from 'src/hooks/useMedia';
import { useAppDispatch } from 'src/store';
import { TSecurityApiUserGroup } from 'src/store/SecurityApiUseGroup/types';
import { getApplicationById } from 'src/store/Application/selectors';
import { IIKO_APPLICATION_ID } from 'src/store/Application/types';
import { codeActivateSecurityApiUserGroup } from 'src/store/SecurityApiUseGroup';

interface IIntegrationItem {
    securityApiUserGroup: TSecurityApiUserGroup;
}

const IntegrationItem: FC<IIntegrationItem> = ({ securityApiUserGroup }) => {
    const intl = useIntl();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);
    const isTablet = useTablet();
    const dispatch = useAppDispatch();

    const [activationCode, setActivationCode] = useState<string>();
    const [activationExpiredAt, setActivationExpiredAt] = useState<string>();

    const application = useSelector(getApplicationById(securityApiUserGroup.applicationId));

    const isActive = securityApiUserGroup.status === 'active';

    const openModalCodeActive = async () => {
        const dataMerchantIntegrationSettingCode = await dispatch(
            codeActivateSecurityApiUserGroup(securityApiUserGroup.id),
        );
        const merchantIntegrationSettingCode: TSecurityApiUserGroup =
            dataMerchantIntegrationSettingCode?.payload as any;
        const activateCode = merchantIntegrationSettingCode?.activationCode;
        const expiredTime = merchantIntegrationSettingCode?.activationExpiredAt;
        if (activateCode && expiredTime) {
            setActivationCode(activateCode);
            setActivationExpiredAt(expiredTime);
            openModal();
        }
    };

    const getStatus = () => {
        if (isTablet) {
            if (isActive) {
                return intl.formatMessage({ defaultMessage: 'Активна' });
            }
            return intl.formatMessage({ defaultMessage: 'Не активна' });
        }
        if (isActive) {
            return intl.formatMessage({ defaultMessage: 'Активирована' });
        }
        return intl.formatMessage({ defaultMessage: 'Не активирована' });
    };

    return (
        <IntegrationWrapper>
            <MerchantIntegrationName variant="regular14">{application?.name}</MerchantIntegrationName>
            <MerchantIntegrationSettingName variant="regular14">
                {securityApiUserGroup?.name}
            </MerchantIntegrationSettingName>
            <StatusWrapper>
                <Status variant="regular14" isActive={isActive}>
                    {getStatus()}
                </Status>
                {!isActive && application?.applicationId === IIKO_APPLICATION_ID && (
                    <ButtonOpenCodeIntegration onClick={openModalCodeActive}>Код активации</ButtonOpenCodeIntegration>
                )}
            </StatusWrapper>
            {activationCode && activationExpiredAt && (
                <ModalIntegrationCode
                    expiredTime={activationExpiredAt}
                    codeActivation={activationCode}
                    isAfterCreateIntegration={false}
                    onClose={onCloseModal}
                    isOpen={isOpenModal}
                />
            )}
        </IntegrationWrapper>
    );
};

export default IntegrationItem;
