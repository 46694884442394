import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'src/components/Typography';
import Skeleton from 'src/components/Skeleton';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';

export const LayoutWrapper = styled.div`
    min-height: 100vh;
`;

export const PageContainer = styled.div`
    margin: 60px 0;
    ${({ theme }) => theme.mixins.mainGrid};

    @media ${({ theme }) => theme.breakpoints.xl} {
        grid-template-columns: 100%;
        margin-top: 25px;
    }

    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: 100%;
    }
`;

export const Sidebar = styled.aside``;

export const NavbarTitle = styled(Typography)`
    margin: 26px 0;
`;

export const PageInner = styled.div`
    width: 100%;
`;

export const NavDropdownSkeleton = styled(Skeleton)`
    height: 76px;
`;

export const ProductsAccordionSkeleton = styled(Skeleton)`
    height: 150px;
`;

interface IMerchantLink {
    isactivelink?: boolean;
}

export const MerchantLink = styled(Link)<IMerchantLink>`
    ${({ theme }) => theme.mixins.dropdownItem};
    padding-left: 56px;
    height: 100%;
    background-color: ${({ theme, isactivelink }) =>
        isactivelink ? theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM) : 'transparent'};
    text-decoration: none;
`;

export const MerchantItemTitle = styled(Typography)`
    padding-right: 20px;
    ${({ theme }) => theme.mixins.textOverflowDots};
`;

export const MerchantItemLabel = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    white-space: nowrap;
`;
