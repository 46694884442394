import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const OrganizationWrapper = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    padding: 20px 30px;
    width: 100%;
    display: grid;
    grid-template-columns: auto 120px 16px;
    align-items: center;
    grid-column-gap: 20px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    margin-bottom: 1px;
    @media ${({ theme }) => theme.breakpoints.md} {
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
        padding: 20px 20px 12px 20px;
        grid-template-columns: auto 16px;
        grid-row-gap: 18px;
    }
`;

export const Title = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
`;

export const IconWrapper = styled.div`
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.lightGrey()};
    width: 36px;
    height: 36px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-shrink: 0;
    svg {
        width: 16px;
        height: 16px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 30px;
        height: 30px;
        svg {
            width: 13px;
            height: 13px;
        }
    }
`;

export const Name = styled(Typography)``;

export const VatNumber = styled(Typography)`
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-start: 1;
        grid-column-end: 2;
    }
`;

export const ButtonDelete = styled.button`
    width: 16px;
    height: 16px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }
`;
