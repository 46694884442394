import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 8px 20px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    gap: 30px;
`;

export const Counter = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 8px;
`;

export const ResetButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
