import React, { FC } from 'react';
import {
    ModalDescription,
    ModalNote,
    ModalSuccessRefundWrapper,
    ModalTitle,
    RefundsNavLink,
} from 'src/pages/businessOffice/Refund/components/ModalSuccessRefund/styles';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface IModalSuccessRefund {
    onClose: () => void;
    isOpen: boolean;
    merchantId: string;
}

const ModalSuccessRefund: FC<IModalSuccessRefund> = ({ merchantId, onClose, isOpen }) => {
    const intl = useIntl();
    const { orderContainerId } = useParams<{ orderContainerId: string }>();
    const history = useHistory();

    const onCloseModal = () => {
        onClose();
        history.push(`${RoutesBusinessOffice.ordercontainer}/${orderContainerId}`);
    };

    return (
        <ModalSuccessRefundWrapper closeModal={onCloseModal} isOpen={isOpen}>
            <ModalTitle variant="title">{intl.formatMessage({ defaultMessage: 'Возврат оформлен!' })}</ModalTitle>
            <ModalDescription variant="regular14">
                {intl.formatMessage({ defaultMessage: 'В ближайшее время клиент получит SMS уведомление' })}
            </ModalDescription>
            <ModalNote variant="regular12">
                {intl.formatMessage({ defaultMessage: 'Повторно найти возврат можно в' })}{' '}
                <RefundsNavLink to={`${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.refunds}`}>
                    {intl.formatMessage({ defaultMessage: 'списке возвратов' })}
                </RefundsNavLink>
            </ModalNote>
        </ModalSuccessRefundWrapper>
    );
};

export default ModalSuccessRefund;
