import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Skeleton from 'src/components/Skeleton';

export const Title = styled(Typography).attrs(() => ({}))`
    padding: 5px 10px;
    margin-top: auto;
    margin-bottom: auto;
`;

export const IconClose = styled.div`
    position: absolute;
    top: -80px;
    right: 10px;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.base()};
`;

export const AutocompleteContentWrapper = styled.div`
    height: 100%;
    padding-top: 24px;
`;

export const LoadingItem = styled(Skeleton).attrs({ height: 17, fullWidth: true })`
    margin: 15px 0;
`;

export const ScrollList = styled.div`
    background: #ffffff;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
    border-radius: 0 0 12px 12px;
    margin-top: 1px;
    position: absolute;
    z-index: 4;
    width: 100%;
`;

export const InputAutocompleteContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const ButtonItemList = styled.button`
    height: 47px;
    width: 100%;
    text-align: left;
    padding-left: 16px;
`;
export const LabelButtonItem = styled(Typography)``;
