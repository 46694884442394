import React from 'react';
import { Field } from 'react-final-form';

import Select, { ISelectProps } from 'src/components/Select';
import Textarea from 'src/components/Textarea';
import Input, { IInputProps } from 'src/components/Input';
import ExternalSelect, { IExternalSelectProps } from 'src/components/ExternalSelect';
import InputAutocompleteAddress from 'src/components/InputAutocomplete/InputAutocompleteAddress';
import InputCurrency, { IInputCurrencyProps } from 'src/components/InputCurrency';
import InputDateTime, { IInputDateTimeProps } from 'src/components/InputDateTime';
import InputAutocompleteVat from 'src/components/InputAutocomplete/InputAutocompleteVat';
import Checkbox from 'src/components/Checkbox';
import { IToggleProps, Toggle } from 'src/components/Toggle';

export enum FormFieldTypeEnum {
    input = 'input',
    textarea = 'textarea',
    select = 'select',
    externalSelect = 'externalSelect',
    autoCompleteAddress = 'autoCompleteAddress',
    autoCompleteVat = 'autoCompleteVat',
    inputCurrency = 'inputCurrency',
    inputDateTime = 'inputDateTime',
    checkbox = 'checkbox',
    toggle = 'toggle',
}

// @ts-ignore
interface IFormFieldProps
    extends IInputProps,
        IInputCurrencyProps,
        ISelectProps,
        IExternalSelectProps,
        IInputDateTimeProps,
        IToggleProps {
    name: string;
    fieldType?: FormFieldTypeEnum;
    autoFill?: (value: any) => { [key: string]: any };
    checked?: boolean;
}

const FieldsMap = {
    [FormFieldTypeEnum.input]: Input,
    [FormFieldTypeEnum.textarea]: Textarea,
    [FormFieldTypeEnum.select]: Select,
    [FormFieldTypeEnum.externalSelect]: ExternalSelect,
    [FormFieldTypeEnum.autoCompleteAddress]: InputAutocompleteAddress,
    [FormFieldTypeEnum.autoCompleteVat]: InputAutocompleteVat,
    [FormFieldTypeEnum.inputCurrency]: InputCurrency,
    [FormFieldTypeEnum.inputDateTime]: InputDateTime,
    [FormFieldTypeEnum.checkbox]: Checkbox,
    [FormFieldTypeEnum.toggle]: Toggle,
};

// eslint-disable-next-line react/function-component-definition
function FormField<T = IInputProps>({ name, fieldType = FormFieldTypeEnum.input, ...inputProps }: T & IFormFieldProps) {
    // @ts-ignore
    const FieldComponent = FieldsMap[fieldType];

    return (
        <Field name={name} allowNull={false} type={inputProps.type}>
            {({ input, meta: { invalid, touched, error } }) => (
                // @ts-ignore
                <FieldComponent {...input} hasError={touched && invalid} error={error} {...inputProps} />
            )}
        </Field>
    );
}

export default React.memo(FormField);
