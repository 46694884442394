import React, { FC } from 'react';
import { CUSTOMER_TYPES, ORDER_CUSTOMER_FIELDS, TOrderCustomer } from 'src/store/Order/types';
import { OrderSubtitle } from 'src/pages/businessOffice/OrderContainer/components/DetailOrder/styles';
import RowLabel from 'src/components/RowLabel';
import { convertPhoneToShow } from 'src/utils/formatters';
import {
    EmptyCustomerLabel,
    RegistrationAddress,
    RequisitesInformation,
} from 'src/pages/businessOffice/OrderContainer/components/Requisites/styles';
import FormRequisites from 'src/pages/businessOffice/OrderContainer/components/FormRequisites';
import { isEqualObjects } from 'src/utils/helpers';
import { useIntl } from 'react-intl';

interface IRequisites {
    isEdit?: boolean;
    customer: TOrderCustomer;
}

const Requisites: FC<IRequisites> = ({ isEdit = false, customer }) => {
    const intl = useIntl();

    const customerFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Телефон' }),
            value: customer?.[ORDER_CUSTOMER_FIELDS.phone] && convertPhoneToShow(customer[ORDER_CUSTOMER_FIELDS.phone]),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Email' }),
            value: customer?.[ORDER_CUSTOMER_FIELDS.email],
        },
        {
            label:
                customer?.[ORDER_CUSTOMER_FIELDS.type] === CUSTOMER_TYPES.legal
                    ? intl.formatMessage({ defaultMessage: 'Наименование' })
                    : intl.formatMessage({ defaultMessage: 'ФИО' }),
            value: customer?.[ORDER_CUSTOMER_FIELDS.name],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'ИНН' }),
            value: customer?.[ORDER_CUSTOMER_FIELDS.vatNumber],
        },
        {
            name: ORDER_CUSTOMER_FIELDS.registrationAddress,
            label: intl.formatMessage({ defaultMessage: 'Юридический адрес' }),
            value: customer?.[ORDER_CUSTOMER_FIELDS.registrationAddress],
        },
    ];

    const renderCustomerFields = () => {
        if (customer && isEqualObjects(customer, {})) {
            return (
                <EmptyCustomerLabel variant="regular12">
                    {intl.formatMessage({ defaultMessage: 'Вы можете заполнить реквизиты в режиме редактирования' })}
                </EmptyCustomerLabel>
            );
        }

        return (
            <RequisitesInformation>
                {customerFields.map((customerField) => {
                    if (!customer) {
                        return (
                            <RowLabel
                                key={customerField.label}
                                label={customerField.label}
                                value={String(customerField.value)}
                                isShowSkeleton
                            />
                        );
                    }
                    if (customerField.value) {
                        if (customerField.name === ORDER_CUSTOMER_FIELDS.registrationAddress) {
                            return (
                                <RegistrationAddress
                                    key={customerField.label}
                                    label={customerField.label}
                                    value={String(customerField.value)}
                                />
                            );
                        }
                        return (
                            <RowLabel
                                key={customerField.label}
                                label={customerField.label}
                                value={String(customerField.value)}
                            />
                        );
                    }
                    return null;
                })}
            </RequisitesInformation>
        );
    };

    return (
        <div>
            <OrderSubtitle variant="regular14">{intl.formatMessage({ defaultMessage: 'Клиент' })}</OrderSubtitle>
            {isEdit ? <FormRequisites /> : renderCustomerFields()}
        </div>
    );
};

export default Requisites;
