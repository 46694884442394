import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCounterparty } from 'src/store/Counterparty/selectors';
import { ICounterparty, ILegalEntityType } from 'src/store/Counterparty/types';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { CounterpartyWrapper } from 'src/components/Widgets/ClaimModeratingWidget/components/Counterparty/styles';
import ColumnLabel from 'src/components/Widgets/ClaimModeratingWidget/components/ColumnLabel';
import { DD_MM_YYYY_DATE_FORMAT, INDIVIDUAL_OGRNIP } from 'src/utils/constants';
import { convertPhoneToShow } from 'src/utils/formatters';
import dayjs from 'dayjs';
import useDictionary from 'src/hooks/useDictionarty';
import { DICTIONARY_TYPES } from 'src/store/Dictionaries/constants';
import { useIntl } from 'react-intl';

const Counterparty = () => {
    const intl = useIntl();
    const counterparty: ICounterparty = useSelector(getCurrentCounterparty) as any;

    const legalEntityTypes: ILegalEntityType[] = useDictionary(DICTIONARY_TYPES.legalEntityType)?.data as any;

    const counterpartyFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Наименование' }),
            value: counterparty?.[COUNTERPARTY_FIELDS.name],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'ИНН' }),
            value: counterparty?.[COUNTERPARTY_FIELDS.vatNumber],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Адрес' }),
            value: counterparty?.[COUNTERPARTY_FIELDS.registrationAddress],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'ОПФ' }),
            value:
                legalEntityTypes &&
                legalEntityTypes?.find(
                    (legalEntityType) => legalEntityType.id === counterparty?.[COUNTERPARTY_FIELDS.legalEntityTypeId],
                )?.name,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Генеральный директор' }),
            value: counterparty?.[COUNTERPARTY_FIELDS.ceoName],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'КПП' }),
            value: counterparty?.[COUNTERPARTY_FIELDS.taxRegistrationReasonCode],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Бухгалтерия' }),
            value: counterparty?.[COUNTERPARTY_FIELDS.cfoName],
        },
        {
            label:
                counterparty?.[COUNTERPARTY_FIELDS.registrationNumber].length === INDIVIDUAL_OGRNIP
                    ? intl.formatMessage({ defaultMessage: 'ОГРНИП' })
                    : intl.formatMessage({ defaultMessage: 'ОГРН' }),
            value: counterparty?.[COUNTERPARTY_FIELDS.registrationNumber],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Email' }),
            value: counterparty?.[COUNTERPARTY_FIELDS.email],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Телефон' }),
            value: convertPhoneToShow(counterparty?.[COUNTERPARTY_FIELDS.phone]),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Дата регистрации' }),
            value: dayjs(counterparty?.[COUNTERPARTY_FIELDS.registrationDate]).format(DD_MM_YYYY_DATE_FORMAT),
        },
    ];

    if (counterparty) {
        return (
            <CounterpartyWrapper>
                {counterpartyFields.map((field) => (
                    <ColumnLabel key={field.label} label={field.label} value={field.value} />
                ))}
            </CounterpartyWrapper>
        );
    }

    return null;
};

export default Counterparty;
