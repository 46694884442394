import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    DetailOrderWrapper,
    OrderBorder,
    OrderForm,
    OrderHeader,
    OrderHeaderTitle,
    OrderInformation,
    OrderTitle,
} from 'src/pages/businessOffice/OrderContainer/components/DetailOrder/styles';
import { Form } from 'react-final-form';

import {
    CUSTOMER_TYPES,
    ORDER_CUSTOMER_FIELDS,
    ORDER_FIELDS,
    TOrder,
    TOrderCustomer,
    UnprocessableOrderStatuses,
} from 'src/store/Order/types';
import OrderGeneral from 'src/pages/businessOffice/OrderContainer/components/OrderGeneral';
import Requisites from 'src/pages/businessOffice/OrderContainer/components/Requisites';
import OrderItems from 'src/pages/businessOffice/OrderContainer/components/OrderItems';
import OrderTotal from 'src/pages/businessOffice/OrderContainer/components/OrderTotal';
import ButtonEditOrSave from 'src/pages/businessOffice/OrderContainer/components/ButtonEditOrSave';
import getCustomerForSubmitOrder from 'src/utils/helpers/getCustomerForSubmitOrder';
import dayjs from 'dayjs';
import { UTC_DATE_FORMAT } from 'src/utils/constants';
import { useAppDispatch } from 'src/store';
import { cancelOrder, updateOrder } from 'src/store/Order';
import useValidationSchema from 'src/hooks/useValidationSchema';
import { orderSchema } from 'src/pages/businessOffice/Shop/validations';
import Breadcrumb from 'src/components/Breadcrumb';
import { RoutesBusinessOffice } from 'src/pages/constants';
import Skeleton from 'src/components/Skeleton';
import { IMerchant } from 'src/store/Merchant/types';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import ButtonCreateRefund from 'src/pages/businessOffice/OrderContainer/components/ButtonCreateRefund';
import { useIntl } from 'react-intl';

interface IDetailOrder {
    orderData: TOrder;
    merchant: IMerchant;
    debitInvoiceCustomer: TOrderCustomer;
}

const DetailOrder: FC<IDetailOrder> = ({ debitInvoiceCustomer, merchant, orderData }) => {
    const intl = useIntl();
    const validation = useValidationSchema(orderSchema);
    const dispatch = useAppDispatch();

    const [isEdit, setIsEdit] = useState(false);
    const [order, setOrder] = useState(orderData);
    const [basketItems, setBasketItems] = useState(order && order[ORDER_FIELDS.basketItems]);
    const [isSaved, setIsSaved] = useState(false);

    const isOrderCanEdit = order?.[ORDER_FIELDS.status] === UnprocessableOrderStatuses.created;
    const isOrderCanRefund = order?.processable && order?.status === UnprocessableOrderStatuses.completed;

    useEffect(() => {
        setOrder(orderData);
        setBasketItems(orderData?.basketItems);
    }, [orderData]);

    const handleSubmitForm = async (formValues: TOrder) => {
        const submitCustomer = getCustomerForSubmitOrder(formValues);
        const dataOrder = {
            ...formValues,
            // items order
            [ORDER_FIELDS.basketItems]: basketItems,
            [ORDER_FIELDS.currencyId]: formValues[ORDER_FIELDS.currencyId],
            [ORDER_FIELDS.amount]: formValues[ORDER_FIELDS.amount],
            [ORDER_FIELDS.vatAmount]: formValues[ORDER_FIELDS.vatAmount],
            // requisites
            [ORDER_FIELDS.merchantOrderId]: formValues[ORDER_FIELDS.merchantOrderId],
            [ORDER_FIELDS.expirationDate]: dayjs(formValues[ORDER_FIELDS.expirationDate]).utc().format(UTC_DATE_FORMAT),
            [ORDER_FIELDS.description]: formValues[ORDER_FIELDS.description],
            // customer
            [ORDER_FIELDS.merchantId]: merchant[MERCHANT_FIELDS.id],
            [ORDER_FIELDS.customer]: submitCustomer,
        };
        setIsSaved(true);
        // @ts-ignore
        const resultSubmit: any = await dispatch(updateOrder(dataOrder));
        if (!resultSubmit.error && resultSubmit?.payload) {
            // @ts-ignore
            setOrder(dataOrder);
            setIsEdit(false);
        }
        setIsSaved(false);
    };

    const initialValues = order && {
        ...order,
        // customer fields
        [ORDER_CUSTOMER_FIELDS.phone]: order?.customer?.[ORDER_CUSTOMER_FIELDS.phone],
        [ORDER_CUSTOMER_FIELDS.email]: order?.customer?.[ORDER_CUSTOMER_FIELDS.email],
        [ORDER_CUSTOMER_FIELDS.name]: order?.customer?.[ORDER_CUSTOMER_FIELDS.name],
        [ORDER_CUSTOMER_FIELDS.vatNumber]: order?.customer?.[ORDER_CUSTOMER_FIELDS.vatNumber],
        [ORDER_CUSTOMER_FIELDS.registrationAddress]: order?.customer?.[ORDER_CUSTOMER_FIELDS.registrationAddress],

        [ORDER_FIELDS.isLegalCustomer]: order?.customer?.[ORDER_CUSTOMER_FIELDS.type] === CUSTOMER_TYPES.legal,
        [ORDER_FIELDS.isHideRequisites]: !order?.customer?.[ORDER_CUSTOMER_FIELDS.name],
    };

    const handleOrderCancel = useCallback(async () => {
        // @ts-ignore
        const cancelResult = await dispatch(cancelOrder(order?.id));
        // @ts-ignore
        if (!cancelResult.error && cancelResult?.payload) {
            // @ts-ignore
            setOrder(cancelResult?.payload);
        }
    }, [dispatch, order?.id]);

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item to={RoutesBusinessOffice.dashboard}>
                    {intl.formatMessage({ defaultMessage: 'Обзор' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    to={
                        merchant &&
                        `${RoutesBusinessOffice.shop}/${merchant[MERCHANT_FIELDS.id]}${RoutesBusinessOffice.orders}`
                    }
                >
                    {merchant?.[MERCHANT_FIELDS.name] || <Skeleton width={80} />}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{intl.formatMessage({ defaultMessage: 'Счёт детально' })}</Breadcrumb.Item>
            </Breadcrumb>
            <DetailOrderWrapper>
                <OrderTitle variant="bigTitle">
                    {intl.formatMessage({ defaultMessage: 'Счёт' })}{' '}
                    {order?.[ORDER_FIELDS.merchantOrderId] || <Skeleton width={140} />}
                </OrderTitle>
                <Form
                    subscription={{ valid: true }}
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validate={validation}
                >
                    {({ handleSubmit, valid }) => (
                        <OrderForm>
                            <OrderHeader>
                                <OrderHeaderTitle variant="title">
                                    {intl.formatMessage({ defaultMessage: 'Счёт' })}
                                </OrderHeaderTitle>
                                {isOrderCanEdit && (
                                    <ButtonEditOrSave
                                        isSaved={isSaved}
                                        valid={valid && basketItems?.length > 0}
                                        handleSubmit={handleSubmit}
                                        isEdit={isEdit}
                                        setIsEdit={setIsEdit}
                                    />
                                )}
                                {isOrderCanRefund && <ButtonCreateRefund />}
                            </OrderHeader>
                            <OrderBorder isEdit={isEdit}>
                                <OrderInformation>
                                    <OrderGeneral isEdit={isEdit} order={order} />
                                    <Requisites isEdit={isEdit} customer={debitInvoiceCustomer || order?.customer} />
                                    <OrderItems
                                        isEdit={isEdit}
                                        basketItems={basketItems}
                                        setBasketItems={setBasketItems}
                                    />
                                </OrderInformation>
                                <OrderTotal
                                    isOrderCanEdit={isOrderCanEdit}
                                    setIsEditForm={setIsEdit}
                                    setOrder={setOrder}
                                    isEdit={isEdit}
                                    basketItems={basketItems}
                                    order={order}
                                    handleSubmit={handleSubmit}
                                    onCancel={handleOrderCancel}
                                />
                            </OrderBorder>
                        </OrderForm>
                    )}
                </Form>
            </DetailOrderWrapper>
        </>
    );
};

export default DetailOrder;
