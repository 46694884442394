import React, { FC, useState } from 'react';
import { InputTypeEnum } from 'src/components/Input';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';
import {
    FinderClientsWrapper,
    InputFinder,
    SearchContainer,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/Clients/components/FinderClients/styles';
import { useIntl } from 'react-intl';

interface IFinderClients {
    applySearchString: (searchWord: string) => void;
}

const FinderClients: FC<IFinderClients> = ({ applySearchString }) => {
    const intl = useIntl();
    const [searchString, setSearchString] = useState('');

    const handleCheckPress = (event: { key: string }) => {
        if (event.key === 'Enter') {
            applySearchString(searchString);
        }
    };
    const handlerSearchContact = () => {
        applySearchString(searchString);
    };

    return (
        <FinderClientsWrapper>
            <InputFinder
                placeholder={intl.formatMessage({ defaultMessage: 'Поиск по клиентам' })}
                value={searchString}
                typeInput={InputTypeEnum.text}
                onChange={(valueInput: string) => setSearchString(valueInput)}
                onKeyPress={handleCheckPress}
            />
            <SearchContainer>
                <button type="button" onClick={handlerSearchContact}>
                    <SearchIcon />
                </button>
            </SearchContainer>
        </FinderClientsWrapper>
    );
};

export default FinderClients;
