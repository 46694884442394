import React, { FC, useEffect } from 'react';
import { getIsOrdersLoading, getOrdersList } from 'src/store/Order/selectors';
import { useSelector } from 'react-redux';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useParams } from 'react-router-dom';
import { fetchOrdersList } from 'src/store/Order';
import { useAppDispatch } from 'src/store';
import { useIntl } from 'react-intl';
import OrdersMerchantList from './components/OrdersMerchantList/index';
import { Container, Heading, WrapTitle, Title, NewOrderBtn, AddIcon, Wrapper } from './styles';

const MerchantOrdersList: FC = () => {
    const intl = useIntl();
    const isLoading = useSelector(getIsOrdersLoading);
    const { merchantId } = useParams<{ merchantId: string }>();
    const dispatch = useAppDispatch();

    const ordersList = useSelector(getOrdersList);

    useEffect(() => {
        dispatch(fetchOrdersList({ merchantId, '_order[createdAt]': 'desc' }));
    }, [dispatch, merchantId]);

    return (
        <Wrapper>
            <Container>
                <Heading>
                    <WrapTitle>
                        <Title variant="title">{intl.formatMessage({ defaultMessage: 'Последние заказы' })}</Title>
                    </WrapTitle>
                    <NewOrderBtn to={`${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.orderToPay}`}>
                        <AddIcon />
                        {intl.formatMessage({ defaultMessage: 'Создать заказ' })}
                    </NewOrderBtn>
                </Heading>
                <OrdersMerchantList isLoading={isLoading} orders={ordersList} />
            </Container>
        </Wrapper>
    );
};
export default MerchantOrdersList;
