import styled from 'styled-components';
import { DateHoursContainer } from 'src/pages/businessOffice/Shop/ShopTabsTables/Settings/components/Address/styles';
import Typography from 'src/components/Typography';

export const DateHoursContainerInForm = styled(DateHoursContainer)`
    margin: 12px 20px;
    min-height: 22px;
`;

export const ScheduleInputWrapper = styled.div`
    position: relative;
    border: 1px solid rgba(153, 153, 153, 0.3);
    margin-top: 10px;
    border-radius: 10px;
`;

export const SettingLabel = styled(Typography)`
    ${({ theme }) => theme.mixins.fieldLabelStyles};
    position: absolute;
    left: 3px;
    top: -9px;
    color: ${({ theme }) => theme.colors.grey()};
`;

export const ButtonOpenModalHoursDay = styled.button`
    position: absolute;
    width: 100%;
    height: 100%;
`;
