import styled, { css } from 'styled-components';
import Typography from 'src/components/Typography';
import { ReactComponent as OrdersIcon } from 'src/assets/icons/order.svg';
import { ReactComponent as RefundsIcon } from 'src/assets/icons/return.svg';
import { NavLink } from 'react-router-dom';

const StyleAmountAndStatus = css`
    text-align: right;

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.regular12.fontSize}px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        font-size: ${({ theme }) => theme.typography.regular14.fontSize}px;
        text-align: left;
        padding: 7px 0;
    }
`;

export const Amount = styled(Typography)`
    ${StyleAmountAndStatus};
    grid-column-start: 3;
    @media ${({ theme }) => theme.breakpoints.sm} {
        text-align: right;
    }
`;

export const Empty = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: center;
`;

export const InnerContent = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    border-top-left-radius: ${({ theme }) => theme.decorations.borderRadius.large}px;
    border-top-right-radius: ${({ theme }) => theme.decorations.borderRadius.large}px;
    overflow: hidden;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-right: 0;
        border-radius: 0;
    }
`;

export const InnerText = styled(Typography)`
    ${({ theme }) => theme.mixins.textOverflowDots}
    padding-right: 10px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.regular12.fontSize}px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-right: 0;
        font-size: ${({ theme }) => theme.typography.regular14.fontSize}px;
    }
`;

export const InnerNumber = styled(Typography)`
    ${({ theme }) => theme.mixins.textOverflowDots}
    grid-column-start: 2;
    grid-row-start: 1;
    text-align: left;
    padding-right: 10px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.regular12.fontSize}px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-right: 0;
        font-size: ${({ theme }) => theme.typography.regular14.fontSize}px;
        text-align: left;
        padding: 0;
        width: auto;
    }
`;

export const InnerTextLeft = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: contents;
    }
`;

export const MobileDescriptionWrapper = styled.div`
    display: contents;
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.flexCenter}
        grid-column-gap: 4px;
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
        overflow: hidden;
    }
`;

export const OrderIcon = styled(OrdersIcon)`
    margin: auto;
`;

export const RefundIcon = styled(RefundsIcon)`
    margin: auto;
`;

export const Status = styled(Typography)`
    ${StyleAmountAndStatus};
    color: ${(props) => props.color};
    text-align: right;
`;

export const TableRow = styled(NavLink)`
    display: grid;
    grid-template-columns: 3fr 4fr 2fr 2fr;
    grid-column-gap: 10px;
    padding: 20px 30px;
    align-items: center;
    text-decoration: none;

    :not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    }

    &:hover {
        background: #ebecf0;
    }

    &:active {
        background: #e6e7eb;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 36px auto 100px;
        grid-template-rows: 20px 20px;
        padding: 20px;
    }
`;

export const TitleDate = styled(Typography)`
    padding: 8px 0;
    color: ${({ theme }) => theme.colors.grey()};
`;

export const WrapDate = styled.div`
    width: 100%;
    padding-left: 30px;
    background-color: ${({ theme }) => theme.colors.lightGrey()};
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-left: 21px;
    }
`;

export const WrapOrderIcon = styled.div`
    background-color: ${({ theme }) => theme.colors.lightGrey()};
    position: relative;
    display: flex;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 20px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-right: 10px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-row-start: 1;
        grid-row-end: 3;
    }
`;

export const WrapStatusIcon = styled.div`
    display: flex;
    position: absolute;
    bottom: 9px;
    right: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.lightGrey()};
`;
