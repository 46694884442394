import yup from 'src/utils/validations';
import { INTRODUCE_FIELDS } from 'src/store/Auth/constants';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';

export const profileSchema = yup.object().shape({
    [INTRODUCE_FIELDS.lastName]: yup.string().required(),
    [INTRODUCE_FIELDS.firstName]: yup.string().required(),
});

export const companySchema = yup.object().shape({
    [COUNTERPARTY_FIELDS.vatNumber]: yup.string().isValidInn().required(),
    [COUNTERPARTY_FIELDS.name]: yup.string().required(),
    [COUNTERPARTY_FIELDS.registrationAddress]: yup.string().required(),
    [COUNTERPARTY_FIELDS.cfoName]: yup.string().required(),
    [COUNTERPARTY_FIELDS.ceoName]: yup.string().required(),
    [COUNTERPARTY_FIELDS.legalEntityTypeId]: yup.number().required(),
});

export const merchantSchema = yup.object().shape({
    [MERCHANT_FIELDS.name]: yup.string().required(),
    [MERCHANT_FIELDS.mccId]: yup.number().required(),
    [MERCHANT_FIELDS.description]: yup.string().max(1000),
});
