import React, { FC } from 'react';
import {
    IntervalSlide,
    ProgressBarWrapper,
} from 'src/pages/businessOffice/Dashboard/components/Stories/components/ProgressBarStories/styles';

interface IProgressBarStories {
    countSlides: number;
    currentIndex: number;
    isPaused: boolean;
}

const ProgressBarStories: FC<IProgressBarStories> = ({ isPaused, currentIndex, countSlides }) => {
    const arrIntervals = new Array(countSlides).fill(IntervalSlide);

    return (
        <ProgressBarWrapper>
            {arrIntervals.map((Interval, index) => (
                <Interval
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    isPaused={isPaused}
                    isFull={currentIndex > index}
                    isActive={index === currentIndex}
                />
            ))}
        </ProgressBarWrapper>
    );
};

export default ProgressBarStories;
