import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const IntegrationWrapper = styled.div`
    padding: 22px 30px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 198px;
    grid-column-gap: 53px;
    :not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: auto auto 1fr;
        justify-content: flex-start;
        align-items: flex-end;
        grid-row-gap: 3px;
        grid-column-gap: 6px;
        padding: 17px 20px;
    }
`;

interface IStatus {
    isActive: boolean;
}

export const StatusWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 24px;
    justify-content: flex-end;
    @media ${({ theme }) => theme.breakpoints.md} {
        display: contents;
    }
`;

export const Status = styled(Typography)<IStatus>`
    color: ${({ theme, isActive }) => (isActive ? theme.colors.green() : theme.colors.red())};
    text-align: right;
    white-space: nowrap;

    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        grid-row: 2/3;
        grid-column: 2/3;
    }
`;

export const ButtonOpenCodeIntegration = styled.button`
    ${({ theme }) => theme.mixins.getTypography('bold14')}
    white-space: nowrap;

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column: 3/4;
        text-align: right;
    }
`;

export const MerchantIntegrationSettingName = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column: 3/4;
        text-align: right;
    }
`;

export const MerchantIntegrationName = styled(Typography)`
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        grid-column: 1/2;
        grid-row: 2/3;
    }
`;
