import React, { FC } from 'react';
import Portal from 'src/components/Portal';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-square.svg';
import { useOutsideClick } from '@invoicebox/ui';
import {
    OverflowHidden,
    Container,
    ContainerInner,
    Backdrop,
    Content,
    CloseButton,
    ModalInner,
    LeftIcon,
    RightIcon,
    ButtonSwitchSlideNext,
    ButtonSwitchSlidePrev,
} from './styles';

interface IStoriesContainer {
    isOpen: boolean;
    closeModal: () => void;
    className?: string;
    onClickNextSlide?: () => void;
    onClickPrevSlide?: () => void;
}

const StoriesContainer: FC<IStoriesContainer> = ({
    onClickNextSlide,
    onClickPrevSlide,
    children,
    isOpen,
    className,
    closeModal,
}) => {
    const contentRef = useOutsideClick(closeModal);

    if (!isOpen) return null;

    return (
        <Portal>
            <OverflowHidden />
            <Container>
                <ContainerInner>
                    <Backdrop />
                    <Content ref={contentRef}>
                        <CloseButton onClick={closeModal}>
                            <CloseIcon />
                        </CloseButton>
                        {onClickPrevSlide && (
                            <ButtonSwitchSlidePrev type="button" onClick={onClickPrevSlide}>
                                <LeftIcon />
                            </ButtonSwitchSlidePrev>
                        )}
                        {onClickNextSlide && (
                            <ButtonSwitchSlideNext type="button" onClick={onClickNextSlide}>
                                <RightIcon />
                            </ButtonSwitchSlideNext>
                        )}
                        <ModalInner className={className}>{children}</ModalInner>
                    </Content>
                </ContainerInner>
            </Container>
        </Portal>
    );
};

export default StoriesContainer;
