import styled from 'styled-components';
import Button from 'src/components/Button';
import Typography from 'src/components/Typography';

export const TitleClaim = styled(Typography)`
    margin: 48px 0 27px 0;
    @media ${({ theme }) => theme.breakpoints.ld} {
        ${({ theme }) => theme.mixins.getTypography('headline')}
        margin: 30px 0 27px 0;
    }
`;

export const ButtonConnect = styled(Button)`
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin: 0 auto;
    }
`;
