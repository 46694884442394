import React, { FC } from 'react';
import { ICounterparty } from 'src/store/Counterparty/types';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { GeneralWrapper } from 'src/pages/businessOffice/Counterparty/components/General/styles';
import RowLabel from 'src/components/RowLabel';
import { convertPhoneToShow } from 'src/utils/formatters';
import ContactsForm from 'src/pages/businessOffice/Counterparty/components/ContactsForm';
import { useIntl } from 'react-intl';
import { FORM_COUNTERPARTY_CONTACT } from '../../index';

interface IContacts {
    isEditMode?: boolean;
    formId?: string;
    setIsEditSettings?: (arg: boolean) => void;
    counterparty: ICounterparty;
    toggleSaving?: () => void;
}

const Contacts: FC<IContacts> = ({
    isEditMode = false,
    formId = FORM_COUNTERPARTY_CONTACT,
    setIsEditSettings = () => null,
    counterparty,
    toggleSaving = () => null,
}) => {
    const intl = useIntl();

    const counterpartyFields = [
        {
            label: 'Email',
            value: counterparty[COUNTERPARTY_FIELDS.email],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Телефон' }),
            value:
                counterparty[COUNTERPARTY_FIELDS.phone] && convertPhoneToShow(counterparty[COUNTERPARTY_FIELDS.phone]),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Сайт' }),
            value: counterparty[COUNTERPARTY_FIELDS.website],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Ген.директор' }),
            value: counterparty[COUNTERPARTY_FIELDS.ceoName],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Главный бухгалтер' }),
            value: counterparty[COUNTERPARTY_FIELDS.cfoName],
        },
    ];

    if (isEditMode) {
        return (
            <ContactsForm
                toggleSaving={toggleSaving}
                counterparty={counterparty}
                formId={formId}
                setIsEditSettings={setIsEditSettings}
            />
        );
    }

    return (
        <GeneralWrapper>
            {counterpartyFields.map((field) => (
                <RowLabel key={field.label} label={field.label} value={field.value} />
            ))}
        </GeneralWrapper>
    );
};

export default Contacts;
