import { IWorkHours } from 'src/store/Merchant/types';

const getValueDay = (nameDay: string, arrayHoursDay: [nameDay: string, time: string][]) => {
    let startValue = null;
    let endValue = null;

    arrayHoursDay.forEach((item) => {
        if (item[0].indexOf('Start') !== -1 && item[0].indexOf(nameDay) !== -1) {
            // eslint-disable-next-line prefer-destructuring
            startValue = item[1];
        } else if (item[0].indexOf(nameDay) !== -1) {
            // eslint-disable-next-line prefer-destructuring
            endValue = item[1];
        }
    });
    if (startValue && endValue) {
        return [[startValue, endValue]];
    }
    return [];
};

export const getConvertHoursDayToBackend = (hoursBeforeConvert: { nameDay: string; time: string }): IWorkHours => {
    const arrayHoursDay = Object.entries(hoursBeforeConvert).map((day) => day);

    return {
        mon: getValueDay('mon', arrayHoursDay),
        tue: getValueDay('tue', arrayHoursDay),
        wed: getValueDay('wed', arrayHoursDay),
        thu: getValueDay('thu', arrayHoursDay),
        fri: getValueDay('fri', arrayHoursDay),
        sat: getValueDay('sat', arrayHoursDay),
        sun: getValueDay('sun', arrayHoursDay),
    };
};
