import React, { FC } from 'react';
import { ORDER_ITEM_FIELDS, TOrderItem, TVatType } from 'src/store/Order/types';
import { find } from 'lodash';
import { formatCurrencyToShow } from 'src/utils/helpers';
import useModal from 'src/hooks/useModal';
import ModalAddItemOrder from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/ModalAddItemOrder';
import { IMeasure } from 'src/store/Merchant/types';
import { useIntl } from 'react-intl';
import {
    ButtonDeleteItemOrder,
    ButtonEditItemOrder,
    InformationItemOrder,
    ItemCompositionOrderWrapper,
    ItemOrderButtonsWrapper,
    ItemOrderPriceWrapper,
    PriceItemOrder,
    Title,
    VatItemOrder,
} from './styles';
import { ReactComponent as IconEdit } from '../../assets/edit.svg';
import { ReactComponent as IconDelete } from '../../assets/delete.svg';

interface IItemCompositionOrder {
    orderItem: TOrderItem;
    vatTypes: TVatType[] | undefined;
    measures: IMeasure[] | undefined;
    removeItemOrder: (orderItem: TOrderItem) => void;
    editItemOrder: (itemOrder: TOrderItem) => void;
}

const ItemCompositionOrder: FC<IItemCompositionOrder> = ({
    editItemOrder,
    removeItemOrder,
    vatTypes,
    measures,
    orderItem,
}) => {
    const intl = useIntl();
    const currentMeasure =
        measures && measures.find((measure) => measure?.id === orderItem[ORDER_ITEM_FIELDS.measureCode]);
    const { isOpen: addItemOrderModalVisible, open: openModal, close: closeModal } = useModal();
    const title = `${orderItem[ORDER_ITEM_FIELDS.name]} x ${orderItem[ORDER_ITEM_FIELDS.quantity]} ${
        currentMeasure?.name
    }`;
    const currentVat = vatTypes && find(vatTypes, { code: orderItem[ORDER_ITEM_FIELDS.vatCode] });
    const vatValue = currentVat?.rate ? currentVat.rate * 100 : 0;
    const formattedCurrency = formatCurrencyToShow(orderItem[ORDER_ITEM_FIELDS.totalAmount]);

    return (
        <ItemCompositionOrderWrapper>
            <Title variant="regular14">{title}</Title>
            <InformationItemOrder>
                <ItemOrderPriceWrapper>
                    <PriceItemOrder variant="regular14">{formattedCurrency}</PriceItemOrder>
                    <VatItemOrder variant="regular10">{`${intl.formatMessage({ defaultMessage: 'НДС' })} ${vatValue} %`}</VatItemOrder>
                </ItemOrderPriceWrapper>
                <ItemOrderButtonsWrapper>
                    <ButtonEditItemOrder type="button" onClick={openModal}>
                        <IconEdit />
                    </ButtonEditItemOrder>
                    <ButtonDeleteItemOrder onClick={() => removeItemOrder(orderItem)}>
                        <IconDelete />
                    </ButtonDeleteItemOrder>
                </ItemOrderButtonsWrapper>
            </InformationItemOrder>
            <ModalAddItemOrder
                measures={measures}
                vatTypes={vatTypes}
                addOrEditItemOrder={editItemOrder}
                closeModal={closeModal}
                isOpen={addItemOrderModalVisible}
                itemOrder={orderItem}
            />
        </ItemCompositionOrderWrapper>
    );
};

export default ItemCompositionOrder;
