import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getError } from 'src/store/Auth/selectors';
import { useAppDispatch } from 'src/store';
import { setError } from 'src/store/Auth';
import useToast from 'src/hooks/useToast';
import Toast from 'src/components/Toast';
import AppRoutes from './AppRoutes';
import { useConsoleLogAppVersion } from '../hooks/useConsoleLogAppVersion';

const Pages: FC = () => {
    const dispatch = useAppDispatch();
    const error = useSelector(getError);
    const { open: openToast, isShow: isShowToast, setIsShow: setIsShowToast } = useToast();

    useConsoleLogAppVersion();

    const funcAfterCloseToast = () => {
        dispatch(
            setError({
                ...error,
                isActive: false,
            }),
        );
    };

    useEffect(() => {
        if (error.isActive) {
            openToast();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error.isActive]);

    return (
        <>
            <AppRoutes />
            <Toast
                funcAfterClose={funcAfterCloseToast}
                onClick={() => {}}
                isShowToast={isShowToast}
                setIsShowToast={setIsShowToast}
                title={error?.title}
            />
        </>
    );
};

export default Pages;
