import { documentAdapter } from './index';
import { RootState } from '../index';

const { selectAll } = documentAdapter.getSelectors((state: RootState) => state.document);

export const getDocumentsList = (state: RootState) => selectAll(state);
export const getUploadDocumentByContractId = (contractId: number) => (state: RootState) =>
    getDocumentsList(state).find(
        (document) =>
            document.setting.merchantContractId === contractId && document.type === 'merchant-contract-package-upload',
    );
