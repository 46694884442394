import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';
import { COLOR_BRIGHTNESS } from '../../theme/utils';

export const Wrapper = styled.div`
    padding: 40px 0;
    background-color: #f5f6fa;
    border-top: 1px solid ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.LIGHT)};

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 40px 24px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 40px 0 10px;
    }
`;

export const Content = styled.div`
    margin: 0 auto;
    width: 1170px;
    ${({ theme }) => theme.mixins.flexStart}
    gap: 155px;

    @media ${({ theme }) => theme.breakpoints.xl} {
        width: 100%;
        display: grid;
        grid-template-columns: auto min-content;
        grid-column-gap: 70px;
        grid-row-gap: 0;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
    }
`;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 28px;
    flex-shrink: 0;

    @media ${({ theme }) => theme.breakpoints.md} {
        display: contents;
    }
`;

export const LogoWrapper = styled.div`
    padding: 16px 8px 8px;

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
        padding-left: 24px;
    }
`;

export const AppsAndContacts = styled.div`
    display: flex;
    gap: 28px;

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
        gap: 24px;
    }
`;

export const AppsContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media ${({ theme }) => theme.breakpoints.md} {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const AppsLinks = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`;

export const Contacts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const Right = styled.div`
    padding: 20px 0 10px 0;

    @media ${({ theme }) => theme.breakpoints.xl} {
        display: contents;
    }
`;

export const PrimaryLinks = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 32px;
    padding-bottom: 30px;

    @media ${({ theme }) => theme.breakpoints.xl} {
        display: grid;
        grid-template-columns: min-content min-content;
        row-gap: 24px;
        column-gap: 40px;

        padding-top: 80px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row: 2;
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 20px;

        padding: 30px 16px 24px;
    }
`;

export const PrimaryLink = styled.a`
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        opacity: 0.8;
    }
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.LIGHTEST)};
    border-radius: 1px;
`;

export const Secondary = styled.div`
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding-top: 20px;
        grid-column: 1/3;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column: auto;
        flex-direction: column-reverse;
        padding-top: 24px;
    }
`;

export const SecondaryLinks = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 8px;

    @media ${({ theme }) => theme.breakpoints.md} {
        gap: 30px;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const SecondaryLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
    border-right: 1px solid ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.PRE_MEDIUM)};
    padding-right: 8px;

    &:last-child {
        border: none;
        padding-right: 0;
    }

    &:hover {
        opacity: 0.8;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        border: none;
    }
`;

export const Description = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.PRE_MEDIUM)};
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media ${({ theme }) => theme.breakpoints.md} {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const QRWrapper = styled.div`
    width: 140px;
    height: 140px;
    background-color: ${({ theme }) => theme.colors.white()};
    ${({ theme }) => theme.mixins.flexCenterCenter};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
`;

export const SectionLabel = styled(Typography)`
    @media ${({ theme }) => theme.breakpoints.md} {
        display: none;
    }
`;

export const TabletFooterCaption = styled(Typography)`
    text-align: center;
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
`;
