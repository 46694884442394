import React from 'react';
import MissingClaim from 'src/pages/businessOffice/Shop/ShopTabsTables/Refunds/components/MissingClaim';
import RefundsTable from 'src/pages/businessOffice/Shop/ShopTabsTables/Refunds/components/RefundsTable';
import { RefundsWrapper, SkeletonMissingClaim } from 'src/pages/businessOffice/Shop/ShopTabsTables/Refunds/styles';
import { useSelector } from 'react-redux';
import { getCurrentMerchant } from 'src/store/Merchant/selectors';
import { IMerchant } from 'src/store/Merchant/types';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';

const Refunds = () => {
    const merchant: IMerchant = useSelector(getCurrentMerchant) as any;

    if (!merchant) {
        return <SkeletonMissingClaim />;
    }

    return (
        <RefundsWrapper>
            {merchant?.[MERCHANT_FIELDS.merchantContractId] ? <RefundsTable /> : <MissingClaim />}
        </RefundsWrapper>
    );
};

export default Refunds;
