import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMobile } from 'src/hooks/useMedia';
import Modal from 'src/components/Modal';
import MerchantModalInner from 'src/pages/businessOffice/Introduce/components/MerchantModalInner';
import CompanyModalInner from 'src/pages/businessOffice/Introduce/components/CompanyModalInner';
import GetProfileName from 'src/pages/businessOffice/Introduce/components/GetProfileName';
import { Nullable } from 'src/utils/utilityTypes';
import useModal from 'src/hooks/useModal';
import { useIntl } from 'react-intl';
import { IntroduceContext, IntroduceStepType, ModalTypes } from './context';
import LegalPayTarget from './components/LegalPayTarget';
import MerchantTarget from './components/MerchantTarget';
import { Logo, Wrapper } from './styles';

interface IFormsMap {
    [key: number]: FC;
}

type IModalsMap = {
    [key in ModalTypes]: FC;
};

type IModalsTitlesMap = {
    [key in ModalTypes]: string;
};

const formsMap: IFormsMap = {
    [IntroduceStepType.saleTarget]: MerchantTarget,
    [IntroduceStepType.legalPayTarget]: LegalPayTarget,
    [IntroduceStepType.getProfileName]: GetProfileName,
};

const modalsMap: IModalsMap = {
    [ModalTypes.merchant]: MerchantModalInner,
    [ModalTypes.company]: CompanyModalInner,
};

const maxStep = Object.keys(formsMap).length - 1;

const Introduce: FC = () => {
    const intl = useIntl();

    const modalTitlesMap: IModalsTitlesMap = {
        [ModalTypes.merchant]: intl.formatMessage({ defaultMessage: 'Карточка магазина' }),
        [ModalTypes.company]: intl.formatMessage({ defaultMessage: 'Карточка компании' }),
    };

    const [step, setStep] = useState(IntroduceStepType.saleTarget);
    const [modalType, setModalType] = useState<Nullable<ModalTypes>>(null);

    const isMobile = useMobile();
    const { isOpen, open, close } = useModal(false);

    const getModalCaption = () => {
        const prefix = isMobile ? '' : intl.formatMessage({ defaultMessage: 'Шаг' });

        if (step === IntroduceStepType.saleTarget) {
            return `${prefix} ${modalType === ModalTypes.company ? 1 : 2}/2`;
        }
    };

    const toNextStep = useCallback(() => {
        if (step !== maxStep) {
            setStep((currentStep) => currentStep + 1);
        }
    }, [step]);
    const toPrevStep = () => setStep((currentStep) => (currentStep === 0 ? currentStep : currentStep - 1));

    const openModal = useCallback(
        (type: ModalTypes) => {
            setModalType(type);
            open();
        },
        [open],
    );

    const closeModal = useCallback(() => {
        setModalType(null);
        close();
    }, [close]);

    const IntroduceInner = formsMap[step];
    const ModalInner = modalType ? modalsMap[modalType] : Fragment;

    const introduceContextProviderValue = useMemo(
        () => ({
            step,
            toNextStep,
            toPrevStep,
            modalIsOpen: isOpen,
            modalType,
            openModal,
            closeModal,
            setModalType,
        }),
        [closeModal, isOpen, modalType, openModal, step, toNextStep],
    );

    return (
        <Wrapper>
            <Helmet>
                <title>{intl.formatMessage({ defaultMessage: 'Создание пользователя' })}</title>
            </Helmet>
            <Logo />
            <IntroduceContext.Provider value={introduceContextProviderValue}>
                <IntroduceInner />
                <Modal
                    isOpen={isOpen}
                    title={modalType ? modalTitlesMap[modalType] : undefined}
                    caption={getModalCaption()}
                    closeModal={closeModal}
                >
                    <ModalInner />
                </Modal>
            </IntroduceContext.Provider>
        </Wrapper>
    );
};

export default Introduce;
