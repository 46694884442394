import styled from 'styled-components';
import { ReactComponent as ArrowDown } from 'src/assets/icons/arrow-down.svg';

export const StatusWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
`;

export const StatusValueWrapper = styled.div`
    position: relative;
`;

export const StatusButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
`;

export const IconOpen = styled(ArrowDown)`
    margin-left: 4px;
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;

export const StatusesList = styled.div`
    position: absolute;
    top: 26px;
    right: -10px;
    display: grid;
    grid-template-columns: 100%;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
    min-width: 94px;
    background: ${({ theme }) => theme.colors.white()};
    padding: 10px 0;
`;

interface IStatusListItem {
    isActiveStatus?: boolean;
}

export const StatusListItem = styled.button<IStatusListItem>`
    padding: 5px 14px;
    text-align: left;
    ${({ theme }) => theme.mixins.getTypography('regular12')}
    color: ${({ theme, isActiveStatus }) => (isActiveStatus ? theme.colors.base() : theme.colors.grey())};
    &:hover {
        color: ${({ theme }) => theme.colors.base()};
    }
`;
