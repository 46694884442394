import styled, { css } from 'styled-components';
import Typography from 'src/components/Typography';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import InputMask from '@mona-health/react-input-mask';
import { IInputContainerProps } from './index';

interface IInputLabelProps {
    inFocus: boolean;
}

export const InputLabel = styled(Typography)<IInputLabelProps>`
    ${({ theme }) => theme.mixins.fieldLabelStyles};
    color: ${({ theme, inFocus }) => theme.helpers.getFieldLabelColor(theme, inFocus)};
`;

interface IInputWrapperProps {
    disabled: boolean;
}

export const InputWrapper = styled.div<IInputWrapperProps>`
    position: relative;
    padding-top: 10px;
    width: 100%;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    &:hover {
        & ${InputLabel} {
            color: ${({ theme }) => theme.colors.base()};
        }
    }
`;
export const inputStyles = css`
    ${({ theme }) => theme.mixins.fieldStyles};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
`;
export const InputContainer = styled.div<IInputContainerProps>`
    & input {
        padding-right: ${({ hasIcon }) => (hasIcon ? 56 : 18)}px;
        border: 1px solid
            ${({ theme, hasError, inFocus }) => theme.helpers.getFieldBorderColor(theme, hasError, inFocus)};

        /* Change autocomplete styles in WebKit */

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border: 1px solid
                ${({ theme, hasError }) => (hasError ? theme.colors.red() : theme.colors.grey(COLOR_BRIGHTNESS.LIGHT))};
            -webkit-text-fill-color: inherit;
            background-color: inherit;
            transition: background-color 5000s ease-in-out 0s;
        }
    }
`;
export const inputContainerStyles = css`
    position: absolute;
    right: 20px;
    bottom: calc(50% - 5px);
    transform: translateY(50%);
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
export const IconContainer = styled.span`
    ${inputContainerStyles};
    pointer-events: none;
`;
export const IconClickableContainer = styled.button`
    ${inputContainerStyles};
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`;
export const InputField = styled.input`
    ${inputStyles};
`;

export const MaskField = styled(InputMask)`
    ${inputStyles};
`;
