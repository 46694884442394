import React, { useEffect } from 'react';
import LastClients from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/LastClients';
import FormField, { FormFieldTypeEnum } from 'src/components/FormField';
import { ORDER_CUSTOMER_FIELDS, ORDER_FIELDS } from 'src/store/Order/types';
import { ICounterpartyDetails } from 'src/components/InputAutocomplete/types';
import { useField, useForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { CheckboxContainer, CustomerWrapper, InputsCustomer, RegistrationAddressCustomerField } from './styles';

const Customer = () => {
    const intl = useIntl();

    const {
        input: { value: isLegalCustomer },
    } = useField(ORDER_FIELDS.isLegalCustomer);
    const {
        input: { value: isHideRequisites },
    } = useField(ORDER_FIELDS.isHideRequisites);
    const { change } = useForm();

    useEffect(() => {
        change(ORDER_FIELDS.isShowRequisites, !isHideRequisites);
    }, [change, isHideRequisites]);

    return (
        <CustomerWrapper>
            <LastClients />
            <CheckboxContainer>
                <FormField
                    type="checkbox"
                    name={ORDER_FIELDS.isLegalCustomer}
                    label={intl.formatMessage({ defaultMessage: 'Покупатель является юридическим лицом' })}
                    fieldType={FormFieldTypeEnum.checkbox}
                />
                <FormField
                    type="checkbox"
                    name={ORDER_FIELDS.isHideRequisites}
                    label={intl.formatMessage({ defaultMessage: 'Покупатель укажет свои данные' })}
                    fieldType={FormFieldTypeEnum.checkbox}
                />
            </CheckboxContainer>
            {!isHideRequisites && (
                <InputsCustomer>
                    <FormField
                        type="tel"
                        label={intl.formatMessage({ defaultMessage: 'Телефон' })}
                        name={ORDER_CUSTOMER_FIELDS.phone}
                        mask="+7 999 999 99 99"
                        placeholder="+7 XXX XXX XX XX"
                    />
                    <FormField
                        name={ORDER_CUSTOMER_FIELDS.email}
                        label="Email"
                        placeholder={intl.formatMessage({ defaultMessage: 'Электронная почта' })}
                    />
                    <FormField
                        label={
                            isLegalCustomer
                                ? intl.formatMessage({ defaultMessage: 'Наименование' })
                                : intl.formatMessage({ defaultMessage: 'ФИО' })
                        }
                        placeholder={
                            isLegalCustomer
                                ? intl.formatMessage({ defaultMessage: 'Введите наименование' })
                                : intl.formatMessage({ defaultMessage: 'Введите ФИО' })
                        }
                        name={ORDER_CUSTOMER_FIELDS.name}
                    />
                    {isLegalCustomer && (
                        <>
                            <FormField
                                fieldType={FormFieldTypeEnum.autoCompleteVat}
                                name={ORDER_CUSTOMER_FIELDS.vatNumber}
                                label={intl.formatMessage({ defaultMessage: 'ИНН' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите номер ИНН' })}
                                autoFill={({ name, registrationAddress }: ICounterpartyDetails) => ({
                                    [ORDER_CUSTOMER_FIELDS.name]: name,
                                    [ORDER_CUSTOMER_FIELDS.registrationAddress]: registrationAddress,
                                })}
                            />
                            <RegistrationAddressCustomerField
                                name={ORDER_CUSTOMER_FIELDS.registrationAddress}
                                label={intl.formatMessage({ defaultMessage: 'Юридический адрес' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите юридический адрес' })}
                            />
                        </>
                    )}
                </InputsCustomer>
            )}
        </CustomerWrapper>
    );
};

export default Customer;
