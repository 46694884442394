import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    ButtonAddNewOrganization,
    ButtonSearch,
    CounterpartiesWrapper,
    InputWrapper,
    ModalSearchWrapper,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/ModalSearchOrganization/styles';
import { ReactComponent as SearchIcon } from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/assets/search.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import Input from 'src/components/Input';
import { useSelector } from 'react-redux';
import { getClientCounterparties } from 'src/store/ClientCounterparty/selectors';
import RowOrganizationModal from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/RowOrganizationModal';
import { CLIENT_COUNTERPARTY_FIELDS } from 'src/store/ClientCounterparty/constants';
import { useIntl } from 'react-intl';

interface IModalSearchOrganization {
    isOpen: boolean;
    closeModal: () => void;
    connectToOrganization: (idClientCounterparty: number) => void;
    clientCounterpartyIds: number[];
    openModalAdding: () => void;
}

const ModalSearchOrganization: FC<IModalSearchOrganization> = ({
    clientCounterpartyIds,
    connectToOrganization,
    isOpen,
    closeModal,
    openModalAdding,
}) => {
    const intl = useIntl();
    const [searchWord, setSearchWord] = useState('');
    const clientCounterparties = useSelector(getClientCounterparties);

    const unSelectedClientCounterparties = useCallback(
        () =>
            clientCounterparties.filter(
                (clientCounterparty) =>
                    !clientCounterpartyIds.includes(clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.id]),
            ),
        [clientCounterparties, clientCounterpartyIds],
    );
    const [counterparties, setCounterparties] = useState(unSelectedClientCounterparties());

    useEffect(() => {
        setCounterparties(unSelectedClientCounterparties());
    }, [unSelectedClientCounterparties]);

    const onFilterCounterparties = () => {
        const filteredCounterparties = unSelectedClientCounterparties().filter((clientCounterparty) =>
            clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.name].toUpperCase().includes(searchWord.toUpperCase()),
        );
        setCounterparties(filteredCounterparties);
    };

    return (
        <ModalSearchWrapper
            title={intl.formatMessage({ defaultMessage: 'Выберите организацию' })}
            closeModal={closeModal}
            isOpen={isOpen}
        >
            <InputWrapper>
                <Input
                    onChange={(string: string) => {
                        setSearchWord(string);
                    }}
                    value={searchWord}
                    name="searchClientWord"
                    placeholder={intl.formatMessage({ defaultMessage: 'Поиск по организации' })}
                />
                <ButtonSearch type="button" onClick={onFilterCounterparties}>
                    <SearchIcon />
                </ButtonSearch>
            </InputWrapper>
            <ButtonAddNewOrganization type="button" onClick={openModalAdding}>
                <PlusIcon />
                {intl.formatMessage({ defaultMessage: 'Добавить организацию' })}
            </ButtonAddNewOrganization>
            <CounterpartiesWrapper>
                {counterparties.map((clientCounterparty) => (
                    <RowOrganizationModal
                        key={clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.id]}
                        connectToOrganization={connectToOrganization}
                        clientCounterparty={clientCounterparty}
                    />
                ))}
            </CounterpartiesWrapper>
        </ModalSearchWrapper>
    );
};

export default ModalSearchOrganization;
