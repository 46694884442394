import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from '../../../theme/utils';

export const RowCell = styled.td`
    padding-top: 12px;
    padding-bottom: 12px;
`;

export const TableRowWrapper = styled.tr<{ $isShowBorder: boolean; $isPointer: boolean }>`
    background-color: ${({ theme }) => theme.colors.white()};
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
    border-bottom: ${({ $isShowBorder, theme }) =>
        $isShowBorder ? `1px solid ${theme.colors.base(COLOR_BRIGHTNESS.LIGHTEST)}` : 'none'};

    ${({ $isPointer }) => $isPointer && `cursor: pointer`};

    td:first-of-type {
        padding-left: 20px;
    }

    td:last-of-type {
        padding-right: 20px;
    }
`;

export const CheckboxRowWrapper = styled.td`
    width: 20px;
    padding-right: 8px;
`;

export const ArrowIconWrapper = styled.td`
    width: 18px;
`;

export const DescriptionWrapper = styled.td`
    padding-bottom: 12px;
`;

export const Description = styled.div`
    border-radius: 16px;
    background-color: #f8f8fa;
    padding: 4px 8px;
`;
