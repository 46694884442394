import React, { FC, InputHTMLAttributes, ReactNode, useState } from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import {
    IconClickableContainer,
    IconContainer,
    InputContainer,
    InputLabel,
    inputStyles,
    InputWrapper,
} from 'src/components/Input/styles';
import { useInputFocus } from '@invoicebox/ui';
registerLocale('ru', ru);

export interface IInputDateTimeProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    disabled?: boolean;
    hasError?: boolean;
    className?: string;
    icon?: ReactNode;
    onIconClick?: () => void;
    iconIsDisabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
    minTimeInSelectDay?: Date;
    showTimeInput?: boolean;
    dateFormat?: string;
    isShowIconIfHaveValue?: boolean;
    onChange: (element: any) => void;
}

interface InputIconContainerProps {
    onClick?: () => void;
    iconIsDisabled?: boolean;
}

const InputIconContainer: FC<InputIconContainerProps> = ({ onClick, iconIsDisabled, children }) =>
    onClick ? (
        <IconClickableContainer onClick={onClick} disabled={iconIsDisabled}>
            {children}
        </IconClickableContainer>
    ) : (
        <IconContainer>{children}</IconContainer>
    );

const InputDateTime: FC<IInputDateTimeProps> = ({
    isShowIconIfHaveValue = true,
    minDate,
    maxDate,
    minTimeInSelectDay,
    showTimeInput = false,
    dateFormat = 'd/MM/yyyy',
    placeholder,
    disabled = false,
    hasError = false,
    label = '',
    className,
    icon,
    onIconClick,
    iconIsDisabled,
    onFocus,
    onBlur,
    ...props
}) => {
    const { inFocus, handleFocus, handleBlur } = useInputFocus({ initialFocusFlag: false, onFocus, onBlur });
    const initialDate = props.value ? new Date(props.value as string) : null;
    const [selectedDate, setSelectedDate] = useState<Date | null>(initialDate);

    const onChangeValue = (date: Date) => {
        if (minDate && minTimeInSelectDay) {
            if (date > minTimeInSelectDay) {
                setSelectedDate(date);
                props.onChange(date);
            } else {
                setSelectedDate(minTimeInSelectDay);
                props.onChange(minTimeInSelectDay);
            }
        } else {
            setSelectedDate(date);
            props.onChange(date);
        }
    };

    // в случае, если инпут слишком мал чтобы отображать значение и иконку одновременно, можно задать флаг isShowIconIfHaveValue=false,
    // тогда отступы для иконки и иконка будет отображаться только при пустом инпуте
    const isShowIcon = (!!icon && isShowIconIfHaveValue) || (!!icon && !isShowIconIfHaveValue && !selectedDate);

    return (
        <InputWrapper disabled={disabled} className={className}>
            {label && (
                <InputLabel variant="regular12" inFocus={inFocus}>
                    {label}
                </InputLabel>
            )}
            <DateTimeContainer hasError={hasError} hasIcon={isShowIcon} inFocus={inFocus}>
                <InputField
                    selected={selectedDate}
                    onChange={onChangeValue}
                    // todo добавить локализацию при реализации
                    locale="ru"
                    placeholderText={placeholder}
                    disabled={disabled}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    name={props.name}
                    minDate={minDate}
                    maxDate={maxDate}
                    showTimeInput={showTimeInput}
                    dateFormat={dateFormat}
                />
                {isShowIcon && (
                    <InputIconContainer onClick={onIconClick} iconIsDisabled={iconIsDisabled}>
                        {icon}
                    </InputIconContainer>
                )}
            </DateTimeContainer>
        </InputWrapper>
    );
};

export default InputDateTime;

const DateTimeContainer = styled(InputContainer)`
    .react-datepicker {
        border: none;
        box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
        border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
        padding: 20px 0;
    }

    .react-datepicker__day-name {
        text-transform: uppercase;
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        width: 18px;
        margin: 5px;
    }

    .react-datepicker__current-month {
        ${({ theme }) => theme.mixins.getTypography('regular14')}
        text-transform: capitalize;
        font-weight: normal;
        margin-bottom: 14px;
    }

    .react-datepicker-time__input-container {
        margin-left: 22px;
    }

    .react-datepicker__month-container {
        width: 270px;
    }

    .react-datepicker__header {
        background: none;
        border: none;
    }

    .react-datepicker-time__caption {
        display: none;
    }

    .react-datepicker-time__input-container .react-datepicker-time__input {
        margin-left: 0;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker-popper {
        z-index: 4;
    }

    .react-datepicker__navigation {
        width: 20px;
        height: 20px;
        top: 28px;

        &:hover {
            .react-datepicker__navigation-icon {
                &:before {
                    border-color: ${({ theme }) => theme.colors.base()};
                }
            }
        }

        &--previous {
            left: 30px;
        }
        &--next {
            right: 30px;
        }
    }

    .react-datepicker__navigation-icon {
        width: 100%;
        height: 100%;
        &:before {
            width: 10px;
            height: 10px;
            border-radius: 0 1px 0 0;
            border-width: 2px 2px 0 0;
        }

        &--previous {
            &:before {
                right: 0;
            }
        }

        &--next {
            &:before {
                left: 0;
            }
        }
    }

    .react-datepicker__day {
        width: 18px;
        height: 18px;
        margin: 5px;
        line-height: 1.6em;
        ${({ theme }) => theme.mixins.getTypography('regular14')}
        &--selected {
            background: none;
            position: relative;
            color: ${({ theme }) => theme.colors.base()};
            &:after {
                content: '';
                display: block;
                width: 16px;
                height: 2px;
                background: #fad247;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                border-radius: 6px 6px 0 0;
            }
        }

        &--today {
            font-weight: 500;
        }

        &--keyboard-selected {
            color: ${({ theme }) => theme.colors.base()};
            background: none;
        }
    }

    .react-datepicker-time__input {
        padding-right: 0;
        width: 47px;
        border: none;
        &:focus-visible {
            border: none;
            outline: none;
        }
    }
`;

// @ts-ignore
const InputField = styled(DatePicker)`
    ${inputStyles};
    color: ${({ theme }) => theme.colors.base()};
    ${({ theme }) => theme.mixins.getTypography('regular14')}
`;
