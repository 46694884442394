import React, { useEffect } from 'react';
import { useField } from 'react-final-form';
import FormField, { FormFieldTypeEnum } from 'src/components/FormField';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import DynamicFormFields from 'src/components/DynamicFormFields';
import { IntegrationInputsWrapper } from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateIntegration/components/IntegrationInputs/styles';
import { useAppDispatch } from 'src/store';
import { maxLengthInputsMerchantIntegration } from 'src/utils/constants';
import { getApplicationById, getApplicationList } from 'src/store/Application/selectors';
import { setSelectedApplication } from 'src/store/Application';

const IntegrationInputs = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const field = useField('applicationId');
    const selectedApplicationId = field?.input?.value;
    const applicationList = useSelector(getApplicationList);
    const selectedApplication = useSelector(getApplicationById(selectedApplicationId));

    useEffect(() => {
        dispatch(setSelectedApplication(selectedApplication));
    }, [dispatch, selectedApplication]);

    return (
        <IntegrationInputsWrapper>
            <FormField
                fieldType={FormFieldTypeEnum.select}
                options={applicationList?.map((application) => ({
                    value: application.id,
                    label: application.name,
                }))}
                name="applicationId"
                label={intl.formatMessage({ defaultMessage: 'Выберите тип вашей учётной системы' })}
                placeholder={intl.formatMessage({ defaultMessage: 'Выберите из списка' })}
            />
            <FormField
                name="name"
                label={intl.formatMessage({ defaultMessage: 'Наименование' })}
                placeholder={intl.formatMessage({ defaultMessage: 'Придумайте имя, например «Касса 1»' })}
                maxLength={maxLengthInputsMerchantIntegration}
            />
            {selectedApplication?.settings && (
                <DynamicFormFields properties={selectedApplication?.settings?.properties} />
            )}
        </IntegrationInputsWrapper>
    );
};

export default IntegrationInputs;
