import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white()};
    height: 80px;
`;

export const Content = styled.div`
    width: 1170px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.xl} {
        width: 100%;
        padding: 0 24px;
    }
`;

export const LogoButton = styled.a`
    display: flex;
    align-items: center;
`;

export const LoginButton = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 6px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.lightGrey()};
    padding: 8px 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.base()};

    &:hover {
        opacity: 0.8;
    }
`;

export const MenuItem = styled(NavLink)`
    text-decoration: none;
    position: relative;

    &:after {
        content: '';
    }

    &.active {
        &:after {
            display: block;
            width: 100%;
            height: 2px;
            background-color: ${({ theme }) => theme.colors.base()};
            position: absolute;
            top: 48px;
            border-radius: 2px 2px 0 0;
        }
    }
`;

export const MenuLinks = styled.div`
    display: flex;
    gap: 24px;
`;
