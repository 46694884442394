import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const CalendarWrapper = styled.div`
    height: 20px;
    display: grid;
    grid-template-columns: 20px auto 20px;
    grid-column-gap: 20px;
    align-items: center;
    justify-content: flex-end;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    text-transform: capitalize;
    cursor: pointer;
    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-right: 0;
        margin-left: auto;
        justify-content: flex-start;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-gap: 5px;
    }
`;

export const PrevMonthButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: center;
    width: 100%;
    height: 12px;
`;

export const FilterHeader = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 20px auto;
    grid-column-gap: 8px;
    justify-content: center;
    text-align: center;
`;

export const FilterButton = styled.button`
    display: contents;
`;

export const SelectedDateInterval = styled(Typography)`
    text-align: center;
    &:first-letter {
        text-transform: capitalize;
    }
`;

export const CalendarImage = styled.div`
    width: 20px;
    height: 20px;
    margin-top: -4px;
`;
