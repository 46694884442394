import { IOrderContainer, TOrder } from 'src/store/Order/types';
import { ICommonResponse } from 'src/utils/http';

export const getOrdersContainerList = (data: ICommonResponse<IOrderContainer[], any>[] | undefined) =>
    data?.reduce((acc, { extendedData, data: orderContainers }) => {
        const orderContainerIds = orderContainers.map(({ id }) => id);
        const includes = extendedData.find(({ entity }) => entity === 'order').data as TOrder[];
        // @TODO костыль для определения заказов с комиссией, они приходят в инклюдах и нам нужно их удалять
        const orders = includes?.filter(
            ({ description, orderContainerId }) =>
                description !== 'Комиссия платёжного агента' && orderContainerIds.includes(orderContainerId),
        );
        return [...acc, ...orders];
    }, [] as TOrder[]);
