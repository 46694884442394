import React, { FC } from 'react';
import { ReactComponent as CopyImage } from 'src/assets/icons/copy.svg';
import { useIntl } from 'react-intl';
import { CopyButton, HelperDescription, HelperFooter, HelperTitle, HelperUploadDocument } from './styles';

interface IHelperUpload {
    openToast: () => void;
}

const HelperUpload: FC<IHelperUpload> = ({ openToast }) => {
    const intl = useIntl();

    const textWhereSendDocuments = intl.formatMessage({
        defaultMessage: '196105, Санкт-Петербург, пр. Юрия Гагарина д. 1',
    });

    const clickOnCopy = () => {
        if (navigator?.clipboard?.writeText) {
            navigator.clipboard.writeText(textWhereSendDocuments);
            openToast();
        }
    };

    return (
        <HelperUploadDocument>
            <HelperTitle variant="regular14">
                {intl.formatMessage({ defaultMessage: 'Оригиналы документов необходимо отправить по адресу:' })}
            </HelperTitle>
            <HelperFooter>
                <HelperDescription variant="regular12">{textWhereSendDocuments}</HelperDescription>
                <CopyButton type="button" onClick={clickOnCopy}>
                    <CopyImage />
                </CopyButton>
            </HelperFooter>
        </HelperUploadDocument>
    );
};

export default HelperUpload;
