import React, { FC } from 'react';
import {
    CompositionOrder,
    Description,
    GeneralData,
    GeneralFields,
    ModalContent,
    ModalHeader,
    ModalOrderDetailWrapper,
    OrderBasketItems,
    OrderIcon,
    OrderStatus,
    WrapperIcon,
    WrapStatusIcon,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/RefundDetail/components/ModalOrderDetail/styles';
import { ORDER_FIELDS, TOrder } from 'src/store/Order/types';
import { getIconByStatusAndColor } from 'src/components/Widgets/helpers';
import colors from 'src/theme/colors';
import { getProcessableOrderStatuses } from 'src/store/Order/constants';
import ColumnField from 'src/pages/businessOffice/Shop/ShopTabsTables/RefundDetail/components/ColumnField';
import { convertDateAndTimeToShow } from 'src/utils/helpers';
import OrderItem from 'src/pages/businessOffice/Shop/ShopTabsTables/RefundDetail/components/OrderItem';
import { useIntl } from 'react-intl';
import Typography from '../../../../../../../components/Typography';

interface IModalOrderDetail {
    onClose: () => void;
    isOpen: boolean;
    order: TOrder;
}

const ModalOrderDetail: FC<IModalOrderDetail> = ({ order, onClose, isOpen }) => {
    const intl = useIntl();
    const { icon, color } = getIconByStatusAndColor(order.status, colors);

    const orderFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Номер счёта' }),
            value: order.merchantOrderId,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Срок оплаты' }),
            value: convertDateAndTimeToShow(order[ORDER_FIELDS.expirationDate]),
        },
    ];

    return (
        <ModalOrderDetailWrapper closeModal={onClose} isOpen={isOpen}>
            <ModalHeader>
                <Typography variant="title">
                    {intl.formatMessage({ defaultMessage: 'Заказ' })} {order.merchantOrderId}
                </Typography>
                <OrderStatus color={color}>
                    <WrapperIcon>
                        <OrderIcon />
                        {icon && <WrapStatusIcon>{icon}</WrapStatusIcon>}
                    </WrapperIcon>
                    {getProcessableOrderStatuses(intl)[order.status]}
                </OrderStatus>
            </ModalHeader>
            <ModalContent>
                <GeneralData>
                    <Typography variant="subheading">
                        {intl.formatMessage({ defaultMessage: 'Общие сведения' })}
                    </Typography>
                    <GeneralFields>
                        {orderFields.map((orderField) => (
                            <ColumnField key={orderField.label} label={orderField.label} value={orderField.value} />
                        ))}
                        <Description
                            label={intl.formatMessage({ defaultMessage: 'Комментарий' })}
                            value={order.description}
                        />
                    </GeneralFields>
                </GeneralData>
                <CompositionOrder>
                    <Typography variant="subheading">
                        {intl.formatMessage({ defaultMessage: 'Состав заказа' })}
                    </Typography>
                    <OrderBasketItems>
                        {order.basketItems.map((basketItem) => (
                            <OrderItem key={basketItem.sku} orderItem={basketItem} />
                        ))}
                    </OrderBasketItems>
                </CompositionOrder>
            </ModalContent>
        </ModalOrderDetailWrapper>
    );
};

export default ModalOrderDetail;
