import qs from 'query-string';
// @ts-ignore
import qs1 from 'qs';
import { isArray, isEmpty, isNull } from 'lodash';
import useSWROriginal, {
    KeyLoader,
    SWRInfiniteResponse,
    SWRResponse,
    useSWRInfinite as useSWRInfiniteOriginal,
} from 'swr';
import http, { ICommonResponse, strapiHttp } from 'src/utils/http';
import { labelTroublesInternetConnection, labelTroubleXHR, networkErrorResponse } from 'src/utils/constants';
import { IErrorNotification, setError } from 'src/store/Auth';
import { AppDispatch, useAppDispatch } from 'src/store';
import { useSelector } from 'react-redux';
import { getError } from 'src/store/Auth/selectors';
// eslint-disable-next-line import/no-unresolved
import { Fetcher, SWRConfiguration } from 'swr/dist/types';

const defaultFetcher = (url: string) => http.get(url).then((res) => res.data);

export const storiesFetcher = (url: string) => strapiHttp.get(url).then((res) => res.data);

const paginator =
    <T>(url: string | null, params?: any): KeyLoader<T> =>
    (pageIndex: number, previousPageData: T | null) => {
        if (isNull(url)) return null;
        const parsedUrl = qs.parseUrl(url);
        parsedUrl.query['_page'] = String(pageIndex + 1);
        if (!parsedUrl.query['_pageSize']) {
            parsedUrl.query['_pageSize'] = '10';
        }
        // @ts-ignore
        parsedUrl.query = { ...parsedUrl.query, ...params };

        if (isArray(previousPageData) && isEmpty(previousPageData)) return null; // reached the end

        return qs.stringifyUrl(parsedUrl, { skipNull: true });
    };

const getUrlWithParams = (url: string | null, params?: any) => {
    if (url === null) return url;
    const parsedUrl = qs.parseUrl(url);
    // @ts-ignore
    parsedUrl.query = { ...parsedUrl.query, ...params };
    return `${parsedUrl.url}?${qs1.stringify(parsedUrl.query, { arrayFormat: 'brackets', encodeValuesOnly: true })}`;
};

const notificationCreator = (
    data: SWRResponse<ICommonResponse<any, any>, any> | SWRInfiniteResponse<ICommonResponse<any, any>, any>,
    dispatch: AppDispatch,
    error: IErrorNotification,
) => {
    const isNetworkError = data?.error?.toString() === networkErrorResponse;
    if (data?.error && !error.isActive) {
        if (isNetworkError) {
            dispatch(setError({ title: labelTroublesInternetConnection, isActive: true }));
        } else {
            dispatch(setError({ title: labelTroubleXHR, isActive: true }));
        }
    }
};
// @ts-ignore
export const useSWR = <T>(
    url: string | null,
    params?: any,
    // @ts-ignore
    // eslint-disable-next-line default-param-last
    fetcher?: Fetcher<ICommonResponse<T>> = defaultFetcher,
    swrConfig?: SWRConfiguration,
) => {
    const data = useSWROriginal<ICommonResponse<T>>(getUrlWithParams(url, params), fetcher, {
        errorRetryCount: 3,
        ...swrConfig,
    });
    const dispatch = useAppDispatch();
    const currentError = useSelector(getError);
    notificationCreator(data, dispatch, currentError);
    return data;
};

export const useSWRInfinite = <T>(url: string | null, params?: any) => {
    const data = useSWRInfiniteOriginal(paginator<ICommonResponse<T>>(getUrlWithParams(url, params)), defaultFetcher);
    const dispatch = useAppDispatch();
    const currentError = useSelector(getError);
    notificationCreator(data, dispatch, currentError);
    return data;
};
