import { isUndefined } from 'lodash';
import { RootState } from '../index';
import { contractsAdapter } from './index';
import { IContract } from './types';

const { selectAll, selectById } = contractsAdapter.getSelectors();

export const getContracts = (state: RootState) => selectAll(state.contracts);
export const getContractById = (id?: number) => (state: RootState) =>
    id ? selectById(state.contracts, id) : undefined;
export const getContractsByCounterpartyId = (id?: string | null) => (state: RootState) =>
    getContracts(state).filter((c) => c.counterpartyId === id && !c.deleted);

export const getContractTemplates = (state: RootState) => state.contracts.contractTemplates;

export const getSelectedContractTemplateId = (state: RootState) => state.contracts.selectedContractTemplateId;

export const getSelectedContractId = (state: RootState) => state.contracts.selectedId;

export const getContractsOrTemplatesForCurrentCounterparty = (state: RootState) => {
    const templates = getContractTemplates(state);
    const currentCounterpartyContractsByTemplateId = getContractsByCounterpartyId(state.counterparty.selectedId)(
        state,
    ).reduce((acc, contract) => ({ ...acc, [contract.templateId]: contract }), {} as Record<string, IContract>);
    // @ts-ignore
    return templates.map((template: IContract) =>
        !isUndefined(currentCounterpartyContractsByTemplateId[template.id])
            ? currentCounterpartyContractsByTemplateId[template.id]
            : template,
    );
};

export const getSelectedContract = (state: RootState) => {
    const selectedContractId = getSelectedContractId(state);
    const contracts = getContracts(state);
    return contracts.find((contract) => contract.id === selectedContractId);
};

export const getIsLoadedContracts = (state: RootState) => state.contracts.isLoaded;
