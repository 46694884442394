import { TNewCounterparty } from './types';
import { assert } from '../../utils/assert';
import { INDIVIDUAL_VAT_NUMBER_LENGTH, ORGANISATION_VAT_NUMBER_LENGTH } from '../../utils/constants';

export class NewCounterparty {
    private readonly id: string;
    private readonly vatNumber: string;
    private readonly taxRegistrationReasonCode?: string;
    private readonly name?: string;
    private readonly nameFull?: string;
    private readonly nameI18n?: string;
    private readonly registrationNumber?: string;
    private readonly registrationDate?: string;
    private readonly registrationAddress?: string;
    private readonly postAddress?: string;
    private readonly postAddressZip?: string;
    private readonly legalEntityTypeId?: number;
    private readonly registered: boolean;

    private constructor(state: TNewCounterparty) {
        const {
            id,
            vatNumber,
            taxRegistrationReasonCode,
            name,
            nameFull,
            nameI18n,
            registrationNumber,
            registrationDate,
            registrationAddress,
            postAddress,
            postAddressZip,
            legalEntityTypeId,
            registered,
        } = state;

        this.id = id;
        this.vatNumber = vatNumber;
        this.taxRegistrationReasonCode = taxRegistrationReasonCode;
        this.name = name;
        this.nameFull = nameFull;
        this.nameI18n = nameI18n;
        this.registrationNumber = registrationNumber;
        this.registrationDate = registrationDate;
        this.registrationAddress = registrationAddress;
        this.postAddress = postAddress;
        this.postAddressZip = postAddressZip;
        this.legalEntityTypeId = legalEntityTypeId;
        this.registered = registered;
    }

    static fromResponse(response: unknown): NewCounterparty {
        assert(typeof response === 'object' && response !== null);

        assert('id' in response);
        const { id } = response;
        assert(typeof id === 'string');
        assert(!!id.length);

        assert('vatNumber' in response);
        const { vatNumber } = response;
        assert(typeof vatNumber === 'string');
        assert(!!vatNumber.length);

        let taxRegistrationReasonCode;
        if ('taxRegistrationReasonCode' in response) {
            taxRegistrationReasonCode = response.taxRegistrationReasonCode;
            assert(typeof taxRegistrationReasonCode === 'string');
        }

        let name;
        if ('name' in response) {
            name = response.name;
            assert(typeof name === 'string');
        }

        let nameFull;
        if ('nameFull' in response) {
            nameFull = response.nameFull;
            assert(typeof nameFull === 'string');
        }

        let nameI18n;
        if ('nameI18n' in response) {
            nameI18n = response.nameI18n;
            assert(typeof nameI18n === 'string');
        }

        let registrationNumber;
        if ('registrationNumber' in response) {
            registrationNumber = response.registrationNumber;
            assert(typeof registrationNumber === 'string');
        }

        let registrationDate;
        if ('registrationDate' in response) {
            registrationDate = response.registrationDate;
            assert(typeof registrationDate === 'string');
        }

        let registrationAddress;
        if ('registrationAddress' in response) {
            registrationAddress = response.registrationAddress;
            assert(typeof registrationAddress === 'string');
        }

        let postAddress;
        if ('postAddress' in response) {
            postAddress = response.postAddress;
            assert(typeof postAddress === 'string');
        }

        let postAddressZip;
        if ('postAddressZip' in response) {
            postAddressZip = response.postAddressZip;
            assert(typeof postAddressZip === 'string');
        }

        let legalEntityTypeId;
        if ('legalEntityTypeId' in response) {
            legalEntityTypeId = response.legalEntityTypeId;
            assert(typeof legalEntityTypeId === 'number');
            assert(Number.isInteger(legalEntityTypeId));
            assert(legalEntityTypeId > 0);
        }

        assert('registered' in response);
        const { registered } = response;
        assert(typeof registered === 'boolean');

        const checkedResponse = {
            id,
            vatNumber,
            taxRegistrationReasonCode,
            name,
            nameFull,
            nameI18n,
            registrationNumber,
            registrationDate,
            registrationAddress,
            postAddress,
            postAddressZip,
            legalEntityTypeId,
            registered,
        } satisfies TNewCounterparty;

        return new NewCounterparty(checkedResponse);
    }

    static fromMock(): NewCounterparty {
        return new NewCounterparty({
            id: 'id',
            vatNumber: '2323232323',
            taxRegistrationReasonCode: '111111111',
            name: 'Название',
            nameFull: 'Название полное',
            nameI18n: 'Название иностранное',
            registrationNumber: '112233',
            registrationDate: 'Название',
            registrationAddress: 'Адрес регистрации',
            postAddress: 'ул. Гагарина 1-1',
            postAddressZip: '112233',
            legalEntityTypeId: 1,
            registered: true,
        });
    }

    static couldIdentifyByVat(vatNumber: string) {
        return vatNumber.length === INDIVIDUAL_VAT_NUMBER_LENGTH || vatNumber.length === ORGANISATION_VAT_NUMBER_LENGTH;
    }
}
