import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';
import { Link } from 'react-router-dom';

export const Header = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 24px;
`;

export const ButtonCheckDocumentsStatus = styled(Link)`
    padding: 5px 8px;
    border-radius: 90px;
    background-color: ${({ theme }) => theme.colors.white()};
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 3px;
    color: #6c6d6e;
    text-decoration: none;

    &:hover {
        opacity: 0.8;
    }
`;

export const CheckStatusLabel = styled(Typography)`
    font-size: 13px;
`;

export const ArrowWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;

export const DocumentReceiveMethodsWrapper = styled.div`
    flex-shrink: 0;
`;
