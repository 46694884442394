import React, { FC, useEffect, useState } from 'react';
import { enumTabsConnection, IStep } from 'src/pages/businessOffice/Claim/components/MainConnectionPayment';
import {
    BankDetailWrapper,
    Banks,
    ButtonAddNew,
    ButtonContent,
} from 'src/pages/businessOffice/Claim/components/BankDetails/styles';
import { useSelector } from 'react-redux';
import { getBankDetailsCurrentCounterparty, getSelectedBankDetailId } from 'src/store/Counterparty/selectors';
import { ReactComponent as PlusImage } from 'src/assets/icons/plus.svg';
import ButtonsNextPrev from 'src/pages/businessOffice/Claim/components/ButtonsNextPrev';
import { ICounterpartyBankDetail } from 'src/store/Counterparty/types';
import useModal from 'src/hooks/useModal';
import ModalCreateBankDetail from 'src/pages/businessOffice/Claim/components/ModalCreateBankDetail';
import FormBankDetail from 'src/pages/businessOffice/Claim/components/FormBankDetail';
import { useAppDispatch } from 'src/store';
import { setBankDetailId } from 'src/store/Counterparty';
import { getSelectedContract, getSelectedContractTemplateId } from 'src/store/Contracts/selectors';
import ButtonBankCard from 'src/pages/businessOffice/Claim/components/ButtonBankCard';
import { useIntl } from 'react-intl';

const BankDetails: FC<IStep> = ({ setStep }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { isOpen, open, close } = useModal(false);
    const bankDetails = useSelector(getBankDetailsCurrentCounterparty);
    const selectedBankDetailId = useSelector(getSelectedBankDetailId);
    const selectedContractTemplateId = useSelector(getSelectedContractTemplateId);
    const selectedContract = useSelector(getSelectedContract);

    const [selectedBank, setSelectedBank] = useState<ICounterpartyBankDetail>();

    // при открытии недозаполненной заявки
    useEffect(() => {
        if (selectedBankDetailId && bankDetails) {
            const foundedBankDetail = bankDetails.find((bank) => bank.id === selectedBankDetailId);
            setSelectedBank(foundedBankDetail);
        }
    }, [bankDetails, selectedBankDetailId]);

    const onClickBack = () => {
        setStep(enumTabsConnection.tariff);
    };

    const onClickNext = async () => {
        if (selectedBank) {
            dispatch(setBankDetailId(selectedBank.id));
            setStep(enumTabsConnection.passport);
        }
    };

    if (bankDetails && bankDetails.length === 0) {
        return <FormBankDetail onClickBack={onClickBack} />;
    }

    return (
        <BankDetailWrapper>
            <Banks>
                {bankDetails &&
                    bankDetails
                        .filter((bank) =>
                            !selectedContractTemplateId && selectedContract
                                ? bank.id === selectedContract?.bankDetailId
                                : bank,
                        )
                        .map((bank) => (
                            <ButtonBankCard
                                key={bank.id}
                                bank={bank}
                                selectedBankId={selectedBank?.id}
                                setSelectedBank={setSelectedBank}
                            />
                        ))}
                {selectedContractTemplateId && !selectedContract && (
                    <ButtonAddNew type="button" onClick={open}>
                        <ButtonContent>
                            <PlusImage />
                            {intl.formatMessage({ defaultMessage: 'Добавить новый' })}
                        </ButtonContent>
                    </ButtonAddNew>
                )}
            </Banks>
            <ButtonsNextPrev
                isActiveNextButton={Boolean(selectedBank)}
                onClickPrev={onClickBack}
                onClickNext={onClickNext}
            />
            <ModalCreateBankDetail isOpen={isOpen} onClose={close} />
        </BankDetailWrapper>
    );
};

export default BankDetails;
