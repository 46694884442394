import React, { FC, ReactNode } from 'react';
import * as S from './styles';

type TProps = {
    icon: ReactNode;
    value: string;
};

const ContactRow: FC<TProps> = ({ icon, value }) => (
    <S.Wrapper>
        {icon}
        <S.Label variant="bodyMRegular">{value}</S.Label>
    </S.Wrapper>
);

export default ContactRow;
