import React, { FC } from 'react';
import {
    IconAdd,
    NavLinkCreateClient,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/Clients/components/ButtonCreateClient/styles';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { typesClients } from '../../index';

interface IButtonCreateClient {
    typeClient: typesClients;
}

const ButtonCreateClient: FC<IButtonCreateClient> = ({ typeClient }) => {
    const intl = useIntl();
    const { merchantId } = useParams<{ merchantId: string }>();
    const pathClientCreate = `${RoutesBusinessOffice.shop}/${merchantId}`;

    return (
        <NavLinkCreateClient
            to={
                typeClient === typesClients.CLIENT
                    ? pathClientCreate + RoutesBusinessOffice.createclient
                    : pathClientCreate + RoutesBusinessOffice.createcounterparty
            }
        >
            <IconAdd />
            {intl.formatMessage({ defaultMessage: 'Новый клиент' })}
        </NavLinkCreateClient>
    );
};

export default ButtonCreateClient;
