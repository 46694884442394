import { IntlShape } from 'react-intl';

export enum ClaimStatuses {
    draft = 'draft',
    moderating = 'moderating',
    moderated = 'moderated',
    canceled = 'canceled',
    blocked = 'blocked',
}

export enum ClaimFields {
    id = 'id',
    counterpartyId = 'counterpartyId',
    merchantContractId = 'merchantContractId',
    merchantId = 'merchantId',
    status = 'status',
    createdAt = 'createdAt',
    closeAt = 'closeAt',
    closed = 'closed',
    clientDescription = 'clientDescription',
    description = 'description',
    passport = 'passport',
}

export enum PASSPORT_DATA_FIELDS {
    name = 'name',
    birthdate = 'birthdate',
    birthplace = 'birthplace',
    series = 'series',
    number = 'number',
    issuedPlace = 'issuedPlace',
    issuedPlaceCode = 'issuedPlaceCode',
    issuedDate = 'issuedDate',
}

export const getClaimStatusesLabelsMap = (intl: IntlShape) => ({
    [ClaimStatuses.draft]: intl.formatMessage({ defaultMessage: 'В процессе заполнения' }),
    [ClaimStatuses.moderating]: intl.formatMessage({ defaultMessage: 'На рассмотрении' }),
    [ClaimStatuses.moderated]: intl.formatMessage({ defaultMessage: 'Проверен' }),
    [ClaimStatuses.canceled]: intl.formatMessage({ defaultMessage: 'Отклонено' }),
    [ClaimStatuses.blocked]: intl.formatMessage({ defaultMessage: 'Отклонено' }),
});
