import { ReactComponent as UsersIcon } from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/assets/users.svg';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ButtonSelectClientWrapper, SelectClientIconContainer } from './styles';

interface IButtonFindClient {
    onClick: () => void;
}

const ButtonFindClient: FC<IButtonFindClient> = ({ onClick }) => {
    const intl = useIntl();

    return (
        <ButtonSelectClientWrapper type="button" onClick={onClick}>
            <SelectClientIconContainer>
                <UsersIcon />
            </SelectClientIconContainer>
            {intl.formatMessage({ defaultMessage: 'Выбор клиента' })}
        </ButtonSelectClientWrapper>
    );
};

export default ButtonFindClient;
