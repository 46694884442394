import React, { FC, useCallback } from 'react';
import { ORDER_ITEM_FIELDS, TOrderItem, TVatType } from 'src/store/Order/types';
import {
    ButtonAddNewItem,
    OrderItemsContainer,
    OrderItemsHeader,
    Title,
} from 'src/pages/businessOffice/OrderContainer/components/OrderItems/styles';
import ItemOrder from 'src/pages/businessOffice/OrderContainer/components/ItemOrder';
import ModalAddItemOrder from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/ModalAddItemOrder';
import useModal from 'src/hooks/useModal';
import { IMeasure } from 'src/store/Merchant/types';
import { ReactComponent as IconPlus } from 'src/assets/icons/plus.svg';
import useDictionary from 'src/hooks/useDictionarty';
import { DICTIONARY_TYPES } from 'src/store/Dictionaries/constants';
import { useIntl } from 'react-intl';

interface IOrderItems {
    isEdit?: boolean;
    basketItems: TOrderItem[];
    setBasketItems?: (arg: TOrderItem[]) => void;
}

const OrderItems: FC<IOrderItems> = ({
    isEdit = false,
    basketItems,
    setBasketItems = (items: TOrderItem[]) => items,
}) => {
    const intl = useIntl();
    const countSkeletonItems = 4;
    const { isOpen: addItemOrderModalVisible, open: openModal, close: closeModal } = useModal();

    const vatTypes: TVatType[] = useDictionary(DICTIONARY_TYPES.vat)?.data as any;
    const measures: IMeasure[] = useDictionary(DICTIONARY_TYPES.measure)?.data as any;

    const addItemOrder = (itemOrder: TOrderItem) => {
        setBasketItems([...basketItems, itemOrder]);
    };

    const removeItem = useCallback(
        (itemOrderWillRemoved: TOrderItem) => {
            const arrayItemsOrderWithoutRemovedItem = basketItems.filter(
                (itemOrder) => itemOrder[ORDER_ITEM_FIELDS.sku] !== itemOrderWillRemoved[ORDER_ITEM_FIELDS.sku],
            );
            setBasketItems(arrayItemsOrderWithoutRemovedItem);
        },
        [basketItems, setBasketItems],
    );

    const editItem = useCallback(
        (editedItemOrder: TOrderItem) => {
            const editedArrayItems = basketItems.map((itemCompositionOrder) =>
                itemCompositionOrder[ORDER_ITEM_FIELDS.sku] === editedItemOrder[ORDER_ITEM_FIELDS.sku]
                    ? editedItemOrder
                    : itemCompositionOrder,
            );
            setBasketItems(editedArrayItems);
        },
        [basketItems, setBasketItems],
    );

    const renderBasketItems = () => {
        if (basketItems) {
            return basketItems.map((itemOrder) => (
                <ItemOrder
                    key={itemOrder[ORDER_ITEM_FIELDS.sku]}
                    basketItem={itemOrder}
                    isEdit={isEdit}
                    removeItem={removeItem}
                    editItem={editItem}
                    vatTypes={vatTypes}
                    measures={measures}
                />
            ));
        }
        const arrSkeletonComponents = new Array(countSkeletonItems).fill(ItemOrder);
        return arrSkeletonComponents.map((itemOrder, index) => (
            <ItemOrder
                isShowSkeleton
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                basketItem={itemOrder}
                isEdit={isEdit}
                removeItem={removeItem}
                editItem={editItem}
                vatTypes={vatTypes}
                measures={measures}
            />
        ));
    };

    return (
        <div>
            <OrderItemsHeader>
                <Title variant="subheading">{intl.formatMessage({ defaultMessage: 'Состав счёта' })}</Title>
                {isEdit && (
                    <ButtonAddNewItem type="button" onClick={openModal}>
                        <IconPlus />
                    </ButtonAddNewItem>
                )}
            </OrderItemsHeader>
            <OrderItemsContainer>{renderBasketItems()}</OrderItemsContainer>
            <ModalAddItemOrder
                measures={measures}
                vatTypes={vatTypes}
                addOrEditItemOrder={addItemOrder}
                closeModal={closeModal}
                isOpen={addItemOrderModalVisible}
            />
        </div>
    );
};

export default OrderItems;
