import { ClaimFields, PASSPORT_DATA_FIELDS } from './constants';

export enum ClaimStatuses {
    draft = 'draft',
    moderating = 'moderating',
    moderated = 'moderated',
    canceled = 'canceled',
    blocked = 'blocked',
}

export interface IClaimPassportData {
    [PASSPORT_DATA_FIELDS.name]: string;
    [PASSPORT_DATA_FIELDS.birthdate]: string;
    [PASSPORT_DATA_FIELDS.birthplace]: string;
    [PASSPORT_DATA_FIELDS.series]: string;
    [PASSPORT_DATA_FIELDS.number]: string;
    [PASSPORT_DATA_FIELDS.issuedPlaceCode]: string;
    [PASSPORT_DATA_FIELDS.issuedDate]: string;
    [PASSPORT_DATA_FIELDS.issuedPlace]: string;
}

export type IClaimStatus = keyof typeof ClaimStatuses;

export interface IUpdateClaimFields {
    [ClaimFields.merchantContractId]?: number;
    [ClaimFields.passport]?: IClaimPassportData;
    [ClaimFields.clientDescription]?: string;
}

export interface IClaim {
    [ClaimFields.id]: string;
    [ClaimFields.counterpartyId]: string;
    [ClaimFields.merchantContractId]: number;
    [ClaimFields.merchantId]: string;
    [ClaimFields.status]: IClaimStatus;
    [ClaimFields.createdAt]: string;
    [ClaimFields.closeAt]: string;
    [ClaimFields.closed]: boolean;
    [ClaimFields.clientDescription]: string;
    [ClaimFields.description]: string;
    [ClaimFields.passport]?: IClaimPassportData;
}

export type IUpdateClaimStatusInfo = Pick<IClaim, ClaimFields.clientDescription | ClaimFields.status>;
