import React from 'react';
import PaymentPromo from 'src/components/PaymentPromo';
import ClaimActiveWidget from 'src/components/Widgets/ClaimActiveWidget';
import ClaimModeratingWidget from 'src/components/Widgets/ClaimModeratingWidget';
import { ClaimFields } from 'src/store/Claims/constants';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import { useSelector } from 'react-redux';
import {
    getClaimByMerchantId,
    getIsLoadedClaims,
    getMerchantClaimModeratingOrDraftOrCanceled,
} from 'src/store/Claims/selectors';
import { IContract } from 'src/store/Contracts/types';
import { getContractById, getIsLoadedContracts } from 'src/store/Contracts/selectors';
import { useParams } from 'react-router-dom';
import { IMerchant } from 'src/store/Merchant/types';
import { getMerchantById } from 'src/store/Merchant/selectors';
import { IShopRouteParams } from '../../index';
import { SkeletonPromoBanner, WidgetsWrapper } from './styles';

const ClaimBanners = () => {
    const { merchantId } = useParams<IShopRouteParams>();

    const merchant: IMerchant = useSelector(getMerchantById(merchantId)) as any;
    const claimCurrentMerchant = useSelector(getClaimByMerchantId(merchantId));
    const isLoadedContracts = useSelector(getIsLoadedContracts);
    const isLoadedClaims = useSelector(getIsLoadedClaims);

    // promoBanner
    const isShowBannerPromoClaim = merchant && !claimCurrentMerchant && !merchant?.[MERCHANT_FIELDS.merchantContractId];
    // widget draft or moderating or canceled claim
    const claimModeratingOrDraftOrCanceled = useSelector(getMerchantClaimModeratingOrDraftOrCanceled(merchantId));
    // widget signed contract and claim
    const signedContract: IContract = useSelector(
        getContractById(merchant?.[MERCHANT_FIELDS.merchantContractId]),
    ) as any;

    const renderClaimBanner = () => {
        if (!merchant || !isLoadedContracts || !isLoadedClaims) {
            return <SkeletonPromoBanner />;
        } else {
            if (isShowBannerPromoClaim) {
                return <PaymentPromo />;
            }
            const arrBanners = [];
            if (signedContract) {
                arrBanners.push(<ClaimActiveWidget key={signedContract.id} contract={signedContract} />);
            }
            if (claimModeratingOrDraftOrCanceled) {
                arrBanners.push(
                    <ClaimModeratingWidget
                        key={claimModeratingOrDraftOrCanceled[ClaimFields.id]}
                        claim={claimModeratingOrDraftOrCanceled}
                    />,
                );
            }
            return arrBanners;
        }
    };

    return <WidgetsWrapper>{renderClaimBanner()}</WidgetsWrapper>;
};

export default ClaimBanners;
