import { IWorkHours } from 'src/store/Merchant/types';

enum statusRemoveItems {
    findStartPoint = 'findStartPoint',
    findEndPoint = 'findEndPoint',
    finished = 'finished',
}

const removeIntervalsWorkDay = (startPointDay: string, endPointDay: string, worksHours: IWorkHours) => {
    let currentStatus = statusRemoveItems.findStartPoint;

    const arrayAfterRemove = Object.entries(worksHours).map((day) => {
        switch (currentStatus) {
            case statusRemoveItems.findStartPoint: {
                if (day[0] === startPointDay) {
                    currentStatus = statusRemoveItems.findEndPoint;
                    if (startPointDay === endPointDay) {
                        currentStatus = statusRemoveItems.finished;
                    }
                    return [day[0], []];
                }
                break;
            }
            case statusRemoveItems.findEndPoint: {
                if (day[0] === endPointDay) {
                    currentStatus = statusRemoveItems.finished;
                    return [day[0], []];
                }
                break;
            }
            default: {
                return [day[0], day[1]];
            }
        }
        return [day[0], day[1]];
    });
    // @ts-ignore
    return Object.fromEntries(arrayAfterRemove);
};

export default removeIntervalsWorkDay;
