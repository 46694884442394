import React from 'react';
import {
    SkeletonItemWrapper,
    SkeletonValue,
} from 'src/components/Widgets/OrderPaymentWidget/components/SkeletonPaymentItem/styles';
import { useMobile } from 'src/hooks/useMedia';

const SkeletonPaymentItem = () => {
    const isMobile = useMobile();

    return (
        <SkeletonItemWrapper>
            <SkeletonValue />
            <SkeletonValue />
            <SkeletonValue />
            {!isMobile && (
                <>
                    <SkeletonValue />
                    <SkeletonValue />
                    <SkeletonValue />
                </>
            )}
        </SkeletonItemWrapper>
    );
};

export default SkeletonPaymentItem;
