import React from 'react';
import { CreatorLabel, FooterWrapper, LinkFooter, LinksWrapper } from 'src/components/Footer/styles';
import { offerInvoiceBoxLink, supportInvoiceBoxLink } from 'src/utils/constants';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <FooterWrapper>
            <CreatorLabel variant="regular12">© {year}, ООО &Prime;ОРЦ&Prime;</CreatorLabel>
            <LinksWrapper>
                <LinkFooter target="_blank" href={offerInvoiceBoxLink}>
                    Оферта
                </LinkFooter>
                <LinkFooter target="_blank" href={supportInvoiceBoxLink}>
                    Поддержка
                </LinkFooter>
            </LinksWrapper>
        </FooterWrapper>
    );
};

export default Footer;
