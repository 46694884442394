import React, { FC, useState } from 'react';
import { CheckboxSelectAll, PositionsWrapper } from 'src/pages/businessOffice/Refund/components/PositionsRefund/styles';
import { ORDER_FIELDS, TOrderItem, TVatType } from 'src/store/Order/types';
import RefundItem from 'src/pages/businessOffice/Refund/components/RefundItem';
import { useFieldArray } from 'react-final-form-arrays';
import { differenceBy } from 'lodash';
import SkeletonRefundItem from 'src/pages/businessOffice/Refund/components/SkeletonRefundItem';
import useDictionary from 'src/hooks/useDictionarty';
import { DICTIONARY_TYPES } from 'src/store/Dictionaries/constants';
import { useIntl } from 'react-intl';

interface IPositionsRefund {
    originalBasketItems?: TOrderItem[];
}

const PositionsRefund: FC<IPositionsRefund> = ({ originalBasketItems }) => {
    const intl = useIntl();
    const countSkeletonItems = 4;
    const arraySkeletonItems = new Array(countSkeletonItems).fill(SkeletonRefundItem);
    const { fields } = useFieldArray<TOrderItem>(ORDER_FIELDS.basketItems);

    const vatTypes: TVatType[] = useDictionary(DICTIONARY_TYPES.vat)?.data as any;

    const [isSelectedAll, setIsSelectedAll] = useState(false);

    const onClickChooseAll = () => {
        if (isSelectedAll) {
            // @ts-ignore
            fields.removeBatch(fields.value.map((item, idx) => idx));
        } else {
            // @ts-ignore
            fields.concat(differenceBy(originalBasketItems, fields.value, 'sku'));
        }
        setIsSelectedAll(!isSelectedAll);
    };

    return (
        <PositionsWrapper>
            <CheckboxSelectAll
                checked={isSelectedAll}
                onChange={onClickChooseAll}
                label={intl.formatMessage({ defaultMessage: 'Выбрать все позиции' })}
            />
            <div>
                {originalBasketItems
                    ? originalBasketItems.map((originalOrderItem) => (
                          <RefundItem
                              vatTypes={vatTypes}
                              fields={fields}
                              isChosen={isSelectedAll}
                              key={originalOrderItem.sku}
                              originalOrderItem={originalOrderItem}
                          />
                      ))
                    : // eslint-disable-next-line react/no-array-index-key
                      arraySkeletonItems.map((SkeletonComponent, index) => <SkeletonComponent key={index} />)}
            </div>
        </PositionsWrapper>
    );
};

export default PositionsRefund;
