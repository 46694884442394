import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 4px;
`;

export const Label = styled(Typography)`
    white-space: nowrap;
`;
