import { IntlShape } from 'react-intl';
import {
    aboutInvoiceBoxLink,
    amlpolicyInvoiceBoxLink,
    apkLink,
    contactsInvoiceBoxLink,
    corprulesInvoiceBoxLink,
    newsInvoiceBoxLink,
    offerInvoiceBoxLink,
    partnersInvoiceBoxLink,
    playMarketAppLink,
    pressInvoiceBoxLink,
    privacyInvoiceBoxLink,
    ruStoreAppLink,
    supportInvoiceBoxLink,
} from '../../utils/constants';

export const appLinks = [
    {
        href: playMarketAppLink,
        variant: 'googlePlay' as const,
    },
    {
        href: apkLink,
        variant: 'apk' as const,
    },
    {
        href: '',
        variant: 'appGallery' as const,
    },
    {
        href: ruStoreAppLink,
        variant: 'ruStore' as const,
    },
].filter((i) => !!i.href);

export const getMainLinks = (intl: IntlShape) => [
    {
        href: aboutInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'О компании' }),
    },
    {
        href: newsInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Новости' }),
    },
    {
        href: partnersInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Партнёры' }),
    },
    {
        href: pressInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Для прессы' }),
    },
    {
        href: supportInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Поддержка' }),
    },
    {
        href: contactsInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Контакты' }),
    },
];

export const getSecondaryLinks = (intl: IntlShape) => [
    {
        href: offerInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Оферта' }),
    },
    {
        href: corprulesInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Правила оказания услуг' }),
    },
    {
        href: privacyInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Обработка персональных данных' }),
    },
    {
        href: amlpolicyInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Политика по борьбе с отмыванием денег' }),
    },
];

export const getTabletSecondaryLinks = (intl: IntlShape) => [
    {
        href: offerInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Оферта' }),
    },
    {
        href: supportInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Поддержка' }),
    },
    {
        href: privacyInvoiceBoxLink,
        label: intl.formatMessage({ defaultMessage: 'Политика ОПД' }),
    },
];
