import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import sizeWidget from 'src/theme/widget';
import { useMiniLaptop } from 'src/hooks/useMedia';
import { getCurrentCounterpartyMerchants } from 'src/store/Merchant/selectors';
import Products from 'src/pages/businessOffice/Dashboard/components/Products';
import MerchantWidget from 'src/components/Widgets/MerchantWidget';
import BaseWidget from 'src/components/Widgets/BaseWidget';
import OrderPaymentWidget from 'src/components/Widgets/OrderPaymentWidget';
import FeedWidget from 'src/components/Widgets/FeedWidget';
import { fetchFeedList } from 'src/store/Feed';
import { useAppDispatch } from 'src/store';
import { COUNT_ITEMS_TO_DASHBOARD_PAGE } from 'src/store/Feed/constants';
import PaymentPromo from 'src/components/PaymentPromo';
import { getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { getOpenClaimsByCounterpartyId } from 'src/store/Claims/selectors';
import Stories from 'src/pages/businessOffice/Dashboard/components/Stories';

const Dashboard: FC = () => {
    const isMiniLaptop = useMiniLaptop();
    const currentCounterpartyMerchants = useSelector(getCurrentCounterpartyMerchants);
    const counterpartyId: string = useSelector(getCurrentCounterpartyId) as any;
    const isShowPaymentPromoBanner =
        useSelector(getOpenClaimsByCounterpartyId(counterpartyId)).length === 0 && counterpartyId;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchFeedList({ _pageSize: COUNT_ITEMS_TO_DASHBOARD_PAGE }));
    }, [dispatch]);

    return (
        <PageInner>
            <Stories />
            {isMiniLaptop && <Products />}
            <WrapWidget>
                {isShowPaymentPromoBanner && <PaymentPromo />}
                {currentCounterpartyMerchants.length > 0 && (
                    <MerchantsWrapper>
                        {currentCounterpartyMerchants.map((merchant: { id: string }) => (
                            <BaseWidget key={merchant.id} size={sizeWidget.md}>
                                <MerchantWidget merchantId={merchant.id} />
                            </BaseWidget>
                        ))}
                    </MerchantsWrapper>
                )}
                <BaseWidget size={sizeWidget.xl}>
                    <OrderPaymentWidget />
                </BaseWidget>
                <FeedWidget isShowMoreButton />
            </WrapWidget>
        </PageInner>
    );
};

export default Dashboard;

export const PageInner = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-row-gap: 30px;
`;

export const WrapWidget = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-row-gap: 40px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        display: grid;
        grid-template-columns: 100%;
    }
`;

export const MerchantsWrapper = styled.div`
    display: contents;
    @media ${({ theme }) => theme.breakpoints.md} {
        display: block;
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 20px;
        width: 100%;
    }
`;
