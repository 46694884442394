import styled from 'styled-components';
import FormField from 'src/components/FormField';

export const RequisitesInputs = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 30px;
    padding: 20px 0 30px 0;

    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: repeat(4, 1fr);
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 100%;
    }
`;

export const OrderMerchantOrderIdField = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-end: 2;
    }
`;

export const OrderExpirationDateField = styled(FormField)`
    grid-column-start: 3;
    grid-column-end: 4;
    min-width: 155px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 5;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 1;
        grid-column-end: 2;
    }
`;

export const OrderDescriptionField = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 4;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 5;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 1;
        grid-column-end: 2;
    }
`;
