import React, { FC } from 'react';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useSelector } from 'react-redux';
import { find, isArray, isEmpty } from 'lodash';
import { useSWR } from 'src/hooks/useSWR';
import { getMerchantById } from 'src/store/Merchant/selectors';
import { currencyFormatter } from 'src/utils/formatters';
import { IMerchant } from 'src/store/Merchant/types';
import OrdersList from 'src/components/Widgets/MerchantWidget/components/OrdersList';
import SkeletonOrderList from 'src/components/Widgets/MerchantWidget/components/SkeletonOrderList';
import { COUNT_ORDERS_MERCHANT_DASHBOARD } from 'src/utils/constants';
import { TOrder } from 'src/store/Order/types';
import { useIntl } from 'react-intl';
import MerchantLogo from '../../MerchantLogo';

import {
    AddIcon,
    Balance,
    Container,
    Empty,
    Footer,
    Heading,
    InnerContent,
    MoreBtn,
    NewOrderBtn,
    WrapTitle,
    WrapTop,
    MerchantWidgetLink,
} from './styles';

interface IMerchantSettlementBalance {
    currencyId: string;
    id: number;
    merchantId: string;
    recalculateDate: string;
    settlementTotalAmount: number;
}

interface IBaseWidgetProps {
    merchantId: string;
}

const MerchantWidget: FC<IBaseWidgetProps> = ({ merchantId }) => {
    const intl = useIntl();
    const merchant = useSelector(getMerchantById(merchantId));

    const { data: dataOrders } = useSWR<Array<IMerchant>>(
        `filter/api/order/order?merchantId=${merchantId}&_order[createdAt]=desc`,
    );
    const { data: dataBalance } = useSWR<IMerchantSettlementBalance>('filter/api/order/merchant-settlement-balance');

    const sliceName = merchant?.name.slice(0, 30);
    const orders: TOrder[] = dataOrders?.data.slice(0, COUNT_ORDERS_MERCHANT_DASHBOARD) as any;

    const balances = dataBalance?.data;
    const balance = find<
        // @ts-ignore
        ArrayLike<IMerchantSettlementBalance> | undefined,
        IMerchantSettlementBalance
    >(balances, { merchantId })?.settlementTotalAmount;

    return (
        <Container>
            <WrapTop>
                <Heading>
                    <WrapTitle>
                        <MerchantLogo merchantId={merchantId} />
                        <MerchantWidgetLink
                            to={`${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.overview}`}
                        >
                            {sliceName}
                        </MerchantWidgetLink>
                    </WrapTitle>
                    <Balance variant="title">{currencyFormatter(balance || 0)}</Balance>
                </Heading>
                <InnerContent>
                    {orders ? <OrdersList orders={orders} /> : <SkeletonOrderList />}
                    {isEmpty(orders) && isArray(orders) && (
                        <Empty variant="regular14">{intl.formatMessage({ defaultMessage: 'Нет заказов' })}</Empty>
                    )}
                </InnerContent>
            </WrapTop>
            <Footer>
                <MoreBtn>{intl.formatMessage({ defaultMessage: 'Ещё' })}</MoreBtn>
                <NewOrderBtn to={`${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.orderToPay}`}>
                    {intl.formatMessage({ defaultMessage: 'Новый счёт' })}
                    <AddIcon />
                </NewOrderBtn>
            </Footer>
        </Container>
    );
};

export default MerchantWidget;
