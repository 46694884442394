import React, { FC } from 'react';
import MerchantListItem from 'src/pages/businessOffice/Shop/components/MerchantListItem';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import { useSelector } from 'react-redux';
import { getSettlementBalances } from 'src/store/MerchantSettlementBalance/selectors';
import { getCurrentCounterpartyMerchants } from 'src/store/Merchant/selectors';
import { IMerchant } from 'src/store/Merchant/types';
import { useIntl } from 'react-intl';
import { ButtonAddMerchant, IconPlus, MerchantList, MerchantsListWrapper } from './styles';

interface IMerchantsList {
    merchant: IMerchant;
    hideMerchantList: () => void;
    openModal: () => void;
}

const MerchantsList: FC<IMerchantsList> = ({ openModal, hideMerchantList, merchant }) => {
    const intl = useIntl();
    const balances = useSelector(getSettlementBalances);
    const currentCounterpartyMerchants = useSelector(getCurrentCounterpartyMerchants);

    const merchantsList = [
        merchant,
        ...currentCounterpartyMerchants.filter(
            (filteredMerchant) => filteredMerchant[MERCHANT_FIELDS.id] !== merchant[MERCHANT_FIELDS.id],
        ),
    ];
    return (
        <MerchantsListWrapper>
            <MerchantList>
                {merchantsList.map((merchantItem) => {
                    const merchantItemBalance = balances.find(
                        (balanceItem) => balanceItem.merchantId === merchantItem.id,
                    );
                    const merchantItemBalanceValue = merchantItemBalance?.settlementTotalAmount || 0;

                    return (
                        <MerchantListItem
                            hideMerchantList={hideMerchantList}
                            isActive={merchantItem.id === merchant[MERCHANT_FIELDS.id]}
                            key={merchantItem.id}
                            merchant={merchantItem}
                            balance={merchantItemBalanceValue}
                        />
                    );
                })}
            </MerchantList>
            <ButtonAddMerchant type="button" onClick={openModal}>
                <IconPlus />
                {intl.formatMessage({ defaultMessage: 'Добавить новый магазин' })}
            </ButtonAddMerchant>
        </MerchantsListWrapper>
    );
};

export default MerchantsList;
