import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ClaimActiveWrapper = styled.div`
    background: ${({ theme }) => theme.colors.lightGrey()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding: 16px 26px 0 30px;
`;

export const Content = styled.div`
    position: relative;
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    padding-bottom: 15px;
    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
        grid-row-gap: 10px;
        align-items: flex-start;
    }
`;

export const ButtonWidget = styled.button`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

export const HeadClaim = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 18px;
`;

export const IconTariffWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 40px;
    height: 40px;
    svg {
        transform: scale(1.7);
    }
`;

interface IIconToggleWrapper {
    isOpen: boolean;
}

export const IconToggleWrapper = styled.div<IIconToggleWrapper>`
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '0')};
`;

export const Description = styled.div`
    ${({ theme }) => theme.mixins.flexStart}
    flex-direction: column;
    grid-row-gap: 1px;
`;

export const TariffName = styled(Typography)`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    grid-column-gap: 4px;
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular14')}
    }
`;

export const TariffDescription = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
    }
`;

export const ButtonAboutTariff = styled.button`
    position: relative;
    z-index: 6;
    width: 24px;
    height: 24px;
`;

export const FooterClaim = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 18px;
    @media ${({ theme }) => theme.breakpoints.md} {
        justify-content: space-between;
        width: 100%;
    }
`;

export const Balance = styled.div``;

export const BalanceLabel = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const BalanceValue = styled(Typography)``;

export const DateEnd = styled.span`
    color: ${({ theme }) => theme.colors.orange()};
`;
