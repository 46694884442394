import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import { ReactComponent as ArrowRightIcon } from 'src/assets/icons/arrow-right.svg';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DownloadIcon } from './assets/download.svg';
import * as S from './styles';
import { TVariantDocumentReceiveMethod } from '../../../../../stories/docs/widgets/DocumentReceiveMethodWidget/constants';
import { DocumentReceiveMethodWidget } from '../../../../../stories/docs/widgets/DocumentReceiveMethodWidget';
import { RoutesDocs } from '../../../../constants';
import path from '../../../../../utils/path';

type TProps = {
    documentId: string;
};

const DocumentReceiveMethods: FC<TProps> = ({ documentId }) => {
    const intl = useIntl();
    const history = useHistory();

    const handleGetByMail = () => {
        history.push(path(RoutesDocs.mail, documentId));
    };

    const handleGetByEdo = () => {
        history.push(path(RoutesDocs.edo, documentId));
    };

    const handleGetByEmail = () => {
        history.push(path(RoutesDocs.email, documentId));
    };

    const documentReceiveMethodsList: Array<{
        onClick: () => void;
        variant: TVariantDocumentReceiveMethod;
        icon: ReactNode;
    }> = [
        {
            variant: 'mail',
            onClick: handleGetByMail,
            icon: <ArrowRightIcon />,
        },
        {
            variant: 'edo',
            onClick: handleGetByEdo,
            icon: <ArrowRightIcon />,
        },
        {
            variant: 'email',
            onClick: handleGetByEmail,
            icon: <ArrowRightIcon />,
        },
        {
            variant: 'download',
            onClick: () => {},
            icon: <DownloadIcon />,
        },
    ];

    return (
        <S.Wrapper>
            <Typography variant="headerText">
                {intl.formatMessage({ defaultMessage: 'Способ получения документа' })}
            </Typography>
            <S.DocumentReceiveMethodsListWrapper>
                {documentReceiveMethodsList.map((methodItem) => (
                    <S.ButtonDocumentReceiveMethod key={methodItem.variant} type="button" onClick={methodItem.onClick}>
                        <DocumentReceiveMethodWidget variant={methodItem.variant} endIcon={methodItem.icon} />
                    </S.ButtonDocumentReceiveMethod>
                ))}
            </S.DocumentReceiveMethodsListWrapper>
        </S.Wrapper>
    );
};

export default DocumentReceiveMethods;
