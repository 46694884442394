import React, { FC } from 'react';
import { ReactComponent as ArrowSolidIcon } from 'src/assets/icons/arrow-right2.svg';
import { currencyFormatter, dateFormatter } from 'src/utils/formatters';
import { getUnprocessableOrderStatuses } from 'src/store/Order/constants';
import { TOrder } from 'src/store/Order/types';
import { useSelector } from 'react-redux';
import { getCurrentCounterparty } from 'src/store/Counterparty/selectors';
import { IMerchant } from 'src/store/Merchant/types';
import { getUserName } from 'src/store/Auth/selectors';
import { useIntl } from 'react-intl';
import { Amount, InnerText, OrderIconWrapper, PaymentData, Status, TableRow } from './styles';

interface IOrderPaymentItem {
    order: TOrder;
    merchants?: IMerchant[];
}

const OrderPaymentItem: FC<IOrderPaymentItem> = ({ order, merchants }) => {
    const intl = useIntl();

    const counterparty = useSelector(getCurrentCounterparty);
    const userName = useSelector(getUserName);

    return (
        <TableRow>
            <OrderIconWrapper />
            <InnerText variant="regular14">{counterparty?.name || userName}</InnerText>
            <PaymentData>
                <ArrowSolidIcon />
                <InnerText variant="regular14">
                    {merchants?.find(({ id: merchId }) => order.merchantId === merchId)?.name || ''}
                </InnerText>
                <InnerText variant="regular14">
                    {intl.formatMessage({ defaultMessage: 'Счёт' })} № {order.merchantOrderId} |{' '}
                    {dateFormatter(order.createdAt)}
                </InnerText>
            </PaymentData>
            <Amount variant="regular14">{currencyFormatter(order.amount)}</Amount>
            <Status variant="regular14">{getUnprocessableOrderStatuses(intl).created}</Status>
        </TableRow>
    );
};

export default OrderPaymentItem;
