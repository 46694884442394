import styled from 'styled-components';
import Skeleton from 'src/components/Skeleton';

export const SkeletonClientsWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
`;

export const SkeletonClientGroupHead = styled(Skeleton)`
    height: 30px;
`;

export const SkeletonClientWrapper = styled.div`
    display: grid;
    grid-column-gap: 50px;
    align-items: center;
    grid-template-columns: minmax(20%, 50%) 200px 133px;
    padding: 20px 25px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    ${({ theme }) => theme.mixins.getTypography('regular14')};
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: auto 141px;
        grid-template-rows: repeat(2, 20px);
        grid-column-gap: 30px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 20px;
    }
`;

export const Name = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-start: 1;
        grid-row-end: 3;
    }
`;

export const SkeletonAvatar = styled(Skeleton).attrs({ circle: true })`
    width: 25px;
    height: 25px;
    flex-shrink: 0;
`;

export const SkeletonName = styled(Skeleton)`
    height: 17px;
    width: 100%;
`;

export const SkeletonEmail = styled(Skeleton)`
    height: 17px;
    @media ${({ theme }) => theme.breakpoints.md} {
        height: 14px;
    }
`;

export const SkeletonPhone = styled(Skeleton)`
    height: 17px;
    @media ${({ theme }) => theme.breakpoints.md} {
        height: 14px;
    }
`;
