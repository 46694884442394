import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';
import Button from '../../../../../components/Button';
import FormProvider from '../../../../../components/FormProvider';
import { defaultValues, REQUISITE_FORM_FIELD, TInnerForm } from './types';
import { requisiteSchema } from './validation';
import { RHFTextInput } from '../../../../../stories/formFields/RHFTextInput';
import { RHFVatNumberAutocomplete } from '../../../../../stories/formFields/widgets/RHFVatNumberAutocomplete';
import { useMobile } from '../../../../../hooks/useMedia';
import Subtitle from '../Subtitle';

type TProps = {
    goToNextStep: () => void;
};

const RequisiteStep: FC<TProps> = ({ goToNextStep }) => {
    const intl = useIntl();
    const isMobile = useMobile();

    const methods = useForm<TInnerForm>({
        defaultValues,
        resolver: yupResolver(requisiteSchema),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isValid },
    } = methods;

    const submit = useCallback(() => {
        // здесь будет какое-то действие с данными из формы
        goToNextStep();
    }, [goToNextStep]);

    return (
        <>
            <Subtitle steps="1/2" label={intl.formatMessage({ defaultMessage: 'Укажите реквизиты платежа и счёта' })} />
            <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
                <S.Wrapper>
                    <S.Inputs>
                        <RHFVatNumberAutocomplete name={REQUISITE_FORM_FIELD.vatNumber} />
                        <RHFTextInput
                            name={REQUISITE_FORM_FIELD.billNumber}
                            label={intl.formatMessage({ defaultMessage: 'Номер оплаченного счёта' })}
                        />
                        <RHFTextInput
                            name={REQUISITE_FORM_FIELD.paymentOperationNumber}
                            label={intl.formatMessage({ defaultMessage: 'Номер платёжного поручения' })}
                        />
                    </S.Inputs>
                    <Button fullWidth={isMobile} htmlType="submit" disabled={!isValid}>
                        {intl.formatMessage({ defaultMessage: 'Продолжить' })}
                    </Button>
                </S.Wrapper>
            </FormProvider>
        </>
    );
};

export default RequisiteStep;
