import React, { createContext, useContext, FC, useState, useCallback, useMemo } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

interface IContextProps {
    token: string;
    onRefreshReCaptcha: () => void;
}

const Context = createContext<IContextProps>({} as IContextProps);

const ReCaptchaTokenContext: FC = ({ children }) => {
    const [token, setToken] = useState('');
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const onRefreshReCaptcha = useCallback(() => {
        setRefreshReCaptcha((value) => !value);
    }, []);

    const recaptchaTokenContextValue = useMemo(
        () => ({
            token,
            onRefreshReCaptcha,
        }),
        [onRefreshReCaptcha, token],
    );

    return (
        <Context.Provider value={recaptchaTokenContextValue}>
            <GoogleReCaptcha onVerify={setToken} refreshReCaptcha={refreshReCaptcha} />
            {children}
        </Context.Provider>
    );
};

export const useReCaptchaTokenContext = () => useContext(Context);

export default ReCaptchaTokenContext;
