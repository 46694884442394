import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Skeleton from 'src/components/Skeleton';

export const ColumnFieldWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 7px;
`;

export const Label = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const Value = styled(Typography)``;

export const SkeletonValue = styled(Skeleton).attrs({ width: 100 })``;
