import React, { FC } from 'react';
import { IClientCounterparty } from 'src/store/ClientCounterparty/types';
import { ReactComponent as IconWork } from 'src/assets/icons/work.svg';
import { ReactComponent as IconDelete } from 'src/assets/icons/delete.svg';
import {
    ButtonDelete,
    IconWrapper,
    Name,
    OrganizationWrapper,
    Title,
    VatNumber,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/RowOrganization/styles';
import { CLIENT_COUNTERPARTY_FIELDS } from 'src/store/ClientCounterparty/constants';
import { useIntl } from 'react-intl';

interface IRowOrganization {
    clientCounterparty: IClientCounterparty;
    removeToOrganization: (counterpartyId: number) => void;
}

const RowOrganization: FC<IRowOrganization> = ({ removeToOrganization, clientCounterparty }) => {
    const intl = useIntl();
    const onClickDelete = () => {
        removeToOrganization(clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.id]);
    };

    return (
        <OrganizationWrapper>
            <Title>
                <IconWrapper>
                    <IconWork />
                </IconWrapper>
                <Name variant="regular14">{clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.name]}</Name>
            </Title>
            <VatNumber variant="regular14">
                {intl.formatMessage({ defaultMessage: 'ИНН' })}:{' '}
                {clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.vatNumber]}
            </VatNumber>
            <ButtonDelete type="button" onClick={onClickDelete}>
                <IconDelete />
            </ButtonDelete>
        </OrganizationWrapper>
    );
};

export default RowOrganization;
