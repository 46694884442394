import React from 'react';
import {
    Benefits,
    ButtonConnectService,
    Description,
    DescriptionWrapper,
    NavLinkToClaim,
    PaymentNavLink,
    PromoWrapper,
    Title,
    TitleWrapper,
    WrapperNavLink,
} from 'src/components/PaymentPromo/styles';
import Benefit from 'src/components/PaymentPromo/components/Benefit';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useTablet } from 'src/hooks/useMedia';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { useIntl } from 'react-intl';

const PaymentPromo = () => {
    const intl = useIntl();
    const { merchantId } = useParams<{ merchantId: string }>();
    const counterpartyId = useSelector(getCurrentCounterpartyId);
    const isTablet = useTablet();

    const pathToClaim = merchantId
        ? `${RoutesBusinessOffice.claim}/${counterpartyId}/${merchantId}`
        : `${RoutesBusinessOffice.claim}/${counterpartyId}`;

    const BenefitDescriptions = [
        {
            description: (
                <Description variant="regular14">
                    {intl.formatMessage({ defaultMessage: 'Автоматизация процесса оформления счетов' })}
                </Description>
            ),
            key: 1,
        },
        {
            description: (
                <Description variant="regular14">
                    {intl.formatMessage({ defaultMessage: 'Не требуется покупать Online-кассу' })}
                </Description>
            ),
            key: 2,
        },
        {
            description: (
                <Description variant="regular14">
                    {intl.formatMessage({ defaultMessage: 'Приём платежей через Visa/MasterCard/МИР' })}
                </Description>
            ),
            key: 3,
        },
        {
            description: (
                <Description variant="regular14">
                    {intl.formatMessage({ defaultMessage: 'И ещё очень' })} <br />
                    {isTablet ? (
                        intl.formatMessage({ defaultMessage: 'много интересного' })
                    ) : (
                        <PaymentNavLink to={pathToClaim}>
                            {' '}
                            {intl.formatMessage({ defaultMessage: 'много интересного' })}{' '}
                        </PaymentNavLink>
                    )}
                </Description>
            ),
            key: 4,
        },
    ];

    const renderContent = () => (
        <>
            <TitleWrapper>
                <Title variant="medium34">{intl.formatMessage({ defaultMessage: 'Приём платежей' })}</Title>
                {!isTablet && (
                    <NavLinkToClaim to={pathToClaim}>
                        <ButtonConnectService>
                            {intl.formatMessage({ defaultMessage: 'Подключить услугу' })}
                        </ButtonConnectService>
                    </NavLinkToClaim>
                )}
            </TitleWrapper>
            <DescriptionWrapper>
                <Benefits>
                    {BenefitDescriptions.map((benefit) => (
                        <Benefit key={benefit.key} description={benefit.description} />
                    ))}
                </Benefits>
            </DescriptionWrapper>
        </>
    );

    if (isTablet) {
        return <WrapperNavLink to={pathToClaim}>{renderContent()}</WrapperNavLink>;
    }

    return <PromoWrapper>{renderContent()}</PromoWrapper>;
};

export default PaymentPromo;
