import React, { ChangeEvent, FC, InputHTMLAttributes, useEffect, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg';
import styled, { css } from 'styled-components';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import { Scrollbar, useOutsideClick } from '@invoicebox/ui';
import Input from '../Input';

export interface ISelectProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    disabled?: boolean;
    hasError?: boolean;
    options?: Array<ISelectOption>;
}

export interface ISelectOption {
    label: string;
    value: string | number;
}

interface ISelectIconProps {
    isOpen: boolean;
}

interface ISelectListItemButtonProps {
    isActive: boolean;
}

const Select: FC<ISelectProps> = ({ label, options, hasError, className, disabled, onChange, ...inputProps }) => {
    const [selectOptionsIsVisible, setSelectOptionsIsVisible] = useState(false);

    const foundOptionLabel = options?.find((option) => option?.value === inputProps?.value)?.label;

    const [selectValue, setSelectValue] = useState<ISelectOption>({
        value: inputProps.value?.toString() || '',
        label: foundOptionLabel || inputProps.value?.toString() || '',
    });

    useEffect(() => {
        setSelectValue({
            value: inputProps.value?.toString() || '',
            label: foundOptionLabel || inputProps.value?.toString() || '',
        });
    }, [foundOptionLabel, inputProps.value]);

    const show = () => setSelectOptionsIsVisible(true);
    const hide = () => setSelectOptionsIsVisible(false);

    const fieldClickHandler = () => (selectOptionsIsVisible ? hide() : show());

    const selectWrapperRef = useOutsideClick(hide);

    const selectItemClickHandler = (option: ISelectOption) => {
        setSelectValue(option);

        if (onChange) {
            onChange({ target: { value: option.value } } as ChangeEvent<HTMLInputElement>);
        }

        hide();
    };

    return (
        <SelectWrapper className={className} ref={selectWrapperRef}>
            <InputFieldWrapper>
                <InputField
                    {...inputProps}
                    label={label}
                    readOnly
                    hasError={hasError}
                    disabled={disabled}
                    onClick={fieldClickHandler}
                    value={selectValue.value}
                    icon={
                        <SelectIcon isOpen={selectOptionsIsVisible}>
                            <ArrowDownIcon />
                        </SelectIcon>
                    }
                />
                {selectValue.label && <VirtualValue>{selectValue.label}</VirtualValue>}
            </InputFieldWrapper>
            {selectOptionsIsVisible && (
                <SelectList>
                    <ScrollbarsWrapper>
                        <Scrollbar maxHeight={120}>
                            <SelectListInner>
                                {options &&
                                    options.map((option, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <SelectListItem key={index}>
                                            <SelectListItemButton
                                                isActive={option.value === selectValue.value}
                                                onClick={() => selectItemClickHandler(option)}
                                            >
                                                {option.label}
                                            </SelectListItemButton>
                                        </SelectListItem>
                                    ))}
                            </SelectListInner>
                        </Scrollbar>
                    </ScrollbarsWrapper>
                </SelectList>
            )}
        </SelectWrapper>
    );
};

export default React.memo(Select);

const SelectWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const SelectIcon = styled.div<ISelectIconProps>`
    display: flex;
    transform: rotate(0);
    transition: ${({ theme }) => theme.decorations.transition.base};
    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: rotate(180deg);
        `};
`;

const InputFieldWrapper = styled.div`
    position: relative;

    input {
        color: ${({ theme }) => theme.colors.white()};
    }
`;

const VirtualValue = styled.div`
    color: ${({ theme }) => theme.colors.base()};
    font-size: 14px;
    line-height: 18px;
    padding: 13px 56px 13px 18px;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const InputField = styled(Input)`
    input {
        cursor: pointer;
    }
`;

const SelectList = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.white()};
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-bottom-left-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border-bottom-right-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    overflow: hidden;
`;

const ScrollbarsWrapper = styled.div``;

const SelectListInner = styled.ul`
    overflow: hidden;
`;

const SelectListItem = styled.li`
    display: flex;
`;

const SelectListItemButton = styled.button.attrs({ type: 'button' })<ISelectListItemButtonProps>`
    ${({ theme }) => theme.mixins.getTypography('regular12')};
    padding: 13px 19px;
    width: 100%;
    text-align: left;
    transition: ${({ theme }) => theme.decorations.transition.base};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.base() : theme.colors.grey())};

    &:hover {
        background-color: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
    }
`;
