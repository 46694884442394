import React, { FC } from 'react';
import {
    ButtonRowWrapper,
    IconWrapper,
    NotificationContent,
    NotificationDate,
    NotificationTitle,
} from 'src/components/Header/components/NotificationRow/styles';
import { ReactComponent as IconChat } from 'src/assets/icons/chat.svg';
import { useHistory } from 'react-router-dom';
import useModal from 'src/hooks/useModal';
import ModalFeed from 'src/components/Widgets/FeedWidget/components/ModalFeed';
import { typesFeedOpenOrderContainer } from 'src/store/Feed/constants';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { IFeedItem } from 'src/store/Feed/types';
import dayjs from 'dayjs';
import { HH_MM_FORMAT } from 'src/utils/constants';

interface INotificationRow {
    feed: IFeedItem;
    hideWindowNotification: () => void;
}

const NotificationRow: FC<INotificationRow> = ({ hideWindowNotification, feed }) => {
    const history = useHistory();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);

    const onClickNotificationRow = () => {
        if (typesFeedOpenOrderContainer.find((type) => type === feed.type) && feed?.metadata?.targetId) {
            history.push(`${RoutesBusinessOffice.ordercontainer}/${feed.metadata.targetId}`, {
                isOrderContainer: true,
            });
        } else {
            openModal();
        }
        hideWindowNotification();
    };

    return (
        <ButtonRowWrapper type="button" onClick={onClickNotificationRow}>
            <IconWrapper>
                <IconChat />
            </IconWrapper>
            <NotificationContent>
                <NotificationTitle variant="regular14">{feed.title}</NotificationTitle>
                <NotificationDate variant="regular12">{dayjs(feed.createdAt).format(HH_MM_FORMAT)}</NotificationDate>
            </NotificationContent>
            <ModalFeed onClose={onCloseModal} isOpen={isOpenModal} feed={feed} />
        </ButtonRowWrapper>
    );
};

export default NotificationRow;
