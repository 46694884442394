import styled from 'styled-components';
import Typography from 'src/components/Typography';
import { ReactComponent as AddsIcon } from 'src/assets/icons/plus.svg';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
    position: relative;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding: 22px 30px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 28px 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const WrapTitle = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
`;

export const Heading = styled.div`
    width: 100%;
    position: relative;
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    padding-bottom: 24px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 27px 20px 24px 20px;
    }
`;

export const Title = styled(Typography)`
    line-height: ${({ theme }) => theme.typography.bigTitle.fontSize}px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.subheading.fontSize}px;
    }
`;

export const NewOrderBtn = styled(NavLink)`
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    ${({ theme }) => theme.mixins.flexCenter}
    color: ${({ theme }) => theme.colors.base()};
    text-decoration: none;
`;

export const AddIcon = styled(AddsIcon)`
    margin-right: 10px;
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;
