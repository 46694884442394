import React, { FC } from 'react';
import { find } from 'lodash';
import { Form } from 'react-final-form';
import { ORDER_ITEM_FIELDS, TOrderItem, TVatType } from 'src/store/Order/types';
import { convertCurrencyToPointNumber, isEqualObjects, calculateOrderItemValues } from 'src/utils/helpers';
import useValidationSchema from 'src/hooks/useValidationSchema';
import { modalAddOrderItemSchema } from 'src/pages/businessOffice/Shop/validations';
import InputsModalAddOrderItem from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/InputsModalAddOrderItem';
import { PIECE_MEASURE_VALUE } from 'src/utils/constants';
import { IMeasure } from 'src/store/Merchant/types';
import { useIntl } from 'react-intl';
import { ButtonModalAddItem, FormAddItemOrder, HeaderModalAddItemOrder, ModalAddItemOrderWrapper } from './styles';

interface IModalAddItemOrder {
    closeModal: () => void;
    isOpen: boolean;
    addOrEditItemOrder: (itemOrder: TOrderItem) => void;
    vatTypes: TVatType[] | undefined;
    measures: IMeasure[] | undefined;
    itemOrder?: TOrderItem;
}

const ModalAddItemOrder: FC<IModalAddItemOrder> = ({
    measures,
    itemOrder,
    vatTypes,
    addOrEditItemOrder,
    closeModal,
    isOpen,
}) => {
    const intl = useIntl();
    const validation = useValidationSchema(modalAddOrderItemSchema);
    return (
        <ModalAddItemOrderWrapper
            HeaderComponent={HeaderModalAddItemOrder}
            closeModal={closeModal}
            isOpen={isOpen}
            title={intl.formatMessage({ defaultMessage: 'Добавить позицию' })}
        >
            <Form
                mutators={{
                    setServiceFields: (arg, state, utils) => {
                        utils.changeValue(state, ORDER_ITEM_FIELDS.quantity, () => '1');
                        utils.changeValue(state, ORDER_ITEM_FIELDS.measureCode, () => PIECE_MEASURE_VALUE);
                    },
                }}
                initialValues={itemOrder}
                onSubmit={(valuesForm) => {
                    if (itemOrder && isEqualObjects(itemOrder, valuesForm)) {
                        closeModal();
                    } else {
                        const { quantity, amount, vatCode } = valuesForm;
                        const convertedAmount =
                            typeof amount === 'number' ? amount : convertCurrencyToPointNumber(amount);
                        const currentVat = find(vatTypes, { code: vatCode }); // выбранная ставка НДС в виде значения из API
                        const vatRate = currentVat?.rate || 0; // значение ставки НДС (0.2 - 20%, 0.15 -15%)
                        const { totalAmount, totalVatAmount, amountWoVat } = calculateOrderItemValues(
                            convertedAmount,
                            quantity,
                            vatRate,
                        );
                        const dataItem = {
                            ...valuesForm,
                            ...(quantity &&
                                convertedAmount && {
                                    [ORDER_ITEM_FIELDS.totalVatAmount]: totalVatAmount,
                                    [ORDER_ITEM_FIELDS.totalAmount]: totalAmount,
                                    [ORDER_ITEM_FIELDS.amountWoVat]: amountWoVat,
                                }),
                            [ORDER_ITEM_FIELDS.quantity]: Number(quantity),
                            [ORDER_ITEM_FIELDS.amount]: convertedAmount,
                        };
                        addOrEditItemOrder(dataItem);
                        closeModal();
                    }
                }}
                validate={validation}
                subscription={{ submitting: true, pristine: true }}
            >
                {({ handleSubmit, form }) => (
                    <FormAddItemOrder id="addItemOrder">
                        <InputsModalAddOrderItem measures={measures} form={form} vatTypes={vatTypes} />
                        <ButtonModalAddItem onClick={handleSubmit} htmlType="button">
                            {intl.formatMessage({ defaultMessage: 'Добавить' })}
                        </ButtonModalAddItem>
                    </FormAddItemOrder>
                )}
            </Form>
        </ModalAddItemOrderWrapper>
    );
};

export default ModalAddItemOrder;
