import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const Wrapper = styled.div`
    width: 442px;
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.38);
    }
    scrollbar-color: rgba(0, 0, 0, 0.38);
    scrollbar-width: thin;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
    position: absolute;
    z-index: 10;
    top: 42px;
    right: 180%;
    transform: translateX(50%);
    background: ${({ theme }) => theme.colors.white()};
    padding: 32px 44px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 402px;
        padding: 32px 30px 25px 30px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        right: 150px;
        width: 320px;
    }
`;

export const Header = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    margin-bottom: 22px;
`;

export const Title = styled(Typography)``;

export const AllNavLink = styled.button`
    ${({ theme }) => theme.mixins.getTypography('regular14')};
    color: ${({ theme }) => theme.colors.grey()};
    text-decoration: none;
`;

export const NotificationsList = styled.div``;
