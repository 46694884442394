import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import NavDropdown, { counterpartyToNavDropdownItem } from 'src/components/NavDropdown';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/profile.svg';
import { useMobile } from 'src/hooks/useMedia';
import { ICounterparty } from 'src/store/Counterparty/types';
import { getUserName } from 'src/store/Auth/selectors';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { Nullable } from 'src/utils/utilityTypes';
import { useIntl } from 'react-intl';
import DrawerLanguageDropdown from './DrawerLanguageDropdown';
import Navigation from './Navigation';
import {
    CloseButton,
    DrawerFooter,
    DrawerHeaderInner,
    DrawerNavigationWrapper,
    DrawerWrapper,
    HeaderWrapper,
    LogoutButton,
    LogoLink,
    NavDropdownSkeleton,
    Overlay,
    ProfileButton,
    ProfileName,
} from '../styles';
import { ReactComponent as LogoIcon } from '../assets/logo.svg';
import { ReactComponent as CloseIcon } from '../assets/close-burger.svg';
import { ReactComponent as LogoutIcon } from '../assets/logout.svg';

interface IDrawerProps {
    isOpen: boolean;
    close: () => void;
    logout: () => void;
    counterparties: Nullable<Array<ICounterparty>>;
    setCounterparty: (id: Nullable<string>) => void;
    selectedCounterparty: Nullable<string>;
    counterpartiesIsPending: boolean;
}

const Drawer: FC<IDrawerProps> = ({
    isOpen,
    close,
    counterparties,
    setCounterparty,
    selectedCounterparty,
    logout,
    counterpartiesIsPending,
}) => {
    const intl = useIntl();
    const isMobile = useMobile();
    const userName = useSelector(getUserName);

    const navDropdownItems = [
        ...(counterparties?.map(counterpartyToNavDropdownItem) || []),
        {
            title: userName || intl.formatMessage({ defaultMessage: 'Приватный счет' }),
            id: null,
            icon: <ProfileIcon />,
        },
    ];

    const NavDropdownWithSkeleton = counterpartiesIsPending ? NavDropdownSkeleton : NavDropdown;

    return (
        <>
            {isOpen && <Overlay onClick={close} />}
            <DrawerWrapper isOpen={isOpen}>
                <HeaderWrapper>
                    <DrawerHeaderInner>
                        <LogoLink to={RoutesBusinessOffice.dashboard}>
                            <LogoIcon />
                        </LogoLink>
                        <CloseButton onClick={close}>
                            <CloseIcon />
                        </CloseButton>
                    </DrawerHeaderInner>
                </HeaderWrapper>

                <NavDropdownWithSkeleton
                    items={navDropdownItems}
                    activeItemId={selectedCounterparty}
                    onChange={setCounterparty}
                />
                <DrawerNavigationWrapper>
                    <Navigation onItemClick={close} />
                </DrawerNavigationWrapper>

                {isMobile && (
                    <DrawerFooter>
                        <DrawerLanguageDropdown />
                        <ProfileButton to={RoutesBusinessOffice.marketplace} onClick={close}>
                            <ProfileIcon />
                            <ProfileName>{userName}</ProfileName>
                        </ProfileButton>
                        <LogoutButton onClick={logout}>
                            <LogoutIcon />
                        </LogoutButton>
                    </DrawerFooter>
                )}
            </DrawerWrapper>
        </>
    );
};

export default Drawer;
