import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import * as S from './styles';

export type TProps = {
    colSpan: number;
};

export const NotFoundTableRow: FC<TProps> = ({ colSpan }) => {
    const intl = useIntl();

    return (
        <S.Wrapper>
            <S.Cell colSpan={colSpan}>
                <Typography variant="headline6">
                    {intl.formatMessage({ id: 'Не найдено', defaultMessage: 'Не найдено' })}
                </Typography>
            </S.Cell>
        </S.Wrapper>
    );
};
