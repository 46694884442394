import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import Typography from 'src/components/Typography';
import Skeleton from 'src/components/Skeleton';

export const SlidePreviewWrapper = styled.button`
    max-width: 198px;
    height: 140px;
    border: 2px solid ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.LIGHT)};
    padding: 2px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.min}px;
    @media ${({ theme }) => theme.breakpoints.md} {
        height: 107px;
        max-width: 153px;
        margin: 0 auto;
        display: block;
        max-width: 180px;
    }
`;

export const SlidePreviewContent = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.min}px;
    max-width: 190px;
    height: 132px;
    padding: 13px 16px;
    position: relative;
    @media ${({ theme }) => theme.breakpoints.md} {
        height: 100%;
        max-width: 180px;
        padding: 9px 12px;
    }
`;

export const PreviewTitle = styled(Typography)`
    color: ${({ theme }) => theme.colors.white()};
`;

export const BackgroundImage = styled.img`
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.min}px;
    width: 100%;
    height: 132px;
    @media ${({ theme }) => theme.breakpoints.md} {
        height: 100%;
    }
`;

export const SkeletonSlide = styled(Skeleton)`
    width: 198px;
    height: 140px;
    border-radius: 4px;
    position: absolute;
    left: -4px;
    top: -4px;
`;
