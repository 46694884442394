import React from 'react';
import { FormFieldTypeEnum } from 'src/components/FormField';
import { ORDER_FIELDS } from 'src/store/Order/types';
import { ReactComponent as CalendarIcon } from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/assets/calendar.svg';
import { InputTypeEnum } from 'src/components/Input';
import { addMinutesToDate } from 'src/utils/helpers';
import { useIntl } from 'react-intl';
import { OrderDescriptionField, OrderExpirationDateField, OrderMerchantOrderIdField, RequisitesInputs } from './styles';

const Requisites = () => {
    const intl = useIntl();

    return (
        <RequisitesInputs>
            <OrderMerchantOrderIdField
                typeInput={InputTypeEnum.numeric}
                name={ORDER_FIELDS.merchantOrderId}
                label={intl.formatMessage({ defaultMessage: 'Номер счёта' })}
            />
            <OrderExpirationDateField
                fieldType={FormFieldTypeEnum.inputDateTime}
                name={ORDER_FIELDS.expirationDate}
                label={intl.formatMessage({ defaultMessage: 'Срок оплаты' })}
                icon={<CalendarIcon />}
                minDate={new Date()}
                minTimeInSelectDay={addMinutesToDate(new Date(), 16)}
                showTimeInput
                dateFormat="d.MM.yyyy, H:mm"
                isShowIconIfHaveValue={false}
                placeholder={intl.formatMessage({ defaultMessage: 'дд.мм.гггг, чч:мм' })}
            />
            <OrderDescriptionField
                fieldType={FormFieldTypeEnum.input}
                name={ORDER_FIELDS.description}
                label={intl.formatMessage({ defaultMessage: 'Комментарий' })}
            />
        </RequisitesInputs>
    );
};

export default Requisites;
