import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from '../../../../../theme/utils';

export const Wrapper = styled.div`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
    ${({ theme }) => theme.mixins.flexStart};
    justify-content: space-between;
    gap: 36px;
    margin-bottom: 40px;
`;
