import React, { FC, useEffect } from 'react';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { useAppDispatch } from 'src/store';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useMiniLaptop } from 'src/hooks/useMedia';
import { currencyFormatter } from 'src/utils/formatters';
import { fetchMerchantsList } from 'src/store/Merchant';
import { getCurrentCounterpartyMerchants, getCurrentMerchantId } from 'src/store/Merchant/selectors';
import { fetchCounterpartiesList, fetchCounterpartyBankDetails, setCounterparty } from 'src/store/Counterparty';
import {
    getAllCounterparties,
    getCounterpartiesIsPending,
    getCurrentCounterpartyId,
} from 'src/store/Counterparty/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import { getSettlementBalances } from 'src/store/MerchantSettlementBalance/selectors';
import { fetchMerchantSettlementBalances } from 'src/store/MerchantSettlementBalance';
import Header from 'src/components/Header';
import NavDropdown, { counterpartyToNavDropdownItem } from 'src/components/NavDropdown';
import ProductsAccordion from 'src/components/ProductsAccordion';
import { Nullable } from 'src/utils/utilityTypes';
import { getUserName } from 'src/store/Auth/selectors';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/profile.svg';
import { ReactComponent as InvoicingIcon } from 'src/assets/icons/invoicing.svg';
import { ReactComponent as BillsIcon } from 'src/assets/icons/bills.svg';
import { ICollapseBodyItem } from 'src/components/ProductsAccordion/components/CollapseItem';
import { fetchMerchantContractsList, fetchMerchantContractTemplates } from 'src/store/Contracts';
import { fetchClaims } from 'src/store/Claims';
import { useIntl } from 'react-intl';
import {
    LayoutWrapper,
    MerchantItemLabel,
    MerchantItemTitle,
    MerchantLink,
    NavbarTitle,
    NavDropdownSkeleton,
    PageContainer,
    PageInner,
    ProductsAccordionSkeleton,
    Sidebar,
} from './styles';

const renderInvoicingCollapseItem = ({ id, title, label, activeId }: ICollapseBodyItem) => (
    <MerchantLink
        // @ts-ignore fix error link to set param isActive
        isactivelink={activeId === id ? 1 : undefined}
        to={`${RoutesBusinessOffice.shop}/${id}${RoutesBusinessOffice.overview}`}
    >
        <MerchantItemTitle variant="regular14">{title}</MerchantItemTitle>
        {label && <MerchantItemLabel variant="regular14">{label}</MerchantItemLabel>}
    </MerchantLink>
);

const MainLayout: FC = ({ children }) => {
    const intl = useIntl();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const isMiniLaptop = useMiniLaptop();
    const counterparties = useSelector(getAllCounterparties);
    const counterpartiesIsPending = useSelector(getCounterpartiesIsPending);
    const selectedCounterpartyId = useSelector(getCurrentCounterpartyId) || null;
    const selectedMerchantId = useSelector(getCurrentMerchantId) || undefined;
    const currentCounterpartyMerchants = useSelector(getCurrentCounterpartyMerchants);
    const balances = useSelector(getSettlementBalances);
    const userName = useSelector(getUserName);
    const history = useHistory();

    const setSelectCounterparty = (id: Nullable<string>) => {
        dispatch(setCounterparty(id));
        history.push(RoutesBusinessOffice.dashboard);
    };

    const navDropdownItems = [
        ...(counterparties?.map(counterpartyToNavDropdownItem) || []),
        {
            title: userName || intl.formatMessage({ defaultMessage: 'Приватный счет' }),
            id: null,
            icon: <ProfileIcon />,
        },
    ];

    const invoicingCollapseItems =
        currentCounterpartyMerchants?.map(({ name, id }) => ({
            id,
            title: name,
            label: `${currencyFormatter(find(balances, { merchantId: id })?.settlementTotalAmount || 0)}`,
        })) || [];

    const NavDropdownWithSkeleton = counterpartiesIsPending ? NavDropdownSkeleton : NavDropdown;
    const ProductsAccordionWithSkeleton = counterpartiesIsPending ? ProductsAccordionSkeleton : ProductsAccordion;

    useEffect(() => {
        dispatch(fetchCounterpartiesList());
        dispatch(fetchMerchantsList({}));
        dispatch(fetchMerchantSettlementBalances());
        dispatch(fetchMerchantContractTemplates());
        dispatch(fetchCounterpartyBankDetails());
        dispatch(fetchClaims());
        dispatch(fetchMerchantContractsList());
    }, [dispatch]);

    return (
        <LayoutWrapper>
            <Helmet>
                <title>{intl.formatMessage({ defaultMessage: 'Инвойсбокс | Панель управления' })}</title>
            </Helmet>
            <Header
                counterparties={counterparties}
                setCounterparty={setSelectCounterparty}
                selectedCounterparty={selectedCounterpartyId}
                counterpartiesIsPending={counterpartiesIsPending}
            />
            <PageContainer>
                {!isMiniLaptop && (
                    <Sidebar>
                        <NavDropdownWithSkeleton
                            items={navDropdownItems}
                            activeItemId={selectedCounterpartyId}
                            onChange={setSelectCounterparty}
                        />
                        <NavbarTitle variant="headline">
                            {intl.formatMessage({ defaultMessage: 'Продукты' })}
                        </NavbarTitle>
                        <ProductsAccordionWithSkeleton>
                            <ProductsAccordion.CollapseItem
                                isActive={location.pathname.includes(RoutesBusinessOffice.shop)}
                                activeId={selectedMerchantId}
                                title={intl.formatMessage({
                                    defaultMessage: 'Продажи',
                                })}
                                icon={<InvoicingIcon />}
                                items={invoicingCollapseItems}
                                renderItem={renderInvoicingCollapseItem}
                                errorTooltipContent={
                                    !selectedCounterpartyId && (
                                        <div>
                                            {intl.formatMessage({
                                                defaultMessage: 'Для открытия меню выберите организацию',
                                            })}
                                        </div>
                                    )
                                }
                            />
                            <ProductsAccordion.Item
                                to={`${RoutesBusinessOffice.payment}/${selectedCounterpartyId || 'user'}`}
                                title={intl.formatMessage({ defaultMessage: 'Входящие счета' })}
                                icon={<BillsIcon />}
                            />
                        </ProductsAccordionWithSkeleton>
                    </Sidebar>
                )}
                <PageInner>{children}</PageInner>
            </PageContainer>
        </LayoutWrapper>
    );
};

export default MainLayout;
