import { RootState } from 'src/store';
import { ordersAdapter } from './index';

const getOrders = (state: RootState) => state.orders;

const { selectAll, selectById } = ordersAdapter.getSelectors(getOrders);

export const getIsOrdersLoading = (state: RootState) => getOrders(state).isLoading;
export const getOrdersMetaData = (state: RootState) => getOrders(state).metaData;
export const getOrderById = (id: string) => (state: RootState) => selectById(state, id);

export const getOrdersList = (state: RootState) => selectAll(state);
