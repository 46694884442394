import React, { FC } from 'react';
import { IClient } from 'src/store/Client/types';
import { CLIENT_FIELDS } from 'src/store/Client/contants';
import { ClientFieldsWrapper } from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/ContactInformation/styles';
import ColumnField from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/ColumnField';
import { convertPhoneToShow } from 'src/utils/formatters';
import FormEditClient from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/FormEditClient';
import { FORM_CREATE_CLIENT } from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/DetailClient';
import { useIntl } from 'react-intl';

interface IContactInformation {
    client?: IClient;
    isEditMode?: boolean;
    formId?: string;
    setIsEditSettings?: (arg: boolean) => void;
    toggleSaving?: () => void;
}

const ContactInformation: FC<IContactInformation> = ({
    setIsEditSettings = () => null,
    client,
    formId = FORM_CREATE_CLIENT,
    isEditMode,
    toggleSaving = () => null,
}) => {
    const intl = useIntl();
    const clientFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'ФИО' }),
            value: client && client[CLIENT_FIELDS.name],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Телефон' }),
            value: client && convertPhoneToShow(client[CLIENT_FIELDS.phone]),
        },
        {
            label: 'Email',
            value: client && client[CLIENT_FIELDS.email],
        },
    ];

    if (isEditMode) {
        return (
            <FormEditClient
                toggleSaving={toggleSaving}
                client={client}
                formId={formId}
                setIsEditSettings={setIsEditSettings}
            />
        );
    }

    return (
        <ClientFieldsWrapper>
            {clientFields.map((clientField) => (
                <ColumnField key={clientField.label} label={clientField.label} value={clientField.value} />
            ))}
        </ClientFieldsWrapper>
    );
};

export default ContactInformation;
