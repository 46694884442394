import React, { FC } from 'react';
import { IOrderContainer, TOrder, TOrderCustomer } from 'src/store/Order/types';
import {
    DetailOrderWrapper,
    OrderForm,
    OrderHeaderTitle,
    OrderTitle,
    OrderHeader,
    OrderBorder,
    OrderInformation,
} from 'src/pages/businessOffice/OrderContainer/components/DetailOrder/styles';
import OrderGeneral from 'src/pages/businessOffice/OrderContainer/components/OrderGeneral';
import OrderItems from 'src/pages/businessOffice/OrderContainer/components/OrderItems';
import Requisites from 'src/pages/businessOffice/OrderContainer/components/Requisites';
import Seller from 'src/pages/businessOffice/OrderContainer/components/Seller';
import TotalPayment from 'src/pages/businessOffice/OrderContainer/components/TotalPayment';
import { IMerchant } from 'src/store/Merchant/types';
import Breadcrumb from 'src/components/Breadcrumb';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { EntityId } from '@reduxjs/toolkit';
import Skeleton from 'src/components/Skeleton';
import { useIntl } from 'react-intl';

interface IDetailPayment {
    orderContainer: IOrderContainer;
    order: TOrder;
    merchant: IMerchant;
    payerId: EntityId | null;
    customer: TOrderCustomer;
}

const DetailPayment: FC<IDetailPayment> = ({ customer, payerId, orderContainer, order, merchant }) => {
    const isEditForm = false;
    const intl = useIntl();

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item to={RoutesBusinessOffice.dashboard}>
                    {intl.formatMessage({ defaultMessage: 'Обзор' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    to={payerId ? `${RoutesBusinessOffice.payment}/${payerId}` : `${RoutesBusinessOffice.payment}/user`}
                >
                    {intl.formatMessage({ defaultMessage: 'Входящие счета' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{intl.formatMessage({ defaultMessage: 'Счёт детально' })}</Breadcrumb.Item>
            </Breadcrumb>
            <DetailOrderWrapper>
                <OrderTitle variant="bigTitle">
                    {intl.formatMessage({ defaultMessage: 'Счёт' })} {order?.merchantOrderId || <Skeleton width={60} />}
                </OrderTitle>
                <OrderForm>
                    <OrderHeader>
                        <OrderHeaderTitle variant="title">
                            {intl.formatMessage({ defaultMessage: 'Счёт' })}
                        </OrderHeaderTitle>
                    </OrderHeader>
                    <OrderBorder isEdit={isEditForm}>
                        <OrderInformation>
                            <OrderGeneral order={order} />
                            <Requisites customer={customer} />
                            <Seller merchant={merchant} />
                            <OrderItems basketItems={order?.basketItems} />
                        </OrderInformation>
                        <TotalPayment orderContainer={orderContainer} order={order} />
                    </OrderBorder>
                </OrderForm>
            </DetailOrderWrapper>
        </>
    );
};

export default DetailPayment;
