import React, { FC, useState } from 'react';
import { useMiniLaptop, useMobile } from 'src/hooks/useMedia';
import { ICounterparty } from 'src/store/Counterparty/types';
import { logout } from 'src/store/Auth';
import { useAppDispatch } from 'src/store';
import { Nullable } from 'src/utils/utilityTypes';
import NotificationMenuItem from 'src/components/Header/components/NotificationMenuItem/index';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { ReactComponent as ProfileIcon } from './assets/profile.svg';
import Drawer from './components/Drawer';
import { ReactComponent as LogoIcon } from './assets/logo.svg';
import { ReactComponent as BurgerIcon } from './assets/burger-icon.svg';
import { BurgerButton, HeaderControls, HeaderInner, HeaderLeft, HeaderMenu, HeaderWrapper, LogoLink } from './styles';
import { ProfileMenuItem } from './components/ProfileMenuItem';

interface IHeaderProps {
    counterparties: Nullable<Array<ICounterparty>>;
    setCounterparty: (id: Nullable<string>) => void;
    selectedCounterparty: Nullable<string>;
    counterpartiesIsPending: boolean;
}

const Header: FC<IHeaderProps> = ({
    counterparties,
    setCounterparty,
    selectedCounterparty,
    counterpartiesIsPending,
}) => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const isMiniLaptop = useMiniLaptop();
    const isMobile = useMobile();
    const dispatch = useAppDispatch();

    const logoutHandler = async () => {
        dispatch(logout());
    };

    const closeDrawer = () => setDrawerIsOpen(false);
    const toggleDrawer = () => setDrawerIsOpen((v) => !v);

    return (
        <HeaderWrapper>
            <HeaderInner>
                <HeaderLeft>
                    <BurgerButton onClick={toggleDrawer}>
                        <BurgerIcon />
                    </BurgerButton>
                    <LogoLink to={RoutesBusinessOffice.dashboard}>
                        <LogoIcon />
                    </LogoLink>
                </HeaderLeft>
                <HeaderControls>
                    {/* todo временно убраны пункты меню из шапки */}
                    {/* {!isMiniLaptop && <Navigation />} */}
                    {isMiniLaptop && (
                        <Drawer
                            isOpen={drawerIsOpen}
                            close={closeDrawer}
                            logout={logoutHandler}
                            counterparties={counterparties}
                            setCounterparty={setCounterparty}
                            selectedCounterparty={selectedCounterparty}
                            counterpartiesIsPending={counterpartiesIsPending}
                        />
                    )}
                    <HeaderMenu>
                        <NotificationMenuItem />
                        {/* todo Временно убран переключатель языков */}
                        {/* {!isMobile && <LanguageMenuItem />} */}
                        {!isMobile && (
                            <ProfileMenuItem
                                isBorderLeft
                                logout={logoutHandler}
                                profileLink={RoutesBusinessOffice.personal}
                            >
                                <ProfileIcon />
                            </ProfileMenuItem>
                        )}
                    </HeaderMenu>
                </HeaderControls>
            </HeaderInner>
        </HeaderWrapper>
    );
};

export default Header;
