import React, { FC, useCallback, useState } from 'react';
import Checkbox from 'src/components/Checkbox';
import { TWeeksDayRU, WeeksDay } from 'src/store/Merchant/types';
import { useMobile } from 'src/hooks/useMedia';
import { useIntl } from 'react-intl';
import { getWeekDay } from '../DateHoursWork';
import { CheckboxLabel, CheckboxLabelText, CheckboxAndDay, InputHours } from './styles';

interface IRowCheckboxHours {
    day: string;
    isWorkDay: boolean;
    nameFieldStart: string;
    nameFieldEnd: string;
    form: any;
}

const RowCheckboxHours: FC<IRowCheckboxHours> = ({ form, isWorkDay, day, nameFieldStart, nameFieldEnd }) => {
    const intl = useIntl();

    const weeksDayFullRU: TWeeksDayRU = {
        [WeeksDay.mon]: intl.formatMessage({ defaultMessage: 'Понедельник' }),
        [WeeksDay.tue]: intl.formatMessage({ defaultMessage: 'Вторник' }),
        [WeeksDay.wed]: intl.formatMessage({ defaultMessage: 'Среда' }),
        [WeeksDay.thu]: intl.formatMessage({ defaultMessage: 'Четверг' }),
        [WeeksDay.fri]: intl.formatMessage({ defaultMessage: 'Пятница' }),
        [WeeksDay.sat]: intl.formatMessage({ defaultMessage: 'Суббота' }),
        [WeeksDay.sun]: intl.formatMessage({ defaultMessage: 'Воскресенье' }),
    };

    const [isChecked, setIsChecked] = useState(isWorkDay);
    const isMobile = useMobile();

    const onClickCheckbox = useCallback(() => {
        if (isChecked) {
            form.mutators.clearField(day);
        }
        setIsChecked(!isChecked);
    }, [day, form.mutators, isChecked]);

    return (
        <CheckboxLabel>
            <CheckboxAndDay>
                <Checkbox checked={isChecked} onChange={onClickCheckbox} />
                <CheckboxLabelText variant="regular14">
                    {isMobile ? getWeekDay(intl, day) : weeksDayFullRU[day]}
                </CheckboxLabelText>
            </CheckboxAndDay>
            <InputHours
                name={nameFieldStart}
                label={intl.formatMessage({ defaultMessage: 'C' })}
                type="time"
                required
                disabled={!isChecked}
            />
            <InputHours
                name={nameFieldEnd}
                label={intl.formatMessage({ defaultMessage: 'До' })}
                type="time"
                required
                disabled={!isChecked}
            />
        </CheckboxLabel>
    );
};

export default RowCheckboxHours;
