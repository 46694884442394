import React from 'react';
import { BankDetailWrapper } from 'src/components/Widgets/ClaimModeratingWidget/components/BankDetail/styles';
import { useSelector } from 'react-redux';
import { getSelectedContract } from 'src/store/Contracts/selectors';
import { getBankDetailById } from 'src/store/Counterparty/selectors';
import { IContract } from 'src/store/Contracts/types';
import ButtonBankCard from 'src/pages/businessOffice/Claim/components/ButtonBankCard';
import { ICounterpartyBankDetail } from 'src/store/Counterparty/types';
import { BANK_DETAILS_FIELDS } from 'src/store/Counterparty/contants';

const BankDetail = () => {
    const selectedContract: IContract = useSelector(getSelectedContract) as any;
    const selectedBankDetail: ICounterpartyBankDetail = useSelector(
        getBankDetailById(selectedContract?.bankDetailId),
    ) as any;

    return (
        <BankDetailWrapper>
            {selectedBankDetail && (
                <ButtonBankCard selectedBankId={selectedBankDetail[BANK_DETAILS_FIELDS.id]} bank={selectedBankDetail} />
            )}
        </BankDetailWrapper>
    );
};

export default BankDetail;
