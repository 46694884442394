import styled from 'styled-components';
import Skeleton from 'src/components/Skeleton';

export const SkeletonFeedWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    overflow: hidden;
`;

export const SkeletonGroupFeedHead = styled(Skeleton)`
    height: 30px;
`;
