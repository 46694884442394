import { TOrder } from 'src/store/Order/types';
import dayjs from 'dayjs';
import { IFeedItem } from 'src/store/Feed/types';
import { UTC_DATE_FORMAT } from 'src/utils/constants';

interface ISortOrders {
    group: string;
    children: Array<TOrder>;
}

interface IGroupFeeds {
    group: string;
    children: Array<IFeedItem>;
    dateFormat: string;
}

// TODO: change dataOrders type
export const groupOrdersListByDate = (dataOrders: any[]) => {
    const data: ISortOrders = dataOrders.reduce((acc, el) => {
        const date = dayjs(el.createdAt).format('D MMMM YYYY');

        if (date) {
            if (!acc[date]) acc[date] = { group: date, children: [el] };
            else acc[date].children.push(el);
        }
        return acc;
    }, {});
    const sorted: Array<ISortOrders> = Object.values(data).sort();
    return sorted;
};

export const groupFeedsListByDate = (feeds: IFeedItem[]): IGroupFeeds[] => {
    const data: IGroupFeeds | {} = feeds.reduce((acc, el) => {
        const dateTitle = dayjs(el.createdAt).format('D MMMM YYYY');
        const dateFormat = dayjs(el.createdAt).format('D.MM.YYYY');
        if (dateTitle) {
            // @ts-ignore
            if (!acc[dateTitle]) acc[dateTitle] = { group: dateTitle, children: [el], dateFormat };
            // @ts-ignore
            else acc[dateTitle].children.push(el);
        }
        return acc;
    }, {});

    // @ts-ignore
    const result = Object.values(data).map((groupedDay: IGroupFeeds) => {
        const sortedArrayFeeds = groupedDay.children.sort(
            // @ts-ignore
            (a: IFeedItem, b: IFeedItem) =>
                new Date(dayjs(a.createdAt).utc().format(UTC_DATE_FORMAT)) <
                new Date(dayjs(b.createdAt).utc().format(UTC_DATE_FORMAT)),
        );
        return { ...groupedDay, children: sortedArrayFeeds };
    });
    // @ts-ignore
    return result.sort((a, b) => a.dateFormat < b.dateFormat);
};
