import React, { FC, useEffect, useState } from 'react';
import {
    ButtonAddNewOrganization,
    Content,
    Header,
    IconWrapper,
    OrganizationsWrapper,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/RelatedOrganizations/styles';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { useSelector } from 'react-redux';
import { getCounterpartyByIds } from 'src/store/ClientCounterparty/selectors';
import RowOrganization from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/RowOrganization';
import useModal from 'src/hooks/useModal';
import { CLIENT_COUNTERPARTY_FIELDS } from 'src/store/ClientCounterparty/constants';
import ModalSearchOrganization from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/ModalSearchOrganization';
import { IClient } from 'src/store/Client/types';
import { CLIENT_FIELDS } from 'src/store/Client/contants';
import { useAppDispatch } from 'src/store';
import { updateClient } from 'src/store/Client';
import ModalAddOrganization from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/ModalAddOrganization';
import { useIntl } from 'react-intl';

interface IRelatedOrganizations {
    contactCounterpartiesIds: number[];
    client: IClient;
}

const RelatedOrganizations: FC<IRelatedOrganizations> = ({ client, contactCounterpartiesIds }) => {
    const intl = useIntl();
    const { isOpen: modalSearchOrganizationVisible, open: openModalSearch, close: closeModalSearch } = useModal();
    const { isOpen: modalAddOrganizationVisible, open: openModalAdding, close: closeModalAdding } = useModal();
    const dispatch = useAppDispatch();

    const [idsClientCounterparties, setIdsClientCounterparties] = useState(contactCounterpartiesIds);

    useEffect(() => {
        setIdsClientCounterparties(contactCounterpartiesIds);
    }, [contactCounterpartiesIds]);

    const organizations = useSelector(getCounterpartyByIds(idsClientCounterparties));

    const connectToOrganization = async (idClientCounterparty: number) => {
        const upgradeClient = {
            ...client,
            [CLIENT_FIELDS.contactCounterpartyIds]: [...idsClientCounterparties, idClientCounterparty],
        };
        const data = await dispatch(updateClient(upgradeClient));
        // @ts-ignore
        if (!data?.error && data?.payload) {
            closeModalSearch();
        }
    };

    const removeToOrganization = (idClientCounterparty: number) => {
        const upgradeClient = {
            ...client,
            [CLIENT_FIELDS.contactCounterpartyIds]: idsClientCounterparties.filter(
                (counterpartyId) => counterpartyId !== idClientCounterparty,
            ),
        };
        dispatch(updateClient(upgradeClient));
    };

    const openModalAddOrganization = () => {
        closeModalSearch();
        openModalAdding();
    };

    return (
        <OrganizationsWrapper>
            <Header>{intl.formatMessage({ defaultMessage: 'Организации' })}</Header>
            <Content>
                {organizations.map((organization) => (
                    <RowOrganization
                        removeToOrganization={removeToOrganization}
                        key={organization[CLIENT_COUNTERPARTY_FIELDS.id]}
                        clientCounterparty={organization}
                    />
                ))}
                <ButtonAddNewOrganization type="button" onClick={openModalSearch}>
                    <IconWrapper>
                        <PlusIcon />
                    </IconWrapper>
                    {intl.formatMessage({ defaultMessage: 'Добавить новую организацию к клиенту' })}
                </ButtonAddNewOrganization>
            </Content>
            <ModalSearchOrganization
                clientCounterpartyIds={idsClientCounterparties}
                connectToOrganization={connectToOrganization}
                isOpen={modalSearchOrganizationVisible}
                closeModal={closeModalSearch}
                openModalAdding={openModalAddOrganization}
            />
            <ModalAddOrganization
                connectToOrganization={connectToOrganization}
                onClose={closeModalAdding}
                isOpen={modalAddOrganizationVisible}
            />
        </OrganizationsWrapper>
    );
};

export default RelatedOrganizations;
