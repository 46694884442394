import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useAppDispatch } from 'src/store';
import { verificationByPhone } from 'src/store/Auth';
import { phoneFormatter, removePlusFromPhone } from 'src/utils/formatters';
import { getIsAuthLoading } from 'src/store/Auth/selectors';
import FormField from 'src/components/FormField';
import useValidationSchema from 'src/hooks/useValidationSchema';
import { ReactComponent as NextIcon } from 'src/assets/icons/arrow-right-circle.svg';
import { useIntl } from 'react-intl';
import { LoginContext } from '../context';
import { welcomeFormSchema } from '../validations';
import { AuthDescription, AuthInner, AuthTitle, FormInner, FormWrapper } from '../styles';
import { useReCaptchaTokenContext } from '../../../../context/ReCaptchaTokenContext';

interface FormValues {
    phone: string;
}

const EnterPhoneForm: FC = () => {
    const intl = useIntl();
    const { token, onRefreshReCaptcha } = useReCaptchaTokenContext();

    const { toNextStep, phone, setPhone } = useContext(LoginContext);
    const validation = useValidationSchema(welcomeFormSchema);
    const isLoading = useSelector(getIsAuthLoading);
    const dispatch = useAppDispatch();

    const onSubmit = async (values: FormValues) => {
        if (token) {
            const data = await dispatch(
                verificationByPhone({
                    phone: phoneFormatter(removePlusFromPhone(values.phone)),
                    token,
                }),
            );
            onRefreshReCaptcha();
            // @ts-ignore
            if (!data?.error && data?.payload) {
                setPhone(values.phone);
                toNextStep();
            }
        }
    };

    return (
        <AuthInner>
            <AuthTitle variant="title">{intl.formatMessage({ defaultMessage: 'Вход в приложение' })}</AuthTitle>
            <AuthDescription variant="regular12">
                {intl.formatMessage({
                    defaultMessage: 'Пожалуйста, введите номер телефона для входа или регистрации в приложении',
                })}
            </AuthDescription>
            <FormWrapper>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{ phone }}
                    validate={validation}
                    initialValuesEqual={() => true}
                >
                    {({ handleSubmit, valid }) => (
                        <FormInner onSubmit={handleSubmit}>
                            <FormField
                                autoFocus
                                type="tel"
                                label={intl.formatMessage({ defaultMessage: 'Телефон' })}
                                name="phone"
                                mask="+7 999 999 99 99"
                                placeholder="+7 XXX XXX XX XX"
                                icon={<NextIcon />}
                                onIconClick={handleSubmit}
                                iconIsDisabled={!valid}
                                disabled={isLoading}
                            />
                        </FormInner>
                    )}
                </Form>
            </FormWrapper>
        </AuthInner>
    );
};

export default EnterPhoneForm;
