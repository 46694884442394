import styled from 'styled-components';
import Typography from 'src/components/Typography';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import Skeleton from 'src/components/Skeleton';

export const CopyLink = styled.button`
    margin: 0 auto;
    ${({ theme }) => theme.mixins.getTypography('regular12')};
    color: ${({ theme }) => theme.colors.grey()};
`;

export const CopyLinkWrapper = styled.div`
    position: relative;
    padding-top: 20px;
    ${({ theme }) => theme.mixins.flexCenter};
`;

export const WrapperQRBlock = styled.div``;

export const QRTitle = styled(Typography)`
    text-align: center;
    margin-bottom: 20px;
`;

export const QRSubtitle = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.PRE_MEDIUM)};
    text-align: center;
    margin-bottom: 30px;
    padding: 0 5px;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 182px;
        margin 0 auto 30px;
}
`;

export const WrapperQRCode = styled.div`
    padding: 2px;
    width: 211px;
    height: 211px;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.lightGrey()};
    margin: 0 auto;
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 189px;
        height: 189px;
        canvas {
            width: 100% !important;
            height: 100% !important;
        }
    }
`;

export const SkeletonLink = styled(Skeleton)`
    width: 100%;
`;
