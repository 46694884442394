import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';
import { getNoun } from '../../../utils/helpers';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';

export type TProps = {
    countLineSelected: number;
    children: ReactNode;
    onReset?: () => void;
};

export const SubHeader: FC<TProps> = ({ countLineSelected, children, onReset }) => {
    const intl = useIntl();

    return (
        <S.Wrapper>
            <S.Counter>
                <Typography variant="headerText">
                    {intl.formatMessage({
                        id: 'Выбрано:',
                        defaultMessage: 'Выбрано:',
                    })}{' '}
                    {countLineSelected}{' '}
                    {getNoun(
                        countLineSelected,
                        intl.formatMessage({
                            id: 'счёт',
                            defaultMessage: 'счёт',
                        }),
                        intl.formatMessage({
                            id: 'счёта',
                            defaultMessage: 'счёта',
                        }),
                        intl.formatMessage({
                            id: 'счётов',
                            defaultMessage: 'счётов',
                        }),
                    )}
                </Typography>
                {onReset && (
                    <S.ResetButton type="button" onClick={onReset}>
                        <CrossIcon />
                    </S.ResetButton>
                )}
            </S.Counter>
            {children}
        </S.Wrapper>
    );
};
