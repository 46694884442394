import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from '../../../theme/utils';

export const Wrapper = styled.div`
    position: relative;
    display: inline-block;
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.PRE_HARD)};
`;

export const Content = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 4px;
`;

export const OptionWrapper = styled.button`
    padding: 6px 16px;
    width: 100%;

    &:hover {
        background-color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.LIGHTEST)};
    }
`;
