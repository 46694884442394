import React, { FC, useRef } from 'react';
import sizeWidget from 'src/theme/widget';
import BaseWidget from 'src/components/Widgets/BaseWidget';
import { AVATAR_SIZE, AVATAR_VARIANT } from 'src/components/Avatar';
import { useSelector } from 'react-redux';
import { getClientIsLoaded, getLastClients } from 'src/store/Client/selectors';
import Slider from 'react-slick';
import { getTwoWordsFromName } from 'src/utils/helpers';
import { useMobile, useTablet } from 'src/hooks/useMedia';
import SkeletonLastClient from 'src/components/LastClientsSelect/components/SkeletonLastClient';
import { useIntl } from 'react-intl';
import {
    Container,
    Heading,
    WrapTitle,
    Title,
    LeftIcon,
    RightIcon,
    ContainerClients,
    WrapAvatar,
    ClientName,
    AvatarClient,
    ButtonsNextPrev,
} from './styles';

const LastClientsSelect: FC = () => {
    const intl = useIntl();
    const isTablet = useTablet();
    const isMobile = useMobile();
    const sliderRef = useRef();

    const lastClients = useSelector(getLastClients);
    const isLoaded = useSelector(getClientIsLoaded);

    const getCountSlideToMedia = () => {
        if (isMobile) {
            return 3;
        }
        if (isTablet) {
            return 4;
        }
        return 7;
    };

    const countSlideToPage = getCountSlideToMedia();
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: countSlideToPage,
        slidesToScroll: countSlideToPage,
    };

    const goToNextSlide = () => {
        // @ts-ignore
        sliderRef?.current?.slickNext();
    };

    const goToPrevSlide = () => {
        // @ts-ignore
        sliderRef?.current?.slickPrev();
    };

    const renderLastClients = () => {
        if (!isLoaded) {
            const countSkeletonItems = 3;
            const arrSkeletonItems = new Array(countSkeletonItems).fill(SkeletonLastClient);

            return arrSkeletonItems.map((SkeletonComponent, index) => (
                <SkeletonComponent
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                />
            ));
        }
        return lastClients.map(({ id, name }) => (
            <WrapAvatar key={id}>
                <AvatarClient size={AVATAR_SIZE.lg} variant={AVATAR_VARIANT.dark} name={name} />
                <ClientName variant="regular14">{getTwoWordsFromName(name)}</ClientName>
            </WrapAvatar>
        ));
    };

    return (
        <BaseWidget size={sizeWidget.xl}>
            <Container>
                <Heading>
                    <WrapTitle>
                        <Title variant="title">{intl.formatMessage({ defaultMessage: 'Последние клиенты' })}</Title>
                    </WrapTitle>
                    <ButtonsNextPrev>
                        {lastClients.length > countSlideToPage && (
                            <>
                                <button type="button" onClick={goToPrevSlide}>
                                    <LeftIcon />
                                </button>
                                <button type="button" onClick={goToNextSlide}>
                                    <RightIcon />
                                </button>
                            </>
                        )}
                    </ButtonsNextPrev>
                </Heading>
                <ContainerClients>
                    <Slider
                        // @ts-ignore
                        ref={sliderRef}
                        {...settings}
                    >
                        {renderLastClients()}
                    </Slider>
                </ContainerClients>
            </Container>
        </BaseWidget>
    );
};
export default LastClientsSelect;
