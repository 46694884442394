import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';

export const ModalIntegrationCodeContent = styled(Modal)`
    padding: 60px 80px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 60px 20px 40px 20px;
    }
`;

export const ModalIntegrationCodeTitle = styled(Typography)`
    margin-bottom: 24px;
    text-align: center;
`;

export const Subtitle = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: center;
`;

export const CodeWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 30px;
    grid-column-gap: 10px;
    grid-row-gap: 4px;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
`;

export const CodeValue = styled(Typography)``;

export const CodeTimer = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    grid-column: 1/3;
    text-align: center;
`;

export const ButtonCopyCode = styled.button`
    height: 30px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.lightGrey()};
    ${({ theme }) => theme.mixins.flexCenterCenter};

    svg {
        path {
            stroke: ${({ theme }) => theme.colors.base()};
        }
    }
`;

export const ButtonEnterCodeLater = styled(Button)`
    margin: 0 auto;
    ${({ theme }) => theme.helpers.getTypography('bold14')};
`;
