import React from 'react';
import {
    CustomerContainer,
    DescriptionStatusWrapper,
    SkeletonAvatar,
    SkeletonDescription,
    SkeletonName,
    SkeletonOrderWrapper,
    SkeletonPrice,
    SkeletonStatus,
} from 'src/components/MerchantOrdersList/components/SkeletonOrderItem/styles';

const SkeletonOrderItem = () => (
    <SkeletonOrderWrapper>
        <CustomerContainer>
            <SkeletonAvatar />
            <SkeletonName />
        </CustomerContainer>
        <DescriptionStatusWrapper>
            <SkeletonDescription />
            <SkeletonStatus />
        </DescriptionStatusWrapper>
        <SkeletonPrice />
    </SkeletonOrderWrapper>
);

export default SkeletonOrderItem;
