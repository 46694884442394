import http, { ICommonResponse } from 'src/utils/http';
import { IUserProfile, IUserResponse, IVerificationCodeResponse } from './types';

export const submitUserPhone = async (phone: string, token: string) =>
    http.post<ICommonResponse<IVerificationCodeResponse>>(`/security/api/auth/verification?_token=${token}`, {
        data: {
            identifier: phone,
            type: 'phone',
        },
        action: 'authentication',
        type: 'code-identifier',
    });

export const submitVerificationCode = async (id: number, code: string) =>
    http.put<ICommonResponse<IVerificationCodeResponse>>(`/security/api/auth/verification/${id}`, {
        code,
    });

export const getUserProfileRequest = async (token: string) =>
    http.get<ICommonResponse<IUserResponse>>(`/security/api/auth/auth?_token=${token}`);

export const deleteAuthSession = async () => http.delete('/security/api/auth/logout');

export const updateUserProfileRequest = async ({
    firstName,
    lastName,
    email,
    id,
}: Pick<IUserProfile, 'firstName' | 'lastName' | 'id' | 'email'>) =>
    http.put<ICommonResponse<IUserProfile>>(`/security/api/profile/${id}`, { firstName, lastName, email });
