import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DICTIONARY_TYPES } from 'src/store/Dictionaries/constants';
import { getDictionary } from 'src/store/Dictionaries/selectors';
import { useAppDispatch } from 'src/store';
import { loadDictionary } from 'src/store/Dictionaries';

function useDictionary(name: DICTIONARY_TYPES) {
    const dict = useSelector(getDictionary(name));
    const dispatch = useAppDispatch();
    const isExternal = dict?.isExternal;
    const isLoaded = dict?.isLoaded;

    useEffect(() => {
        if (isExternal && !isLoaded) {
            dispatch(loadDictionary(name));
        }
    }, [dispatch, isExternal, isLoaded, name]);

    return dict;
}

export default useDictionary;
