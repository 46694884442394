import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import Typography from 'src/components/Typography';

export const CounterpartyWrapper = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.colors.white()};
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
`;

export const CounterpartyHeader = styled.div`
    background-color: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
    ${({ theme }) => theme.mixins.flexCenter}
    padding: 28px 25px 28px 29px;
    justify-content: space-between;
`;
export const ButtonAddCounterparty = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 10px;
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;

export const LabelAddCounterparty = styled(Typography)`
    @media ${({ theme }) => theme.breakpoints.md} {
        display: none;
    }
`;

export const CounterpartiesList = styled.div`
    padding: 30px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 30px 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 0;
    }
`;
