import {
    ClaimLabel,
    ClaimColumnWrapper,
} from 'src/components/Widgets/ClaimModeratingWidget/components/ClaimColumn/styles';
import React, { FC, ReactNode } from 'react';

interface IClaimColumn {
    label: string;
    value: ReactNode;
}

const ClaimColumn: FC<IClaimColumn> = ({ label, value }) => (
    <ClaimColumnWrapper>
        <ClaimLabel variant="regular12">{label}</ClaimLabel>
        {value}
    </ClaimColumnWrapper>
);

export default ClaimColumn;
