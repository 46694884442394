import styled from 'styled-components';
import FormField from 'src/components/FormField';

export const OrderGeneralWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    margin-bottom: 46px;

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 100%;
    }
`;

export const DescriptionField = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-start: auto;
        grid-column-end: auto;
    }
`;
