import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';
import {
    IconClickableContainer,
    IconContainer,
    InputContainer,
    InputLabel,
    inputStyles,
    InputWrapper,
    MaskField,
} from 'src/components/Input/styles';
import { useInputFocus } from '@invoicebox/ui';

export interface IInputCurrencyProps extends InputHTMLAttributes<HTMLInputElement> {
    decimalsLimit?: number;
    decimalSeparator?: string;
    groupSeparator?: string;
    decimalScale?: number;
    disableGroupSeparators?: boolean;
    allowDecimals?: boolean;
    label?: string;
    disabled?: boolean;
    hasError?: boolean;
    className?: string;
    mask?: string;
    icon?: ReactNode;
    onIconClick?: () => void;
    iconIsDisabled?: boolean;
}

interface InputIconContainerProps {
    onClick?: () => void;
    iconIsDisabled?: boolean;
}

const InputIconContainer: FC<InputIconContainerProps> = ({ onClick, iconIsDisabled, children }) =>
    onClick ? (
        <IconClickableContainer onClick={onClick} disabled={iconIsDisabled}>
            {children}
        </IconClickableContainer>
    ) : (
        <IconContainer>{children}</IconContainer>
    );

const InputCurrency: FC<IInputCurrencyProps> = ({
    allowDecimals = false,
    decimalsLimit = 2,
    decimalSeparator = ',',
    decimalScale = 2,
    disableGroupSeparators,
    groupSeparator = ' ',
    placeholder,
    disabled = false,
    hasError = false,
    label = '',
    className,
    mask,
    icon,
    onIconClick,
    iconIsDisabled,
    onFocus,
    onBlur,
    ...props
}) => {
    const { inFocus, handleFocus, handleBlur } = useInputFocus({ initialFocusFlag: false, onFocus, onBlur });

    // only use the input mask if there is a value -- this prevents issues with Chrome auto-fill
    const inputMask = props.value ? mask : null;

    return (
        <InputWrapper disabled={disabled} className={className}>
            {label && (
                <InputLabel variant="regular12" inFocus={inFocus}>
                    {label}
                </InputLabel>
            )}
            <InputContainer hasError={hasError} hasIcon={!!icon} inFocus={inFocus}>
                {mask ? (
                    <MaskField
                        // @ts-ignore
                        mask={inputMask}
                        placeholder={placeholder}
                        disabled={disabled}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        {...props}
                    />
                ) : (
                    <InputField
                        allowDecimals={allowDecimals}
                        placeholder={placeholder}
                        value={props.value}
                        decimalsLimit={decimalsLimit}
                        decimalScale={decimalScale}
                        disabled={disabled}
                        decimalSeparator={decimalSeparator}
                        groupSeparator={groupSeparator}
                        disableGroupSeparators={disableGroupSeparators}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        name={props.name}
                        // @ts-ignore
                        onValueChange={props.onChange}
                    />
                )}
                {icon && (
                    <InputIconContainer onClick={onIconClick} iconIsDisabled={iconIsDisabled}>
                        {icon}
                    </InputIconContainer>
                )}
            </InputContainer>
        </InputWrapper>
    );
};

export default React.memo(InputCurrency);

const InputField = styled(CurrencyInput)`
    ${inputStyles};
`;
