import React, { FC } from 'react';
import { IPointWorkTime } from 'src/utils/helpers/putTogetherTimetable';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-white.svg';
import { WeeksDay } from 'src/store/Merchant/types';
import { IntlShape, useIntl } from 'react-intl';
import { DateHours, RemoveHoursButton, DateHoursWorkContainer } from './styles';

export const getWeekDay = (intl: IntlShape, weekDay: WeeksDay | string) => {
    const weekDayCorrectType: WeeksDay = weekDay as any;

    const weekDays = {
        [WeeksDay.mon]: intl.formatMessage({ defaultMessage: 'Пн' }),
        [WeeksDay.tue]: intl.formatMessage({ defaultMessage: 'Вт' }),
        [WeeksDay.wed]: intl.formatMessage({ defaultMessage: 'Ср' }),
        [WeeksDay.thu]: intl.formatMessage({ defaultMessage: 'Чт' }),
        [WeeksDay.fri]: intl.formatMessage({ defaultMessage: 'Пт' }),
        [WeeksDay.sat]: intl.formatMessage({ defaultMessage: 'Сб' }),
        [WeeksDay.sun]: intl.formatMessage({ defaultMessage: 'Вс' }),
    };

    return weekDays[weekDayCorrectType];
};

interface IDateHoursWork extends IPointWorkTime {
    isEdit: boolean;
    removeIntervalDays?: (startDay: string, endDay: string) => void;
}

const DateHoursWork: FC<IDateHoursWork> = ({
    removeIntervalDays = () => {},
    startPointDay,
    endPointDay,
    time,
    isEdit,
}) => {
    const intl = useIntl();
    const days =
        startPointDay === endPointDay
            ? getWeekDay(intl, startPointDay)
            : `${getWeekDay(intl, startPointDay)}-${getWeekDay(intl, endPointDay)}`;

    return (
        <DateHoursWorkContainer>
            <DateHours variant="regular10">
                {days} {time[0]}-{time[1]}
                {isEdit && (
                    <RemoveHoursButton
                        type="button"
                        onClick={() => {
                            removeIntervalDays(startPointDay, endPointDay);
                        }}
                    >
                        <CloseIcon />
                    </RemoveHoursButton>
                )}
            </DateHours>
        </DateHoursWorkContainer>
    );
};

export default DateHoursWork;
