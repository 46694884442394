import React from 'react';
import { isArray, isEmpty, isNil } from 'lodash';
import { useSWRInfinite } from 'src/hooks/useSWR';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { IOrderContainer } from 'src/store/Order/types';
import { IMerchant } from 'src/store/Merchant/types';
import { getUserId } from 'src/store/Auth/selectors';
import { getOrdersContainerList } from 'src/utils/helpers/getOrdersContainerList';
import OrderPaymentItem from 'src/components/Widgets/OrderPaymentWidget/components/OrderPaymentItem';
import SkeletonPaymentList from 'src/components/Widgets/OrderPaymentWidget/components/SkeletonPaymentList';
import { useIntl } from 'react-intl';
import { Empty, InnerContent } from './styles';

const OrderPaymentList = () => {
    const intl = useIntl();
    const payerCounterpartyId = useSelector(getCurrentCounterpartyId);
    const userId = useSelector(getUserId);

    const { data } = useSWRInfinite<IOrderContainer[]>('billing/api/order/order-container', {
        ...(isNil(payerCounterpartyId) ? { payerUserId: userId } : { payerCounterpartyId }),
        _pageSize: 5,
        status: undefined,
        _include: ['order'],
        _extend: ['merchant'],
        '_order[id]': 'desc',
    });

    const ordersList = getOrdersContainerList(data);

    const merchants = data?.reduce((acc, { extendedData }) => {
        const includes = extendedData.find(({ entity }) => entity === 'merchant');
        return [...acc, ...includes.data];
    }, [] as IMerchant[]);

    return (
        <>
            {!isEmpty(ordersList) && (
                <InnerContent>
                    {ordersList?.map((order) => (
                        <OrderPaymentItem key={order.id} order={order} merchants={merchants} />
                    ))}
                </InnerContent>
            )}
            {!ordersList && <SkeletonPaymentList />}
            {isEmpty(ordersList) && isArray(ordersList) && (
                <Empty variant="subheading">{intl.formatMessage({ defaultMessage: 'Нет счетов' })}</Empty>
            )}
        </>
    );
};

export default OrderPaymentList;
