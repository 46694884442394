import React, { FC } from 'react';
import { IMerchant } from 'src/store/Merchant/types';
import { OrderSubtitle } from 'src/pages/businessOffice/OrderContainer/components/DetailOrder/styles';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import RowLabel from 'src/components/RowLabel';
import { RequisitesInformation } from 'src/pages/businessOffice/OrderContainer/components/Requisites/styles';
import { useIntl } from 'react-intl';

interface ISeller {
    merchant: IMerchant;
}

const Seller: FC<ISeller> = ({ merchant }) => {
    const intl = useIntl();

    const sellerFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Наименование' }),
            value: merchant?.[MERCHANT_FIELDS.name],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Сайт' }),
            value: merchant?.[MERCHANT_FIELDS.website],
        },
    ];
    return (
        <div>
            <OrderSubtitle variant="subheading">{intl.formatMessage({ defaultMessage: 'Продавец' })}</OrderSubtitle>
            <RequisitesInformation>
                {sellerFields.map((merchantField) => (
                    <RowLabel
                        isShowSkeleton={!merchant}
                        key={merchantField.label}
                        label={merchantField.label}
                        value={merchantField.value}
                    />
                ))}
            </RequisitesInformation>
        </div>
    );
};

export default Seller;
