import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { Currency } from './entity';
import { getCurrencyListRequest } from './http';

export const currencyAdapter = createEntityAdapter<Currency>();

const initialState = currencyAdapter.getInitialState({
    isInitialized: false,
    isLoading: false,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

export const fetchCurrencyList = createAsyncThunk('currency/fetchList', async () => {
    const { data } = await getCurrencyListRequest();
    return data;
});

const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCurrencyList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCurrencyList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isInitialized = true;
            state.metaData = payload.metaData;
            currencyAdapter.setAll(
                state as EntityState<Currency>,
                payload.data.map((currencyFromResponse) => Currency.fromResponse(currencyFromResponse)),
            );
        });
        builder.addCase(fetchCurrencyList.rejected, (state) => {
            state.isLoading = false;
            state.isInitialized = true;
        });
    },
});

export default currencySlice.reducer;
