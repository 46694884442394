import styled from 'styled-components';
import Typography from 'src/components/Typography';
import FormField from 'src/components/FormField';

export const CheckboxLabel = styled.label`
    ${({ theme }) => theme.mixins.flexCenter};
    margin-top: 30px;
    grid-column-gap: 20px;

    @media ${({ theme }) => theme.breakpoints.md} {
        display: grid;
        grid-template-columns: 25% 34% 34%;
        width: 100%;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 20% 35% 35%;
    }
`;

export const CheckboxLabelText = styled(Typography)`
    color: ${({ theme }) => theme.colors.base()};
    margin-left: 10px;
    margin-top: 3px;
    width: 115px;
`;

export const CheckboxAndDay = styled.div`
    display: grid;
    grid-column-gap: 9px;
    grid-template-columns: 22px 1fr;
`;

export const InputHours = styled(FormField)`
    @media ${({ theme }) => theme.breakpoints.md} {
        display: block;
        width: auto;
        max-width: 100%;
    }
`;
