import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ClientWrapper = styled.div`
    padding-top: 40px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 30px;
    }
`;

export const ClientTitle = styled(Typography)`
    margin-bottom: 21px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 17px;
    }
`;

export const ClientFieldsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 32px;
    padding: 0 11px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 18px;
    }
`;
