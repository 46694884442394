import React, { FC } from 'react';
import { IClient } from 'src/store/Client/types';
import { ReactComponent as IconProfile } from 'src/assets/icons/profile.svg';
import {
    ButtonRemove,
    Email,
    IconWrapper,
    Phone,
    RowContactWrapper,
    Title,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/RowContactPerson/styles';
import { CLIENT_FIELDS } from 'src/store/Client/contants';
import { ReactComponent as IconDelete } from 'src/assets/icons/delete.svg';
import { convertPhoneToShow } from 'src/utils/formatters';

interface IRowContactPerson {
    contactPerson: IClient;
    removeContactPerson: (contactPerson: IClient) => void;
}

const RowContactPerson: FC<IRowContactPerson> = ({ contactPerson, removeContactPerson }) => (
    <RowContactWrapper>
        <Title>
            <IconWrapper>
                <IconProfile />
            </IconWrapper>
            {contactPerson[CLIENT_FIELDS.name]}
        </Title>
        <Phone variant="regular14">
            {contactPerson[CLIENT_FIELDS.phone] && convertPhoneToShow(contactPerson[CLIENT_FIELDS.phone])}
        </Phone>
        <Email variant="regular14">{contactPerson[CLIENT_FIELDS.email]}</Email>
        <ButtonRemove
            type="button"
            onClick={() => {
                removeContactPerson(contactPerson);
            }}
        >
            <IconDelete />
        </ButtonRemove>
    </RowContactWrapper>
);

export default RowContactPerson;
