import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchOrderContainerList } from '../../../../../../store/OrderContainer';
import { convertDateToString } from '../../../../../../utils/helpers';
import { useAppDispatch } from '../../../../../../store';
import { ICounterparty } from '../../../../../../store/Counterparty/types';
import {
    getIsInitializedOrderContainerList,
    getIsLoadingOrderContainerList,
    getOrderContainerList,
    getOrderContainerMetaData,
} from '../../../../../../store/OrderContainer/selectors';

export const useData = (selectedCounterparty: ICounterparty) => {
    const dispatch = useAppDispatch();
    const pageSizeOptions = [5, 10, 15];

    const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
    const [page, setPage] = useState(1);
    const [createdAtFilter, setCreatedAtFilter] = useState<[Date, Date] | null>(null);

    const orderContainerList = useSelector(getOrderContainerList);
    const isLoadingOrderContainerList = useSelector(getIsLoadingOrderContainerList);
    const isInitializedOrderContainerList = useSelector(getIsInitializedOrderContainerList);
    const metaData = useSelector(getOrderContainerMetaData);

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(1);
    };

    const goToNextPage = () => setPage((prevState) => prevState + 1);
    const goToPrevPage = () => setPage((prevState) => prevState - 1);

    useEffect(() => {
        dispatch(
            fetchOrderContainerList({
                _pageSize: pageSize,
                _page: page,
                payerCounterpartyId: selectedCounterparty.id,
                'createdAt[_ge]': createdAtFilter ? convertDateToString(createdAtFilter[0]) : undefined,
                'createdAt[lt]': createdAtFilter ? convertDateToString(createdAtFilter[1]) : undefined,
            }),
        );
    }, [createdAtFilter, dispatch, page, pageSize, selectedCounterparty.id]);

    return {
        orderContainerList,
        isLoading: isLoadingOrderContainerList,
        isInitialized: isInitializedOrderContainerList,
        metaData,
        goToNextPage,
        goToPrevPage,
        handlePageSizeChange,
        handleCreatedAtFilterChange: setCreatedAtFilter,
        createdAtFilter,
        pageSizeOptions,
        pageSize,
    };
};
