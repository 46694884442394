import { IWorkHours } from 'src/store/Merchant/types';

const getValidHoursDay = (hoursDayWork: IWorkHours) =>
    // @ts-ignore
    Object.fromEntries(
        Object.entries(hoursDayWork)
            .map((key) => key)
            .map((dayWork) => {
                if (dayWork[1][0] && dayWork[1][0][0]) {
                    return dayWork;
                } else {
                    return [dayWork[0], []];
                }
            }),
    );

export default getValidHoursDay;
