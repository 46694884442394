import React, { FC, useEffect } from 'react';
import TotalRow from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/TotalRow';
import { ORDER_FIELDS, TOrderItem } from 'src/store/Order/types';
import { formatCurrencyToShow, getTotalAmountAndTotalVat, toValidCurrencyFloat } from 'src/utils/helpers';
import { useForm } from 'react-final-form';
import useLoadingSubmit from 'src/hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';
import { ButtonCreateOrderPay, TotalValuesContainer, TotalValuesDescriptions } from './styles';

interface ITotalValues {
    itemsOrder: TOrderItem[];
    valid: boolean;
    handleSubmit: (
        event?: Partial<Pick<React.SyntheticEvent<Element, Event>, 'preventDefault' | 'stopPropagation'>> | undefined,
    ) => Promise<any | undefined> | undefined;
}

const TotalValues: FC<ITotalValues> = ({ handleSubmit, itemsOrder, valid }) => {
    const intl = useIntl();
    const { change } = useForm();
    const { totalAmount, totalVat } = getTotalAmountAndTotalVat(itemsOrder);
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const validAmount = toValidCurrencyFloat(totalAmount);
    const validVatAmount = toValidCurrencyFloat(totalVat);

    useEffect(() => {
        change(ORDER_FIELDS.amount, validAmount);
        change(ORDER_FIELDS.vatAmount, validVatAmount);
    }, [change, validAmount, validVatAmount]);

    const onClickCreateOrder = async () => {
        toggleLoadingSubmit();
        await handleSubmit();
        toggleLoadingSubmit();
    };

    return (
        <TotalValuesContainer>
            <TotalValuesDescriptions>
                <TotalRow
                    label={intl.formatMessage({ defaultMessage: 'Сумма заказа' })}
                    value={formatCurrencyToShow(validAmount)}
                />
                <TotalRow
                    label={intl.formatMessage({ defaultMessage: 'НДС' })}
                    value={formatCurrencyToShow(validVatAmount)}
                />
            </TotalValuesDescriptions>
            <ButtonCreateOrderPay
                isLoading={isLoadingSubmit}
                onClick={onClickCreateOrder}
                disabled={!valid || itemsOrder.length === 0}
            >
                {intl.formatMessage({ defaultMessage: 'Сформировать счет' })}
            </ButtonCreateOrderPay>
        </TotalValuesContainer>
    );
};

export default React.memo(TotalValues);
