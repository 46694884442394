import styled from 'styled-components';
// @ts-ignore
import DateRangePickerFromLibrary from '@wojtekmaj/react-daterange-picker';

export const DateRangeCustom = styled(DateRangePickerFromLibrary)`
    .react-daterange-picker__wrapper {
        display: none;
    }
    .react-daterange-picker__calendar {
        padding: 30px;
        background: ${({ theme }) => theme.colors.white()};
        box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
        border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
        width: 270px;
    }

    .react-calendar {
        border: none;
    }

    .react-calendar__month-view__weekdays__weekday {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        font-weight: normal;
        abbr {
            text-decoration: none;
        }
    }

    .react-calendar__month-view__days__day {
        color: ${({ theme }) => theme.colors.grey()};
    }

    .react-calendar__month-view__days__day--weekend {
        color: ${({ theme }) => theme.colors.grey()};
    }

    .react-calendar__tile {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        height: 22px;
        padding: 0;
        margin-bottom: 6px;
    }

    .react-calendar__tile--active {
        color: ${({ theme }) => theme.colors.base()};
        background: ${({ theme }) => theme.colors.dragAndDropBackground()};
    }
    .react-calendar__navigation__prev2-button {
        display: none;
    }
    .react-calendar__navigation__next2-button {
        display: none;
    }
    .react-calendar__navigation {
        height: auto;
    }
    .react-calendar__tile--rangeStart {
        background: ${({ theme }) => theme.colors.yellow()};
        border-radius: 5px;
        position: relative;
        border-top-right-radius: 8px 10px;
        border-bottom-right-radius: 8px 10px;
    }
    .react-calendar__navigation__label__labelText {
        display: inline;
        &:first-letter {
            text-transform: capitalize;
        }
    }
    .react-calendar__tile--active:enabled:focus {
        background: ${({ theme }) => theme.colors.yellow()};
    }
    .react-calendar__navigation__arrow {
        @media ${({ theme }) => theme.breakpoints.sm} {
            min-width: 22px;
        }
    }
`;
