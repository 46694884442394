import styled from 'styled-components';
import Input from 'src/components/Input';

export const FinderClientsWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const InputFinder = styled(Input)`
    padding: 0;
    min-height: 46px;
    width: 100%;
    border-radius: 12px;
    outline: none;
    &::placeholder {
        color: ${({ theme }) => theme.colors.grey()};
    }
`;

export const SearchContainer = styled.button`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
`;
