import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from '../../../theme/utils';

export const Wrapper = styled.tr``;

export const Cell = styled.td`
    text-align: center;
    padding-top: 30px;
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.HARD)};
`;
