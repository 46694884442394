import React, { FC, useEffect, useState } from 'react';
import {
    AllNavLink,
    Header,
    NotificationsList,
    Title,
    Wrapper,
} from 'src/components/Header/components/NotificationWindow/styles';
import { RoutesBusinessOffice } from 'src/pages/constants';
import NotificationRow from 'src/components/Header/components/NotificationRow';
import { IFeedItem } from 'src/store/Feed/types';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useOutsideClick } from '@invoicebox/ui';

interface INotificationWindow {
    feeds: IFeedItem[];
    setIsShowWindowNotifications: (arg: boolean) => void;
    isShowWindowNotifications: boolean;
}

const NotificationWindow: FC<INotificationWindow> = ({
    isShowWindowNotifications,
    feeds,
    setIsShowWindowNotifications,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const [savedFeeds, setSavedFeeds] = useState<IFeedItem[]>([]);

    const hideWindowNotification = () => setIsShowWindowNotifications(false);

    const windowRef = useOutsideClick(() => {
        hideWindowNotification();
    });

    useEffect(() => {
        if (feeds.length > 0) {
            setSavedFeeds(feeds);
        }
    }, [feeds]);

    const onClickAllFeeds = () => {
        history.push(RoutesBusinessOffice.feeds);
        hideWindowNotification();
    };

    if (isShowWindowNotifications) {
        return (
            <Wrapper ref={windowRef}>
                <Header>
                    <Title variant="title">{intl.formatMessage({ defaultMessage: 'Уведомления' })}</Title>
                    <AllNavLink onClick={onClickAllFeeds}>{intl.formatMessage({ defaultMessage: 'Все' })}</AllNavLink>
                </Header>
                <NotificationsList>
                    {savedFeeds.map((feed) => (
                        <NotificationRow hideWindowNotification={hideWindowNotification} key={feed.id} feed={feed} />
                    ))}
                </NotificationsList>
            </Wrapper>
        );
    }
    return null;
};

export default NotificationWindow;
