import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from '../../../theme/utils';

export const Wrapper = styled.tr`
    padding: 12px 20px;
    background: #f5f6fa;
    width: 100%;
    text-align: left;
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};

    th {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    th:first-of-type {
        padding-left: 20px;
    }

    th:last-of-type {
        padding-right: 20px;
    }
`;
