import styled from 'styled-components';
import Typography from 'src/components/Typography';
import { ReactComponent as addIcon } from 'src/assets/icons/plusDark.svg';
import { NavLink } from 'react-router-dom';
import Input from 'src/components/Input';

export const Row = styled.div`
    display: grid;
    grid-template-columns: auto 170px;
    grid-column-gap: 30px;
    margin-bottom: 20px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
    }
`;

export const Wrapper = styled.div`
    position: relative;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding: 22px 30px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 28px 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0;
    }
`;

export const RowForFilter = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    margin-bottom: 20px;
    align-items: center;
    ${({ theme }) => theme.breakpoints.xl} {
        grid-template-columns: 1% 70% 29%;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: repeat(2, auto);
        grid-row-gap: 20px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 27px 20px 18px 20px;
        margin: 0;
    }
`;

export const FilterMain = styled.div``;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    @media ${({ theme }) => theme.breakpoints.ld} {
        justify-content: flex-start;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('regular12')};
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
`;

export const FilterContainer = styled.div``;

export const FilterBody = styled.div`
    display: flex;
    width: 100%;
`;

export const PaginationContainer = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
`;

export const Title = styled(Typography).attrs({ variant: 'title' })`
    line-height: ${({ theme }) => theme.typography.bigTitle.fontSize}px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.subheading.fontSize}px;
    }
`;

interface IFilterButton {
    isActive: boolean;
}

export const FilterButton = styled.button<IFilterButton>`
    border: none;
    padding: 5px;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.base() : theme.colors.grey())};
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    &:hover {
        color: ${({ theme }) => theme.colors.grey()};
    }
    @media ${({ theme }) => theme.breakpoints.xl} {
        grid-template-columns: 5% 90% 5%;
        padding: 0 10px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        justify-content: left;
        padding: 5px 7px 5px 0;
    }
`;

export const HeaderLeft = styled.div``;

export const HeaderRight = styled.div``;

export const TopFinderContainer = styled.div`
    width: 100%;
    position: relative;
`;

export const TopFinder = styled(Input)`
    padding: 0;
    min-height: 46px;
    width: 100%;
    border-radius: 12px;
    outline: none;
    &::placeholder {
        color: ${({ theme }) => theme.colors.grey()};
    }
`;

export const SearchContainer = styled.button`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
`;

export const CreateOrderButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-left: 5px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-row-start: 1;
    }
`;

export const OrderButton = styled(NavLink)`
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    min-height: 46px;
    border: 1px solid ${({ theme }) => theme.colors.yellow()};
    background-color: ${({ theme }) => theme.colors.yellow()};
    border-radius: 10px;
    padding: 0 20px;
    ${({ theme }) => theme.mixins.flexCenter}
    text-decoration: none;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
        ${({ theme }) => theme.mixins.flexCenterCenter};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.hoverYellow()};
    }

    &:active,
    &:focus {
        background-color: ${({ theme }) => theme.colors.pressedYellow()};
    }
`;

export const AddIcon = styled(addIcon)`
    margin-bottom: -2px;
    margin-right: 10px;
    flex-shrink: 0;
`;
