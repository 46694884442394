import React, { FC } from 'react';
import { convertDateAndTimeToShow, formatCurrencyToShow } from 'src/utils/helpers';
import { IOrderContainer, ORDER_FIELDS, OrderContainerStatuses, TOrder } from 'src/store/Order/types';
import {
    TotalValues,
    TotalValuesBlock,
    TotalWrapper,
} from 'src/pages/businessOffice/OrderContainer/components/OrderTotal/styles';
import { getOrderContainerStatuses } from 'src/store/Order/constants';
import { StatusWrapper } from 'src/pages/businessOffice/OrderContainer/components/StatusOrder/styles';
import TotalRow from 'src/pages/businessOffice/OrderContainer/components/TotalRow';
import { ButtonPay } from 'src/pages/businessOffice/Payment/styles';
import QRCode from 'src/pages/businessOffice/OrderContainer/components/QRCodeBlock';
import Skeleton from 'src/components/Skeleton';
import { useIntl } from 'react-intl';

interface ITotalPayment {
    orderContainer: IOrderContainer;
    order: TOrder;
}

const TotalPayment: FC<ITotalPayment> = ({ orderContainer, order }) => {
    const intl = useIntl();
    const currentStatus = getOrderContainerStatuses(intl).find((status) => status.state === orderContainer?.status);
    const currentStatusLabel = currentStatus?.label;
    const isCanPay = currentStatus?.state === OrderContainerStatuses.pending;
    const paymentURL = order?.paymentUrl;

    const orderTotalValues = [
        {
            label: intl.formatMessage({ defaultMessage: 'Сумма' }),
            value: orderContainer?.originalAmount && formatCurrencyToShow(orderContainer.originalAmount),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'НДС' }),
            value:
                (orderContainer?.originalVatAmount || orderContainer?.originalVatAmount === 0) &&
                formatCurrencyToShow(orderContainer.originalVatAmount),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Оформлен' }),
            value: order?.[ORDER_FIELDS.createdAt] && convertDateAndTimeToShow(order[ORDER_FIELDS.createdAt]),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Оплачен' }),
            value: order?.[ORDER_FIELDS.expirationDate] && convertDateAndTimeToShow(order[ORDER_FIELDS.expirationDate]),
        },
    ];

    return (
        <TotalWrapper>
            <TotalValuesBlock>
                <StatusWrapper>
                    {intl.formatMessage({ defaultMessage: 'Статус' })}{' '}
                    <div>{currentStatusLabel || <Skeleton width={80} />} </div>
                </StatusWrapper>
                <TotalValues>
                    {orderTotalValues.map((orderTotalValue) => (
                        <TotalRow
                            isShowSkeleton={!orderTotalValue.value}
                            key={orderTotalValue.label}
                            label={orderTotalValue.label}
                            value={String(orderTotalValue.value)}
                        />
                    ))}
                </TotalValues>
                {isCanPay && (
                    <ButtonPay
                        onClick={() => {
                            window.open(paymentURL);
                        }}
                    >
                        {intl.formatMessage({ defaultMessage: 'Оплатить' })}
                    </ButtonPay>
                )}
            </TotalValuesBlock>
            <QRCode paymentURL={paymentURL} />
        </TotalWrapper>
    );
};

export default TotalPayment;
