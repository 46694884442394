import React from 'react';
import {
    ButtonAddCounterparty,
    CounterpartiesList,
    CounterpartyHeader,
    CounterpartyWrapper,
    LabelAddCounterparty,
} from 'src/pages/businessOffice/Profile/ProfileTabs/Organizations/styles';
import { ReactComponent as IconPlus } from 'src/assets/icons/plus.svg';
import RowCounterparty from 'src/pages/businessOffice/Profile/ProfileTabs/Organizations/components/RowCounterparty';
import { getAllCounterparties } from 'src/store/Counterparty/selectors';
import { useSelector } from 'react-redux';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import Modal from 'src/components/Modal';
import CompanyModalInner from 'src/pages/businessOffice/Introduce/components/CompanyModalInner';
import useModal from 'src/hooks/useModal';
import { useIntl } from 'react-intl';

const Organizations = () => {
    const intl = useIntl();
    const counterparties = useSelector(getAllCounterparties);
    const { isOpen, open, close } = useModal(false);

    return (
        <CounterpartyWrapper>
            <CounterpartyHeader>
                {intl.formatMessage({ defaultMessage: 'Мои организации' })}
                <ButtonAddCounterparty type="button" onClick={open}>
                    <IconPlus />
                    <LabelAddCounterparty variant="regular14">
                        {intl.formatMessage({ defaultMessage: 'Добавить организацию' })}
                    </LabelAddCounterparty>
                </ButtonAddCounterparty>
            </CounterpartyHeader>
            <CounterpartiesList>
                {counterparties.map((counterparty) => (
                    <RowCounterparty key={counterparty[COUNTERPARTY_FIELDS.id]} counterparty={counterparty} />
                ))}
            </CounterpartiesList>
            <Modal
                title={intl.formatMessage({ defaultMessage: 'Добавить организацию' })}
                isOpen={isOpen}
                closeModal={close}
            >
                <CompanyModalInner onClose={close} />
            </Modal>
        </CounterpartyWrapper>
    );
};

export default Organizations;
