import React, { FC } from 'react';
import {
    CounterpartyNavLink,
    IconWrapper,
    Phone,
    Title,
    VatNumber,
    Email,
} from 'src/pages/businessOffice/Profile/ProfileTabs/Organizations/components/RowCounterparty/styles';
import { ICounterparty } from 'src/store/Counterparty/types';
import { ReactComponent as IconWork } from 'src/assets/icons/work.svg';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { convertPhoneToShow } from 'src/utils/formatters';
import { useIntl } from 'react-intl';

interface IRowCounterparty {
    counterparty: ICounterparty;
}

const RowCounterparty: FC<IRowCounterparty> = ({ counterparty }) => {
    const intl = useIntl();

    return (
        <CounterpartyNavLink to={`${RoutesBusinessOffice.counterparty}/${counterparty[COUNTERPARTY_FIELDS.id]}`}>
            <Title>
                <IconWrapper>
                    <IconWork />
                </IconWrapper>
                {counterparty[COUNTERPARTY_FIELDS.name]}
            </Title>
            {counterparty[COUNTERPARTY_FIELDS.phone] && (
                <Phone variant="regular14">{convertPhoneToShow(counterparty[COUNTERPARTY_FIELDS.phone])}</Phone>
            )}
            {counterparty[COUNTERPARTY_FIELDS.email] && (
                <Email variant="regular14">{counterparty[COUNTERPARTY_FIELDS.email]}</Email>
            )}
            <VatNumber variant="regular14">{`${intl.formatMessage({ defaultMessage: 'ИНН' })}: ${
                counterparty[COUNTERPARTY_FIELDS.vatNumber]
            }`}</VatNumber>
        </CounterpartyNavLink>
    );
};

export default RowCounterparty;
