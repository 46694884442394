import React, { FC, useState } from 'react';
import {
    BackgroundCircleBase,
    BackgroundCircleYellow,
    ButtonNext,
    ButtonPrev,
    ButtonsSlider,
    SliderContent,
} from 'src/pages/businessOffice/Claim/components/DesktopSlider/styles';
import DesktopSlide from 'src/pages/businessOffice/Claim/components/DesktopSlide';
import { ReactComponent as IconButtonLeft } from '../../assets/buttonSliderLeft.svg';

export interface ISliderContent {
    frame: number;
    title: string;
    paragraphs: string[];
}

interface IDesktopSlider {
    slides: ISliderContent[];
}

const DesktopSlider: FC<IDesktopSlider> = ({ slides }) => {
    const [activeFrame, setActiveFrame] = useState(1);

    return (
        <SliderContent>
            {slides.map((slide) => (
                <DesktopSlide
                    setActiveFrame={setActiveFrame}
                    activeSlide={activeFrame}
                    slide={slide}
                    key={slide.frame}
                />
            ))}
            <ButtonsSlider>
                <ButtonPrev
                    type="button"
                    onClick={() => {
                        setActiveFrame(activeFrame === 1 ? 4 : activeFrame - 1);
                    }}
                >
                    <IconButtonLeft />
                </ButtonPrev>
                <ButtonNext
                    type="button"
                    onClick={() => {
                        setActiveFrame(activeFrame === 4 ? 1 : activeFrame + 1);
                    }}
                >
                    <IconButtonLeft />
                </ButtonNext>
            </ButtonsSlider>
            <BackgroundCircleYellow frame={activeFrame} />
            <BackgroundCircleBase frame={activeFrame} />
        </SliderContent>
    );
};

export default DesktopSlider;
