import React, { FC, ReactNode } from 'react';
import Tooltip from 'src/components/Tooltip';
import CircleIcon from 'src/components/CircleIcon';
import { ClickableItemLink, ClickableItem, Label, Title } from '../styles';
import { ReactComponent as ErrorIcon } from '../assets/error-icon.svg';

export interface IItemProps {
    icon: ReactNode;
    title: string;
    isActive?: boolean;
    label?: string;
    errorTooltipContent?: ReactNode;
    to: string;
    disabled?: boolean;
}

const Item: FC<IItemProps> = ({ to, icon, isActive = false, label, title, errorTooltipContent, disabled = false }) => {
    const renderContentItem = () => (
        <>
            <CircleIcon isActive={isActive} icon={icon} />
            <Title variant="subheading">{title}</Title>
            {!errorTooltipContent && label && <Label variant="regular14">{label}</Label>}

            {errorTooltipContent && (
                <Tooltip content={errorTooltipContent} interactive placement="right">
                    <ErrorIcon />
                </Tooltip>
            )}
        </>
    );

    if (disabled) {
        return <ClickableItem>{renderContentItem()}</ClickableItem>;
    }

    return <ClickableItemLink to={to}>{renderContentItem()}</ClickableItemLink>;
};

export default Item;
