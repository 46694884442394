import React, { FC, ReactNode } from 'react';
import { ColumnsHeader } from '../ColumnsHeader';
import { THeadLabel, TRow } from '../constants';
import { TableRow } from '../TableRow';
import * as S from './styles';
import { NotFoundTableRow } from '../NotFoundTableRow';
import { getColSpanFromHeadLabels } from '../getColSpanFromHeadLabels';

export type TProps = {
    headLabels: Array<THeadLabel>;
    rows: Array<TRow>;
    isLoading: boolean;
    isInitialized: boolean;
    selectedRowIds: Array<string>;
    onSelectRows: (ids: Array<string>) => void;
    children: ReactNode;
};

export const Table: FC<TProps> = ({
    headLabels,
    rows,
    isLoading,
    selectedRowIds,
    onSelectRows,
    isInitialized,
    children,
}) => {
    const isSelectedAllRows =
        !!rows.length && rows.every((rowItem) => selectedRowIds.includes(rowItem.id)) && !!selectedRowIds.length;
    const rowsIds = rows.map((rowItem) => rowItem.id);

    const handleSelectAllRows = () => {
        if (rows.length) {
            onSelectRows(
                isSelectedAllRows
                    ? selectedRowIds.filter((selectedRowId) => !rowsIds.includes(selectedRowId))
                    : [...selectedRowIds, ...rowsIds.filter((rowItem) => !selectedRowIds.includes(rowItem))],
            );
        }
    };

    const handleSelectRow = (id: string) =>
        onSelectRows(
            selectedRowIds.includes(id)
                ? [...selectedRowIds.filter((selectedId) => selectedId !== id)]
                : [...selectedRowIds, id],
        );

    return (
        <S.TableWrapper $isLoading={isLoading}>
            <ColumnsHeader
                headLabels={headLabels}
                isSelectedAllRows={isSelectedAllRows}
                onSelectAllRows={handleSelectAllRows}
            />
            <tbody>
                {rows.length
                    ? rows.map((row, index, array) => (
                          <TableRow
                              key={row.id}
                              row={row}
                              headLabels={headLabels}
                              isShowBorderBottom={index !== array.length - 1}
                              onSelectRow={handleSelectRow}
                              isSelected={selectedRowIds.includes(row.id)}
                          >
                              <div>Children</div>
                          </TableRow>
                      ))
                    : isInitialized && <NotFoundTableRow colSpan={getColSpanFromHeadLabels(headLabels)} />}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={getColSpanFromHeadLabels(headLabels)}>{children}</td>
                </tr>
            </tfoot>
        </S.TableWrapper>
    );
};
