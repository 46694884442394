import { CLIENT_COUNTERPARTY_FIELDS } from 'src/store/ClientCounterparty/constants';
import http, { ICommonResponse } from '../../utils/http';
import { IClientCounterparty, TCounterpartyClientRequestFields } from './types';

export const createClientCounterpartyRequest = (
    data: Pick<
        IClientCounterparty,
        | CLIENT_COUNTERPARTY_FIELDS.vatNumber
        | CLIENT_COUNTERPARTY_FIELDS.name
        | CLIENT_COUNTERPARTY_FIELDS.registrationAddress
        | CLIENT_COUNTERPARTY_FIELDS.ownerCounterpartyId
    >,
) => http.post<ICommonResponse<IClientCounterparty>>('billing/api/counterparty/contact-counterparty', data);

export const getClientCounterpartyRequest = () =>
    http.get<ICommonResponse<IClientCounterparty[]>>('filter/api/counterparty/contact-counterparty');

export const updateClientCounterpartyRequest = async (body: TCounterpartyClientRequestFields) =>
    http.put<ICommonResponse<IClientCounterparty>>(`billing/api/counterparty/contact-counterparty/${body.id}`, body);

export const removeClientCounterpartyRequest = (id: string) =>
    http.delete(`billing/api/counterparty/contact-counterparty/${id}`);
