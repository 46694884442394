import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        gap: 12px;
    }
`;
