import React, { ChangeEvent, useState, FocusEvent, ClipboardEvent, KeyboardEvent, FC, useEffect } from 'react';
import * as S from './styles';
import { CodeInputItem } from './styles';
import { ONLY_NUMBERS_REGEX } from '../../../utils/constants';

export type TProps = {
    onChange: (value: string) => void;
    codeLength: number;
    hasError?: boolean;
};

export const CodeInput: FC<TProps> = ({ hasError, onChange, codeLength }) => {
    const getCodeArrFromCodeString = (value = '') =>
        [...value.split(''), ...Array(codeLength).fill('')].slice(0, codeLength);

    const [codeArr, setCodeArr] = useState(getCodeArrFromCodeString(''));

    useEffect(() => {
        onChange(codeArr.join(''));
    }, [codeArr, onChange]);

    const setValue = (newValue: string, index: number) => {
        setCodeArr((prev) => {
            const newArr = [...prev];
            newArr[index] = newValue;
            return newArr;
        });
    };

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const index = Number(event.target.dataset.index);
        const inputValue = event.target.value;

        if (ONLY_NUMBERS_REGEX.test(inputValue)) {
            setValue(inputValue.length > 1 ? inputValue.replace(codeArr[index], '') : inputValue, index);
            if (event.target.nextSibling) {
                (event.target.nextSibling as HTMLInputElement).focus();
            }
        }
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Backspace') {
            event.preventDefault();
            const target = event.target as HTMLInputElement;
            const index = Number(target.dataset.index);

            if (target.value) {
                setValue('', index);
            } else if (target.previousSibling) {
                setValue('', index - 1);
                (target.previousSibling as HTMLInputElement).focus();
            }
        }
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        const currentInput = event.target as HTMLInputElement;
        const prevInput = currentInput.previousSibling as HTMLInputElement;

        if (prevInput && prevInput.value === '') {
            prevInput.focus();
        }
    };

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        const pasteText = event.clipboardData.getData('Text').trim();

        if (ONLY_NUMBERS_REGEX.test(pasteText)) {
            const newValue = getCodeArrFromCodeString(pasteText);
            setCodeArr(newValue);
        }
    };

    return (
        <S.Wrapper>
            {codeArr.map((codeItem, index) => (
                <CodeInputItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    data-index={String(index)}
                    onChange={changeHandler}
                    value={codeItem}
                    inputMode="numeric"
                    onKeyDown={onKeyDown}
                    onPaste={handlePaste}
                    onFocus={handleFocus}
                    placeholder="-"
                    $hasError={hasError}
                />
            ))}
        </S.Wrapper>
    );
};
