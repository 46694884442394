import { useEffect } from 'react';
import packageJson from '../../package.json';

export const useConsoleLogAppVersion = () => {
    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(packageJson.version);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
