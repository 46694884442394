import styled from 'styled-components';
import Typography from 'src/components/Typography';
import { NavLink } from 'react-router-dom';

export const CounterpartyNavLink = styled(NavLink)`
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    border-top: none;
    padding: 20px 30px;
    display: grid;
    grid-template-columns: 1fr 120px 140px;
    grid-column-gap: 60px;
    align-items: center;
    text-decoration: none;

    &:first-child {
        border-top: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    }

    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 20px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 0;
    }
`;

export const Title = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
`;

export const IconWrapper = styled.div`
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.lightGrey()};
    width: 36px;
    height: 36px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-shrink: 0;
    
    svg {
        width: 16px;
        heightL 16px;
    }
    
    @media ${({ theme }) => theme.breakpoints.md} {
    width: 30px;
    height: 30px;
        svg {
        width: 13px;
        heightL 13px;
    }
}
`;

export const VatNumber = styled(Typography)`
    grid-column-start: 3;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-start: 2;
        grid-column-start: 1;
        margin-top: 16px;
    }
`;

export const Phone = styled(Typography)`
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 8px;
    }
`;

export const Email = styled(Typography)`
    display: none;
    @media ${({ theme }) => theme.breakpoints.md} {
        display: block;
        grid-row-start: 3;
        margin-top: 8px;
    }
`;
