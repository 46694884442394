import React, { FC } from 'react';
import { ORDER_ITEM_FIELDS, TOrderItem, TVatType } from 'src/store/Order/types';
import { formatCurrencyToShow } from 'src/utils/helpers';
import { ReactComponent as IconEdit } from 'src/assets/icons/edit.svg';
import { ReactComponent as IconDelete } from 'src/assets/icons/delete.svg';
import ModalAddItemOrder from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/ModalAddItemOrder';
import useModal from 'src/hooks/useModal';
import { IMeasure } from 'src/store/Merchant/types';
import Skeleton from 'src/components/Skeleton';
import {
    ButtonEdit,
    ButtonRemove,
    ButtonsContainer,
    ItemDetail,
    ItemName,
    ItemOrderWrapper,
    ItemPrice,
} from './styles';

interface IItemOrder {
    basketItem: TOrderItem;
    isEdit: boolean;
    removeItem: (itemOrder: TOrderItem) => void;
    editItem: (itemOrder: TOrderItem) => void;
    vatTypes: TVatType[] | undefined;
    measures: IMeasure[] | undefined;
    isShowSkeleton?: boolean;
}

const ItemOrder: FC<IItemOrder> = ({
    isShowSkeleton = false,
    measures,
    basketItem,
    isEdit,
    removeItem,
    vatTypes,
    editItem,
}) => {
    const { isOpen: addItemOrderModalVisible, open: openModal, close: closeModal } = useModal();
    const currentMeasure =
        measures && measures.find((measure) => measure?.id === basketItem[ORDER_ITEM_FIELDS.measureCode]);

    return (
        <ItemOrderWrapper>
            <ItemName variant="regular14">
                {isShowSkeleton ? <Skeleton width={60} /> : basketItem[ORDER_ITEM_FIELDS.name]}
            </ItemName>
            <ItemDetail>
                <ItemPrice variant="regular14">
                    {isShowSkeleton ? (
                        <Skeleton width={120} />
                    ) : (
                        `${basketItem[ORDER_ITEM_FIELDS.quantity]} ${currentMeasure?.name} x ${formatCurrencyToShow(
                            basketItem?.[ORDER_ITEM_FIELDS.amount],
                        )}`
                    )}
                </ItemPrice>
                {isEdit && (
                    <ButtonsContainer>
                        <ButtonEdit type="button" onClick={openModal}>
                            <IconEdit />
                        </ButtonEdit>
                        <ButtonRemove
                            type="button"
                            onClick={() => {
                                removeItem(basketItem);
                            }}
                        >
                            <IconDelete />
                        </ButtonRemove>
                    </ButtonsContainer>
                )}
            </ItemDetail>
            <ModalAddItemOrder
                measures={measures}
                vatTypes={vatTypes}
                addOrEditItemOrder={editItem}
                closeModal={closeModal}
                isOpen={addItemOrderModalVisible}
                itemOrder={basketItem}
            />
        </ItemOrderWrapper>
    );
};

export default ItemOrder;
