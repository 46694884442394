import React, { FC } from 'react';
import Slider from 'react-slick';
import MobileSlide from 'src/pages/businessOffice/Claim/components/MobileSlide';
import {
    MobileSliderWrapper,
    Pagination,
    PaginationWrapper,
} from 'src/pages/businessOffice/Claim/components/MobileSlider/styles';
import { ISliderContent } from 'src/pages/businessOffice/Claim/components/DesktopSlider';
// eslint-disable-next-line import/no-unresolved
import 'src/globalStyles/sliderStyles/slick.css';
// eslint-disable-next-line import/no-unresolved
import 'src/globalStyles/sliderStyles/slick-theme.css';

interface IMobileSlider {
    slides: ISliderContent[];
}

const MobileSlider: FC<IMobileSlider> = ({ slides }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // eslint-disable-next-line react/no-unstable-nested-components
        customPaging: () => (
            <PaginationWrapper>
                <Pagination />
            </PaginationWrapper>
        ),
    };
    return (
        <MobileSliderWrapper>
            <Slider {...settings}>
                {slides.map((slide) => (
                    <MobileSlide key={slide.frame} title={slide.title} paragraphs={slide.paragraphs} />
                ))}
            </Slider>
        </MobileSliderWrapper>
    );
};

export default MobileSlider;
