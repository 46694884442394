import React, { FC } from 'react';
import { ICounterparty } from 'src/store/Counterparty/types';
import { Form } from 'react-final-form';
import { InputsWrapper } from 'src/pages/businessOffice/Counterparty/components/FormGeneral/styles';
import FormField from 'src/components/FormField';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { useAppDispatch } from 'src/store';
import useValidationSchema from 'src/hooks/useValidationSchema';
import { contactSchema } from 'src/pages/businessOffice/Counterparty/validations';
import { updateCounterparty } from 'src/store/Counterparty';
import { removePlusAndGapsToPhone } from 'src/utils/formatters';
import { useIntl } from 'react-intl';

interface IContactsForm {
    counterparty: ICounterparty;
    formId: string;
    setIsEditSettings: (arg: boolean) => void;
    toggleSaving: () => void;
}

const ContactsForm: FC<IContactsForm> = ({ toggleSaving, counterparty, formId, setIsEditSettings }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const validation = useValidationSchema(contactSchema);
    const onSubmit = async (formValues: ICounterparty) => {
        toggleSaving();
        const result = await dispatch(
            updateCounterparty({
                ...formValues,
                [COUNTERPARTY_FIELDS.phone]: formValues[COUNTERPARTY_FIELDS.phone]
                    ? removePlusAndGapsToPhone(formValues[COUNTERPARTY_FIELDS.phone])
                    : '',
                [COUNTERPARTY_FIELDS.email]: formValues[COUNTERPARTY_FIELDS.email] || '',
                [COUNTERPARTY_FIELDS.website]: formValues[COUNTERPARTY_FIELDS.website] || '',
            }),
        );
        // @ts-ignore
        if (!result?.error && result?.payload) {
            setIsEditSettings(false);
        }
        toggleSaving();
    };
    return (
        <Form onSubmit={onSubmit} initialValues={counterparty} validate={validation}>
            {({ handleSubmit }) => (
                <InputsWrapper onSubmit={handleSubmit} id={formId}>
                    <FormField
                        name={COUNTERPARTY_FIELDS.email}
                        label="Email"
                        placeholder={intl.formatMessage({ defaultMessage: 'Электронная почта' })}
                    />
                    <FormField
                        type="tel"
                        label={intl.formatMessage({ defaultMessage: 'Телефон' })}
                        name={COUNTERPARTY_FIELDS.phone}
                        mask="+7 999 999 99 99"
                        placeholder="+7 XXX XXX XX XX"
                    />
                    <FormField
                        name={COUNTERPARTY_FIELDS.website}
                        label={intl.formatMessage({ defaultMessage: 'Сайт' })}
                    />
                    <FormField
                        name={COUNTERPARTY_FIELDS.ceoName}
                        label={intl.formatMessage({ defaultMessage: 'Ген.директор' })}
                    />
                    <FormField
                        name={COUNTERPARTY_FIELDS.cfoName}
                        label={intl.formatMessage({ defaultMessage: 'Главный бухгалтер' })}
                    />
                </InputsWrapper>
            )}
        </Form>
    );
};

export default ContactsForm;
