import React, { useEffect, useState } from 'react';
import { ReactComponent as IconNotification } from 'src/assets/icons/notification.svg';
import { ReactComponent as IconNewNotification } from 'src/assets/icons/notificationNew.svg';
import { useAppDispatch } from 'src/store';
import { fetchOneFeed, subscribeNotifications } from 'src/store/Feed';
import { getNoun } from 'src/utils/helpers';
import { IFeedItem } from 'src/store/Feed/types';
import Toast from 'src/components/Toast';
import useToast from 'src/hooks/useToast';
import useModal from 'src/hooks/useModal';
import ModalFeed from 'src/components/Widgets/FeedWidget/components/ModalFeed';
import { useHistory } from 'react-router-dom';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { typesFeedOpenOrderContainer } from 'src/store/Feed/constants';
import NotificationWindow from 'src/components/Header/components/NotificationWindow';
import { useIntl } from 'react-intl';
import { IconWrapper, NotificationWrapper } from './styles';
import { isSupportedFirebase, onMessageListener } from '../../../../firebaseInit';

const NotificationMenuItem = () => {
    const intl = useIntl();
    const history = useHistory();
    const { open: openToast, isShow: isShowToast, setIsShow: setIsShowToast } = useToast();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);
    const dispatch = useAppDispatch();

    const [newFeedsNotification, setNewFeedsNotification] = useState<IFeedItem[]>([]);
    const [feedContent, setFeedContent] = useState('');

    const [feedsIconNotification, setFeedsIconNotification] = useState<IFeedItem[]>([]);
    const [isShowWindowNotifications, setIsShowWindowNotifications] = useState(false);

    useEffect(() => {
        if (isSupportedFirebase) {
            dispatch(subscribeNotifications());
        }
    }, [dispatch]);

    // отображение фидов в открытом приложении каждые 30 секунд
    useEffect(() => {
        if (isSupportedFirebase) {
            const queueFeedsInterval = setInterval(() => {
                if (newFeedsNotification.length > 1) {
                    setFeedContent(
                        `${intl.formatMessage({ defaultMessage: 'У вас' })} ${newFeedsNotification.length} ${getNoun(
                            newFeedsNotification.length,
                            intl.formatMessage({ defaultMessage: 'новое уведомление' }),
                            intl.formatMessage({ defaultMessage: 'новых уведомления' }),
                            intl.formatMessage({ defaultMessage: 'новых уведомлений' }),
                        )}`,
                    );
                    setFeedsIconNotification(newFeedsNotification);
                    openToast();
                }
                if (newFeedsNotification.length === 1) {
                    setFeedContent(newFeedsNotification[0]?.description);
                    openToast();
                    setFeedsIconNotification(newFeedsNotification);
                }
            }, 30000);

            return () => {
                clearInterval(queueFeedsInterval);
            };
        }
    }, [dispatch, intl, newFeedsNotification, openToast]);

    // слушатель на пуши при открытом приложении
    if (isSupportedFirebase) {
        onMessageListener().then((payload) => {
            dispatch(fetchOneFeed(payload.data.feedItemId)).then((data) =>
                // @ts-ignore
                setNewFeedsNotification([...newFeedsNotification, data?.payload[0] as IFeedItem]),
            );
        });
    }

    const onClickToast = () => {
        if (newFeedsNotification.length > 1) {
            history.push(RoutesBusinessOffice.feeds);
        }
        if (newFeedsNotification.length === 1) {
            const newFeed = newFeedsNotification[0];
            if (typesFeedOpenOrderContainer.find((type) => type === newFeed.type) && newFeed?.metadata?.targetId) {
                history.push(`${RoutesBusinessOffice.ordercontainer}/${newFeed.metadata.targetId}`, {
                    isOrderContainer: true,
                });
            } else {
                openModal();
            }
        }
    };

    const onClickIconNotifications = () => {
        if (feedsIconNotification.length > 0) {
            setIsShowWindowNotifications(true);
            setFeedsIconNotification([]);
        } else {
            history.push(RoutesBusinessOffice.feeds);
        }
    };

    return (
        <>
            <NotificationWrapper>
                <IconWrapper type="button" onClick={onClickIconNotifications}>
                    {feedsIconNotification.length === 0 ? <IconNotification /> : <IconNewNotification />}
                </IconWrapper>
                <NotificationWindow
                    isShowWindowNotifications={isShowWindowNotifications}
                    setIsShowWindowNotifications={setIsShowWindowNotifications}
                    feeds={feedsIconNotification}
                />
            </NotificationWrapper>
            <Toast
                onClick={onClickToast}
                isShowToast={isShowToast}
                setIsShowToast={setIsShowToast}
                title={feedContent}
                funcAfterClose={() => setNewFeedsNotification([])}
            />
            <ModalFeed onClose={onCloseModal} isOpen={isOpenModal} feed={newFeedsNotification[0]} />
        </>
    );
};

export default NotificationMenuItem;
