import React, { FC, useState } from 'react';
import { Arrow, Dropdown, Typography, useOutsideClick } from '@invoicebox/ui';
import * as S from './styles';

export type TProps = {
    options: Array<number>;
    value: number;
    onChange: (value: number) => void;
};

export const SwitcherCountRowsOnPage: FC<TProps> = ({ value, options, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => setIsOpen(false);
    const toggle = () => setIsOpen((prev) => !prev);

    const wrapperRef = useOutsideClick(handleClose);

    const handleChange = (newValue: number) => {
        onChange(newValue);
        handleClose();
    };

    return (
        <S.Wrapper ref={wrapperRef}>
            <S.Content type="button" onClick={toggle}>
                <Typography variant="bodyMRegular">{value}</Typography>
                <Arrow isOpen={isOpen} outterSize={16} />
            </S.Content>
            <Dropdown isOpen={isOpen} isAutoPosition>
                {options.map((option) => (
                    <S.OptionWrapper key={option} type="button" onClick={() => handleChange(option)}>
                        <Typography variant="bodyMRegular"> {option} </Typography>
                    </S.OptionWrapper>
                ))}
            </Dropdown>
        </S.Wrapper>
    );
};
