import styled from 'styled-components';
import Skeleton from 'src/components/Skeleton';

export const SkeletonOrderWrapper = styled.div`
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 13px minmax(35%, 60%) 70px 70px;
    padding: 4px 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 13px minmax(20%, 40%) 1fr;
    }
`;

export const SkeletonIcon = styled(Skeleton)`
    height: 15px;
`;

export const Description = styled(Skeleton)`
    height: 16px;
`;

export const WrapperPriceDate = styled.div`
    display: contents;
    @media ${({ theme }) => theme.breakpoints.md} {
        display: grid;
        grid-template-columns: 70px 70px;
        grid-column-gap: 10px;
        justify-content: flex-end;
    }
`;
