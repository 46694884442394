import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const Title = styled(Typography)`
    margin: 40px 0 54px 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin: 40px 0 23px 0;
        ${({ theme }) => theme.mixins.getTypography('title')}
    }
`;

export const SettingsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-gap: 20px;
    }
`;
