import React from 'react';
import SettingsWidget from 'src/components/Widgets/SettingsWidget';
import { useSelector } from 'react-redux';
import { getUser } from 'src/store/Auth/selectors';
import { IUserProfile } from 'src/store/Auth/types';
import ContactInformation from 'src/pages/businessOffice/Profile/ProfileTabs/Personal/components/ContactInformation';
import { useIntl } from 'react-intl';

export const FORM_USER_PERSONAL = 'form_user_personal';

const Personal = () => {
    const intl = useIntl();
    const user: IUserProfile = useSelector(getUser) as any;

    return (
        <SettingsWidget
            title={intl.formatMessage({ defaultMessage: 'Контактная информация' })}
            formId={FORM_USER_PERSONAL}
        >
            <ContactInformation user={user} />
        </SettingsWidget>
    );
};

export default Personal;
