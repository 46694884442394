import React, { FC } from 'react';
import { ReactComponent as VectorLeft } from 'src/assets/icons/vectorLeft.svg';
import { ReactComponent as VectorRight } from 'src/assets/icons/vectorRight.svg';
import * as S from './styles';

export type TProps = {
    isOpen: boolean;
    value: Date[] | null;
    onChange: (value: Date[] | null) => void;
    onClose: () => void;
};

export const DateRangePicker: FC<TProps> = ({ isOpen, value, onChange, onClose }) => (
    <S.DateRangeCustom
        isOpen={isOpen}
        onCalendarClose={onClose}
        clearIcon={null}
        calendarIcon={null}
        onChange={onChange}
        value={value}
        nextLabel={<VectorRight />}
        prevLabel={<VectorLeft />}
    />
);
