import { TState } from './types';
import { assert } from '../../utils/assert';
import { convertDateAndTimeToShow } from '../../utils/helpers';
import { Currency } from '../Currency/entity';

export class OrderContainer {
    readonly id: string;
    private readonly merchantId: string;
    private readonly originalCurrencyId: string;
    private readonly originalAmount: number;
    private readonly originalVatAmount: number;
    private readonly expirationDate: string;
    private readonly status: string;
    private readonly returnUrl?: string;
    private readonly successUrl?: string;
    private readonly failUrl?: string;
    private readonly paymentUrl: string;
    private readonly payerUserId?: string;
    private readonly payerCounterpartyId?: string;
    readonly merchantOrderIdVisible?: string;
    private readonly createdAt: string;

    private readonly currency: Currency;

    private constructor(state: TState) {
        const {
            id,
            merchantId,
            merchantOrderIdVisible,
            payerCounterpartyId,
            createdAt,
            payerUserId,
            originalCurrencyId,
            originalAmount,
            originalVatAmount,
            paymentUrl,
            failUrl,
            returnUrl,
            successUrl,
            status,
            expirationDate,
            currency,
        } = state;

        this.id = id;
        this.merchantId = merchantId;
        this.originalCurrencyId = originalCurrencyId;
        this.originalAmount = originalAmount;
        this.originalVatAmount = originalVatAmount;
        this.expirationDate = expirationDate;
        this.status = status;
        this.returnUrl = returnUrl;
        this.successUrl = successUrl;
        this.failUrl = failUrl;
        this.paymentUrl = paymentUrl;
        this.payerUserId = payerUserId;
        this.payerCounterpartyId = payerCounterpartyId;
        this.merchantOrderIdVisible = merchantOrderIdVisible;
        this.createdAt = createdAt;
        this.currency = currency;
    }

    static fromResponse(response: unknown): OrderContainer {
        assert(typeof response === 'object' && response !== null);

        assert('id' in response);
        const { id } = response;
        assert(typeof id === 'string');
        assert(!!id.length);

        assert('merchantId' in response);
        const { merchantId } = response;
        assert(typeof merchantId === 'string');
        assert(!!merchantId.length);

        assert('originalCurrencyId' in response);
        const { originalCurrencyId } = response;
        assert(typeof originalCurrencyId === 'string');
        assert(!!originalCurrencyId.length);
        assert(originalCurrencyId.length <= 3);

        assert('originalAmount' in response);
        const { originalAmount } = response;
        assert(typeof originalAmount === 'number');
        assert(originalAmount > 0);

        assert('originalVatAmount' in response);
        const { originalVatAmount } = response;
        assert(typeof originalVatAmount === 'number');
        assert(originalAmount >= 0);

        assert('expirationDate' in response);
        const { expirationDate } = response;
        assert(typeof expirationDate === 'string');
        assert(!!expirationDate.length);

        assert('status' in response);
        const { status } = response;
        assert(typeof status === 'string');
        assert(!!status.length);

        let returnUrl;
        if ('returnUrl' in response) {
            returnUrl = response.returnUrl;
            assert(typeof returnUrl === 'string');
        }

        let successUrl;
        if ('successUrl' in response) {
            successUrl = response.successUrl;
            assert(typeof successUrl === 'string');
        }

        let failUrl;
        if ('failUrl' in response) {
            failUrl = response.failUrl;
            assert(typeof failUrl === 'string');
        }

        assert('paymentUrl' in response);
        const { paymentUrl } = response;
        assert(typeof paymentUrl === 'string');
        assert(!!paymentUrl.length);

        let payerUserId;
        if ('payerUserId' in response) {
            payerUserId = response.payerUserId;
            assert(typeof payerUserId === 'string');
        }

        let payerCounterpartyId;
        if ('payerCounterpartyId' in response) {
            payerCounterpartyId = response.payerCounterpartyId;
            assert(typeof payerCounterpartyId === 'string');
        }

        let merchantOrderIdVisible;
        if ('merchantOrderIdVisible' in response) {
            merchantOrderIdVisible = response.merchantOrderIdVisible;
            assert(typeof merchantOrderIdVisible === 'string');
        }

        assert('createdAt' in response);
        const { createdAt } = response;
        assert(typeof createdAt === 'string');
        assert(!!createdAt.length);

        assert('currency' in response);
        const currency = Currency.fromResponse(response.currency);

        const checkedResponse = {
            id,
            merchantId,
            merchantOrderIdVisible,
            payerCounterpartyId,
            createdAt,
            payerUserId,
            originalCurrencyId,
            originalAmount,
            originalVatAmount,
            paymentUrl,
            failUrl,
            returnUrl,
            successUrl,
            status,
            expirationDate,
            currency,
        } satisfies TState;

        return new OrderContainer(checkedResponse);
    }

    static fromMock(): OrderContainer {
        return new OrderContainer({
            id: 'id',
            merchantId: 'merchantId',
            merchantOrderIdVisible: '112233',
            payerCounterpartyId: 'payerCounterpartyId',
            createdAt: '2024-04-13T16:05:16+00:00',
            payerUserId: 'payerUserId',
            originalCurrencyId: 'RUB',
            originalAmount: 1234.56,
            originalVatAmount: 23,
            paymentUrl: 'https:/paymentUrl.ru',
            failUrl: 'https:/failUrl.ru',
            returnUrl: 'https:/returnUrl.ru',
            successUrl: 'https:/successUrl.ru',
            status: 'pending',
            expirationDate: '2024-12-18T17:10:02+00:00',
            currency: Currency.fromMock(),
        });
    }

    formattedCreatedAt() {
        return convertDateAndTimeToShow(this.createdAt);
    }

    formattedOriginalAmount() {
        return this.currency.moneyView(this.originalAmount);
    }
}
