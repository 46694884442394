export enum REQUISITE_FORM_FIELD {
    vatNumber = 'vatNumber',
    billNumber = 'billNumber',
    paymentOperationNumber = 'paymentOperationNumber',
}

export type TInnerForm = {
    [REQUISITE_FORM_FIELD.vatNumber]: string;
    [REQUISITE_FORM_FIELD.billNumber]: string;
    [REQUISITE_FORM_FIELD.paymentOperationNumber]: string;
};

export const defaultValues = {
    [REQUISITE_FORM_FIELD.vatNumber]: '',
    [REQUISITE_FORM_FIELD.billNumber]: '',
    [REQUISITE_FORM_FIELD.paymentOperationNumber]: '',
};
