import styled, { css } from 'styled-components';
import Typography from 'src/components/Typography';
import { ReactComponent as leftIcon } from 'src/assets/icons/circleLeftSolid.svg';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';
import Skeleton from 'src/components/Skeleton';

export const StoriesWrapper = styled.div`
    width: 100%;

    // styles slider
    .slick-slider {
        display: grid;
        grid-template-columns: 1fr;
    }

    .slick-list {
        margin-right: -20px;

        @media ${({ theme }) => theme.breakpoints.xxl} {
            margin-right: 0;
        }
    }

    .slick-track {
        margin-left: 0;
    }

    .slick-dots {
        position: static;
        margin: 20px auto 0;
        width: auto;
        li {
            width: 8px;
            height: 8px;

            button {
                width: 8px;
                height: 8px;

                &:before {
                    width: 8px;
                    height: 8px;
                    background: ${({ theme }) => theme.colors.grey(COLOR_BRIGHTNESS.HARD)};
                    opacity: 1;
                    content: '';
                    border-radius: 50%;
                }
            }
        }

        .slick-active {
            button {
                &:before {
                    background: ${({ theme }) => theme.colors.yellow()};
                }
            }
        }
    }
`;

export const StoriesHeader = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    margin-bottom: 24px;
`;

export const StoriesTitle = styled(Typography)``;

export const StoriesButtons = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
`;

export const ButtonScrollSlider = styled.button`
    width: 20px;
    height: 20px;
`;

const IconStyles = css`
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    path {
        fill: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.LIGHT)};
    }
`;

export const LeftIcon = styled(leftIcon)`
    ${IconStyles}
`;

export const RightIcon = styled(leftIcon)`
    transform: rotate(180deg);
    ${IconStyles}
`;

export const SkeletonSlide = styled(Skeleton)`
    max-width: 198px;
    height: 140px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.min}px;
    @media ${({ theme }) => theme.breakpoints.md} {
        height: 100px;
        margin: 0 auto;
    }
`;

export const SlidesWrapper = styled.div`
    overflow: hidden;
    max-height: 184px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-bottom: 20px;
    }
`;
