import React, { useMemo } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import Skeleton from 'src/components/Skeleton';
import { useSWR } from 'src/hooks/useSWR';
import { IOrderContainer, TOrder, TOrderItem } from 'src/store/Order/types';
import { useParams } from 'react-router-dom';
import {
    BreadcrumbRefund,
    FormRefundContent,
    RefundOptions,
    RefundTitle,
} from 'src/pages/businessOffice/Refund/styles';
import NewOrderPayAccordion from 'src/components/NewOrderPayAccordion';
import RefundTotal from 'src/pages/businessOffice/Refund/components/RefundTotal';
import PositionsRefund from 'src/pages/businessOffice/Refund/components/PositionsRefund';
import { Form } from 'react-final-form';
import ReasonRefund, { enumsReasons, getLabelReason } from 'src/pages/businessOffice/Refund/components/ReasonRefund';
import arrayMutators from 'final-form-arrays';
import { isNil } from 'lodash';
import { getRequestUrlOrNull } from 'src/utils/helpers';
import useValidationSchema from 'src/hooks/useValidationSchema';
import { createRefundSchema } from 'src/pages/businessOffice/Refund/validation';
import { useAppDispatch } from 'src/store';
import { createRefund } from 'src/store/OrderRefunds';
import { ICreateRefundForm, REFUND_FIELD } from 'src/store/OrderRefunds/types';
import useModal from 'src/hooks/useModal';
import ModalSuccessRefund from 'src/pages/businessOffice/Refund/components/ModalSuccessRefund';
import { useTablet } from 'src/hooks/useMedia';
import { useIntl } from 'react-intl';

const Refund = () => {
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);
    const intl = useIntl();
    const isTablet = useTablet();
    const dispatch = useAppDispatch();
    const validation = useValidationSchema(createRefundSchema);
    const { orderContainerId } = useParams<{ orderContainerId: string }>();

    const mutators = useMemo(
        () => ({
            ...arrayMutators,
            setAmountAndVatAmount: (args: number[], state: any, utils: any) => {
                utils.changeValue(state, REFUND_FIELD.amount, () => args[0]);
                utils.changeValue(state, REFUND_FIELD.vatAmount, () => args[1]);
            },
        }),
        [],
    );

    const { data } = useSWR<IOrderContainer>(`billing/api/order/order-container/${orderContainerId}`, {
        _include: ['order', 'debit-invoice'],
        _extend: ['merchant', 'counterparty'],
    });

    const order =
        data?.extendedData &&
        data?.extendedData
            .find((item) => item.entity === 'order')
            ?.data.find((orderData: TOrder) => orderData.subtype === 'order');
    const debitInvoice =
        data?.extendedData && data?.extendedData.find((item) => item.entity === 'debit-invoice')?.data[0];
    const merchant = data?.extendedData && data?.extendedData.find((item) => item.entity === 'merchant')?.data[0];

    const { data: dataBasketItems } = useSWR<TOrderItem[]>(
        getRequestUrlOrNull(`billing/api/order/order/${order?.id}/refund-basket-item`, !isNil(order?.id)),
    );
    const originalBasketItems = dataBasketItems?.data;

    const onSubmit = async (formValues: ICreateRefundForm) => {
        const dataSubmit = await dispatch(
            createRefund({
                ...formValues,
                [REFUND_FIELD.parentId]: order.id,
                [REFUND_FIELD.description]:
                    formValues?.reasonId !== enumsReasons.other
                        ? getLabelReason(intl, formValues?.[REFUND_FIELD.reasonId])
                        : formValues.description,
            }),
        );
        // @ts-ignore
        if (!dataSubmit?.error && dataSubmit?.payload) {
            openModal();
        }
    };

    return (
        <div>
            <BreadcrumbRefund>
                <Breadcrumb.Item to={RoutesBusinessOffice.dashboard}>
                    {intl.formatMessage({ defaultMessage: 'Обзор' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    to={
                        merchant &&
                        `${RoutesBusinessOffice.shop}/${merchant?.[MERCHANT_FIELDS.id]}${RoutesBusinessOffice.orders}`
                    }
                >
                    {merchant?.[MERCHANT_FIELDS.name] || <Skeleton width={80} />}
                </Breadcrumb.Item>
                <Breadcrumb.Item to={merchant && `${RoutesBusinessOffice.ordercontainer}/${orderContainerId}`}>
                    {order?.merchantOrderId ? (
                        `${intl.formatMessage({ defaultMessage: 'Счет' })} ${order?.merchantOrderId}`
                    ) : (
                        <Skeleton width={80} />
                    )}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{intl.formatMessage({ defaultMessage: 'Создание возврата' })}</Breadcrumb.Item>
            </BreadcrumbRefund>
            <RefundTitle variant="bigTitle">{intl.formatMessage({ defaultMessage: 'Создание возврата' })}</RefundTitle>
            <Form validate={validation} mutators={mutators} onSubmit={onSubmit}>
                {({ form, handleSubmit, valid }) => (
                    <FormRefundContent>
                        <RefundOptions>
                            <NewOrderPayAccordion
                                title={
                                    isTablet
                                        ? intl.formatMessage({ defaultMessage: 'Настройка позиций возврата' })
                                        : intl.formatMessage({ defaultMessage: 'Настройка позиций и деталей возврата' })
                                }
                            >
                                <PositionsRefund originalBasketItems={originalBasketItems} />
                            </NewOrderPayAccordion>
                            <NewOrderPayAccordion title={intl.formatMessage({ defaultMessage: 'Причина возврата' })}>
                                <ReasonRefund />
                            </NewOrderPayAccordion>
                        </RefundOptions>
                        <RefundTotal
                            form={form}
                            isValid={valid}
                            debitInvoice={debitInvoice}
                            onSubmit={handleSubmit}
                            order={order}
                        />
                    </FormRefundContent>
                )}
            </Form>
            <ModalSuccessRefund merchantId={merchant?.id} onClose={onCloseModal} isOpen={isOpenModal} />
        </div>
    );
};

export default Refund;
