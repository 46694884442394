import React, { FC } from 'react';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useIntl } from 'react-intl';
import { ShopNavContainer, ShopNavButton, ShopNavLink } from '../styles';

interface INavigationProps {
    merchantId: string;
}

const ShopNavigation: FC<INavigationProps> = ({ merchantId }) => {
    const intl = useIntl();

    const NAVIGATION_FOR_SHOP = [
        {
            label: intl.formatMessage({ defaultMessage: 'Обзор' }),
            to: `${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.overview}`,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Заказы' }),
            to: `${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.orders}`,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Возвраты' }),
            to: `${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.refunds}`,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Клиенты' }),
            to: `${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.clients}`,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Настройки' }),
            to: `${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.settings}`,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Интеграции' }),
            to: `${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.integrations}`,
        },
    ];

    return (
        <ShopNavContainer>
            {NAVIGATION_FOR_SHOP.map(({ label, to }) => (
                <ShopNavButton key={label + to}>
                    <ShopNavLink to={to} activeClassName="isActive">
                        {label}
                    </ShopNavLink>
                </ShopNavButton>
            ))}
        </ShopNavContainer>
    );
};

export default ShopNavigation;
