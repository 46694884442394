import { TDictionary } from './types';

export enum DICTIONARY_TYPES {
    vat = 'vat',
    measure = 'measure',
    legalEntityType = 'legalEntityType',
    mcc = 'mcc',
    timeZone = 'timeZone',
}

const dictionaryInitializers = [
    {
        name: DICTIONARY_TYPES.vat,
        dataUrl: '/filter/api/system/vat',
        isExternal: true,
    },
    {
        name: DICTIONARY_TYPES.measure,
        isExternal: true,
        dataUrl: '/billing/api/order/measure',
    },
    {
        name: DICTIONARY_TYPES.legalEntityType,
        isExternal: true,
        dataUrl: '/filter/api/system/legal-entity-type',
    },
    {
        name: DICTIONARY_TYPES.mcc,
        isExternal: true,
        dataUrl: '/filter/api/system/mcc',
    },
    {
        name: DICTIONARY_TYPES.timeZone,
        isExternal: true,
        dataUrl: '/filter/paymentgateway/system/timezone',
    },
];

export const createInitialDictionaries = (): TDictionary[] =>
    dictionaryInitializers.map<TDictionary>(
        ({ name, isExternal, dataUrl }) =>
            ({
                id: name,
                isExternal,
                data: [],
                ...(isExternal && {
                    dataUrl,
                    isLoaded: false,
                }),
            }) as TDictionary,
    );
