import React, { FC, useCallback, useState } from 'react';
import { useForm } from 'react-final-form';
import LoadingIndicator from 'src/components/InputAutocomplete/components/LoadingIndicator';
import { useSWR } from 'src/hooks/useSWR';
import Input from 'src/components/Input';
import { useIntl } from 'react-intl';
import { Scrollbar, useOutsideClick } from '@invoicebox/ui';
import { ADDRESS_REQUEST_URL } from '../constants';
import { IAutocompleteDialogProps, ILocationData } from '../types';
import { ButtonItemList, ScrollList, LabelButtonItem, InputAutocompleteContainer } from '../styles';

const FULL_DETALIZATION_NUMBER = 8;

const InputAutocompleteAddress: FC<IAutocompleteDialogProps> = ({ name, value, label, ...props }) => {
    const intl = useIntl();
    const { change } = useForm();

    const [isShowAddresses, setIsShowAddresses] = useState(false);
    const [isWasFocus, setIsWasFocus] = useState(false);

    const { data, error } = useSWR<Array<ILocationData>>(`${ADDRESS_REQUEST_URL}?_searchQuery=${value}`);

    const addresses = data?.data ?? [];
    const addressesIsLoading = !data && !error;

    const closeAddressesWindow = useCallback(() => {
        if (isShowAddresses) {
            setIsShowAddresses(false);
        }
    }, [isShowAddresses]);

    const addressesRef = useOutsideClick(closeAddressesWindow);

    const handleSelect = (details: ILocationData) => {
        change(name, details.title);
        closeAddressesWindow();
    };

    return (
        <InputAutocompleteContainer
            ref={addressesRef}
            onFocus={() => {
                setIsShowAddresses(true);
                setIsWasFocus(true);
            }}
            className={props?.className}
        >
            <Input name={name} label={label} value={value} {...props} />
            {isShowAddresses && (
                <ScrollList>
                    <Scrollbar maxHeight={120}>
                        {addressesIsLoading && <LoadingIndicator />}
                        {addresses.length > 0 &&
                            addresses
                                .filter((address) => address.detalizationLevel <= FULL_DETALIZATION_NUMBER)
                                .map((listItem: ILocationData) => (
                                    <ButtonItemList
                                        type="button"
                                        key={listItem.title}
                                        onClick={() => handleSelect(listItem)}
                                    >
                                        <LabelButtonItem variant="regular12">{listItem.title}</LabelButtonItem>
                                    </ButtonItemList>
                                ))}
                    </Scrollbar>
                </ScrollList>
            )}
            {isWasFocus && addresses.length === 0 && !addressesIsLoading && (
                <LabelButtonItem variant="regular12">
                    {intl.formatMessage({ defaultMessage: 'Введите корректный адрес' })}
                </LabelButtonItem>
            )}
        </InputAutocompleteContainer>
    );
};
export default InputAutocompleteAddress;
