import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { DocumentReceiveMethod } from '../../DocumentReceiveMethod';
import { ReactComponent as CloudIcon } from './assets/cloud.svg';
import { ReactComponent as MailIcon } from './assets/mail.svg';
import { ReactComponent as ListIcon } from './assets/list.svg';
import { ReactComponent as DownloadIcon } from './assets/download.svg';
import { TVariantDocumentReceiveMethod } from './constants';

export type TProps = {
    variant: TVariantDocumentReceiveMethod;
    endIcon: ReactNode;
};

export const DocumentReceiveMethodWidget: FC<TProps> = ({ variant, endIcon }) => {
    const intl = useIntl();

    const variantMap: Record<TVariantDocumentReceiveMethod, any> = {
        mail: {
            label: intl.formatMessage({
                id: 'Оригиналы по почте',
                defaultMessage: 'Оригиналы по почте',
            }),
            logo: <ListIcon />,
            bgColor: '#ECFDF2',
            iconColor: '#59CF83',
        },
        edo: {
            label: intl.formatMessage({
                id: 'По ЭДО',
                defaultMessage: 'По ЭДО',
            }),
            logo: <CloudIcon />,
            bgColor: '#E0F6FF',
            iconColor: '#4490B0',
        },
        email: {
            label: intl.formatMessage({
                id: 'По электрононной почте',
                defaultMessage: 'По электрононной почте',
            }),
            logo: <MailIcon />,
            bgColor: '#FFF7EF',
            iconColor: '#FFB266',
        },
        download: {
            label: intl.formatMessage({
                id: 'Скачать',
                defaultMessage: 'Скачать',
            }),
            logo: <DownloadIcon />,
            bgColor: '#EFF2FF',
            iconColor: '#8F98C2',
        },
    };

    const { logo, label, bgColor, iconColor } = variantMap[variant];

    return (
        <DocumentReceiveMethod label={label} bgColor={bgColor} iconColor={iconColor} logo={logo} endIcon={endIcon} />
    );
};
