import { RootState } from 'src/store';
import { filter, flow, isNil, map, slice, uniqBy } from 'lodash/fp';
import { getOrdersList } from 'src/store/Order/selectors';
import { getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { TOrder } from 'src/store/Order/types';
import { CLIENT_FIELDS } from 'src/store/Client/contants';
import { clientAdapter } from '.';

// не во всех заказах есть customer, например 017d3898-8991-5eb6-3b73-8a49a4fc5b72
// @ts-ignore
const filterOrdersWithPhone = filter((order: TOrder) => {
    if (order?.customer?.phone) {
        return order.customer.phone;
    }
    return null;
});
const getUniqueCustomers = uniqBy(({ customer: { phone } }) => phone);
const getLastFiveCustomers = slice(0, 5);
const getCustomerPhone = map(({ customer: { phone } }) => phone);
const getCustomerId = map(({ clientId }) => clientId);
const getLastUniqueCustomers = flow(filterOrdersWithPhone, getUniqueCustomers, getLastFiveCustomers);
const omitUndefined = filter((el) => !isNil(el));
const getLastCustomersPhones = flow(getLastUniqueCustomers, getCustomerPhone);
const getLastCustomersIds = flow(getLastUniqueCustomers, getCustomerId, omitUndefined);

const { selectAll, selectById } = clientAdapter.getSelectors();

export const getClients = (state: RootState) => selectAll(state.client).filter(({ name }) => name);

export const getCurrentClientId = (state: RootState) => state.client.selectedId;

export const getCurrentCounterpartyClients = (state: RootState) =>
    getClients(state).filter(({ ownerCounterpartyId }) => ownerCounterpartyId === getCurrentCounterpartyId(state));

export const getCounterpartyClients = (counterpartyId: number) => (state: RootState) =>
    getClients(state).filter((client) => {
        if (
            client[CLIENT_FIELDS.contactCounterpartyIds] &&
            client[CLIENT_FIELDS.contactCounterpartyIds].indexOf(counterpartyId) !== -1
        ) {
            return client;
        }
        return null;
    });

export const getCurrentClient = (state: RootState) =>
    // @ts-ignore
    selectById(state.client, getCurrentClientId(state));

export const getClientCounterpartiesIds = (state: RootState) => getCurrentClient(state)?.contactCounterpartyIds;

export const getLastClients = (state: RootState) => {
    const customerPhones = getLastCustomersPhones(getOrdersList(state));
    const customerIds = getLastCustomersIds(getOrdersList(state));

    return getClients(state).filter(({ phone, id }) => customerPhones.includes(phone) || customerIds.includes(id));
};

export const getClientById = (clientId: number | undefined) => (state: RootState) => {
    if (clientId) {
        return getClients(state).find((client) => client[CLIENT_FIELDS.id] === clientId);
    }
    return undefined;
};

export const getClientIsLoaded = (state: RootState) => state.client.isLoaded;
