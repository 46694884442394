import React, { FC } from 'react';
import {
    TotalRowLabel,
    TotalRowValue,
    TotalRowWrapper,
} from 'src/pages/businessOffice/OrderContainer/components/TotalRow/styles';
import Skeleton from 'src/components/Skeleton';

interface ITotalRow {
    label: string;
    value: string;
    isShowSkeleton?: boolean;
}

const TotalRow: FC<ITotalRow> = ({ isShowSkeleton = false, label, value }) => (
    <TotalRowWrapper>
        <TotalRowLabel variant="regular14">{label}</TotalRowLabel>
        <TotalRowValue variant="regular14">{isShowSkeleton ? <Skeleton width={70} /> : value}</TotalRowValue>
    </TotalRowWrapper>
);

export default TotalRow;
