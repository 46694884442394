import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';
import { COLOR_BRIGHTNESS } from '../../../theme/utils';

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 20px;
    justify-content: flex-end;
    border-top: ${({ theme }) => `1px solid ${theme.colors.base(COLOR_BRIGHTNESS.LIGHTEST)}`};
    padding: 5px 16px;
`;

export const PageSwitchersWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
`;

export const ButtonPageSwitcher = styled.button<{ disabled: boolean }>`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    padding: 8px;
    opacity: 0.7;

    &:hover {
        opacity: 0.5;
    }

    ${({ disabled }) =>
        disabled &&
        `
            opacity: 0.3;
            cursor: default;
            
            &:hover {
                opacity: 0.3;
            }
        `}
`;

export const CountRowsOnPageLabel = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.PRE_HARD)};
`;

export const RangeRows = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.PRE_HARD)};
`;

export const ArrowRightIconWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    transform: rotateZ(180deg);
`;
