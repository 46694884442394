import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ProfileHeader = styled.div`
    margin: 33px 0 45px 0;
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 16px;
`;

export const ProfileIconWrapper = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    background: ${({ theme }) => theme.colors.lightGrey()};

    svg {
        path {
            stroke: ${({ theme }) => theme.colors.grey()};
        }
        width: 16px;
        height: 16px;
    }
`;

export const ProfileTitle = styled(Typography)``;

export const Navbar = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    width: 175px;
    margin-bottom: 61px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-bottom: 50px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 40px;
    }
`;
