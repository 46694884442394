import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import * as S from '../styles';
import Tooltip from '../../Tooltip';
import Typography from '../../Typography';
import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import { ReactComponent as LogoutButton } from '../assets/logout.svg';

type TProfileTooltipContentProps = {
    logout: () => void;
    profileLink: string;
};

const ProfileTooltipContent: FC<TProfileTooltipContentProps> = ({ logout, profileLink }) => {
    const intl = useIntl();

    return (
        <S.ProfileTooltipInner>
            <S.ProfileMenuLink to={profileLink}>
                <Typography variant="regular14">
                    {intl.formatMessage({
                        id: '0t5q4G',
                        defaultMessage: 'Профиль',
                    })}
                </Typography>
                <SettingsIcon />
            </S.ProfileMenuLink>
            <S.ProfileMenuButton onClick={logout}>
                <Typography variant="regular14">
                    {intl.formatMessage({
                        id: 'ZNSvf0',
                        defaultMessage: 'Выход',
                    })}
                </Typography>
                <LogoutButton />
            </S.ProfileMenuButton>
        </S.ProfileTooltipInner>
    );
};

type TProps = TProfileTooltipContentProps & {
    children: ReactNode;
    isBorderLeft: boolean;
};

export const ProfileMenuItem: FC<TProps> = ({ logout, children, profileLink, isBorderLeft }) => (
    <S.HeaderMenuItem $isBorderLeft={isBorderLeft}>
        <Tooltip
            interactive
            placement="bottom-end"
            offset={[20, 20]}
            content={<ProfileTooltipContent logout={logout} profileLink={profileLink} />}
        >
            {children}
        </Tooltip>
    </S.HeaderMenuItem>
);
