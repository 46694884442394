import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    ICounterparty,
    ICounterpartyBankDetail,
    ICounterpartyBankDetailPayload,
    ICounterpartyRequestFields,
} from 'src/store/Counterparty/types';
import {
    createBankDetailsRequest,
    createCounterpartyRequest,
    getCounterpartiesListRequest,
    getCounterpartyBankDetailList,
    updateCounterpartyRequest,
} from 'src/store/Counterparty/http';
import { Nullable } from 'src/utils/utilityTypes';
import { thunkHandler } from 'src/utils/helpers';
import { BANK_DETAILS_FIELDS, DEFAULT_BANK_DETAILS_CURRENCY_ID } from 'src/store/Counterparty/contants';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';

export const createCounterparty = createAsyncThunkWithErrorHandler(
    'counterparty/counterpartyCreate',
    async (payload: ICounterpartyRequestFields) => {
        const data: { data: any } = await createCounterpartyRequest(payload).then((res) => res.data);
        return data;
    },
);

export const updateCounterparty = createAsyncThunkWithErrorHandler(
    'counterparty/counterpartyUpdate',
    async (payload: any) => {
        const {
            data: { data },
        } = await updateCounterpartyRequest(payload);
        return data;
    },
);

export const fetchCounterpartiesList = createAsyncThunkWithErrorHandler(
    'counterparty/getCounterpartiesList',
    async (thunkAPI) => thunkHandler(getCounterpartiesListRequest(), thunkAPI),
);

export const fetchCounterpartyBankDetails = createAsyncThunkWithErrorHandler(
    'counterparty/fetchBankDetails',
    async (arg, thunkAPI) => {
        if (thunkAPI.getState().counterparty.ids.length > 0) {
            const {
                data: { data },
            } = await getCounterpartyBankDetailList();

            return data;
        }
        throw new Error('You need have organizations for getting bank details');
    },
);

export const createCounterpartyBankDetails = createAsyncThunkWithErrorHandler<
    ICounterpartyBankDetail,
    Omit<ICounterpartyBankDetailPayload, BANK_DETAILS_FIELDS.currencyId>
>('counterparty/createBankDetails', async (payload) => {
    const {
        data: { data },
    } = await createBankDetailsRequest({
        ...payload,
        [BANK_DETAILS_FIELDS.currencyId]: DEFAULT_BANK_DETAILS_CURRENCY_ID,
    });

    return data;
});

export const counterpartyAdapter = createEntityAdapter<ICounterparty>();

const initialState = counterpartyAdapter.getInitialState({
    loading: false,
    selectedId: null as Nullable<string>,
    selectedBankDetailId: null as Nullable<number>,
    initialized: false,
    bankDetails: null as Array<ICounterpartyBankDetail> | null,
});

const Counterparty = createSlice({
    name: 'counterparty',
    initialState,
    reducers: {
        setCounterparty(state, action) {
            state.selectedId = action.payload;
        },
        setBankDetailId(state, action) {
            state.selectedBankDetailId = action.payload;
        },
        clearCounterparties() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createCounterparty.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createCounterparty.fulfilled, (state, { payload }) => {
            state.loading = false;
            counterpartyAdapter.addOne(state, payload.data);
            state.selectedId = payload.data.id;
        });
        builder.addCase(createCounterparty.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(fetchCounterpartiesList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchCounterpartiesList.fulfilled, (state, { payload }) => {
            state.loading = false;
            counterpartyAdapter.addMany(state, payload);
            state.initialized = true;
        });
        builder.addCase(fetchCounterpartiesList.rejected, (state) => {
            state.loading = false;
            state.initialized = true;
        });
        builder.addCase(fetchCounterpartyBankDetails.fulfilled, (state, action) => {
            state.bankDetails = action.payload;
        });
        builder.addCase(createCounterpartyBankDetails.fulfilled, (state, { payload }) => {
            if (state.bankDetails) {
                state.bankDetails.push(payload);
            }
        });
        builder.addCase(updateCounterparty.fulfilled, (state, action) => {
            counterpartyAdapter.upsertOne(state, action.payload);
        });
    },
});

export const { setCounterparty, clearCounterparties, setBankDetailId } = Counterparty.actions;
export default Counterparty.reducer;
