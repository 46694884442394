import styled from 'styled-components';
import FormField from 'src/components/FormField';

export const FormRequisitesWrapper = styled.div``;

export const CheckboxContainer = styled.div`
    ${({ theme }) => theme.mixins.flexStart}
    flex-direction: column;
    grid-row-gap: 12px;
    margin-bottom: 30px;
`;

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 19px;
    grid-column-gap: 30px;
    margin-bottom: 60px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: repeat(3, 1fr);
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 100%;
    }
`;

export const RegistrationAddressCustomerField = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-start: 2;
        grid-column-end: 4;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-start: auto;
        grid-column-end: auto;
    }
`;
