import React, { FC, useState } from 'react';
import {
    BackgroundImage,
    PreviewTitle,
    SlidePreviewContent,
    SlidePreviewWrapper,
    SkeletonSlide,
} from 'src/pages/businessOffice/Dashboard/components/Stories/components/SlidePreview/styles';
import { IStory } from 'src/pages/businessOffice/Dashboard/components/Stories/types';
import { STRAPI_BASE_URL } from 'src/utils/http';

interface ISlidePreview {
    slide: IStory;
    onClickToPreview: (indexSlide: number) => void;
    index: number;
}

const SlidePreview: FC<ISlidePreview> = ({ index, onClickToPreview, slide }) => {
    const imageURL = STRAPI_BASE_URL + slide.preview.slice(1);

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    return (
        <SlidePreviewWrapper
            type="button"
            onClick={() => {
                onClickToPreview(index);
            }}
        >
            <SlidePreviewContent>
                <BackgroundImage
                    alt="preview_story"
                    src={imageURL}
                    onLoad={() => {
                        setIsImageLoaded(true);
                    }}
                />
                <PreviewTitle variant="regular14">{slide.title}</PreviewTitle>
                {!isImageLoaded && <SkeletonSlide />}
            </SlidePreviewContent>
        </SlidePreviewWrapper>
    );
};

export default SlidePreview;
