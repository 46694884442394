import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const ShopNav = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    ${({ theme }) => theme.breakpoints.sm} {
        width: 100vw;
        margin-right: 10px;
    }
`;

export const ShopNavContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: hidden;

    ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-left: -20px;
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100% + 20px);
    }
`;

export const ShopNavButton = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    padding: 0 12px;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
`;

export const ShopNavLink = styled(NavLink)`
    color: ${({ theme }) => theme.colors.grey()};
    ${({ theme }) => theme.mixins.getTypography('regular14')};
    line-height: 20px;
    text-decoration: none;
    height: 100%;
    position: relative;
    transition: ${({ theme }) => theme.decorations.transition?.base};
    padding-bottom: 12px;

    &:hover {
        color: ${({ theme }) => theme.colors.darkest()};

        @media ${({ theme }) => theme.breakpoints.ld} {
            color: ${({ theme }) => theme.colors.base()};
        }
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        border-radius: 10px 10px 0 0;
        background-color: ${({ theme }) => theme.colors.white()};
        transition: ${({ theme }) => theme.decorations.transition?.base};
    }

    ${css`
        &.isActive {
            color: ${({ theme }) => theme.colors.black()};
            @media ${({ theme }) => theme.breakpoints.xl} {
                color: ${({ theme }) => theme.colors.darkest()};
            }

            &:before {
                background-color: ${({ theme }) => theme.colors.grey()};
                height: 2px;
                width: 100%;

                @media ${({ theme }) => theme.breakpoints.xxl} {
                    color: ${({ theme }) => theme.colors.darkest()};
                }
            }
        }
    `}
`;

export const ContainerNavPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-row-gap: 30px;
    margin-top: 30px;
`;
