import React, { ReactNode } from 'react';
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { IS_DEV } from 'src/utils/constants';
import Portal from '../Portal';

type Props = {
    children: ReactNode;
    methods: UseFormReturn<any>;
    onSubmit?: () => void;
};

const FormProvider = ({ children, onSubmit, methods }: Props) => (
    <Form {...methods}>
        <form onSubmit={onSubmit}>
            {children}
            {IS_DEV && (
                <Portal>
                    <DevTool control={methods.control} placement="top-right" />
                </Portal>
            )}
        </form>
    </Form>
);

export default FormProvider;
