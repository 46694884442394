import React, { FC } from 'react';
import { IClientCounterparty } from 'src/store/ClientCounterparty/types';
import { IClient } from 'src/store/Client/types';
import ButtonSelectContactPerson from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/ButtonSelectContactPerson';
import { getSortedClients } from 'src/utils/helpers';
import { useIntl } from 'react-intl';
import {
    ClientsList,
    ContentModalFindClient,
    HeaderModalFindClient,
    HeadLetterModalFindClient,
    ModalFindClientWrapper,
} from '../../styles';

interface IModalAddContactPerson {
    closeModal: () => void;
    isOpen: boolean;
    selectClient: (clients: IClient | IClientCounterparty, isLegal: boolean, isClientCounterparty: boolean) => void;
    clients: IClient[];
}

const ModalAddContactPerson: FC<IModalAddContactPerson> = ({ clients, selectClient, closeModal, isOpen }) => {
    const intl = useIntl();
    const sortedClients = getSortedClients(clients);

    let firstLetter: string | null = null;

    if (clients.length === 0) {
        return null;
    }

    return (
        <ModalFindClientWrapper
            title={intl.formatMessage({ defaultMessage: 'Выберите контактное лицо' })}
            HeaderComponent={HeaderModalFindClient}
            closeModal={closeModal}
            isOpen={isOpen}
        >
            <ContentModalFindClient>
                <ClientsList>
                    {sortedClients.map((client, index) => {
                        if (client.name[0].toUpperCase() !== firstLetter) {
                            // @ts-ignore
                            // eslint-disable-next-line prefer-destructuring
                            firstLetter = client.name[0].toUpperCase();
                            return (
                                <div key={client.id}>
                                    <HeadLetterModalFindClient isFirstLetter={index === 0}>
                                        {firstLetter}
                                    </HeadLetterModalFindClient>
                                    <ButtonSelectContactPerson
                                        isLegalCustomer={false}
                                        selectClient={selectClient}
                                        client={client}
                                        closeModal={closeModal}
                                    />
                                </div>
                            );
                        }
                        return (
                            <ButtonSelectContactPerson
                                isLegalCustomer={false}
                                selectClient={selectClient}
                                key={client.id}
                                client={client}
                                closeModal={closeModal}
                            />
                        );
                    })}
                </ClientsList>
            </ContentModalFindClient>
        </ModalFindClientWrapper>
    );
};

export default React.memo(ModalAddContactPerson);
