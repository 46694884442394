import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const FooterWrapper = styled.div`
    ${({ theme }) => theme.mixins.mainGrid};
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    padding-top: 17px;
    padding-bottom: 14px;
    color: ${({ theme }) => theme.colors.grey()};

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding-left: 0;
        padding-right: 0;
        max-width: calc(100% - 48px);
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-top: 17px;
        padding-bottom: 14px;
        max-width: calc(100% - 32px);
    }
`;

export const CreatorLabel = styled(Typography)``;

export const LinksWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 24px;
`;

export const LinkFooter = styled.a`
    ${({ theme }) => theme.mixins.getTypography('regular12')}
    text-decoration: none;
`;
