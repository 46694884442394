import React from 'react';
import { AppCardLink, InvoiceboxLogo, Typography, QRCode } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import qrIcon from 'src/assets/qr/invoiceboxQRLogo.png';
import * as S from './styles';
import { invoiceForAccountantLink, supportEmail, supportPhone1, supportPhone2 } from '../../utils/constants';
import ContactRow from './components/ContactRow';
import { ReactComponent as MailIcon } from './assets/mail.svg';
import { ReactComponent as PhoneIcon } from './assets/phone.svg';
import { appLinks, getMainLinks, getSecondaryLinks, getTabletSecondaryLinks } from './constants';
import { useMiniLaptop, useTablet } from '../../hooks/useMedia';

const year = new Date().getFullYear();

const DocsFooter = () => {
    const intl = useIntl();
    const isMiniLaptop = useMiniLaptop();
    const isTablet = useTablet();

    const secondaryLinks = isTablet ? getTabletSecondaryLinks(intl) : getSecondaryLinks(intl);
    const tabletDivider = isTablet && <S.Divider />;

    return (
        <S.Wrapper>
            <S.Content>
                <S.Left>
                    <S.LogoWrapper>
                        <InvoiceboxLogo height={25} />
                    </S.LogoWrapper>
                    <S.AppsAndContacts>
                        {tabletDivider}
                        <S.AppsContent>
                            <S.SectionLabel variant="bodyMRegular">
                                {intl.formatMessage({
                                    defaultMessage: 'Скачать приложение',
                                })}
                            </S.SectionLabel>
                            {isTablet ? (
                                <S.AppsLinks>
                                    {appLinks.map((app) => (
                                        <AppCardLink key={app.href} height={28} variant={app.variant} href={app.href} />
                                    ))}
                                </S.AppsLinks>
                            ) : (
                                <S.QRWrapper>
                                    <QRCode image={qrIcon} value={invoiceForAccountantLink} size={110} />
                                </S.QRWrapper>
                            )}
                        </S.AppsContent>
                        <S.Contacts>
                            <S.SectionLabel variant="bodyMRegular">
                                {intl.formatMessage({
                                    defaultMessage: 'Контакты',
                                })}
                            </S.SectionLabel>
                            <ContactRow icon={<MailIcon />} value={supportEmail} />
                            <ContactRow icon={<PhoneIcon />} value={supportPhone1} />
                            <ContactRow icon={<PhoneIcon />} value={supportPhone2} />
                        </S.Contacts>
                        {tabletDivider}
                    </S.AppsAndContacts>
                </S.Left>
                <S.Right>
                    <S.PrimaryLinks>
                        {getMainLinks(intl).map((link) => (
                            <S.PrimaryLink key={link.href} target="_blank" href={link.href}>
                                <Typography variant="buttonM">{link.label}</Typography>
                            </S.PrimaryLink>
                        ))}
                    </S.PrimaryLinks>
                    {!isMiniLaptop && <S.Divider />}
                    <S.Secondary>
                        {isTablet && (
                            <S.TabletFooterCaption variant="captionRegular">
                                © {year}, ООО &Prime;ОРЦ&Prime;
                            </S.TabletFooterCaption>
                        )}
                        <S.SecondaryLinks>
                            {secondaryLinks.map((link) => (
                                <S.SecondaryLink target="_blank" key={link.href} href={link.href}>
                                    <Typography variant={isTablet ? 'bodyMRegular' : 'captionRegular'}>
                                        {link.label}
                                    </Typography>
                                </S.SecondaryLink>
                            ))}
                        </S.SecondaryLinks>
                        {tabletDivider}
                        <S.DescriptionWrapper>
                            <S.Description variant="captionRegular">
                                {intl.formatMessage({
                                    defaultMessage:
                                        '*ООО «ОРЦ» принимает переводы денежных средств как лицо, уполномоченное поставщиком. Финансовые услуги оказывают банки - партнёры. Услуги оказываются в рамках обеспечения информационного и технологического взаимодействия между участниками расчётов. Услуги агента оказываются в соответствии с гл. 52 ГК РФ',
                                })}
                            </S.Description>
                            <S.Description variant="captionRegular">
                                {intl.formatMessage({
                                    defaultMessage:
                                        'Информация на сайте не является публичной офертой, определяемой положениями статей 435, 437 Гражданского Кодекса РФ',
                                })}
                            </S.Description>
                        </S.DescriptionWrapper>
                    </S.Secondary>
                </S.Right>
            </S.Content>
        </S.Wrapper>
    );
};

export default DocsFooter;
