import styled from 'styled-components';
import FormField from 'src/components/FormField';

export const PassportForm = styled.form`
    padding-top: 40px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 30px;
    }
`;

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 20% 20%;
    grid-column-gap: 30px;
    grid-row-gap: 24px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: 37% 23% 30%;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }
`;

export const InputName = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;
