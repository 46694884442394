import React, { ReactNode, useState } from 'react';
import PhoneEnterStep from '../PhoneEnterStep';
import CodeEnterStep from '../CodeEnterStep';
import { useNewCodeTimer } from './hooks/useNewCodeTimer';

enum SMS_STEP {
    phoneEnter = 'phoneEnter',
    codeEnter = 'codeEnter',
}

const SmsStep = () => {
    const { startNewCodeTimer, timer } = useNewCodeTimer();

    const goToEnterCodeStep = () => {
        startNewCodeTimer();
        setSmsStep(SMS_STEP.codeEnter);
    };

    const getNewCode = () => {
        // логика по отправке нового кода в СМС
        startNewCodeTimer();
    };

    const [smsStep, setSmsStep] = useState(SMS_STEP.phoneEnter);

    const smsStepMap: Record<SMS_STEP, ReactNode> = {
        [SMS_STEP.phoneEnter]: <PhoneEnterStep goToNextStep={goToEnterCodeStep} />,
        [SMS_STEP.codeEnter]: <CodeEnterStep timer={timer} onGetNewCode={getNewCode} />,
    };

    return <> {smsStepMap[smsStep]} </>;
};

export default SmsStep;
