import React, { FC } from 'react';
import Skeleton from 'src/components/Skeleton';
import { RowLabelWrapper, RefundLabel, RefundValue } from './styles';

interface IRowValue {
    label: string;
    value?: string;
    className?: string;
}

const RowValue: FC<IRowValue> = ({ className, label, value }) => (
    <RowLabelWrapper className={className}>
        <RefundLabel variant="regular14">{label}</RefundLabel>
        <RefundValue variant="regular14">{value || <Skeleton width={80} />}</RefundValue>
    </RowLabelWrapper>
);

export default RowValue;
