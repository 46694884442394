import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import { IDocument, IDocumentPayload } from './types';
import { createDocumentRequest } from './http';

export const documentAdapter = createEntityAdapter<IDocument>();
const initialState = documentAdapter.getInitialState({
    selectedId: null as null | number,
});

export const createDocument = createAsyncThunkWithErrorHandler<IDocument, IDocumentPayload>(
    'document/create-document',
    async (payload) => {
        const {
            data: { data },
        } = await createDocumentRequest(payload);
        return data;
    },
);

const documents = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        addDocuments: (state, { payload }) => {
            documentAdapter.upsertMany(state, payload);
        },
    },
    extraReducers: (builder) => {
        // createDocument
        builder.addCase(createDocument.fulfilled, (state, action) => {
            documentAdapter.addOne(state, action.payload);
            state.selectedId = action.payload.id;
        });
    },
});

export const { addDocuments } = documents.actions;
export default documents.reducer;
