import React from 'react';
import {
    SkeletonOrderGroupHead,
    SkeletonWrapper,
} from 'src/components/MerchantOrdersList/components/SkeletonOrderList/styles';
import SkeletonOrderItem from 'src/components/MerchantOrdersList/components/SkeletonOrderItem';

const SkeletonOrderList = () => {
    const skeletonQueueComponents = [
        {
            component: SkeletonOrderGroupHead,
            id: 1,
        },
        {
            component: SkeletonOrderItem,
            id: 2,
        },
        {
            component: SkeletonOrderItem,
            id: 3,
        },
        {
            component: SkeletonOrderGroupHead,
            id: 4,
        },
        {
            component: SkeletonOrderItem,
            id: 5,
        },
        {
            component: SkeletonOrderItem,
            id: 6,
        },
        {
            component: SkeletonOrderItem,
            id: 7,
        },
    ];
    return (
        <SkeletonWrapper>
            {skeletonQueueComponents.map((componentSkeleton) => {
                const Component = componentSkeleton.component;
                return <Component key={componentSkeleton.id} />;
            })}
        </SkeletonWrapper>
    );
};

export default SkeletonOrderList;
