import React, { FC, useState } from 'react';
import {
    ConnectionPaymentWrapper,
    Header,
    Steps,
    Title,
} from 'src/pages/businessOffice/Claim/components/MainConnectionPayment/styles';
import MerchantPayment from 'src/pages/businessOffice/Claim/components/MerchantPayment';
import TariffPayment from 'src/pages/businessOffice/Claim/components/TariffPayment';
import TabsConnection from 'src/pages/businessOffice/Claim/components/TabsConnection';
import FormCounterparty from 'src/pages/businessOffice/Claim/components/FormCounterparty';
import BankDetails from 'src/pages/businessOffice/Claim/components/BankDetails';
import PassportData from 'src/pages/businessOffice/Claim/components/PassportData';
import Documents from 'src/pages/businessOffice/Claim/components/Documents';
import { IMerchantFile } from 'src/store/Merchant/types';
import { useIntl } from 'react-intl';

export enum enumTabsConnection {
    merchant,
    tariff,
    counterparty,
    bankDetails,
    passport,
    documents,
}

export interface IStep {
    setStep: (arg: number) => void;
    setIsTabsActive: (arg: boolean) => void;
    file: IMerchantFile | null;
    setFile: (arg: IMerchantFile | null) => void;
}

interface IMainConnectionPayment {
    isMerchantPageToStart: boolean;
}

const MainConnectionPayment: FC<IMainConnectionPayment> = ({ isMerchantPageToStart }) => {
    const intl = useIntl();

    const tabs = {
        [enumTabsConnection.merchant]: {
            component: MerchantPayment,
            name: intl.formatMessage({ defaultMessage: 'Магазин' }),
        },
        [enumTabsConnection.tariff]: {
            component: TariffPayment,
            name: intl.formatMessage({ defaultMessage: 'Тариф' }),
        },
        [enumTabsConnection.counterparty]: {
            component: FormCounterparty,
            name: intl.formatMessage({ defaultMessage: 'Контрагент' }),
        },
        [enumTabsConnection.bankDetails]: {
            component: BankDetails,
            name: intl.formatMessage({ defaultMessage: 'Банковские реквизиты' }),
        },
        [enumTabsConnection.passport]: {
            component: PassportData,
            name: intl.formatMessage({ defaultMessage: 'Паспортные данные' }),
        },
        [enumTabsConnection.documents]: {
            component: Documents,
            name: intl.formatMessage({ defaultMessage: 'Документы' }),
        },
    };

    const [step, setStep] = useState(enumTabsConnection.merchant);
    const [isActiveTabs, setIsActiveTabs] = useState(false);
    const [documentFile, setDocumentFile] = useState<IMerchantFile | null>(null);

    const CurrentStepComponent = tabs[step].component;
    const maxStep = isMerchantPageToStart ? Object.keys(tabs).length - 1 : Object.keys(tabs).length;

    return (
        <ConnectionPaymentWrapper>
            <Header>
                <Title variant="headline">
                    {intl.formatMessage({ defaultMessage: 'Подключение приёма платежей' })}
                </Title>
                <Steps variant="title">
                    {`${intl.formatMessage({ defaultMessage: 'Шаг ' })} ${
                        isMerchantPageToStart ? step : step + 1
                    }/${maxStep}`}
                </Steps>
            </Header>
            <TabsConnection
                isSkipFirstTab={isMerchantPageToStart}
                isActiveTabs={isActiveTabs}
                setStep={setStep}
                activeStep={step}
                tabs={tabs}
            />
            <CurrentStepComponent
                file={documentFile}
                setFile={setDocumentFile}
                setIsTabsActive={setIsActiveTabs}
                setStep={setStep}
            />
        </ConnectionPaymentWrapper>
    );
};

export default MainConnectionPayment;
