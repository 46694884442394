import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';

type TProps = {
    steps: string;
    label: string;
};

const Subtitle: FC<TProps> = ({ steps, label }) => (
    <S.Wrapper>
        <Typography variant="headline3">{label}</Typography>
        <Typography variant="headline3">{steps}</Typography>
    </S.Wrapper>
);

export default Subtitle;
