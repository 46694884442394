export enum FEED_TYPES {
    verificationRegistrationCreated = 'verification_registration_created',
    verificationVerified = 'verification_verified',
    verificationFailed = 'verification_failed',
    userAuthorized = 'user_authorized',
    userUnauthorized = 'user_unauthorized',
    smsNotificationFailed = 'sms_notification_failed',
    orderContainerCreated = 'order_container_created',
    orderContainerExpired = 'order_container_expired',
    debitInvoicePaid = 'debit_invoice_paid',
    orderCanceled = 'order_canceled',
    refundOrderCreated = 'refund_order_created',
    creditInvoiceDebitTransactionPaid = 'credit_invoice_debit_transaction_paid',
    creditInvoiceRefundOrder = 'credit_invoice_refund_order',
    creditInvoiceSettlementRegistry = 'credit_invoice_settlement_registry',
}

export enum FEED_META_TARGET_TYPE {
    orderContainer = 'order-container',
}

export interface IFeedItem {
    id: number;
    type: FEED_TYPES;
    title: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    resolved: boolean;
    metadata?: {
        targetId?: string;
        targetType?: FEED_META_TARGET_TYPE;
    };
    unread: boolean;
}

export interface INotificationTokenResponse {
    id: number;
    token: string;
    userId: string;
    typeOS: string;
    versionOS: string;
    deviceType: string;
    appName: string;
    appVersion: string;
}
