// @ts-ignore
import { AsyncThunkPayloadCreator, GetThunkAPI } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';
import { logout, setError } from 'src/store/Auth';
import { labelTroublesInternetConnection, labelTroubleXHR, networkErrorResponse } from 'src/utils/constants';

function commonThunkHandler<A, B, C extends { state: RootState; dispatch: AppDispatch }>(
    callback: AsyncThunkPayloadCreator<A, B, C>,
): AsyncThunkPayloadCreator<A, B, C> {
    // eslint-disable-next-line no-shadow,@typescript-eslint/no-unused-vars,no-unused-vars,@typescript-eslint/no-shadow
    return <A, B, C>(args: B, thunkAPI: GetThunkAPI<C>, ...rest: any[]) =>
        // @ts-ignore
        callback(args, thunkAPI, ...rest).catch((error) => {
            const isNetworkError = error.toString() === networkErrorResponse;
            if (isNetworkError) {
                thunkAPI.dispatch(setError({ title: labelTroublesInternetConnection, isActive: true }));
            }
            const {
                auth: { initialized },
            } = thunkAPI.getState() as RootState;
            const isSessionExpired = error.response.data.error.code === 'unauthorized';
            if (isSessionExpired && initialized) {
                thunkAPI.dispatch(logout());
            }
            thunkAPI.dispatch(setError({ title: labelTroubleXHR, isActive: true }));
            return thunkAPI.rejectWithValue(error.response.data);
        });
}

export default commonThunkHandler;
