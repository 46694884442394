import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useAppDispatch } from 'src/store';
import { getClientsList } from 'src/store/Client';
import CreateOrderToPay from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay';
import { getClientCounterparty } from 'src/store/ClientCounterparty';
import { getCounterpartyIdByIdMerchant } from 'src/store/Counterparty/selectors';
import { setCounterparty } from 'src/store/Counterparty';
import CreateNewClient from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient';
import { setSelectedMerchantId } from 'src/store/Merchant';
import DashboardPage from 'src/pages/businessOffice/Shop/ShopTabsTables/Dashboard';
import MerchantTemplate from 'src/pages/businessOffice/Shop/components/MerchantTemplate';
import Integrations from 'src/pages/businessOffice/Shop/ShopTabsTables/Integrations';
import CreateIntegration from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateIntegration';
import { fetchApplicationList } from 'src/store/Application';
import Orders from './ShopTabsTables/Orders/Orders';
import Clients from './ShopTabsTables/Clients';
import Settings from './ShopTabsTables/Settings';
import { ShopNav } from './styles';
import Refunds from './ShopTabsTables/Refunds';
import RefundDetail from './ShopTabsTables/RefundDetail';

export interface IShopRouteParams {
    merchantId: string;
}

const Shop: FC = () => {
    const dispatch = useAppDispatch();
    const { merchantId } = useParams<IShopRouteParams>();
    const currentCounterpartyId = useSelector(getCounterpartyIdByIdMerchant(merchantId));

    useEffect(() => {
        if (currentCounterpartyId && merchantId) {
            dispatch(setCounterparty(currentCounterpartyId));
            dispatch(getClientsList());
            dispatch(getClientCounterparty());
            dispatch(setSelectedMerchantId(merchantId));
            dispatch(fetchApplicationList());
        }
    }, [currentCounterpartyId, dispatch, merchantId]);

    return (
        <>
            <MerchantTemplate />
            <ShopNav>
                <Switch>
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.overview}`}
                        exact
                        component={DashboardPage}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.orders}`}
                        component={Orders}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.refunds}`}
                        component={Refunds}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.clients}`}
                        component={Clients}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.settings}`}
                        component={Settings}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.orderToPay}`}
                        component={CreateOrderToPay}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.createclient}`}
                        component={CreateNewClient}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.createcounterparty}`}
                        component={CreateNewClient}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.detailclient}/:clientId`}
                        component={CreateNewClient}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.detailclientcounterparty}/:clientCounterpartyId`}
                        component={CreateNewClient}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.refunddetail}/:refundId`}
                        component={RefundDetail}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.integrations}`}
                        component={Integrations}
                    />
                    <Route
                        path={`${RoutesBusinessOffice.shop}/:merchantId${RoutesBusinessOffice.createintegration}`}
                        component={CreateIntegration}
                    />
                </Switch>
            </ShopNav>
        </>
    );
};

export default Shop;
