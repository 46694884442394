import styled from 'styled-components';
import Typography from 'src/components/Typography';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';

export const ClaimModeratingWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    overflow: hidden;
`;

interface IClaimValues {
    isOpen: boolean;
}

export const ButtonClaimValues = styled.button<IClaimValues>`
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    width: 100%;
    background: ${({ theme, isOpen }) =>
        isOpen ? theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM) : theme.colors.white()};
    padding: 16px 26px 16px 30px;
`;

export const ClaimsValues = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 60px;
    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 20px;
    }
`;

export const ArrowOpenWrapper = styled.div<IClaimValues>`
    transform: ${({ isOpen }) => (isOpen ? `rotateZ(180deg)` : `none`)};
`;

export const ValueStatus = styled(Typography)`
    color: ${({ theme }) => theme.colors.orange()};
`;

export const ValueTariff = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 4px;
    text-align: left;
`;

export const IconTariffWrapper = styled.div``;
