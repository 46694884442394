import styled from 'styled-components';
import Skeleton from 'src/components/Skeleton';

export const SkeletonOrderWrapper = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 4fr 2fr 2fr;
    grid-column-gap: 10px;
    padding: 20px 30px;

    :not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 36px auto 60px;
        grid-template-rows: 20px 20px;
        padding: 20px;
    }
`;

export const CustomerContainer = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: contents;
    }
`;

export const SkeletonName = styled(Skeleton)`
    height: 20px;
    width: 100px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        height: 16px;
        width: 80px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 70px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 2;
        grid-column-end: 3;
    }
`;

export const SkeletonAvatar = styled(Skeleton).attrs({ circle: true })`
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-row-start: 1;
        grid-row-end: 3;
    }
`;

export const SkeletonDescription = styled(Skeleton)`
    height: 20px;
    width: 220px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        height: 16px;
        width: 180px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 130px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100px;
    }
`;

export const SkeletonPrice = styled(Skeleton)`
    height: 20px;
    width: 90px;
    margin-right: 0;
    margin-left: auto;
    @media ${({ theme }) => theme.breakpoints.ld} {
        height: 16px;
        width: 70px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 60px;
    }
`;

export const SkeletonStatus = styled(Skeleton)`
    height: 20px;
    width: 90px;
    margin-right: 0;
    margin-left: auto;
    @media ${({ theme }) => theme.breakpoints.ld} {
        height: 16px;
        width: 70px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 60px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin: 0;
    }
`;

export const DescriptionStatusWrapper = styled.div`
    display: contents;
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.flexCenter};
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-gap: 4px;
    }
`;
