import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';
import { fetchOrdersList } from 'src/store/Order';
import { useAppDispatch } from 'src/store';
import { InputTypeEnum } from 'src/components/Input';
import { useIntl } from 'react-intl';
import { TopFinder, SearchContainer, TopFinderContainer } from '../../styles';

const FinderByNumber = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [orderNumber, setOrderNumber] = useState('');

    const handleCheckPress = (event: { key: string }) => {
        if (event.key === 'Enter') {
            dispatch(fetchOrdersList({ _searchQuery: orderNumber }));
        }
    };
    const handlerSearchContact = () => {
        dispatch(fetchOrdersList({ _searchQuery: orderNumber }));
    };

    return (
        <TopFinderContainer>
            <TopFinder
                placeholder={intl.formatMessage({ defaultMessage: 'Поиск по номеру заказа' })}
                value={orderNumber}
                typeInput={InputTypeEnum.numeric}
                onChange={(valueInput: string) => setOrderNumber(valueInput)}
                onKeyPress={handleCheckPress}
            />
            <SearchContainer>
                <button type="button" onClick={handlerSearchContact}>
                    <SearchIcon />
                </button>
            </SearchContainer>
        </TopFinderContainer>
    );
};
export default FinderByNumber;
