import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as VectorLeft } from 'src/assets/icons/vectorLeft.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar.svg';
import { ReactComponent as VectorRight } from 'src/assets/icons/vectorRight.svg';
import dayjs from 'dayjs';
import { getFirstAndLastDaysMonthCurrentDay } from 'src/utils/helpers';
import { DD_MM_YYYY_DATE_FORMAT } from 'src/utils/constants';
import { useIntl } from 'react-intl';
import { DateRangePicker } from 'src/stories/common/DateRangePicker';
import * as S from './styles';

interface ICalendar {
    isOpenCalendar: boolean;
    handlerAcceptDate: (dates: Date[]) => void;
    onToggle: () => void;
}

const Calendar: FC<ICalendar> = ({ isOpenCalendar, handlerAcceptDate, onToggle }) => {
    const intl = useIntl();

    // if value is null then search without dates
    const [dateInterval, setDateInterval] = useState<Date[] | null>(null);
    const firstDateFormatted = dateInterval && dayjs(dateInterval[0]).format(DD_MM_YYYY_DATE_FORMAT);
    const secondDateFormatted = dateInterval && dayjs(dateInterval[1]).format(DD_MM_YYYY_DATE_FORMAT);
    const { firstDayOfSelectedMonth, lastDayOfSelectedMonth } = dateInterval
        ? getFirstAndLastDaysMonthCurrentDay(dateInterval[0])
        : { firstDayOfSelectedMonth: null, lastDayOfSelectedMonth: null };

    const getTitleDateInterval = (datesInterval: Date[] | null) => {
        if (!datesInterval) {
            return intl.formatMessage({ defaultMessage: 'За всё время' });
        }
        if (
            firstDateFormatted === dayjs(firstDayOfSelectedMonth).format(DD_MM_YYYY_DATE_FORMAT) &&
            secondDateFormatted === dayjs(lastDayOfSelectedMonth).format(DD_MM_YYYY_DATE_FORMAT)
        ) {
            return dayjs(datesInterval[0]).format('MMMM YYYY');
        }
        return `${firstDateFormatted} - ${secondDateFormatted}`;
    };

    useEffect(() => {
        if (dateInterval) {
            handlerAcceptDate(dateInterval);
        }
    }, [dateInterval, handlerAcceptDate]);

    const applyFirstAndLastDaysMonth = (date: Date) => {
        const firstAndLastDates = getFirstAndLastDaysMonthCurrentDay(date);
        setDateInterval([firstAndLastDates.firstDayOfSelectedMonth, firstAndLastDates.lastDayOfSelectedMonth]);
    };

    const filterToPrevOrNextMonth = (isNextMonth: boolean) => {
        const date = dateInterval ? dateInterval[0] : new Date();
        date.setMonth(isNextMonth ? date.getMonth() + 1 : date.getMonth() - 1);
        applyFirstAndLastDaysMonth(date);
    };

    return (
        <S.CalendarWrapper>
            <S.PrevMonthButton
                type="button"
                onClick={() => {
                    filterToPrevOrNextMonth(false);
                }}
            >
                <VectorLeft />
            </S.PrevMonthButton>
            <S.FilterHeader>
                <S.FilterButton type="button" onClick={onToggle}>
                    <S.CalendarImage>
                        <CalendarIcon />
                    </S.CalendarImage>
                    <S.SelectedDateInterval variant="regular14">
                        {getTitleDateInterval(dateInterval)}
                    </S.SelectedDateInterval>
                </S.FilterButton>
                <DateRangePicker
                    isOpen={isOpenCalendar}
                    value={dateInterval}
                    onChange={setDateInterval}
                    onClose={onToggle}
                />
            </S.FilterHeader>
            <S.PrevMonthButton
                type="button"
                onClick={() => {
                    filterToPrevOrNextMonth(true);
                }}
            >
                <VectorRight />
            </S.PrevMonthButton>
        </S.CalendarWrapper>
    );
};

export default Calendar;
