import React, { FC, useState } from 'react';
import {
    IconOpen,
    StatusButton,
    StatusesList,
    StatusListItem,
    StatusValueWrapper,
    StatusWrapper,
} from 'src/pages/businessOffice/OrderContainer/components/StatusOrder/styles';
import { getUnprocessableOrderStatuses } from 'src/store/Order/constants';
import { ORDER_FIELDS, ProcessableOrderStatuses, TOrder, UnprocessableOrderStatuses } from 'src/store/Order/types';
import { useAppDispatch } from 'src/store';
import { updateOrderStatus } from 'src/store/Order';
import Skeleton from 'src/components/Skeleton';
import { useIntl } from 'react-intl';
import { useOutsideClick } from '@invoicebox/ui';

interface IStatusOrder {
    order: TOrder;
    setOrder: (order: TOrder) => void;
    setIsEditForm: (isEditForm: boolean) => void;
    isCanEdit: boolean;
}

const StatusOrder: FC<IStatusOrder> = ({ setIsEditForm, setOrder, order, isCanEdit }) => {
    const intl = useIntl();
    const [isOpenStatuses, setIsOpenStatuses] = useState(false);
    const orderStatus = order?.[ORDER_FIELDS.status];
    const dispatch = useAppDispatch();
    const hideStatusesList = () => setIsOpenStatuses(false);

    const toggleOpenedStatuses = () => setIsOpenStatuses(!isOpenStatuses);

    const statusRef = useOutsideClick(hideStatusesList);

    const arrayStatuses = Object.entries(getUnprocessableOrderStatuses(intl)).map((status) => ({
        name: status[0],
        value: status[1],
    }));

    const currentStatusValue = arrayStatuses.find((status) => orderStatus === status.name)?.value;

    const onClickItemStatusesList = async (chooseStatus: ProcessableOrderStatuses | UnprocessableOrderStatuses) => {
        const dataRequest = {
            id: order[ORDER_FIELDS.id],
            status: chooseStatus,
        };
        const resultUpdateStatus = await dispatch(updateOrderStatus(dataRequest));
        // @ts-ignore
        if (!resultUpdateStatus.payload.error as any) {
            // @ts-ignore
            setOrder({ ...order, [ORDER_FIELDS.status]: chooseStatus });
            setIsEditForm(false);
        }
        hideStatusesList();
    };

    return (
        <StatusWrapper>
            {intl.formatMessage({ defaultMessage: 'Статус' })}
            <StatusValueWrapper ref={statusRef}>
                {order ? (
                    <StatusButton type="button" onClick={isCanEdit ? toggleOpenedStatuses : undefined}>
                        {currentStatusValue}
                        {isCanEdit && <IconOpen />}
                    </StatusButton>
                ) : (
                    <Skeleton width={80} />
                )}

                {isOpenStatuses && (
                    <StatusesList>
                        {arrayStatuses.map((status) => (
                            <StatusListItem
                                type="button"
                                onClick={
                                    status.name === orderStatus
                                        ? undefined
                                        : () => {
                                              onClickItemStatusesList(
                                                  status.name as ProcessableOrderStatuses | UnprocessableOrderStatuses,
                                              );
                                          }
                                }
                                key={status.name}
                                isActiveStatus={status.name === orderStatus}
                            >
                                {status.value}
                            </StatusListItem>
                        ))}
                    </StatusesList>
                )}
            </StatusValueWrapper>
        </StatusWrapper>
    );
};

export default StatusOrder;
