import React, { useEffect } from 'react';
import { BusinessOfficeAuthRouter, BusinessOfficeNotAuthRouter } from 'src/pages/routes';
import { useSelector } from 'react-redux';
import { getUser } from 'src/store/Auth/selectors';
import { useAppDispatch } from 'src/store';
import { initializeDictionaries } from 'src/store/Dictionaries';
import Footer from '../components/Footer';

const BusinessOfficeRoutes = () => {
    const user = useSelector(getUser);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(initializeDictionaries());
    }, [dispatch]);

    return (
        <>
            {user ? <BusinessOfficeAuthRouter user={user} /> : <BusinessOfficeNotAuthRouter />}
            <Footer />
        </>
    );
};

export default BusinessOfficeRoutes;
