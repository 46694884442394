import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { Header } from '../../../../../stories/docsTable/Header';
import { getAllCounterparties } from '../../../../../store/Counterparty/selectors';
import { OrganizationSelect } from '../../../../../stories/docsTable/OrganizationSelect';
import { SubHeader } from '../../../../../stories/docsTable/SubHeader';
import { Table } from '../../../../../stories/docsTable/Table';
import { DateRangePicker } from '../../../../../stories/docsTable/DateRangePicker';
import { Footer } from '../../../../../stories/docsTable/Footer';
import { SwitcherCountRowsOnPage } from '../../../../../stories/docsTable/SwitcherCountRowsOnPage';
import { useData } from './hooks/useData';

type TProps = {
    addCounterparty: () => void;
};

const OrderContainerTable: FC<TProps> = ({ addCounterparty }) => {
    const intl = useIntl();

    const headLabels = [
        { label: intl.formatMessage({ id: '№ счёта', defaultMessage: '№ счёта' }), name: 'merchantOrderIdVisible' },
        { label: intl.formatMessage({ id: 'Сумма', defaultMessage: 'Сумма' }), name: 'originalAmount' },
        { label: intl.formatMessage({ id: 'Дата', defaultMessage: 'Дата' }), name: 'createdAt' },
        { label: intl.formatMessage({ id: 'Статус', defaultMessage: 'Статус' }), name: 'status' },
    ];

    const counterparties = useSelector(getAllCounterparties);

    const [selectedCounterparty, setSelectedCounterparty] = useState(counterparties[0]);
    const [selectedRowIds, setSelectedRowIds] = useState<Array<string>>([]);

    const handleSelectedRowsReset = () => setSelectedRowIds([]);

    const {
        orderContainerList,
        isLoading,
        isInitialized,
        metaData,
        goToNextPage,
        goToPrevPage,
        handlePageSizeChange,
        handleCreatedAtFilterChange,
        createdAtFilter,
        pageSizeOptions,
        pageSize,
    } = useData(selectedCounterparty);

    const counterpartyOptions = counterparties.map((counterpartyItem) => ({
        label: counterpartyItem.name,
        value: counterpartyItem.id,
    }));

    const handleSelectOrganization = (id: string) => {
        const findOrganization = counterparties.find((counterpartyItem) => counterpartyItem.id === id);
        if (findOrganization) {
            setSelectedCounterparty(findOrganization);
            handleCreatedAtFilterChange(null);
        }
    };

    const rows = orderContainerList.map((orderContainerItem) => ({
        ...orderContainerItem,
        id: orderContainerItem.id,
        merchantOrderIdVisible: orderContainerItem.merchantOrderIdVisible,
        originalAmount: orderContainerItem.formattedOriginalAmount(),
        createdAt: `${intl.formatMessage({
            id: 'от',
            defaultMessage: 'от',
        })} ${orderContainerItem.formattedCreatedAt()}`,
    }));

    return (
        <S.Wrapper>
            <Header
                vatNumber={selectedCounterparty.vatNumber}
                taxRegistrationReasonCode={selectedCounterparty.taxRegistrationReasonCode}
            >
                <OrganizationSelect
                    onClickAddOrganization={addCounterparty}
                    onSelectOrganization={handleSelectOrganization}
                    value={selectedCounterparty.id}
                    options={counterpartyOptions}
                />
            </Header>
            <Divider />
            <SubHeader
                countLineSelected={selectedRowIds.length}
                onReset={selectedRowIds.length ? handleSelectedRowsReset : undefined}
            >
                <DateRangePicker value={createdAtFilter} onChange={handleCreatedAtFilterChange} />
            </SubHeader>
            <Table
                isLoading={isLoading}
                headLabels={headLabels}
                rows={rows}
                selectedRowIds={selectedRowIds}
                onSelectRows={setSelectedRowIds}
                isInitialized={isInitialized}
            >
                <Footer metaData={metaData} onNextPage={goToNextPage} onPrevPage={goToPrevPage}>
                    <SwitcherCountRowsOnPage
                        options={pageSizeOptions}
                        value={pageSize}
                        onChange={handlePageSizeChange}
                    />
                </Footer>
            </Table>
        </S.Wrapper>
    );
};

export default OrderContainerTable;
