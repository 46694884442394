import React, { FC } from 'react';
import {
    MobileSlideWrapper,
    Paragraph,
    Paragraphs,
    Title,
} from 'src/pages/businessOffice/Claim/components/MobileSlide/styles';

interface IMobileSlide {
    title: string;
    paragraphs: string[];
}

const MobileSlide: FC<IMobileSlide> = ({ title, paragraphs }) => (
    <MobileSlideWrapper>
        <Title variant="title">{title}</Title>
        <Paragraphs>
            {paragraphs.map((paragraph) => (
                <Paragraph variant="regular14" key={paragraph}>
                    {paragraph}
                </Paragraph>
            ))}
        </Paragraphs>
    </MobileSlideWrapper>
);

export default MobileSlide;
