import styled, { DefaultTheme } from 'styled-components';
import { COLOR_BRIGHTNESS } from 'src/theme/utils';

interface ILabelProps {
    position: 'left' | 'right';
    isActive?: boolean;
    disabled?: boolean;
}

interface IInputProps {
    isHovered: boolean;
}

interface IGetToggleColorProps {
    theme: DefaultTheme;
    isActive: boolean;
    isHovered: boolean;
    disabled?: boolean;
}

const getToggleBackground = ({ theme, isActive, isHovered, disabled }: IGetToggleColorProps): string => {
    if (disabled) return 'transparent';
    if (isActive) {
        return isHovered ? theme.colors.hoverYellow() : theme.colors.yellow();
    }
    return isHovered ? theme.colors.base(COLOR_BRIGHTNESS.MEDIUM) : theme.colors.base(COLOR_BRIGHTNESS.LIGHT);
};

export const Wrapper = styled.div`
    display: flex;
    width: fit-content;
`;

export const StyledInput = styled.input<IInputProps>`
    position: relative;
    width: 26px;
    min-width: 26px;
    height: 16px;
    vertical-align: top;
    border-radius: 30px;
    outline: none;
    transition: ${({ theme }) => theme.decorations.transition.base};
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.colors.base(COLOR_BRIGHTNESS.LIGHT) : 'transparent')};
    background-color: ${({ theme, checked, disabled, isHovered }) =>
        getToggleBackground({ theme, isActive: checked || false, isHovered, disabled })};

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        transform: ${({ checked }) => `translateX(${checked ? 10 : 0}px)`};
        background-color: ${({ theme }) => theme.colors.white()};
        transition: ${({ theme }) => theme.decorations.transition.base};
        box-shadow: ${({ disabled }) => (disabled ? '0px 3px 8px rgba(0, 0, 0, 0.15)' : 'none')};
    }

    &:checked::after {
        transform: translateX(10px);
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const Label = styled.label`
    ${({ theme }) => theme.mixins.flexCenter};
    cursor: pointer;
`;

export const LabelText = styled.span<ILabelProps>`
    ${({ theme }) => theme.mixins.getTypography('regular12')}
    margin-right: ${({ position }) => (position === 'left' ? 10 : 0)}px;
    margin-left: ${({ position }) => (position === 'right' ? 10 : 0)}px;
    transition: ${({ theme }) => theme.decorations.transition.base};
    color: ${({ theme, isActive, disabled }) => (isActive && !disabled ? theme.colors.base() : theme.colors.grey())};
`;
