import { IMerchant } from '../Merchant/types';
import { MERCHANT_FIELDS } from '../Merchant/contants';

export enum typeContract {
    template = 'template',
    contract = 'contract',
}

export enum ContractStatuses {
    draft = 'draft',
    moderating = 'moderating',
    moderated = 'moderated',
    canceled = 'canceled',
    blocked = 'blocked',
    signed = 'signed',
}

export enum ContractTemplateCode {
    acquiring = 'acquiring',
    invoicing = 'invoicing',
    full = 'full',
}

export type TContractStatus = keyof typeof ContractStatuses;
export type TContractTemplateCode = keyof typeof ContractTemplateCode;

export interface IContract {
    id: number;
    counterpartyId: string;
    systemCounterpartyId: string;
    status: TContractStatus;
    reportPeriod: string;
    settlementPeriod: string;
    settlementMinAmount: number;
    settlementType: string;
    settlementHour: string;
    settlementCurrencyId: string;
    parentId: number;
    deleted: boolean;
    currentId: number;
    templateId: number;
    number: string;
    date?: string;
    startAt?: string;
    finishAt?: string;
    active: boolean;
    code: TContractTemplateCode;
    title: string;
    shortDescription: string;
    fullDescription: string;
    minFeePercent: number;
    maxInvoiceAmount: number;
    type: typeContract;
    bankDetailId: number;
}

export type TContractWithMerchantInfo = IContract & {
    merchants: Array<
        Pick<
            IMerchant,
            MERCHANT_FIELDS.id | MERCHANT_FIELDS.merchantContractId | MERCHANT_FIELDS.name | MERCHANT_FIELDS.status
        >
    >;
};

export interface ICreateOrUpdateContractInfo {
    counterpartyId: string;
    parentId?: number;
    templateId?: number;
    bankDetailId?: number;
    status?: ContractStatuses;
}
