import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Typography from 'src/components/Typography';
import CircleIcon from 'src/components/CircleIcon';
import { ReactComponent as ArrowIcon } from './assets/arrow-right.svg';
import { ReactComponent as ErrorIcon } from './assets/error-icon.svg';

interface IProductCardProps {
    title: string;
    icon: ReactNode;
    onClick?: () => void;
    withBadge?: boolean;
    label?: string;
    hasWarning?: boolean;
}

const ProductCard: FC<IProductCardProps> = ({ title, label, icon, onClick, withBadge = false, hasWarning }) => (
    <Wrapper as={onClick ? 'button' : 'div'} onClick={onClick}>
        <IconWrapper>
            <CircleIcon icon={icon} withBadge={withBadge} />
        </IconWrapper>
        <Title variant="subheading">{title}</Title>
        <LabelWrapper>
            {hasWarning ? (
                <ErrorIconWrapper>
                    <ErrorIcon />
                </ErrorIconWrapper>
            ) : (
                label && <Label variant="regular12">{label}</Label>
            )}
        </LabelWrapper>
        <ArrowWrapper>
            <ArrowIcon />
        </ArrowWrapper>
    </Wrapper>
);

export default ProductCard;

const Wrapper = styled.button`
    padding: 21px;
    position: relative;
    ${({ theme }) => theme.mixins.flexCenter};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 12px;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
    }
`;

const IconWrapper = styled.div`
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column: 1/2;
    }
`;

const Title = styled(Typography)`
    flex: 1;
    margin-left: 20px;
    margin-right: auto;
    padding-right: 10px;

    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular14')};
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-left: 0;
        margin-top: 9px;
        margin-bottom: auto;
        max-width: 100px;
    }
`;

const LabelWrapper = styled.div`
    margin-right: 17px;

    @media ${({ theme }) => theme.breakpoints.md} {
        margin-right: 10px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-row: 1/2;
        grid-column: 2/3;
        margin-right: 0;
    }
`;

const Label = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
`;

const ArrowWrapper = styled.div`
    display: flex;

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-left: auto;
    }
`;

const ErrorIconWrapper = styled.div`
    display: flex;

    svg {
        width: 12px;
        height: 12px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        position: absolute;
        top: 12px;
        right: 10px;
    }
`;
