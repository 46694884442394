import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ClientsWrapper = styled.div`
    padding-top: 54px;
    width: 100%;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 50px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 30px;
    }
`;

export const ClientsHeader = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 30px;
    margin-bottom: 30px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-direction: column-reverse;
        grid-row-gap: 30px;
    }
`;

export const ClientsListWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    padding: 30px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 0;
    }
`;
export const ClientsListHeader = styled.div`
    ${({ theme }) => theme.mixins.getTypography('subheading')}
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 30px 20px 0 30px;
    }
`;

export const SelectorsTypeClient = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-gap: 10px;
    }
`;

interface ISelectorTypeClient {
    isActive: boolean;
}

export const SelectorTypeClient = styled.button<ISelectorTypeClient>`
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    color: ${({ theme, isActive }) => (isActive ? theme.colors.base() : theme.colors.grey())};
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
    }
`;

export const EmptyClients = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: center;
`;
