import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const LabelWrapper = styled.div``;

export const LabelField = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const ValueField = styled(Typography)`
    margin-top: 7px;
`;
