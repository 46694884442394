import React from 'react';
import {
    Description,
    SkeletonIcon,
    SkeletonOrderWrapper,
    WrapperPriceDate,
} from 'src/components/Widgets/MerchantWidget/components/SkeletonOrderList/styles';
import { COUNT_ORDERS_MERCHANT_DASHBOARD } from 'src/utils/constants';

const SkeletonOrder = () => (
    <SkeletonOrderWrapper>
        <SkeletonIcon />
        <Description />
        <WrapperPriceDate>
            <Description />
            <Description />
        </WrapperPriceDate>
    </SkeletonOrderWrapper>
);

const SkeletonOrderList = () => {
    const arraySkeletonOrders = new Array(COUNT_ORDERS_MERCHANT_DASHBOARD).fill(SkeletonOrder);

    return (
        <>
            {arraySkeletonOrders.map((SkeletonComponent, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SkeletonComponent key={index} />
            ))}
        </>
    );
};

export default SkeletonOrderList;
