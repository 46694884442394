import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSWR } from 'src/hooks/useSWR';
import { getMerchantById } from 'src/store/Merchant/selectors';
import { nameToInitialsFormatter } from 'src/utils/formatters';
import { IMerchantFile } from 'src/store/Merchant/types';
import { Logo, LogoImg, LogoInitials, MerchantLogoSkeleton } from './styles';

export enum enumSizeLogo {
    SMALL = 'small',
    MEDIUM = 'medium',
}

export const sizeLogo = {
    [enumSizeLogo.SMALL]: '19px',
    [enumSizeLogo.MEDIUM]: '36px',
};

export const sizeLogoMobile = {
    [enumSizeLogo.SMALL]: '18px',
    [enumSizeLogo.MEDIUM]: '30px',
};

interface IBaseWidgetProps {
    merchantId: string;
    size?: enumSizeLogo;
}

const MerchantLogo: FC<IBaseWidgetProps> = ({ merchantId, size = enumSizeLogo.MEDIUM }) => {
    const [showImg, setShowImg] = useState(true);
    const merchant = useSelector(getMerchantById(merchantId));
    const { data: dataLogo, error } = useSWR<Array<IMerchantFile>>(
        merchant?.logoImageId ? `filter/api/file/file?id=${merchant?.logoImageId}` : null,
    );

    const hideImg = () => {
        setShowImg(false);
    };

    const isShowSkeleton = merchant?.logoImageId && dataLogo === undefined && error === undefined;
    const isShowImage = merchant?.logoImageId && showImg && dataLogo?.data?.[0]?.url && !error;

    if (isShowSkeleton) return <MerchantLogoSkeleton size={size} />;

    if (isShowImage) {
        return (
            <Logo size={size}>
                <LogoImg
                    size={size}
                    src={dataLogo?.data[0].url}
                    onError={hideImg}
                    alt={merchant && nameToInitialsFormatter(merchant.name)}
                />
            </Logo>
        );
    }

    return (
        <Logo size={size}>
            <LogoInitials size={size}>{merchant && nameToInitialsFormatter(merchant.name)}</LogoInitials>
        </Logo>
    );
};

export default MerchantLogo;
