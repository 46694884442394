import React, { FC } from 'react';
import {
    ButtonRemoveClientCounterparty,
    CounterpartyClientTitle,
    CounterpartyClientWrapper,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/DetailClientCounterparty/styles';
import { useSelector } from 'react-redux';
import { getCounterpartyClientById } from 'src/store/ClientCounterparty/selectors';
import SettingsWidget from 'src/components/Widgets/SettingsWidget';
import ClientCounterpartyInformation from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/ClientCounterpartyInformation';
import RelatedContacts from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/RelatedContacts';
import { ReactComponent as IconDelete } from 'src/assets/icons/delete.svg';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useAppDispatch } from 'src/store';
import { useHistory, useParams } from 'react-router-dom';
import { removeClientCounterparty } from 'src/store/ClientCounterparty';
import { useIntl } from 'react-intl';

interface IDetailClientCounterparty {
    idClientCounterparty?: number;
}

export const FORM_CREATE_CLIENT_COUNTERPARTY = 'form_create_client_counterparty';

const DetailClientCounterparty: FC<IDetailClientCounterparty> = ({ idClientCounterparty }) => {
    const intl = useIntl();
    const clientCounterparty = useSelector(getCounterpartyClientById(idClientCounterparty));
    const dispatch = useAppDispatch();
    const { merchantId } = useParams<{ merchantId: string }>();
    const history = useHistory();

    const onClickRemoveClientCounterparty = async () => {
        const result = await dispatch(removeClientCounterparty(String(idClientCounterparty)));
        // @ts-ignore
        if (!result?.error && result?.payload) {
            history.push(`${RoutesBusinessOffice.shop}/${merchantId}${RoutesBusinessOffice.clients}`);
        }
    };

    return (
        <CounterpartyClientWrapper>
            <CounterpartyClientTitle variant="bigTitle">
                {intl.formatMessage({ defaultMessage: 'Карточка организации' })}
            </CounterpartyClientTitle>
            <SettingsWidget
                isEditDefault={Boolean(!clientCounterparty)}
                title={intl.formatMessage({ defaultMessage: 'Организация' })}
                formId={FORM_CREATE_CLIENT_COUNTERPARTY}
            >
                <ClientCounterpartyInformation clientCounterparty={clientCounterparty} />
            </SettingsWidget>
            {clientCounterparty && (
                <>
                    <RelatedContacts clientCounterparty={clientCounterparty} />
                    <ButtonRemoveClientCounterparty
                        onClick={onClickRemoveClientCounterparty}
                        icon={<IconDelete />}
                        type="secondary"
                    >
                        {intl.formatMessage({ defaultMessage: 'Удалить организацию' })}
                    </ButtonRemoveClientCounterparty>
                </>
            )}
        </CounterpartyClientWrapper>
    );
};

export default DetailClientCounterparty;
