import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import GlobalStyle from './globalStyles/GlobalStyle';
import theme from './theme';
import store from './store';
import Pages from './pages';
import ruMessages from './i18/ru';
import ReCaptchaTokenContext from './context/ReCaptchaTokenContext';

export const locale = 'ru';

const App = () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}>
                <ReCaptchaTokenContext>
                    <IntlProvider locale={locale} messages={ruMessages}>
                        <GlobalStyle />
                        <HelmetProvider>
                            <Pages />
                        </HelmetProvider>
                    </IntlProvider>
                </ReCaptchaTokenContext>
            </GoogleReCaptchaProvider>
        </ThemeProvider>
    </Provider>
);

export default App;
