import styled from 'styled-components';
import Modal from 'src/components/Modal';
import FormField from 'src/components/FormField';
import Button from 'src/components/Button';
import { ReactComponent as IconPlus } from 'src/assets/icons/plus.svg';

export const ModalWrapper = styled(Modal)`
    width: 642px;
    padding: 30px 50px 52px 50px;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 10px 20px 30px 20px;
    }
`;

export const InputsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 1fr;
    }
`;

export const FormInner = styled.form``;

export const RegistrationAddress = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-end: 2;
    }
`;

export const SubmitButton = styled(Button)`
    margin: 30px auto 0;
`;

export const IconAdd = styled(IconPlus)`
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;
