import styled from 'styled-components';
import Modal from 'src/components/Modal';
import Typography from 'src/components/Typography';

export const ModalFeedWrapper = styled(Modal)`
    padding: 50px;
    width: 642px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 100%;
        padding: 26px 20px;
    }
`;

export const Title = styled(Typography)`
    margin-bottom: 53px;
`;

export const Description = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
`;
