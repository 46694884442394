import React, { FC, useCallback, useState } from 'react';
import { IAutocompleteDialogProps, ICounterpartyDetails } from 'src/components/InputAutocomplete/types';
import { useForm } from 'react-final-form';
import { useSWR } from 'src/hooks/useSWR';
import { VAT_NUMBER_REQUEST_URL } from 'src/components/InputAutocomplete/constants';
import Input from 'src/components/Input';
import {
    ScrollList,
    ButtonItemList,
    LabelButtonItem,
    InputAutocompleteContainer,
} from 'src/components/InputAutocomplete/styles';
import LoadingIndicator from 'src/components/InputAutocomplete/components/LoadingIndicator';
import { isNull } from 'lodash';
import { getRequestUrlOrNull } from 'src/utils/helpers';
import { INDIVIDUAL_VAT_NUMBER_LENGTH, ORGANISATION_VAT_NUMBER_LENGTH } from 'src/utils/constants';
import { useIntl } from 'react-intl';
import { Scrollbar, useOutsideClick } from '@invoicebox/ui';

const isValidVatNumber = (value: string) =>
    value.length === ORGANISATION_VAT_NUMBER_LENGTH || value.length === INDIVIDUAL_VAT_NUMBER_LENGTH;

const InputAutocompleteVat: FC<IAutocompleteDialogProps> = ({ autoFill, name, value, label, ...props }) => {
    const { change } = useForm();
    const intl = useIntl();

    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const isValidVat = isValidVatNumber(value);
    const params = `?${name.split('.').pop()}=${value}`;
    const requestUrl = getRequestUrlOrNull(VAT_NUMBER_REQUEST_URL + params, isValidVat);
    const { data, error } = useSWR<Array<ICounterpartyDetails>>(requestUrl);

    const counterpartiesIsLoading = !data && !error && !isNull(requestUrl);
    const counterparties = data?.data ?? [];

    const closeVatsWindow = useCallback(() => {
        if (isShowDropdown) {
            setIsShowDropdown(false);
        }
    }, [isShowDropdown]);

    const vatsRef = useOutsideClick(closeVatsWindow);

    const handleSelect = (details: ICounterpartyDetails) => {
        if (autoFill) {
            const fillResults = autoFill(details);
            Object.keys(fillResults).forEach((key) => {
                change(key, fillResults[key]);
            });
        } else {
            change(name, details.vatNumber);
        }

        closeVatsWindow();
    };

    return (
        <InputAutocompleteContainer
            ref={vatsRef}
            onFocus={() => {
                setIsShowDropdown(true);
            }}
        >
            <Input name={name} label={label} value={value} {...props} />
            {isShowDropdown && (
                <ScrollList>
                    <Scrollbar maxHeight={120}>
                        {counterpartiesIsLoading && <LoadingIndicator />}
                        {counterparties.length > 0 &&
                            counterparties.map((listItem: ICounterpartyDetails) => (
                                <ButtonItemList
                                    type="button"
                                    key={listItem.name}
                                    onClick={() => handleSelect(listItem)}
                                >
                                    <LabelButtonItem variant="regular12">{listItem.name}</LabelButtonItem>
                                </ButtonItemList>
                            ))}
                    </Scrollbar>
                </ScrollList>
            )}
            {value && !isValidVat && counterparties.length === 0 && !counterpartiesIsLoading && (
                <LabelButtonItem variant="regular12">
                    {intl.formatMessage({ defaultMessage: 'Введите корректный ИНН (10 или 12 цифр)' })}
                </LabelButtonItem>
            )}
        </InputAutocompleteContainer>
    );
};

export default InputAutocompleteVat;
