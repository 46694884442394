import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { ReactComponent as ArrowIcon } from './assets/arrow-right.svg';
import { RoutesBusinessOffice, RoutesDocs } from '../../constants';
import DocsContentWrapper from '../../../components/DocsContentWrapper';
import DocumentReceiveMethods from './components/DocumentReceiveMethods';
import OrderContainerTable from './components/OrderContainerTable';

const DashboardDocs = () => {
    const intl = useIntl();
    const history = useHistory();

    const handleAddCounterparty = () => {
        history.push(RoutesBusinessOffice.organizations);
    };

    return (
        <div>
            <S.Header>
                <Typography variant="headline2">
                    {intl.formatMessage({ defaultMessage: 'Запрос отчётных документов' })}
                </Typography>
                <S.ButtonCheckDocumentsStatus to={RoutesDocs.statuses}>
                    <S.CheckStatusLabel variant="captionRegular">
                        {intl.formatMessage({ defaultMessage: 'Проверка статуса отправления доументов' })}
                    </S.CheckStatusLabel>
                    <S.ArrowWrapper>
                        <ArrowIcon />
                    </S.ArrowWrapper>
                </S.ButtonCheckDocumentsStatus>
            </S.Header>
            <DocsContentWrapper>
                <OrderContainerTable addCounterparty={handleAddCounterparty} />
                <S.DocumentReceiveMethodsWrapper>
                    <DocumentReceiveMethods documentId="documentId" />
                </S.DocumentReceiveMethodsWrapper>
            </DocsContentWrapper>
        </div>
    );
};

export default DashboardDocs;
