import React, { FC } from 'react';
import { ICounterpartyBankDetail } from 'src/store/Counterparty/types';
import { useIntl } from 'react-intl';
import {
    BankNameWrapper,
    BicWrapper,
    ButtonBankCardWrapper,
    CorrespondentAccountWrapper,
    Label,
    SettlementAccountWrapper,
    Value,
} from './styles';

interface IButtonBankCard {
    bank: ICounterpartyBankDetail;
    setSelectedBank?: (arg: ICounterpartyBankDetail) => void;
    selectedBankId?: number;
}

const ButtonBankCard: FC<IButtonBankCard> = ({ bank, setSelectedBank, selectedBankId }) => {
    const intl = useIntl();

    const onClickToBank = () => {
        if (setSelectedBank) {
            setSelectedBank(bank);
        }
    };

    return (
        <ButtonBankCardWrapper
            type="button"
            onClick={onClickToBank}
            isActive={bank.id === selectedBankId}
            key={bank.id}
        >
            <BankNameWrapper>
                <Label variant="regular12">{intl.formatMessage({ defaultMessage: 'Банк' })}</Label>
                <Value variant="regular14">{bank.bankName}</Value>
            </BankNameWrapper>
            <CorrespondentAccountWrapper>
                <Label variant="regular12">{intl.formatMessage({ defaultMessage: 'Номер кор.счёта' })}</Label>
                <Value variant="regular14">{bank.correspondentAccount}</Value>
            </CorrespondentAccountWrapper>
            <SettlementAccountWrapper>
                <Label variant="regular12">{intl.formatMessage({ defaultMessage: 'Номер рассч.счёта' })}</Label>
                <Value variant="regular14">{bank.settlementAccount}</Value>
            </SettlementAccountWrapper>
            <BicWrapper>
                <Label variant="regular12">{intl.formatMessage({ defaultMessage: 'БИК' })}</Label>
                <Value variant="regular14">{bank.bic}</Value>
            </BicWrapper>
        </ButtonBankCardWrapper>
    );
};

export default ButtonBankCard;
