import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';

export const NavLinkCreateClient = styled(NavLink)`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-shrink: 0;
    grid-column-gap: 10px;
    background: ${({ theme }) => theme.colors.yellow()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    text-decoration: none;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    height: 46px;
    width: 160px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
    }
`;

export const IconAdd = styled(PlusIcon)`
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;
