import styled from 'styled-components';
import Skeleton from 'src/components/Skeleton';

export const SkeletonFeedItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 36px 1fr 35px;
    align-items: center;
    grid-column-gap: 28px;
    padding: 20px 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    &:last-child {
        border: none;
    }
`;

export const SkeletonIcon = styled(Skeleton).attrs({ circle: true })`
    width: 36px;
    height: 36px;
`;

export const SkeletonTitle = styled(Skeleton)`
    width: 60%;
    height: 16px;
`;

export const SkeletonDate = styled(Skeleton)`
    width: 100%;
    height: 16px;
`;
