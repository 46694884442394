import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const InvisibleButtons = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
`;

export const InvisibleButtonHalf = styled.button`
    width: 100%;
    height: 100%;
`;

export const SlideBackground = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
`;

export const SlideContent = styled.div``;

interface ISlideTitle {
    isDark: boolean;
}

export const SlideTitle = styled(Typography)<ISlideTitle>`
    color: ${({ theme, isDark }) => (isDark ? theme.colors.darkest() : theme.colors.white())};
    margin-bottom: 17px;
    position: relative;
    z-index: 2;

    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
`;

export const SlideDescription = styled(Typography)<ISlideTitle>`
    color: ${({ theme, isDark }) => (isDark ? theme.colors.darkest() : theme.colors.white())};
    position: relative;
    z-index: 2;

    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
`;
